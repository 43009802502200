<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'"  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Status</th>
      <th>Purchase No</th>
      <th>Quotation No</th>
      <th>Requisition No</th>
      <th>Created By</th>
      <th>Create Date</th>
      <th>Quotation Date</th>
      <th>Requisition Date</th>
      <th>Supplier Name</th>
      <th v-if="!isPrinting && isPermissionExist(PERMISSIONS.SERVICE_RECEIVE_ITEMS)">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(delivery , index) in deliveryData" :key="delivery.id">
      <td>{{++index}}</td>
      <td>{{delivery.status}} 1</td>
      <td>{{delivery.po_no}}</td>
      <td>  <span v-if="delivery.quotation"> {{delivery.quotation.quotation_no}}  </span> </td>
      <td> <span v-if="delivery.requisition"> {{delivery.requisition.requisition_no}} </span>  </td>
      <td>{{delivery.user.username}}</td>
      <td>{{delivery.date}}</td>
      <td> <span v-if="delivery.quotation"> {{delivery.quotation.date}} </span> </td>
      <td> <span v-if="delivery.requisition"> {{delivery.requisition.date}} </span>  </td>
      <td><span v-if="delivery.supplier">{{delivery.supplier.name}}</span></td>
      <td v-if="!isPrinting && isPermissionExist(PERMISSIONS.SERVICE_RECEIVE_ITEMS)">
        <button type="button" v-if="isPermissionExist(PERMISSIONS.SERVICE_RECEIVE_ITEMS)" @click="getPurchaseItemData(delivery.id)" class="btn btn-success btn-sm" title="Receive items"><i class="fa fa-calculator"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    deliveryData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    getPurchaseItemData: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
