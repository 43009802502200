import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

//install vue js moment
Vue.use(require("vue-moment"));

//initialize vue v-form class
import {Form, HasError, AlertError, AlertSuccess} from 'vform'

window.Form = Form;

//initialize vue notification package
import Snotify, {SnotifyPosition} from 'vue-snotify'
import 'vue-snotify/styles/material.css'

//notification position setup
const SnotifyOptions = {
  toast: {
    position: SnotifyPosition.rightTop
  }
};
Vue.use(Snotify, SnotifyOptions);

import VuejsDialog from "vuejs-dialog";
// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";
// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "/print.css",
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
  ]
};
Vue.use(VueHtmlToPaper, options);

import auth from '@/auth'

//default header set
axios.defaults.headers.common["Authorization"] = "Bearer " + auth.getToken();
axios.defaults.headers.common["LoggedAdminId"] = auth.getUserId();
axios.defaults.headers.common["LoggedAdminVesselId"] = auth.getVesselId();
axios.defaults.headers.common["LoggedAdminRoleId"] = auth.getUserType();

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'content-type': 'multipart/form-data'
  }
});

Form.axios = instance;
Vue.prototype.$http = instance;
Vue.config.productionTip = false;
Vue.prototype.$isSideBarpermission = true;
Vue.prototype.$baseURL = process.env.VUE_APP_API_URL;

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component(AlertSuccess.name, AlertSuccess);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
