<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Opening Stock</th>
<!--      <th>Impa Code</th>-->
<!--      <th>Model</th>-->
<!--      <th>Maker</th>-->
      <th>Stock In</th>
      <th>Stock Out(Used)</th>
      <th>Closing Stock</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td></td>
      <td>{{item?.opening_stock_date | moment('DD/MM/YYYY') }}</td>
      <td>{{item?.opening_stock}}</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr v-for="(stock, index) in stockLedgerData" :key="index">
      <td>{{++index}}</td>
      <td>{{ stock?.date | moment('DD/MM/YYYY') }}</td>

      <td> </td>
<!--      <td>{{stock?.inpa_code}}</td>-->
<!--      <td>{{stock?.model}}</td>-->
<!--      <td>{{stock?.maker}}</td>-->
      <td> <span v-if="stock?.in_qty">{{stock.in_qty}}</span><span v-else>-</span></td>
      <td><span v-if="stock?.out_qty">{{stock.out_qty}}</span><span v-else>-</span></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td> </td>
      <td > </td>
      <td></td>
      <td></td>
      <td>{{stockClosingData}}</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    stockLedgerData: {
      required: true
    },
    item: {
      required: true
    },
    stockClosingData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    invoiceView: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
