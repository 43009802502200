import {printUtil} from "@/utils/print";


export default {
  methods: {
    print (id, title = '') {
      printUtil(id, title);
    },
  },
};
