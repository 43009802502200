<template>
  <div>
    <div class="row">
      <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
        <PulseLoader :loading="loading"></PulseLoader>
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class>
            <div class="x_panel">
              <div class="row">
                <div class="col-md-2">
                  <div>
                    <div class="form-group">
                      <select v-model="budget_year" name="budget_year" class="form-control">
                        <option v-for="year in years" :key="year" selected>{{year}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div>
                    <div class="form-group">
                      <select v-model="budget_month" name="budget_month" class="form-control">
                        <option v-for="sMonth in month" :key="sMonth" selected :value="sMonth">{{sMonth}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <div class="form-group">
                      <label></label>
                      <button title="Search Budget" class="btn btn-info" @click="budgetYearInfo()">Search</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-4">
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                  <button @click="print('printArea','Budget Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                  <router-link to="budget-report" class="btn btn-info btn-sm float-right">Budget Report</router-link>

                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="x_title">
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header text-center">
                <h5 class="text-center">{{vessel_name}}</h5>
                <p>Budget Variance of {{selectedMonth}} {{selectedYear}} </p>
              </div>
              <div class="card-body">
                <div class="table-responsive tableStyle">
                  <TableData :budgetInfoList="budgetInfoList" :createVarianceRemarks="createVarianceRemarks" :selectedMonth="selectedMonth" :selectedYear="selectedYear" :isEmpty="isEmpty"></TableData>
                  <div id="printArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <div class="font-weight-bold">{{vessel_name}}</div>
                      <div class="font-weight-bold">Budget Variance of {{selectedYear}}</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      <TableData :budgetInfoList="budgetInfoList" :isEmpty="isEmpty" :isPrinting="true"></TableData>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create purchase order Modal -->
    <div class="modal fade" id="budgetVarianceModal" tabindex="-1" role="dialog" aria-labelledby="budgetVarianceModalTitle" aria-hidden="true">
      <div class="modal-dialog w-25" role="document">
        <div class="modal-content">
          <form @submit="storeVarianceRemarks" action="#" method="POST">
            <div class="modal-header">
              <h5 class="modal-title" id="budgetVarianceModalTitle">Add {{budget_head_name}} Variance Remarks</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Variance Remarks<span class="text-danger">*</span></label>
                    <input v-model="budgetVarianceForm.remarks" type="text" name="remarks" class="form-control" placeholder="Enter Variance Remarks" autocomplete="off" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="disable" type="submit" class="btn btn-info">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Create purchase order Modal -->
  </div>

</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import auth from "@/auth";
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import ItemTable from "@/components/Item/ItemTable.vue";

    export default {
        name: "BudgetVarianceReport",
        components: {
            ItemTable,
            TableData,
            PulseLoader,
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                loading: false,
                color: "#1ABB9C",
                size: "20px",
                month: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                budgetVarianceForm: {
                    budget_head_id: "",
                    remarks: "",
                    year: '',
                    month: '',
                    vessel_id: auth.getVesselId(),
                },
                isActive: "bg-secondary",
                vessel_name: "",
                budgetHeads: [],
                selectedYear: "",
                selectedMonth: "",
                budget_year: new Date().getFullYear(),
                budget_month: "March",
                budget_head_name: "",
                budgetInfoList: [],
                years: [],
                disable: false,
                vessel_id: auth.getVesselId(),
            };
        },

        mounted() {
            this.yearsGenerate();
            this.veselInfo();
            this.budgetYearInfo();
        },

        methods: {
            yearsGenerate() {
                let i = new Date().getFullYear();
                for (i; i >= 2000; i--) {
                    this.years.push(i);
                }
            },


            createVarianceRemarks(budgetInfo) {
                this.budgetVarianceForm.budget_head_id = budgetInfo.id;
                this.budgetVarianceForm.year = this.selectedYear;
                this.budgetVarianceForm.month = this.selectedMonth;
                this.budget_head_name = budgetInfo.name;
                $('#budgetVarianceModal').modal('show')
            },


            storeVarianceRemarks: function (e) {
                e.preventDefault()
                this.disable = true;
                $('#budgetVarianceModal').modal('show')
                this.$http.post('/api/budget-variance-remarks', this.budgetVarianceForm).then(response => {
                    $('#budgetVarianceModal').modal('hide')
                    this.disable = false;
                    this.budgetVarianceForm.remarks = ''
                    if (response.data.success) {
                        this.budgetYearInfo();
                        this.$snotify.success('Variance Remarks created successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    this.disable = false;
                    let response = e.response
                    let status = e.response.status
                    if (status === 500) {
                        let msg = response.data.error
                        this.$snotify.error(msg)
                    }
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },

            async budgetYearInfo() {
                this.loading = true;
                this.selectedYear = this.budget_year;
                this.selectedMonth = this.budget_month;
                var budgetInfo = await this.$http.get(
                    "/api/budget-report/" +
                    this.budget_year +
                    "/" +
                    this.budget_month +
                    "/" +
                    this.vessel_id
                );
                console.log(budgetInfo)
                this.budgetInfoList = [];
                this.budgetInfoList = budgetInfo.data.success;
                this.loading = false;
            },
            print(id, title = '') {
                printUtil(id, title)
            },
            veselInfo() {
                this.$http.get("/api/vessel/" + auth.getVesselId()).then((response) => {
                    this.vessel_name = response.data.success.name;
                }).catch((e) => {
                    console.log(e);
                });
            },

            isEmpty(obj) {
                if (obj.length < 1) {
                    return false;
                } else {
                    return true;
                }
            },
            goBack() {
                this.$router.go(-1);
            }
        },
    };
</script>

<style scoped>
  /* .list-group{
      display: block !important;
  } */
  .x_title {
    border-bottom: none !important;
  }

  .tableStyle {
    max-height: 450px;
    overflow: auto;
  }
</style>
