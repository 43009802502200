<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left"> Sub Sub Component List</h2>
              <ul class="nav navbar-right panel_toolbox">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th> Name</th>
                          <th> Code</th>
                          <th> Sub Component Name</th>
                          <th> Component Name</th>
                          <th> Main Component Name</th>
                          <th>Department Name</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(subsubcomponent , index) in subsubcomponents" :key="subsubcomponent.id">
                          <td>{{index+1}}</td>
                          <td>{{subsubcomponent.name}}</td>
                          <td>{{subsubcomponent.code}}</td>
                          <td>{{subsubcomponent?.parent?.name}}</td>
                          <td>{{subsubcomponent?.parent?.parent?.name}}</td>
                          <td>{{subsubcomponent?.maincomponent?.name}}</td>
                          <td>{{subsubcomponent?.department?.name}}</td>

                          <td>
                            <button type="button" @click="show(subsubcomponent)" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                            <button type="button" @click="edit(subsubcomponent)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                            <button type="button" @click="destroy(subsubcomponent)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                            <button title="Copy Component" type="button" @click="subSubComponentnCopy(subsubcomponent)" class="btn btn-success btn-sm"><i class="fa fa-clone"
                                                                                                                                                          aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Sub Sub Component </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-12">

                    <div class="form-group">
                      <label>Department Name</label>
                      <select required v-model="form.department_id" name="department_id" class="form-control" :class="{ 'is-invalid': form.errors.has('department_id') }"
                              @change="mainComponent">
                        <option value="" selected>Select department</option>
                        <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{sdepartment.name}}</option>
                      </select>
                      <has-error :form="form" field="department_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Main Component </label>
                      <select required v-model="form.main_component_id" name="main_component_id" class="form-control" :class="{ 'is-invalid': form.errors.has('main_component_id') }"
                              @change="Component">
                        <option value="" selected>Select component</option>
                        <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{mainComponent.name}}</option>
                      </select>
                      <has-error :form="form" field="main_component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Component </label>
                      <select required v-model="component_id" name="component_id" class="form-control" :class="{ 'is-invalid': form.errors.has('component_id') }" @change="subComponent">
                        <option value="" selected>Select component</option>
                        <option v-for="component in components" :key="component.id" :value="component.id">{{component.name}}</option>
                      </select>
                      <has-error :form="form" field="component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Sub Component </label>
                      <select required v-model="form.parent_id" name="parent_id" class="form-control" :class="{ 'is-invalid': form.errors.has('parent_id') }">
                        <option value="" selected>Select component</option>
                        <option v-for="subcomponent in subcomponents" :key="subcomponent.id" :value="subcomponent.id">{{subcomponent.name}}</option>
                      </select>
                      <has-error :form="form" field="parent_id"></has-error>
                    </div>


                    <div class="form-group">
                      <label> Name</label>
                      <input required autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                      <has-error :form="form" field="name"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Code</label>
                      <input required autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                      <has-error :form="form" field="code"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Status</label>
                      <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                        <option value="Activate">Activate</option>
                        <option value="Deactivate">Deactivate</option>
                      </select>
                      <has-error :form="form" field="status"></has-error>
                    </div>

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade" id="copyModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Sub Sub Component </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-12">

                    <div class="form-group">
                      <label> Name</label>
                      <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                      <has-error :form="form" field="name"></has-error>
                    </div>

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="showModalLabel">{{ form.name }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{{ form.name }}</td>
                  </tr>
                  <tr>
                    <th>Manual Id</th>
                    <td>{{ form.manual_id }}</td>
                  </tr>
                  <tr>
                    <th>Main Component Name</th>
                    <td>
                      {{showInfo.mainComponentName}}
                    </td>
                  </tr>
                  <tr>
                    <th> Component Name</th>
                    <td>
                      {{showInfo.ComponentName}}
                    </td>
                  </tr>
                  <tr>
                    <th>Sub Component Name</th>
                    <td>{{ showInfo.subComponentName }}</td>
                  </tr>

                  <tr>
                    <th>Code</th>
                    <td>{{ form.code }}</td>
                  </tr>
                  <tr>
                    <th>Maker</th>
                    <td>{{ form.maker }}</td>
                  </tr>
                  <tr>
                    <th>Model</th>
                    <td>{{ form.model }}</td>
                  </tr>
                  <tr>
                    <th>Maker Ref Info</th>
                    <td>{{ form.maker_ref_info }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import auth from '../../auth'
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'sub_sub_component',
        components: {PulseLoader},

        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                departments: [],
                mainComponents: [],
                components: [],
                subcomponents: [],
                subsubcomponents: [],
                budgetHeads: [],
                component_id: '',
                form: new Form({
                    id: '',
                    name: '',
                    code: '',
                    department_id: '',
                    main_component_id: '',
                    parent_id: '',
                    budget_head_id: '',
                    type: 'sub-sub-component',
                    vessel_id: auth.getVesselId(),
                    status: 'Activate'
                }),
                showInfo: {
                    mainComponentName: '',
                    ComponentName: '',
                    subComponentName: ''
                },
                pagination: {
                    current_page: 1
                },
                vessel_id: auth.getVesselId(),
            }
        },
        mounted() {
            this.departmentList()
            this.budgetHeadsList()
            this.getData()
        },
        watch: {
            'form.parent_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.subcomponents);
                }
            },
        },
        methods: {
            updateCode(parent_id, components) {
                const selectedComponent = components.find(component => component.id === parent_id);
                if (selectedComponent && selectedComponent.code) {
                    return selectedComponent.code;
                }
                return '';
            },
            getData() {
                this.loading = true;
                this.$http.get('/api/filter/sub-sub-component/' + this.vessel_id).then(response => {
                    this.subsubcomponents = response.data.success
                    this.loading = false
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false

                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                $('#roleModal').modal('show')
            },
            store() {
                this.form.busy = true
                this.form.post('/api/component').then(response => {
                    $('.dataTables_empty').closest('tr').remove()
                    this.getData()
                    $('#roleModal').modal('hide')
                    $('#copyModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success(' Sub Component Created Successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        var error = this.form.errors.errors.error
                        this.$snotify.error(error)
                    }

                })
            },
            subSubComponentnCopy(SubSubComponent) {
                this.component_id = SubSubComponent.parent?.parent.id
                this.form.reset()
                this.form.clear()
                this.form.fill(SubSubComponent)
                $('#copyModal').modal('show')
            },
            edit(SubSubComponent) {
                this.editMode = true;
                this.component_id = SubSubComponent.parent?.parent.id;
                this.form.reset();
                this.form.clear();
                this.form.fill(SubSubComponent);
                setTimeout(() => {
                    this.form.code = SubSubComponent.code;
                }, 1000);
                this.mainComponent();
                this.Component();
                this.subComponent();
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/component/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success(' component successfully Update')
                    } else {
                        this.$snotify.error('Something went wrong try again later.', 'Error')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            destroy(SubComponent) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/component/' + SubComponent.id).then(response => {
                        this.getData()
                        this.$snotify.success(' Component Successfully Deleted')
                    }).catch(error => {
                        let response = error.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            var err = error.response.data.error
                            this.$snotify.error(err)
                        }

                    })
                }).catch(function () {

                })

            },
            departmentList() {
                this.mainComponents = []
                this.components = []
                this.$http.get('/api/filter/department/' + this.vessel_id).then(response => {
                    this.departments = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            mainComponent() {
                this.mainComponents = []
                this.components = []
                this.subcomponents = []
                if (this.form.department_id != '') {
                    this.$http.get('/api/filter/main-component/' + this.vessel_id + '/' + this.form.department_id).then(response => {
                        this.mainComponents = response.data.success
                    }).catch(e => {
                        console.log(e)
                    })

                } else {
                    this.mainComponents = []
                }
            },
            Component() {
                if (this.form.main_component_id != '') {
                    this.$http.get('/api/filter/component/' + this.vessel_id + '/' + this.form.main_component_id).then(response => {
                        this.components = []
                        this.components = response.data.success
                    }).catch(e => {
                        console.log(e)
                    })
                } else {
                    this.components = []
                }
            },
            subComponent() {
                if (this.component_id != '') {
                    this.$http.get('/api/filter/sub-component/' + this.vessel_id + '/' + this.component_id).then(response => {
                        this.subcomponents = []
                        this.subcomponents = response.data.success
                    }).catch(e => {
                        console.log(e)
                    })
                } else {
                    this.subcomponents = []
                }
            },
            budgetHeadsList() {
                this.$http.get('/api/filter/budget-head/' + this.vessel_id).then(response => {
                    this.budgetHeads = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            show(SubSubcomponent) {
                this.form.reset()
                this.form.fill(SubSubcomponent)
                this.showInfo.mainComponentName = this.isEmpty(SubSubcomponent.maincomponent) ? SubSubcomponent.maincomponent.name : ''
                this.showInfo.ComponentName = this.isEmpty(SubSubcomponent.parent) ? SubSubcomponent.parent.name : ''
                this.showInfo.subComponentName = this.isEmpty(SubSubcomponent.subcomponent) ? SubSubcomponent.subcomponent.name : ''
                $('#showModal').modal('show')
            },
            onImageChange(e) {
                let file = e.target.files[0]
                let reader = new FileReader()
                reader.onloadend = (file) => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            },

            isEmpty(obj) {
                var type = typeof obj
                if (type == 'object') {
                    if (obj.length < 1) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
