<template>
  <div>
    <table :class="getTableClasses" style="width: 100%" >
      <thead>
      <tr>
        <th><strong>Title: </strong>{{purchaseData.title}}</th>
        <th><strong>Requisition No: </strong>{{purchaseData.requisition.requisition_no}}</th>
        <th><strong>Status: </strong>{{purchaseData.status}}</th>
        <th><strong>Type: </strong>{{purchaseData.type}}</th>
        <th><strong>Date: </strong>{{purchaseData.date}}</th>
      </tr>
      <tr>
        <th><strong>Remarks: </strong>{{purchaseData.remarks}}</th>
        <th><strong>Priority: </strong>{{purchaseData.requisition.priority}}</th>
        <th><strong>Address: </strong>{{purchaseData.requisition.address}}</th>
        <th><strong>Required By: </strong>{{purchaseData.requisition.supply_date}}</th>
        <th></th>
      </tr>
      <tr>
        <th><strong>Vessel ETA: </strong>{{purchaseData.requisition.vessel_eta}}</th>
        <th><strong>Vessel ETD: </strong>{{purchaseData.requisition.vessel_etd}}</th>
        <th><strong>Supplier: </strong>{{purchaseData.supplier.name}}</th>
        <th><strong>Country: </strong>
          <span v-if="purchaseData.requisition.country">
                                           {{purchaseData.requisition.country.name}}
                                       </span>
        </th>
        <th>
          <strong>Port: </strong>
          <span v-if="purchaseData.requisition.port">
                                           {{purchaseData.requisition.port.name}}
                                       </span>
        </th>
      </tr>
      </thead>
    </table>
    <hr>

    <table  :class="getTableClasses"  style="width: 100%">
      <thead>
      <tr>
        <th>Status</th>
        <th>Item name</th>
        <th>Impa code</th>
        <th>Maker Part no</th>
        <th>Maker</th>
        <th>Model</th>
        <th>Opening stock</th>
        <th>Details</th>
        <th>Unit</th>
        <th class="text-center">Qty</th>
        <th class="text-right">Price</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item , index) in purchaseData.purchase_item" :key="index">
        <td>{{item.status}}</td>
        <td>{{item.item.name}}</td>
        <td>{{item.item.inpa_code}}</td>
        <td>{{item.item.maker_part_no}}</td>
        <td>{{item.item.maker}}</td>
        <td>{{item.item.model}}</td>
        <td>{{item.item.opening_stock}}</td>
        <td>{{item.item.description}}</td>
        <td>{{item.item.unit.name}}</td>
        <td class="text-center">{{item.qty}}</td>
        <td class="text-right">${{item.price}}</td>
      </tr>
      </tbody>
    </table>

      <table  :class="getBottomClasses" style="width: 100%"   >
        <tbody>
        <tr>
          <th>Sub Total:</th>
          <td width="10%">${{purchaseData.amount}}</td>
        </tr>
        <tr>
          <th>Tax ({{parseFloat(purchaseData.tax).toFixed(2)}}%)</th>
          <td>${{purchaseData.tax_amount}}</td>
        </tr>
        <tr>
          <th>Shipping Cost:</th>
          <td>${{purchaseData.shipping_cost}}</td>
        </tr>
        <tr>
          <th>Grand Total:</th>
          <td>${{purchaseData.grand_amount}}</td>
        </tr>
        </tbody>
      </table>


  </div>
</template>



<script>
export default {
  props: {
    purchaseData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      }
      },
    getBottomClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
        'text-right': true,
      };
    }

  }
};
</script>
<style scoped>

</style>
