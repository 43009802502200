<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'"  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Job Name</th>
      <th>Job Code</th>
      <th>Component Name</th>
      <th>Due Date</th>
      <th>Completion Date</th>
      <th>Assign To</th>
      <th>Status</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(job, index) in jobList?.schedulers" :key="job.id">
      <td>{{index+1}}</td>
      <td>{{jobList.name}}</td>
      <td>{{jobList.code}}</td>
      <td>{{jobList.component?.name}}</td>
      <td>{{job.start_date | moment('DD-MM-YYYY')}}</td>
      <td>{{job.end_date | moment('DD-MM-YYYY')}}</td>
      <td>
        {{jobList?.rank_fixed_role?.name}}
      </td>
      <td>
        <span :class="isPrinting ? '' : 'badge badge-warning'"   v-if="job.status=='Pending'">Pending</span>
        <span :class="isPrinting ? '' : 'badge badge-info'"  v-if="job.status=='In_Progress'">In Progress</span>
        <span :class="isPrinting ? '' : 'badge badge-success'"   v-if="job.status=='Done'">Done</span>
      </td>
      <td v-if="!isPrinting" class="text-nowrap">
        <!--RISK ASSESMENT BUTTON -->
        <button title="Job Instruction " class="btn btn-secondary btn-sm" @click="readJobRemark(jobList)"><i class="fa fa-book"></i></button>
        <span v-if="job.pw_ra_assign=='Yes' && false">
                                <button title="Risk Assessment" v-if="job.risk_assessments == null && user_type == 4" type="button" class="btn btn-info btn-sm" @click="createRA(job,index)"><i class="fa fa-warning"></i> </button>
                                <button title="Risk Assessment" v-else-if="job.risk_assessments == null  && user_type < 4" type="button" class="btn btn-info btn-sm" @click="createRaAleart"><i class="fa fa-warning"></i> </button>
                                <button title="Risk Assessment" v-else type="button" class="btn btn-success btn-sm" @click="createRA(job,index)"><i class="fa fa-warning"></i> </button>
          <!--PERMIT TO  BUTTON -->
          <!--PERMIT TO  BUTTON nulll -->
                                <button title="Permit To Work " type="button" v-if="job.permit_works == null && user_type == 4" class="btn btn-info btn-sm" @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <button title="Permit To Work " type="button" v-if="job.permit_works == null && user_type <= 3" class="btn btn-info btn-sm" @click="createP2WAleart"><i class="fa fa-tasks"></i></button>

          <!--PERMIT TO  BUTTON not  nulll -->
                                <button title="Permit To Work " type="button" v-if="job.permit_works != null && user_type == 4 && job.permit_works.permission_type == 'No'" class="btn  btn-sm" style="background-color: #69845b; color:#ffff" @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <button title="Permit To Work " type="button" v-if="job.permit_works != null && user_type <= 3 && job.permit_works.permission_type == 'No'" class="btn  btn-sm" style="background-color: #69845b; color:#ffff" @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
          <!-- <button title="Permit To Work " type="button" v-if="job.job_schedules_report[index].permit_to_work!=null && user_type ==3"  class="btn btn-info btn-sm" @click="createP2WAleart"><i class="fa fa-tasks"></i></button> -->
                                <button title="Permit To Work " type="button" v-if="job.permit_works != null && user_type == 4 && job.permit_works.permission_type == 'Yes'" class="btn btn-success  btn-sm" @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <button title="Permit To Work " type="button" v-if="job.permit_works != null && user_type <= 3 && job.permit_works.permission_type == 'Yes'" class="btn btn-success btn-sm" @click="createP2WChiefMode(job,index)"><i class="fa fa-tasks"></i></button>
                              </span>

        <!-- deffer job icon  -->
        <!-- this button for super admin to chief engineer for accept defer request  -->
        <!-- <button title="Deffer Job " v-if="user_type<=3" class="btn btn-sm btn-info" type="button"><i class="fa fa-compass" aria-hidden="true"></i></button> -->
         <div v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE) && false ">
           <button title="Job Done" type="button" v-if="job.job_dones == null" class="btn btn-info btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
           <button title="Job Done" type="button" v-else-if="job.job_dones != null && job.status != 'Done'" style="background-color: #69845b; color:#ffff" class="btn  btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
           <button title="Job Done" type="button" v-else class="btn btn-success btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
         </div>

        <button v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB) && false "  title="Deffer Job " class="btn btn-sm btn-info" type="button" @click="viewDeferSchedule(job, job.id)"><i class="fa fa-compass" aria-hidden="true"></i></button>

        <!-- <button  title="Defer Reschedule List" type="button"  class="btn btn-info btn-sm"><i class="fa fa-refresh"></i></button> -->
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import permissionsMixin from "@/mixin/permissionsMixin";
import printMixin from "@/mixin/printMixin";
import goBackButtonMixin from "@/mixin/goBackButtonMixin";
import auth from "@/auth";

export default {
  mixins: [permissionsMixin,printMixin,goBackButtonMixin],
  data(){
    return {
      permissions:auth.getPermissionData(),
    }
  },
  props: {
    jobList: {
      required: true
    },
    user_type: {
      required: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    readJobRemark: {
      type: Function,
      required: false
    },
    createRA: {
      type: Function,
      required: false
    },
    createRaAleart: {
      type: Function,
      required: false
    },
    createP2W: {
      type: Function,
      required: false
    },
    createP2WAleart: {
      type: Function,
      required: false
    },
    createP2WChiefMode: {
      type: Function,
      required: false
    },
    jobDoneFunction: {
      type: Function,
      required: false
    },
    viewDeferSchedule: {
      type: Function,
      required: false
    }

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
