<template>
    <div>
        <footer>
            <div class="pull-center">
               © Copyright 2020 - Global Maricruit. All rights reserved.
            </div>
            <div class="clearfix"></div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
