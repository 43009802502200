<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th> Type</th>
      <th> Name</th>
      <th> Email</th>
      <th> Contact Number</th>
      <th> Address</th>
      <th> Status</th>
      <th> Approved By</th>
      <th>Country</th>
      <th v-if="!isPrinting"> ISO</th>
      <th v-if="!isPrinting && (isPermissionExist(PERMISSIONS.SUPPLIER_EDIT) || isPermissionExist(PERMISSIONS.SUPPLIER_DELETE))">Action</th>
    </tr>
    </thead>
    <tbody>


    <tr v-for="(supplier , index) in supplierList" :key="supplier.id" :class="supplier.status"  >
      <td>{{++index}}</td>
      <td><span v-if="supplier.suppliertype">{{supplier.suppliertype.name}}</span></td>
      <td>{{supplier.name}}</td>
      <td>{{supplier.email}}</td>
      <td>{{supplier.contact_no}}</td>
      <td>{{supplier.address}}</td>
      <td>
        {{supplier.status}}
      </td>
      <td>
        <span v-if="supplier.approveby">{{supplier.approveby.username}}</span>
      </td>
      <td>{{supplier?.country?.name}}</td>
      <td v-if="!isPrinting">
        <a v-if="supplier.iso_certificate" :href="`${supplier.iso_certificate}`" target="_blank">View</a>
      </td>

      <td  v-if="!isPrinting && (isPermissionExist(PERMISSIONS.SUPPLIER_EDIT) || isPermissionExist(PERMISSIONS.SUPPLIER_DELETE))">
        <button v-if="supplier.status == 'Deactivate' && isPermissionExist(PERMISSIONS.SUPPLIER_EDIT)" type="button" @click="changeStatus(supplier, 'Activate')" class="btn btn-success btn-sm" title="Approve"><i
          class="fa fa-check"></i></button>
        <button v-if="supplier.status == 'Activate' && isPermissionExist(PERMISSIONS.SUPPLIER_EDIT)" type="button" @click="changeStatus(supplier, 'Deactivate')" class="btn btn-warning btn-sm" title="Deactive">
          <i class="fa fa-times"></i></button>
        <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_EDIT)"  @click="edit(supplier)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
        <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_DELETE)"  @click="destroy(supplier)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    supplierList: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Function,
      required: false
    },
    changeStatus: {
      type: Function,
      required: false
    },
    destroy: {
      type: Function,
      required: false
    },
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
