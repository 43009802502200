<template>
  <div>
    <div>
      <div>
        <a class="hiddenanchor" id="signup"></a>
        <a class="hiddenanchor" id="signin"></a>
        <div class="login_wrapper">
          <div class="animate form login_form">
            <section>
              <img class="logo_class" :src="logoLink" alt/>
            </section>
            <section class="login_content">
              <form @submit.prevent="submitLogin">
                <h1 style="color: #fff">Login Form</h1>
                <h6 class="text-danger" v-if="errorAlert">You Information Is not Valid 😥😥</h6>
                <div style="padding: 0 8px">
                  <input type="text" v-model="username" class="form-control" placeholder="Enter User Name or Email" required/>
                </div>
                <br/>
                <div style="padding: 0 8px">
                  <input type="password" v-model="passWord" class="form-control" placeholder="Password" required/>
                </div>
                <div>
                  <br/>
                  <button :disabled="loading" type="submit" class="btn btn-info submit float-left" style="margin-left: 8px">Log in</button>
                </div>

                <div class="clearfix"></div>

                <div class="separator">
                  <div class="clearfix"></div>
                  <br/>
                  <div></div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import auth from '@/auth'

    export default {
        name: 'Login',
        data() {
            return {
                logoLink: 'http://pms.maricruit.com/images/logo.png',
                username: '',
                passWord: '',
                errorAlert: false,
                loading: false,
            }
        },
        computed: {},
        mounted() {
            this.checkLoggedIn()
        },
        methods: {
            checkLoggedIn: async function () {
                const isLoggedIn = auth.loggedIn();
                if (isLoggedIn) {
                    return this.$router.push({path: '/'})
                }
            },
            submitLogin: async function () {
                this.loading = true;
                const userData = {
                    username: this.username,
                    password: this.passWord
                };
                this.$http.post('/api/user-login', userData).then(response => {
                    const loginData = response.data;
                    localStorage.setItem('adminLoginData', JSON.stringify(loginData));
                    localStorage.setItem('vessel_id', loginData.vessel_id);
                    localStorage.setItem('vessel_name', loginData.vessel_name);
                    localStorage.setItem('vessel_type_id', loginData.vessel_type);
                    this.loading = false;
                    window.location.reload();
                }).catch(error => {
                    console.log(error?.response);
                    this.loading = false;
                    this.errorAlert = true;
                });
            }
        }
    }
</script>

<style scoped>

  .login_content {
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.4);
  }

  .nav-md {
    background: url('https://i.ibb.co/3mr0P2m/vessel.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .logo_class {
    display: block;
    margin: auto;
    padding-bottom: 8%;
  }
</style>
