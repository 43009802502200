<template>
    <div>
      <div
        v-if="loading"
        class="col-md-12 text-center"
        style="margin-top:25%"
      >
        <PulseLoader
          :loading="loading"
        ></PulseLoader>
      </div>

      <div v-if="!loading">

        <div class="row">
            <div class="col-md-12 col-sm-12 ">
                <div class="">
                    <div class="x_panel">
                        <h2 class="float-left"> Country List</h2>
                        <ul class="nav navbar-right panel_toolbox align-right">
                            <li>
                                <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                            </li>
                          <li>
                            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
                          </li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-box table-responsive">
                                        <p class="text-muted font-13 m-b-30"></p>
                                        <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Country Name</th>
                                                <th>Tel Code</th>
                                                <th>ISO Code</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(country , index) in counteryes" :key="country.id">
                                                <td>{{index+1}}</td>
                                                <td>{{country.name}}</td>
                                                <td>{{country.phone_code}}</td>
                                                <td>{{country.iso_code}}</td>
                                                <td>
                                                    <button type="button" @click="edit(country)" class="btn btn-primary btn-sm">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button type="button" @click="destroy(country)" class="btn btn-danger btn-sm">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }} Country</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                        <div class="modal-body">
                            <!-- <alert-error :form="form"></alert-error> -->
                            <div class="form-group">
                                <label>Country Name</label>
                                <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Phone Code</label>
                                <input v-model="form.phone_code" type="text" name="phone_code" class="form-control" autocomplete="off" :class="{ 'is-invalid': form.errors.has('phone_code') }">
                                <has-error :form="form" field="phone_code"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Currency Code</label>
                                <input v-model="form.iso_code" type="text" name="iso_code" class="form-control" :class="{ 'is-invalid': form.errors.has('iso_code') }">
                                <has-error :form="form" field="iso_code"></has-error>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Modal -->
      </div>
    </div>
</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: "Role",
         mixins:[goBackButtonMixin],
        components: {PulseLoader},

        data() {
            return {
                loading:false,
                editMode: false,
                query: "",
                queryFiled: "name",
                counteryes: [],
                form: new Form({
                    id: "",
                    name: "",
                    phone_code: "",
                    iso_code: "",
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
        },
        methods: {

            getData() {
              this.loading = true;
                this.$http.get("/api/country")
                    .then(response => {
                      this.loading = false;
                        this.counteryes = response.data.success;
                        $('.dataTables_empty').closest("tr").remove();
                        setTimeout(() => $('#datatables').DataTable(), 1000);
                    })
                    .catch(e => {
                      this.loading = false;

                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }
                    });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#roleModal").modal("show");
            },
            store() {
                this.form.busy = true;
                this.form
                    .post("/api/country")
                    .then(response => {
                        this.getData();
                        $("#roleModal").modal("hide");
                        if (this.form.successful) {
                            this.$snotify.success("Country Created Successfully");
                        } else {
                            this.$snotify.error(
                                "Something went wrong try again later.",
                                "Error"
                            );
                        }
                    })
                    .catch(e => {
                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }else{
                        var error = this.form.errors.errors.error;
                        this.$snotify.error(error);
                      }
                    });
            },

            edit(role) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(role);
                $("#roleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form
                    .put("/api/country/" + this.form.id)
                    .then(response => {
                        this.getData();
                        $("#roleModal").modal("hide");
                        if (this.form.successful) {
                            this.$snotify.success("Country Successfully Updated");
                        } else {
                            this.$snotify.error(
                                "Something went wrong try again later."
                            );
                        }
                    })
                    .catch(e => {
                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }
                    });
            },
            destroy(role) {
              this.$dialog.confirm('Do You Want to delete This ?')
                    .then(() => {
                        this.$http.delete("/api/country/" + role.id)
                            .then(response => {
                                this.getData();
                                this.$snotify.success(
                                    "Country Successfully Deleted",
                                    "Success"
                                );
                            })
                            .catch(error => {
                              let response = error.response;
                              if (response.status === 403) {
                                this.$snotify.warning(response?.data?.error)
                              }else{
                                var err = error.response.data.error;
                                this.$snotify.error(err);
                              }

                            });
                    })
                    .catch(function () {

                    });

            },
        }
    }
</script>

<style scoped>

</style>
