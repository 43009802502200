<template>
   <div>
     <div
       v-if="loading"
       class="col-md-12 text-center"
       style="margin-top:25%"
     >
       <PulseLoader
         :loading="loading"
       ></PulseLoader>
     </div>
     <div v-if="!loading">
        <div class="row">
            <div class="col-md-12 col-sm-12 ">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Vessel Type  List</h2>
                        <ul class="nav navbar-right panel_toolbox">
                            <li>
                            <button type="button" class="btn btn-info btn-sm" @click="create">
                                Add New
                                <i class="fa fa-plus"></i>
                            </button>
                            </li>
                           <li>
                             <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
                           </li>
                        </ul>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content table-responsive">
                        <table  id="datatable" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th> Name </th>
                                <th> Status </th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(vesselType , index) in vesselTypes"  :key="vesselType.id">
                                    <td>{{index+1}}</td>
                                    <td>{{vesselType.name}}</td>
                                    <td>{{vesselType.status}}</td>
                                    <td>
                                        <button type="button" @click="edit(vesselType)" class="btn btn-primary btn-sm">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                            type="button"
                                            @click="destroy(vesselType)"
                                            class="btn btn-danger btn-sm"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

            <!-- Modal -->
    <div
      class="modal fade"
      id="vessetType"
      tabindex="-1"
      role="dialog"
      aria-labelledby="customerModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="customerModalLongTitle"
            >{{ editMode ? "Edit" : "Add New" }} Vessel Type </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <alert-error :form="form"></alert-error>
              <div class="form-group">
                <label>Vessel Type Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  name="name"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('name') }"
                >
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label>Vessel Type Status </label>
                <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="Activate">Activate</option>
                    <option value="Deactivate">Deactivate</option>
                </select>

                <has-error :form="form" field="status"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->
   </div>
   </div>
</template>

<script>
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins:[goBackButtonMixin],
        name: "VesselType",
        components: {PulseLoader},

        data() {
            return {
                loading:false,
                editMode: false,
                query: "",
                queryFiled: "name",
                vesselTypes: [],
                form: new Form({
                    id: "",
                    name: "",
                    status: "Activate"
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
         mounted() {
            this.getData();
        },
        methods:{
            getData() {
              this.loading = true;
                this.$http
                    .get("/api/vessel-type")
                    .then(response => {
                      this.loading = false;

                         this.vesselTypes = response.data.success;
                    })
                    .catch(e => {
                      this.loading = false;

                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }
                    });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#vessetType").modal("show");
            },
            store(){
                this.form.busy = true;
                this.form
                .post("/api/vessel-type")
                .then(response => {
                    this.getData();
                $("#vessetType").modal("hide");
                if (this.form.successful) {
                    this.$snotify.success("Vessel Type Successfully Saved");
                } else {
                    this.$snotify.error(
                    "Something went wrong try again later.",
                    "Error"
                    );
                }
                })
                .catch(e => {
                  let response = e.response;
                  if (response.status === 403) {
                    this.$snotify.warning(response?.data?.error)
                  }
                });
            },

            edit(vesselType) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(vesselType);
                $("#vessetType").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form
                .put("/api/vessel-type/" + this.form.id)
                .then(response => {
                this.getData();
                $("#vessetType").modal("hide");
                if (this.form.successful) {
                    this.$snotify.success("Vessel Type Successfully Updated", "Success");
                } else {
                    this.$snotify.error(
                    "Something went wrong try again later.",
                    "Error"
                    );
                }
                })
                .catch(e => {
                  let response = e.response;
                  if (response.status === 403) {
                    this.$snotify.warning(response?.data?.error)
                  }
                });
            },
            destroy(vesselType) {
              this.$dialog.confirm('Do You Want to delete This ?')
                .then(()=>{
                    this.$http.delete("/api/vessel-type/" + vesselType.id)
                    .then(response => {
                        this.getData();
                        this.$snotify.success(
                        "Vessel Type  Successfully Deleted",
                        "Success"
                        );
                    })
                    .catch(e => {
                      console.log(e)
                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }else{
                        this.$snotify.error(
                          "Vessel Type  Not Deleted",
                          "error"
                        );
                      }

                    });
                })
                .catch(function() {

                });

            },

        }
    }
</script>

<style scoped>

</style>
