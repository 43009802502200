import { render, staticRenderFns } from "./ItemToComponent.vue?vue&type=template&id=c8352104&scoped=true"
import script from "./ItemToComponent.vue?vue&type=script&lang=js"
export * from "./ItemToComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8352104",
  null
  
)

export default component.exports