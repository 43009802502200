const printUtil = function (id, title = '') {
  let table = document.getElementById(id);
  //display block
  table.style.display = 'block';
  let printWindow = window.open();
  printWindow.document.open();
  printWindow.document.write('<html><head><title>'+ title +'</title>');
  printWindow.document.write('<style>.print-table { border-collapse: collapse; }  .print-table th, .print-table td { border: 1px solid black; padding: 8px; }</style>');
  printWindow.document.write('<style>.print-page-break { page-break-after: always; } </style>');
  printWindow.document.write('</style></head><body>');
  printWindow.document.write(table.outerHTML);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
  table.style.display = 'none';
  printWindow.addEventListener('afterprint', () => {
    printWindow.close();
  });
};

export { printUtil };
