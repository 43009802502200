<template>
  <div>
    <table :class="getTableClasses" style="width: 100%" >
        <thead>
        <tr>
          <th><strong>Title: </strong>{{requisitionItem?.title}}</th>
          <th><strong>PRO No: </strong>{{requisitionItem?.return_no}}</th>
          <th><strong>PO No: </strong>{{requisitionItem?.purchase?.po_no}}</th>
          <th><strong>Date: </strong>{{requisitionItem?.date}}</th>
        </tr>
        <tr>
          <th><strong>Status: </strong>{{requisitionItem?.status}}</th>
          <th><strong>Remarks: </strong>{{requisitionItem?.remarks}}</th>
          <th><strong>Supplier: </strong>{{requisitionItem?.purchase?.supplier?.name}}</th>
          <th><strong>Supplier Contact: </strong>{{requisitionItem?.purchase?.supplier?.contact_no}}</th>
        </tr>
        </thead>
    </table>
    <hr>

    <table  :class="getTableClasses"  style="width: 100%">
      <thead>
      <tr>
        <th>Item name</th>
        <th>Impa code</th>
        <th>Maker Part no</th>
        <th>Maker</th>
        <th>Model</th>
        <th>Details</th>
        <th>Qty</th>
        <th>Price</th>
        <th>Unit</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item , index) in requisitionItem.return_item" :key="index">
        <td>{{item.item.name}}</td>
        <td>{{item.item.inpa_code}}</td>
        <td>{{item.item.maker_part_no}}</td>
        <td>{{item.item.maker}}</td>
        <td>{{item.item.model}}</td>
        <td>{{item.item.description}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.price}}</td>
        <td>{{item.item.unit.name}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</template>



<script>
export default {
  props: {
    requisitionItem: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      }
    },
  }
};
</script>
<style scoped>

</style>
