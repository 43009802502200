<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="seriaNumber =   1" >
    <tr>
      <th>#</th>
      <th>Job Name(Code)</th>
      <th>Department Name(Code)</th>
      <th>Component Name(Code)</th>
      <th>Due Date</th>
      <th>Completion Date</th>
      <th>Counter Type  </th>
      <th>Assign To</th>
      <th>Status</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr  v-for="(job,i) in jobList" :key="job.id">
      <td>{{i+1}}</td>
      <td>{{job?.job?.name}}({{job?.job?.code}})</td>
      <td>{{job?.job?.department?.name}}({{job?.job?.department?.code}})</td>

      <td>
        {{job?.job?.main_component?.name}}({{job?.job?.main_component?.code}}) >>
        {{
          job?.job?.sub_sub_component != null ? job?.job?.sub_sub_component?.name + '(' + job?.job?.sub_component?.name + ')' :
            job?.job?.sub_component != null ? job?.job?.sub_component?.name + '(' + job?.job?.component?.name + ')' :
              job?.job?.component != null ? job?.job?.component?.name + '(' + job?.job?.component?.code + ')'  : 'N/A'
        }} </td>
      <td>{{job?.due_date | moment('DD-MM-YYYY')}}</td>
      <td>{{job?.end_date | moment('DD-MM-YYYY')}}</td>
      <td>{{job?.counter_type}}</td>
      <td> {{job?.job?.rank_fixed_role?.name}}</td>
      <td>
        <span class="badge badge-success" v-if="job?.status=='Done'">Done</span>
      </td>
      <td v-if="!isPrinting">
        <button title="Job Done" type="button"  class="btn btn-success btn-sm" @click="jobDoneFunction(job)"><i class="fa fa-eye"></i></button>
      </td>

    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobList : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    jobDoneFunction: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
