<template>
    <div>
      <div
        v-if="loading"
        class="col-md-12 text-center"
        style="margin-top:25%"
      >
        <PulseLoader
          :loading="loading"
        ></PulseLoader>
      </div>

      <div v-if="!loading">
        <div class="row">
            <div class="col-md-12 col-sm-12 ">
                <div class="">
                    <div class="x_panel">
                        <h2 class="float-left"> Role List</h2>
                        <ul class="nav navbar-right panel_toolbox align-right">
                            <li>
                                <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                            </li>
                          <li>
                            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
                          </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-box table-responsive">
                                        <p class="text-muted font-13 m-b-30"></p>
                                        <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Role Name</th>
                                                <th>Role Status </th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(role , index) in roles"  :key="role.id">
                                                <td>{{index+1}}</td>
                                                <td>{{role.name}}</td>
                                                <td>{{role.status}}</td>
                                                <td>
                                                  <button title="Add Module" type="button" @click="addModule(role)" class="btn btn-info btn-sm">
                                                    <i class="fa fa-plus-circle"></i>
                                                  </button>
                                                    <button type="button" @click="edit(role)" class="btn btn-primary btn-sm">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button  type="button"   @click="destroy(role)"  class="btn btn-danger btn-sm" >
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <!-- Modal -->
    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }} Role</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <!-- <alert-error :form="form"></alert-error> -->
              <div class="form-group">
                <label>Role Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  name="name"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('name') }"
                >
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label>Role Type </label>
                <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="Activate">Activate</option>
                    <option value="Deactivate">Deactivate</option>
                </select>

                <has-error :form="form" field="status"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->

      <div class="modal fade" id="moduleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <input type="checkbox" class="selectAllModules" id="selectAllModules" v-model="selectAllModulesCheckbox"> &nbsp; &nbsp;
              <h5 class="modal-title" id="customerModalLongTitle">Assign Module {{this.role_name}} Role </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form  @submit.prevent="addModuleSubmit()">
              <div class="modal-body">
                <div class="form-group
                " v-for="(module , index) in modulesData"  :key="module.id">
                  <input
                    type="checkbox"
                    name="module"
                    :checked="module.selected"
                    v-model="module.selected"
                    :id="'checkbox_' + module.id"
                    @change="toggleChildren(module)"
                  > &nbsp;
                  <label :for="'checkbox_' + module.id" class="  module-label " > {{module?.name}}</label>

                  <div  v-if="module.children.length > 0">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-12" v-for="(child , index) in module.children">
                      <input
                        type="checkbox"
                        name="module"
                        :checked="child.selected"
                        :id="'checkbox_' + child.id"
                        v-model="child.selected"
                      >&nbsp;
                      <label :for="'checkbox_' + child.id"> {{ child?.name }}</label>
                    </div>
                  </div>
                  </div>
                </div>



              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save </button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>

    </div>
</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: "Role",
        components: { PulseLoader },
        mixins:[goBackButtonMixin],
        data() {
            return {
                loading:false,
                selectAllModulesCheckbox: false,
                editMode: false,
                query: "",
                queryFiled: "name",
                roles: [],
                modulesData:[],
                moduleFormData:[],
                role_id:'',
                role_name:'',
                form: new Form({
                    id: "",
                    name: "",
                    status: "Activate"
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
         mounted() {
            this.getData();
            this.getModules();
        },
        watch: {
          selectAllModulesCheckbox(newVal) {
            this.modulesData.forEach(module => {
              module.selected = newVal;
              module.children.forEach(child => {
                child.selected = newVal;
              });
            });
          },

        },
        methods:{
            getData() {
                this.loading = true
                this.$http.get("/api/role")
                    .then(response => {
                         this.loading = false
                         this.roles = response.data.success;
                         setTimeout(() => $('#datatables').DataTable(), 1000);
                    })
                    .catch(e => {
                      this.loading = false
                      let response = e.response;
                      if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                      }
                    });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#roleModal").modal("show");
            },
            store(){
                this.form.busy = true;
                this.form
                .post("/api/role")
                .then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Ranked Created Successfully");
                    } else {
                        this.$snotify.error(
                        "Something went wrong try again later.",
                        "Error"
                        );
                    }
                })
                .catch(e => {
                  let response = e.response;
                  if (response.status === 403) {
                    this.$snotify.warning(response?.data?.error)
                  }
                });
            },

            getModules() {
              this.$http.get("/api/permission/get-all-module")
                .then(response => {
                  this.modulesData = response.data.success;
                })
                .catch(e => {
                  console.log(e);
                });
            },
            toggleChildren(module) {
              module.children.forEach(child => {
                this.$set(child, 'selected', module.selected);
              });

              this.$set(module, 'children', [...module.children]);
            },
            addModuleSubmit(){
              this.moduleFormData = [];
             this.modulesData.filter(module => module.selected).map(module => {
                   this.moduleFormData.push(module.slug);
                    module.children.filter(child => child.selected).map(child => {
                      this.moduleFormData.push(child.slug);
                    });
              });
             if (this.moduleFormData.length === 0) {
               this.$snotify.error("Please Select Module", "Error");
               return;
             }
              this.$http
                .post("/api/permission/role-permission",{
                  role_id:this.role_id,
                  role_permission: this.moduleFormData
                })
                .then(response => {
                  this.getData();
                  $("#moduleModal").modal("hide");
                  this.$snotify.success("Role Permission Updated Successfully");
                })
                .catch(e => {
                  let response = e.response;
                  if (response.status === 403) {
                    this.$snotify.warning(response?.data?.error)
                  }else{
                    this.$snotify.error("Sorry Something went wrong", "Error");
                  }

                });

            },

            addModule(role){
              let permissions = role.permission;
              this.modulesData.forEach(module => {
                module.selected = false;
                module.children.forEach(child => {
                  child.selected = false;
                });
              });
              if (permissions != null) {
                this.modulesData.forEach(module => {
                  module.selected = permissions.includes(module.slug);
                  module.children.forEach(child => {
                    child.selected = permissions.includes(child.slug);
                  });


                });

              }
                this.role_id = role.id
                this.role_name = role.name;
              $("#moduleModal").modal("show");
            },


            edit(role) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(role);
                $("#roleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form
                .put("/api/role/" + this.form.id)
                .then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Role Successfully Updated", "Success");
                    }else {
                        this.$snotify.error(
                        "Something went wrong try again later.",
                        "Error"
                        );
                    }
                })
                .catch(e => {
                  let response = e.response;
                  if (response.status === 403) {
                    this.$snotify.warning(response?.data?.error)
                  }
                });
            },
            destroy(role) {
              this.$dialog.confirm('Do You Want to delete This ?')
                .then(()=>{
                    this.$http.delete("/api/role/" + role.id)
                    .then(response => {
                        this.getData();
                        this.$snotify.success(
                        "Role Successfully Deleted",
                        "Success"
                        );
                    })
                    .catch(error => {
                        var err=error.response.data.error;
                       this.$snotify.error(err
                        );
                    });
                })
                .catch(function() {

                });

             },
        }
    }
</script>

<style scoped>
   .module-label{
    font-size: 14px;
    font-weight: bold;
   }
   .selectAllModules{
    margin-top: 10px;
   }
</style>
