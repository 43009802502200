

<template>
  <div class="container">
    <div class="error-box">
      <h2>403</h2>
      <p>Forbidden - You don't have permission to access this page.</p>
      <router-link :to="{ path: '/', replace: true }"   class="back-btn">Go Back to Home</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Forbidden',
}
</script>
<style scoped>
body {
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-box {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #e74c3c;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

.back-btn {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #2980b9;
}
</style>
