<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <div class="x_title">
              <h2>Vessel List</h2>
              <ul class="nav navbar-right panel_toolbox">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">
                    Add New
                    <i class="fa fa-plus"></i>
                  </button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
            <div class="x_content table-responsive">
              <table id="datatables" class="table table-sm table-striped table-bordered  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th> Name</th>
                  <th> Email</th>
                  <th> image</th>
                  <th> Vessel Type</th>
                  <th> Vessel Model</th>
                  <th> Build Year</th>
                  <th> Status</th>
                  <th> Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(vessel , index) in vessels" :key="vessel.id">
                  <td>{{index+1}}</td>
                  <td>{{vessel?.name}}</td>
                  <td>{{vessel?.email}}</td>
                  <td>
                    <img v-if="vessel.image!=null " :src="vessel.image" height="50" width="50"/>

                  </td>
                  <td>{{vessel?.vessel_type?.name}}</td>
                  <td>{{vessel?.engine_model}}</td>
                  <td>{{vessel?.build_year}}</td>
                  <td>{{vessel?.status}}</td>
                  <td>
                    <button type="button" @click="show(vessel)" class="btn btn-info btn-sm">
                      <i class="fa fa-eye"></i>
                    </button>
                    <button type="button" @click="edit(vessel)" class="btn btn-primary btn-sm">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      @click="destroy(vessel)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>


      <!-- Modal -->
      <div
        class="modal fade"
        id="roleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="customerModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="customerModalLongTitle"
              >{{ editMode ? "Edit" : "Add New" }} Vessel </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Vessel Name</label>
                      <input
                        v-model="form.name"
                        type="text"
                        name="name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('name') }"
                      >
                      <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Email</label>
                      <input
                        v-model="form.email"
                        type="text"
                        name="name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('email') }"
                      >
                      <has-error :form="form" field="email"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Imo Number :</label>
                      <input
                        v-model="form.imo_no"
                        type="text"
                        name="imo_no"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('imo_no') }"
                      >
                      <has-error :form="form" field="imo_no"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Vessel Flag :</label>
                      <select v-model="form.flag_id" name="flag_id" class="form-control" :class="{ 'is-invalid': form.errors.has('flag_id') }">
                        <option value="" selected>Select Flag</option>
                        <option v-for="country in countryes" :key="country.id" :value="country.id">{{country.name}}</option>
                      </select>
                      <has-error :form="form" field="flag_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Breadth :</label>
                      <input
                        v-model="form.breadth"
                        type="text"
                        name="breadth"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('breadth') }"
                      >
                      <has-error :form="form" field="breadth"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Vessel Build Yard :</label>
                      <input
                        v-model="form.build_yard"
                        type="text"
                        name="build_yard"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('build_yard') }"
                      >
                      <has-error :form="form" field="build_year"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Build Year :</label>
                      <input
                        v-model="form.build_year"
                        type="text"
                        name="build_year"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('build_year') }"
                      >
                      <has-error :form="form" field="build_year"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Engine Model :</label>
                      <input
                        v-model="form.engine_model"
                        type="text"
                        name="engine_model"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('engine_model') }"
                      >
                      <has-error :form="form" field="engine_model"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Type </label>
                      <select v-model="form.vessel_type_id" name="vessel_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('vessel_type_id') }">
                        <option value="">Select Vessel Type</option>
                        <option v-for="vesselType in vesselTypes" :key="vesselType.id" :value="vesselType.id">{{vesselType.name}}</option>
                      </select>
                      <has-error :form="form" field="vessel_type_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Owner name :</label>
                      <input
                        v-model="form.owner_name"
                        type="text"
                        name="owner_name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('owner_name') }"
                      >
                      <has-error :form="form" field="owner_name"></has-error>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">


                    <div class="form-group">
                      <label>Manager name :</label>
                      <input
                        v-model="form.manager_name"
                        type="text"
                        name="manager_name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('manager_name') }"
                      >
                      <has-error :form="form" field="owner_name"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Vessel Length :</label>
                      <input
                        v-model="form.length"
                        type="text"
                        name="length"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('length') }"
                      >
                      <has-error :form="form" field="length"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Vessel Classification Society :</label>
                      <input
                        v-model="form.classic_society"
                        type="text"
                        name="classic_society"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('classic_society') }"
                      >
                      <has-error :form="form" field="classic_society"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Engine Maker :</label>
                      <input
                        v-model="form.engine_maker"
                        type="text"
                        name="engine_maker"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('engine_maker') }"
                      >
                      <has-error :form="form" field="engine_maker"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Class Name :</label>
                      <input
                        v-model="form.class_name"
                        type="text"
                        name="class_no"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('class_name') }"
                      >
                    </div>
                    <div class="form-group">
                      <label>Class No :</label>
                      <input
                        v-model="form.class_no"
                        type="text"
                        name="class_no"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('class_no') }"
                      >
                      <has-error :form="form" field="class_no"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Class Notation :</label>
                      <input
                        v-model="form.class_notation"
                        type="text"
                        name="class_notation"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('class_notation') }"
                      >
                      <has-error :form="form" field="class_notation"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Image:</label>
                      <input type="file" class="form-control" @change="covertDocument" accept=".png, .jpg, .jpeg">
                      <input type="hidden" value="" id="itemImage">
                    </div>
                    <!--<div class="form-group">
                      <label>Vessel Status  </label>
                      <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                          <option value="Activate">Activate</option>
                          <option value="Deactivate">Deactivate</option>
                      </select>

                      <has-error :form="form" field="status"></has-error>
                    </div>-->
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="showModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="showModalLabel">{{ form.name }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{{ form.name }}</td>
                  </tr>
                  <tr>
                    <th>Image</th>
                    <td>{{ form.image }}</td>
                  </tr>
                  <tr>
                    <th>Imo Number</th>
                    <td>{{ form.imo_no }}</td>
                  </tr>
                  <tr>
                    <th>Length</th>
                    <td>{{ form.length }}</td>
                  </tr>
                  <tr>
                    <th>Class Society</th>
                    <td>{{ form.classic_society }}</td>
                  </tr>
                  <tr>
                    <th>Build Year</th>
                    <td>{{ form.build_year }}</td>
                  </tr>
                  <tr>
                    <th>Engine Maker</th>
                    <td>{{ form.engine_maker }}</td>
                  </tr>
                  <tr>
                    <th>Engine Model</th>
                    <td>{{ form.engine_model }}</td>
                  </tr>
                  <tr>
                    <th>Class Name</th>
                    <td>{{ form.class_name }}</td>
                  </tr>
                  <tr>
                    <th>Engine Model</th>
                    <td>{{ form.class_no }}</td>
                  </tr>
                  <tr>
                    <th>Engine Model</th>
                    <td>{{ form.class_notation }}</td>
                  </tr>

                  <tr>
                    <th>Status</th>
                    <td>{{ form.status }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker';
    import Select2 from "v-select2-component";
    import auth from "@/auth";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: "Vessel",
        mixins: [goBackButtonMixin],
        components: {datetime, PulseLoader},
        data() {
            return {
                loading: false,
                editMode: false,
                vesselId: auth.getVesselId(),
                query: "",
                queryFiled: "name",
                vessels: [],
                vesselTypes: [],
                countryes: [],
                form: new Form({
                    id: "",
                    name: "",
                    email: "",
                    vessel_type_id: "",
                    imo_no: "",
                    flag_id: '',
                    build_yard: '',
                    owner_name: '',
                    manager_name: '',
                    length: "",
                    breadth: "",
                    classic_society: "",
                    build_year: "",
                    engine_maker: "",
                    engine_model: "",
                    class_name: '',
                    class_no: '',
                    class_notation: '',
                    image: '',
                    status: "Activate",
                }),
                cftForm: new Form({
                    name: "",
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                this.loading = true;
                this.$http.get("/api/vessel").then(response => {
                    this.loading = false;
                    this.vessels = response.data.success.vessel;
                    this.vesselTypes = response.data.success.vesselType;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            allCountry() {
                //  country
                this.$http.get("/api/country").then(response => {
                    this.countryes = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            covertDocument(event) {
                let file = event.target.files[0];
                this.form.image = file;
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.allCountry();
                $("#roleModal").modal("show");
            },
            store() {
                this.form.busy = true;
                this.form.post("/api/vessel").then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Vessel Successfully Saved");
                    } else {
                        this.$Snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            edit(vessel) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(vessel);
                this.allCountry();
                $("#roleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/vessel/" + this.form.id).then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Vessel Successfully Updated");
                    } else {
                        this.$snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            show(vessel) {
                this.form.reset();
                this.form.fill(vessel);
                $("#showModal").modal("show");
            },
            destroy(vessel) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/vessel/" + vessel.id).then(response => {
                        this.getData();
                        this.$snotify.success("Vessel Successfully Deleted");
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error("Vessel Not Deleted", "error");
                        }
                    });
                }).catch(function () {

                });
            },
        }
    }
</script>

<style scoped>

</style>
