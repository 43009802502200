<template>
  <div>
    <div class="row">
      <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
        <PulseLoader :loading="loading"></PulseLoader>
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <h2 class="float-left"> Budget Head</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <!--              <li>-->
              <!--                <router-link to="/budget-head">-->
              <!--                  <button class="btn btn-info btn-sm">View Tree <i class="fa fa-eye"></i></button>-->
              <!--                </router-link>-->
              <!--              </li>-->
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <h5 class="card-header">Budget Head List</h5>
              <div class="card-body">
                <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Head Name</th>
                    <th>Parent Head</th>
                    <th>Head Code</th>
                    <th>Item Code</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(budgetHead , index) in budgetHeads" :key="budgetHead.id">
                    <td>{{index+1}}</td>
                    <td>{{budgetHead.name}}</td>
                    <td>{{budgetHead?.parent?.name}}</td>
                    <td>{{budgetHead.code}}</td>
                    <td>{{budgetHead.group_code}}</td>
                    <td>
                      <button title="Edit Budget Head" type="button" @click="edit(budgetHead)" class="btn btn-primary btn-sm">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        title="Delete Budget Head"
                        type="button"
                        @click="destroy(budgetHead)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Modal -->
            <div class="card">
              <h5 class="card-header" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add " }} Budget Head</h5>
              <div class="card-body">
                <div class="modal-dialog" role="document">
                  <div class="modal-content modal-content2">
                    <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                      <div class="modal-body">
                        <alert-error :form="form"></alert-error>
                        <div class="form-group">
                          <label> Name</label>
                          <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                          <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                          <label> Budget Head Name : </label>
                          <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
                        </div>
                        <div class="form-group">
                          <label> Budget Head Code</label>
                          <input v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                          <has-error :form="form" field="code"></has-error>
                        </div>
                        <div class="form-group">
                          <label> Item Code</label>
                          <input v-model="form.group_code" type="text" name="group_code" class="form-control" :class="{ 'is-invalid': form.errors.has('group_code') }">
                          <has-error :form="form" field="group_code"></has-error>
                        </div>
                        <div class="form-group">
                          <label> Remarks : </label>
                          <textarea v-model="form.remarks" class="form-control"></textarea>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Select2 from 'v-select2-component';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import auth from "@/auth";

    export default {
        name: "BudgetHead",
        components: {Select2, PulseLoader},
        data() {
            return {
                loading: false,
                color: "#1ABB9C",
                size: "20px",
                editMode: false,
                budgetHeads: [],
                form: new Form({
                    id: "",
                    name: "",
                    code: "",
                    group_code: "",
                    parent_id: "",
                    remarks: '',
                    vessel_id: auth.getVesselId(),
                }),
                query: '',
                results: [],
                myOptions: []
            }
        },
        mounted() {
            this.budgetMainHead();
            this.create();
        },
        methods: {
            async create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.myOptions = [];
                var itemList = await this.$http.get("/api/filter/budget-head/" + this.form.vessel_id);
                this.results = itemList.data.success;
                var newObj = {id: "", text: "No Selected"};
                this.myOptions.push(newObj);
                this.results.filter(item => {
                    var newObj = {id: item.id, text: item.name + ' (' + item.code + ')'};
                    this.myOptions.push(newObj);
                })
            },
            budgetMainHead() {
                this.loading = true;
                this.$http.get("/api/filter/budget-head/" + this.form.vessel_id).then(response => {
                    console.log("budget-head", response.data.success)
                    this.budgetHeads = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 100);
                    this.loading = false;
                }).catch(e => {
                    console.log(e);
                });
            },
            store() {
                this.form.busy = true;
                this.form.post("/api/budget-head").then(response => {
                    this.budgetMainHead();
                    if (this.form.successful) {
                        this.create();
                        this.$snotify.success("Budget Head Created Successfully");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later."
                        );
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                });
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/budget-head/" + this.form.id).then(response => {
                    if (this.form.successful) {
                        this.budgetMainHead();
                        this.create();
                        this.$snotify.success("Budget Head Successfully Updated");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later.",
                            "Error"
                        );
                    }
                }).catch(e => {
                    console.log(e);
                });
            },
            edit(head) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(head);
            },
            destroy(category) {
                this.$dialog.confirm('Do You Want to delete This ?')
                    .then(() => {
                        this.$http.delete("/api/budget-head/" + category.id)
                            .then(response => {
                                this.budgetMainHead();
                                this.create();
                                this.$snotify.success(
                                    "Budget Head Successfully Deleted"
                                );
                            })
                            .catch(error => {
                                var err = error.response.data.error;
                                this.$snotify.error(err
                                );
                            });
                    })
                    .catch(function () {

                    });
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.form.parent_id = id;
            },
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
  /* .list-group{
      display: block !important;
  } */
  .x_title {
    border-bottom: none !important;
  }

  .easy-autocomplete {
    width: 100% !important;
  }
</style>
