<template>
  <div>
    <router-view></router-view>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

import '../public/admin/styles.css';
import _ from "lodash";
import auth from './auth'

export default {
  name: "App",
  components: {},
  data() {
    return {
      selectedVessels: "",
      vessels: [],
      test: "",
    };
  },
  computed: {
    loggedIn() {
      var status = auth.loggedIn();
      if (status) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: async function () {
    await this.loadCustomJsScripts();
    await this.loadMainJsScripts();
    //this.validateToken();
  },
  methods: {
    loadCustomJsScripts: async function () {
      let script = document.createElement('script')
      script.setAttribute('src', '/admin/build/js/custom.js')
      document.head.appendChild(script);
    },
    loadMainJsScripts: async function () {
      let script = document.createElement('script')
      script.setAttribute('src', '/admin/js/custom/main.js')
      document.head.appendChild(script);
    },
    validateToken() {
      //this
    },
    isEmpty(obj) {
      if (obj.length < 1) {
        return false;
      } else {
        return true;
      }
    },
    userInfo() {
      this.$http.post("/api/user-details").then((response) => {
        localStorage.setItem("user_type", response.data.success.role_id);
      }).catch((error) => {
      });
    },
  },
  created() {
    var self = this;
    this.validateToken = _.debounce(async () => {
      try {
        var validity = await self.$http.post("/api/validate-token");
      } catch (e) {
        auth.logout();
      }
    }, 30000);
  },
};
</script>

<style>
  thead th{
    color:white !important;
  }
</style>
