<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Opening List</h2>
          <ul class="nav navbar-right panel_toolbox align-right">
            <li>
              <button type="button" class="btn btn-info btn-sm" @click="showRequisitionModal()"> Add Opening Stock <i class="fa fa-plus"></i></button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-box table-responsive">
                <p class="text-muted font-13 m-b-30"></p>
                <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(requisition , index) in requisitionData" :key="index">
                    <td>{{ requisition.opening_stock_no }}</td>
                    <td>{{ requisition.date }}</td>
                    <td>
                      <button type="button" @click="show(requisition.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
                      <button
                        type="button"
                        @click="destroy(row)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Requisition view Modal -->
    <div class="modal fade" id="addRequisitionModal" tabindex="-1" role="dialog" aria-labelledby="addRequisitionModalTitle" aria-hidden="true">
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addRequisitionModalTitle">Add Stock </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form role="form" action="#" method="POST" @submit="formSubmit" class="requisitionForm">

              <div class="row">


                <div class="x_panel">
                  <div class="x_content">
                    <span class="btn btn-info btn-sm pull-right" @click="addItem()" style="margin-top: -5px;">Add Item</span>

                    <div class="clearfix"></div>

                    <div class="tab-content mt-2" id="myTabContent">

                      <div class="tab-pane fade active show" id="line" role="tabpanel" aria-labelledby="line-tab">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Category</label>
                              <select class="form-control form-control-sm" v-model="category_id" @change="getCategoriesItem">
                                <option value="" selected>Select category</option>
                                <option v-for="(row, key) in CategoryData" :value="`${row.id}`">{{ row.name }}</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Select Item<span class="required">*</span></label>
                              <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                <option value="" selected>Select item</option>
                                <option v-for="(row, key) in CategoriesItemData" :value="`${row.id}`">{{ row.name }}</option>
                              </select>
                            </div>
                          </div>


                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Opening Quantity<span class="required">*</span></label>
                              <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Unit</label>
                              <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                            </div>
                          </div>

                          <div class="col-md-2">
                            <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i></button>
                          </div>

                          <div class="col-md-12">
                            <table class="table table-bordered">
                              <thead>
                              <tr>
                                <th>Item name</th>
                                <th>Impa code</th>
                                <th>Maker Part no</th>
                                <th>Maker</th>
                                <th>Model</th>
                                <th>Qty</th>
                                <th>Unit</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(item , index) in requisitionForm.item_id" :key="index">
                                <td>{{ requisitionForm.item_name[index] }}</td>
                                <td>{{ requisitionForm.item[index].item.inpa_code }}</td>
                                <td>{{ requisitionForm.item[index].item.maker_part_no }}</td>
                                <td>{{ requisitionForm.item[index].item.maker }}</td>
                                <td>{{ requisitionForm.item[index].item.model }}</td>
                                <td>{{ requisitionForm.qty[index] }}</td>
                                <td>{{ requisitionForm.item[index].item.unit.name }}</td>
                                <td>
                                  <button type="button" class="btn btn-round btn-danger" @click="removeRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary pull-right">Submit</button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Requisition view Modal -->

    <!-- Requisition view Modal -->
    <div class="modal fade" id="requisitionModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="requisitionModalTitle">Stock Item </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <table class="table table-bordered">
              <thead>
              <tr>
                <th><strong>Opening Stock No: </strong>{{ requisitionItem.opening_stock_no }}</th>
                <th><strong>Date: </strong>{{ requisitionItem.date }}</th>
              </tr>
              </thead>
            </table>
            <hr>

            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Item name</th>
                <th>Impa code</th>
                <th>Maker Part no</th>
                <th>Maker</th>
                <th>Model</th>
                <th>Details</th>
                <th class="text-center">Qty</th>
                <th>Unit</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item , index) in requisitionItem.stock_items" :key="index">
                <td>{{ item.item.name }}</td>
                <td>{{ item.item.inpa_code }}</td>
                <td>{{ item.item.maker_part_no }}</td>
                <td>{{ item.item.maker }}</td>
                <td>{{ item.item.model }}</td>
                <td>{{ item.item.description }}</td>
                <td class="text-center">{{ item.qty }}</td>
                <td>{{ item.item.unit.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Requisition view Modal -->


    <!--        <div class="modal fade" id="requisitionEditModal" tabindex="-1" role="dialog" aria-labelledby="requisitionEditModalTitle" aria-hidden="true">
                <div class="modal-dialog mw-100 w-75" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="requisitionEditModalTitle">Update Requisition Item </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <ul class="stats-overview">
                                <li>
                                    <span class="name"> Requisition No </span>
                                    <span class="value text-success"> {{ requisitionItem.requisition_no }} </span>
                                </li>
                                <li>
                                    <span class="name"> Status </span>
                                    <span class="value text-success"> {{ requisitionItem.status }} </span>
                                </li>
                                <li class="hidden-phone">
                                    <span class="name"> Date </span>
                                    <span class="value text-success"> {{ requisitionItem.date }} </span>
                                </li>
                            </ul>

                            <form role="form" action="#" method="POST" @submit="formUpdate" class="requisitionForm">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Req Title</label>
                                            <input v-model="RequisitionTitle" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Requisition Priority</label>
                                            <select v-model="RequisitionPriority" class="form-control form-control-sm">
                                                <option value="">Select one</option>
                                                <option value="High">High</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Normal">Normal</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="remark">Remarks</label>
                                            <textarea v-model="RequisitionRemarks" class="form-control form-control-sm" name="remark" rows="2" id="remarks"></textarea>
                                        </div>
                                    </div>

                                    <div class="x_panel">
                                        <div class="x_content">
                                            <ul class="nav nav-tabs nav-pills nav-justified bg-light" id="myEditTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="supply-edit-tab" data-toggle="tab" href="#supplyEdit" role="tab" aria-controls="supplyEdit" aria-selected="true">Supply Details</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="line-edit-tab" data-toggle="tab" href="#lineEdit" role="tab" aria-controls="lineEdit" aria-selected="false">Line Items</a>
                                                </li>
                                            </ul>

                                            <div class="tab-content mt-2" id="myEditTabContent">
                                                <div class="tab-pane fade active show" id="supplyEdit" role="tabpanel" aria-labelledby="supply-edit-tab">
                                                    <div class="row">

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Country</label>
                                                                <select class="form-control form-control-sm" v-model="country_id" @change="getCountriesport">
                                                                    <option value="" selected>Select country</option>
                                                                    <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{ row.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Port</label>
                                                                <select class="form-control form-control-sm" v-model="port_id">
                                                                    <option value="" selected>Select port</option>
                                                                    <option v-for="(row, key) in portData" :value="`${row.id}`">{{ row.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="address">Address</label>
                                                                <textarea v-model="SupplyAddress" class="form-control form-control-sm" name="address" rows="2" id="addresse"></textarea>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Supply Date</label>
                                                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyDate"></datetime>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Vessel ETA</label>
                                                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETA"></datetime>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Vessel ETD</label>
                                                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETD"></datetime>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Advised on Date</label>
                                                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyAdvised"></datetime>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tab-pane fade" id="lineEdit" role="tabpanel" aria-labelledby="line-edit-tab">
                                                    <div class="row">
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Category Items</label>
                                                                <select class="form-control form-control-sm" v-model="category_id" @change="getCategoriesItem">
                                                                    <option value="" selected>Select category</option>
                                                                    <option v-for="(row, key) in CategoryData" :value="`${row.id}`">{{ row.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Requisition Items</label>
                                                                <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                                                    <option value="" selected>Select item</option>
                                                                    <option v-for="(row, key) in CategoriesItemData" :value="`${row.id}`">{{ row.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Status</label>
                                                                <select v-model="itemStatus" class="form-control form-control-sm">
                                                                    <option value="" selected>Select one</option>
                                                                    <option value="High">High</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="Normal">Normal</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Quantity *</label>
                                                                <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Unit</label>
                                                                <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i></button>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th>Status</th>
                                                                    <th>Item name</th>
                                                                    <th>Impa code</th>
                                                                    <th>Maker Part no</th>
                                                                    <th>Maker</th>
                                                                    <th>Model</th>
                                                                    <th>Unit</th>
                                                                    <th>Qty</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr v-for="(item , index) in requisitionItem.requisition_item" :key="index">
                                                                    <td>{{ item.status }}</td>
                                                                    <td>{{ item.item.name }}</td>
                                                                    <td>{{ item.item.inpa_code }}</td>
                                                                    <td>{{ item.item.maker_part_no }}</td>
                                                                    <td>{{ item.item.maker }}</td>
                                                                    <td>{{ item.item.model }}</td>
                                                                    <td>{{ item.item.unit.name }}</td>
                                                                    <td>
                                                                        <input :value="`${item.qty}`" @change="onChangeQuantity(index, $event)" style="width:100px" type="number" step="any" min="0" name="name" class="form-control" placeholder="Enter quantity" autocomplete="off">
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-round btn-danger" @click="removeEditRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-primary pull-right">Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      -->

    <div class="modal fade" id="addItemModal" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <form @submit.prevent="store()" @keydown="form.onKeydown($event)" role="form">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add New Item </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section>
                <div class="">
                  <div class="tab-content">
                    <div class="tab-pane active" role="tabpanel" id="step1">
                      <div class="panel-heading">
                        <h3 class="panel-title text-center">Basic Information</h3>
                      </div>
                      <div class="row">
                        <div class="col-md-1 col-sm-1"></div>
                        <div class="col-md-5 col-sm-5">
                          <div class="form-group">
                            <label> Name<span class="required">*</span></label>
                            <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group">
                            <label> Category:<span class="required">*</span></label>
                            <select v-model="form.category_id" name="category_id" class="form-control category-tree" :class="{ 'is-invalid': form.errors.has('category_id') }">
                              <option v-for="category in CategoryData" :key="category.id" :value="category.id">{{ category.name }}</option>
                            </select>
                            <has-error :form="form" field="category_id"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Unit:<span class="required">*</span></label>
                            <select v-model="form.unit_id" name="unit_id" class="form-control" :class="{ 'is-invalid': form.errors.has('unit_id') }">
                              <option v-for="row in units" :key="row.id" :value="row.id">{{ row.name }}</option>
                            </select>
                            <has-error :form="form" field="unit_id"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Budget Head:<span class="required">*</span></label>
                            <Select2 v-model="form.budget_head_id" name="budget_head_id" :options="myOptions" :class="{ 'is-invalid': form.errors.has('budget_head_id') }"/>
                            <has-error :form="form" field="budget_head_id"></has-error>
                          </div>


                        </div>
                        <div class="col-md-5 col-sm-5">
                          <div class="form-group">
                            <label>Impa Code:<span class="required">*</span></label>
                            <input autocomplete="off" v-model="form.inpa_code" type="text" name="model" class="form-control" :class="{ 'is-invalid': form.errors.has('inpa_code') }">
                            <has-error :form="form" field="inpa_code"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Location:<span class="required">*</span></label>
                            <select v-model="form.location_id" name="location_id" class="form-control location-tree" :class="{ 'is-invalid': form.errors.has('location_id') }">
                              <option v-for="location in locationList" :key="location.id" :value="location.id">{{ location.name }}</option>
                            </select>
                            <has-error :form="form" field="location_id"></has-error>
                          </div>

                          <div class="form-group">
                            <label> Average Price:</label>
                            <input v-model="form.price" autocomplete="off" type="number" step="any" min="0" name="price" class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price"></has-error>
                          </div>

                          <div class="form-group">
                            <label>Model:<span class="required">*</span></label>
                            <input autocomplete="off" v-model="form.model" type="text" name="model" class="form-control" :class="{ 'is-invalid': form.errors.has('model') }">
                            <has-error :form="form" field="model"></has-error>
                          </div>

                        </div>
                        <div class="col-md-1 col-sm-1"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">

                          <div class="file-upload">
                            <!-- <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )">Add Image</button> -->

                            <div class="image-upload-wrap">
                              <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*"/>
                              <input type="hidden" value="" id="itemImage">
                              <div class="drag-text">
                                <h3 style="padding: 10px 0;">Drag and drop an image or select image</h3>
                              </div>
                            </div>
                            <div class="file-upload-content">
                              <img class="file-upload-image" src="#" alt="your image"/>
                              <div class="image-title-wrap">
                                <button type="button" onclick="removeUpload()" class="remove-image">Remove <span class="image-title">Uploaded Image</span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <button type="submit" class="btn btn-primary pull-right">Submit</button>
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>

                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import Select2 from 'v-select2-component';
    import datetime from 'vuejs-datetimepicker';

    export default {
        name: `Requisition`,
        components: {Select2, datetime},
        data() {
            return {
                editMode: false,
                isDisabled: false,
                locationList: null,
                units: null,
                myOptions: [],
                vesselId: this.$store.state.vessal_id,
                CategoryData: [],
                category_id: '',
                country_id: '',
                port_id: '',
                RequisitionTitle: '',
                RequisitionPriority: '',
                RequisitionRemarks: '',
                SupplyAddress: '',
                SupplyDate: '',
                SupplyETA: '',
                SupplyETD: '',
                SupplyAdvised: '',
                itemId: '',
                itemName: '',
                itemStatus: '',
                itemQuantity: '',
                itemTotalPrice: '',
                CategoriesItemData: [],
                CountryData: [],
                portData: [],
                itemData: {
                    "item": {
                        "id": '',
                        "budget_head_id": '',
                        "category_id": '',
                        "location_id": '',
                        "vessel_id": '',
                        "other_ref": "",
                        "name": "",
                        "maker": "",
                        "maker_part_no": "",
                        "model": "",
                        "drawing": "",
                        "size": "",
                        "unit": "",
                        "description": "",
                        "min_order": "",
                        "max_order": "",
                        "reorder_level": "",
                        "reorder_qty": "",
                        "opening_stock": "",
                        "opening_stock_date": "",
                        "image": "",
                        "doc": "",
                        "status": "",
                        "created_at": "",
                        "updated_at": "",
                        "budgethead": {
                            "id": "",
                            "vessel_id": "",
                            "parent_id": "",
                            "code": "",
                            "group_code": "",
                            "name": "",
                            "created_at": "",
                            "updated_at": "",
                            "budget": {
                                "id": "",
                                "budget_head_id": "",
                                "amount": "",
                                "budget_year": "",
                                "created_at": "",
                                "updated_at": ""
                            }

                        }

                    },
                    "component": [],
                    "currentStock": "",
                    "lastRequisitionData": {
                        "id": "",
                        "requisition_id": "",
                        "item_id": "",
                        "qty": "",
                        "status": "",
                        "created_at": "",
                        "updated_at": ""

                    },
                    "lastPurchaseData": ""
                },
                form: new Form({
                    id: "",
                    category_id: '',
                    location_id: '',
                    supplier_id: '',
                    vessel_id: this.$store.state.vessal_id,
                    other_ref: '',
                    name: "",
                    maker: '',
                    maker_part_no: '',
                    model: '',
                    drawing: '',
                    size: '',
                    unit_id: '',
                    description: '',
                    min_order: '',
                    max_order: '',
                    reorder_level: '',
                    reorder_qty: '',
                    opening_stock: '',
                    class: '',
                    opening_stock_date: '',
                    image: '',
                    doc: '',
                    inpa_code: '',
                    status: '',
                    component_id: [],
                    budget_head_id: '',
                    price: 0
                }),
                supplierData: [],
                requisitionData: [],
                requisitionItem: [],
                requisitionItems: [],
                requisitionForm: {
                    vessel_id: this.$store.state.vessal_id,
                    type: 'Others',
                    item_id: [],
                    item_name: [],
                    status: [],
                    qty: [],
                    item: [],
                },
                requestRequisitionId: '',
                selected: [],
                selectAll: false
            };
        },
        mounted() {
            this.getRequisitionData();
            this.getCategoryData();
            this.getCountryData();
            this.getBudgetHeadInfo();
            this.locationListInfo();
            this.getUnit();
        },
        methods: {
            getRequisitionData() {
                console.log(this.vesselId);
                this.$http.get("/api/filter/opening-stock/" + this.vesselId).then(response => {
                    this.requisitionData = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            getUnit() {
                this.$http.get("/api/unit")
                    .then(response => {
                        this.units = response.data.success;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            locationListInfo() {
                this.$http.get("/api/locationTree/" + this.form.vessel_id)
                    .then(response => {
                        $('.location-tree').html(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            store() {
                this.form.busy = true;
                this.form.image = $('#itemImage').val();
                this.form.post("/api/item").then(response => {
                    $("#roleModal").modal("hide");
                    if (response.data.success) {
                        $("#addItemModal").modal("hide");
                        this.CategoriesItemData = response.data.success;
                        this.category_id = response.data.success.category_id;
                        this.getCategoriesItem();
                    } else {
                        this.$snotify.error(
                            "Please check form again and fillup all required filed."
                        );
                    }
                }).catch(e => {
                    var error = this.form.errors.errors.error;
                    this.$snotify.error("Please check form again and fillup all required filed.");
                });
            },
            getSupplierData() {
                this.$http.get("/api/supplier").then(response => {
                    this.supplierData = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getCategoryData() {
                this.$http.get("/api/filter/category/create/" + this.vesselId).then(response => {
                    this.CategoryData = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getCategoriesItem() {
                this.$http.post("/api/item-filter", {category_id: this.category_id, vessel_id: this.vesselId}).then(response => {
                    this.CategoriesItemData = response.data;
                    console.log(this.CategoriesItemData);
                }).catch(e => {
                    this.CategoriesItemData = [];
                    console.log(e);
                });
            },
            getCountryData() {
                this.$http.get("/api/country").then(response => {
                    this.CountryData = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getCountriesport() {
                this.$http.get("/api/port-by-country/" + this.country_id).then(response => {
                    this.portData = response.data.success;
                }).catch(e => {
                    this.portData = [];
                    console.log(e);
                });
            },
            getItemData() {
                this.$http.get("/api/item/" + this.itemId).then(response => {
                    this.itemData = response.data;
                    this.itemName = response.data.item.name;
                }).catch(e => {
                    console.log(e);
                });
            },
            showRequisitionModal() {
                $("#addRequisitionModal").modal("show");
            },
            addItem() {
                $("#addItemModal").modal("show");
            },
            setRequisitionItem() {
                if (!this.category_id || !this.itemId || !this.itemQuantity) {
                    this.$snotify.error("Please fill required field");
                } else {
                    // for edit requisition add new item
                    if (this.requisitionItem.id) {
                        let editItemData = {
                            requisition_id: this.requisitionItem.id,
                            item_id: this.itemId,
                            qty: this.itemQuantity,
                            status: this.itemStatus,
                            item: this.itemData.item
                        };
                        this.requisitionItem.requisition_item.push(editItemData);
                    } else {
                        // for new add
                        if (this.requisitionForm.item_id.includes(this.itemId)) {
                            this.$snotify.error("This requisition item already added");
                            this.itemId = '';
                            this.itemQuantity = '';
                            return false;
                        }
                        this.message = false;
                        this.requisitionForm.item.push(this.itemData);
                        this.requisitionForm.item_id.push(this.itemId);
                        this.requisitionForm.item_name.push(this.itemName);
                        this.requisitionForm.status.push(this.itemStatus);
                        this.requisitionForm.qty.push(this.itemQuantity);
                    }
                    this.itemId = '';
                    this.itemName = '';
                    this.itemStatus = '';
                    this.itemQuantity = '';
                    this.itemTotalPrice = '';
                }
            },
            removeRequisitionItem(index) {
                if (confirm("Do you really want to delete?")) {
                    this.requisitionForm.item_id.splice(index, 1);
                    this.requisitionForm.item_name.splice(index, 1);
                    this.requisitionForm.status.splice(index, 1);
                    this.requisitionForm.item.splice(index, 1);
                    this.requisitionForm.qty.splice(index, 1);
                }
            },
            removeEditRequisitionItem(index) {
                if (confirm("Do you really want to delete?")) {
                    this.requisitionItem.requisition_item.splice(index, 1);
                }
            },
            onChangeItem(index, event) {
                this.requisitionItem.requisition_item[index].item_id = event.target.value;
            },
            onChangeQuantity(index, event) {
                this.requisitionItem.requisition_item[index].qty = event.target.value;
                //console.log(this.requisitionItem);
            },
            onChangeCopyQuantity(item, index, event) {
                this.requisitionItem.requisition_item[index].item_id = item.item_id;
                this.requisitionItem.requisition_item[index].status = item.status;
                this.requisitionItem.requisition_item[index].qty = event.target.value;
                //console.log(this.requisitionItem);
            },
            formSubmit: function (e) {
                e.preventDefault();

                if (this.requisitionForm.item_id.length > 0) {
                    this.$http.post(`/api/opening-stock`, this.requisitionForm).then((response) => {
                        if (response.data.success) {
                            //remove blank row
                            $('.dataTables_empty').closest("tr").remove();

                            this.$snotify.success("Opening stock added successfully");
                            this.requisitionForm = {};
                            this.getRequisitionData();
                            $("#addRequisitionModal").modal("hide");
                        }
                    }).catch((error) => {
                        if (error.response.status) {
                            this.$snotify.error("Opening stock failed, please try again later.");
                        }
                    });
                } else {
                    this.$snotify.error("Please fill required field");
                }
            },
            formUpdate: function (e) {
                e.preventDefault();
                if (this.requisitionItem.requisition_item.length > 0) {
                    this.$http.put(`/api/requisition/` + this.requisitionItem.id, this.requisitionItem).then((response) => {
                        if (response.data.success) {
                            this.$snotify.success("Requisition updated successfully");
                            this.requisitionItem = {};
                        }
                    }).catch((error) => {
                        if (error.response.status) {
                            this.$snotify.error("Update failed, please try again later.");
                        }
                    });
                } else {
                    this.$snotify.error("Please select requisition item & quantity");
                }
                $("#requisitionEditModal").modal("hide");
            },
            viewQuotation(item) {
                this.requestRequisitionId = item;
                this.isDisabled = false;
                this.selected = [];
                this.selectAll = false;
                this.getSupplierData();
                $("#quotationModal").modal("show");
            },
            show(item) {
                $("#requisitionModal").modal("show");
                this.$http.get("/api/opening-stock/" + item).then(response => {
                    this.requisitionItem = response.data;
                }).catch(e => {
                    console.log(e);
                });
            },
            showBudget(id, index) {
                this.$http.get("/api/get-budget-info/" + id).then(response => {
                    $(".budget-" + index).html("Budget:" + response.data.totalBudget + ", Budget Left:" + response.data.totalBudgetLeft);
                }).catch(e => {
                    console.log(e);
                    this.$snotify.error(e.response.data.error)
                    $(".budget-" + index).html(e.response.data.error);
                });
            },
            edit(item) {
                $("#requisitionEditModal").modal("show");
                this.$http.get("/api/requisition/" + item).then(response => {
                    this.requisitionItem = response.data;

                    this.RequisitionTitle = this.requisitionItem.title;
                    this.RequisitionPriority = this.requisitionItem.priority;
                    this.RequisitionRemarks = this.requisitionItem.remarks;
                    this.country_id = this.requisitionItem.country_id;
                    this.port_id = this.requisitionItem.port_id;
                    this.SupplyAddress = this.requisitionItem.address;
                    this.SupplyDate = this.requisitionItem.supply_date;
                    this.SupplyETA = this.requisitionItem.vessel_eta;
                    this.SupplyETD = this.requisitionItem.vessel_etd;
                    this.SupplyAdvised = this.requisitionItem.advised_on_date;
                    this.getCountriesport();
                }).catch(e => {
                    console.log(e);
                });
            },
            copy(item) {
                $("#requisitionCopyModal").modal("show");
                this.$http.get("/api/requisition/" + item).then(response => {
                    this.requisitionItem = response.data;
                    this.RequisitionTitle = this.requisitionItem.title;
                    this.RequisitionPriority = this.requisitionItem.priority;
                    this.RequisitionRemarks = this.requisitionItem.remarks;
                    this.country_id = this.requisitionItem.country_id;
                    this.port_id = this.requisitionItem.port_id;
                    this.SupplyAddress = this.requisitionItem.address;
                    this.SupplyDate = this.requisitionItem.supply_date;
                    this.SupplyETA = this.requisitionItem.vessel_eta;
                    this.SupplyETD = this.requisitionItem.vessel_etd;
                    this.SupplyAdvised = this.requisitionItem.advised_on_date;
                    this.getCountriesport();
                }).catch(e => {
                    console.log(e);
                });
            },
            destroy(item) {
                this.$dialog.confirm('Are you sure to delete?')
                    .then(() => {
                        this.$http.delete("/api/opening-stock/" + item).then(response => {
                            this.getRequisitionData();
                            this.$snotify.success("Data deleted successfully");
                        }).catch(error => {
                            var err = error.response.data.error;
                            this.$snotify.error(err);
                        });
                    })
                    .catch(function () {
                    });
            },
            formRequestQuotation: function (e) {
                e.preventDefault();
                if (this.selected.length > 0) {
                    const requestQuotationData = {
                        requisition_id: this.requestRequisitionId,
                        items: this.selected,
                    }
                    this.isDisabled = true;
                    this.$http.post(`/api/request-for-quotation`, requestQuotationData).then((response) => {
                        $("#quotationModal").modal("hide");
                        this.isDisabled = false;
                        if (response.data.success) {
                            this.$snotify.success("Request quotation created successfully");
                            this.requestRequisitionId = '';
                            this.selected = [];
                            $("#quotationModal").modal("hide");
                        }
                    }).catch((error) => {
                        this.isDisabled = false;
                        if (error.response.status) {
                            this.$snotify.error("Request quotation failed, please try again later.");
                        }
                    });
                } else {
                    this.$snotify.error("Please select at least one supplier");
                }
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.itemId = id;
                this.itemName = text;
            },

            /**
             * Requisition item request approve function
             * Recive requistion item id
             */
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {type: type, status: status, key: key, ref_id: ItemId}).then((response) => {
                    if (response.data.success) {
                        this.getRequisitionData();
                        this.$snotify.success("Request Approved Successfully.");
                    }
                }).catch((error) => {
                    if (error.response.status) {
                        this.$snotify.error("Request Approval Failed");
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
