<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left"> User List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Vessel Name</th>
                          <th>Nationality</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(user,index) in useInfos" :key="user.id">
                          <td>{{index+1}}</td>
                          <td>{{user?.username}}</td>
                          <td>{{user?.email}}</td>
                          <td>{{isEmpty(user.role)?user.role?.name:''}}</td>
                          <td>{{isEmpty(user.vessel)?user.vessel?.name:''}}</td>
                          <td>{{isEmpty(user.user_detailes)?user.user_detailes.nationality?.name:''}}</td>
                          <td>
                            <button type="button" @click="show(user)" class="btn btn-info btn-sm">
                              <i class="fa fa-eye"></i>
                            </button>
                            <button type="button" @click="edit(user)" class="btn btn-primary btn-sm">
                              <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" @click="changePassword(user)" class="btn btn-success btn-sm">
                              <i class="fa fa-key"></i>
                            </button>
                            <!-- <button
                                type="button"
                                @click="destroy(user)"
                                class="btn btn-danger btn-sm"
                            >
                                <i class="fa fa-trash"></i>
                            </button> -->
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }} User</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <!-- <alert-error :form="form"></alert-error> -->
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>User Name</label>
                      <input autocomplete="off" v-model="form.username" type="text" name="username" class="form-control" :class="{ 'is-invalid': form.errors.has('username') }">
                      <has-error :form="form" field="username"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Email : </label>
                      <input autocomplete="off" v-model="form.email" type="text" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                      <has-error :form="form" field="email"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel Type </label>
                      <select v-model="form.vessel_type" name="vessel_type" class="form-control" :class="{ 'is-invalid': form.errors.has('vessel_type') }" @change="getVesselInfo">
                        <option value="" selected>Select Vessel Type</option>
                        <option v-for="type in vesselType" :key="type.id" :value="type.id">{{type.name}}</option>
                      </select>
                      <has-error :form="form" field="vessel_type"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Vessel </label>
                      <select v-model="form.vessel_id" name="vessel_type" class="form-control" :class="{ 'is-invalid': form.errors.has('vessel_type') }">
                        <option value="" selected>Select Vessel</option>
                        <option v-for="vessel in vessels" :key="vessel.id" :value="vessel.id">{{vessel.name}}</option>
                      </select>
                      <has-error :form="form" field="vessel_type"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Role Name</label>
                      <select v-model="form.role_id" name="role_id" class="form-control" :class="{ 'is-invalid': form.errors.has('role_id') }">
                        <option value="" selected>Select Role</option>
                        <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                      </select>
                      <has-error :form="form" field="role_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label> License Number</label>
                      <input autocomplete="off" v-model="form.license_number" type="text" name="license_number" class="form-control" :class="{ 'is-invalid': form.errors.has('license_number') }">
                      <has-error :form="form" field="license_number"></has-error>
                    </div>
                    <div v-if="!editMode" class="form-group">
                      <label>User Password </label>
                      <input autocomplete="off" v-model="form.password" type="text" name="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                      <has-error :form="form" field="password"></has-error>
                    </div>
                    <div v-if="!editMode" class="form-group">
                      <label>Confirm Password </label>
                      <input autocomplete="off" v-model="form.confirm_password" type="text" name="confirm_password" class="form-control" :class="{ 'is-invalid': form.errors.has('confirm_password') }">
                      <has-error :form="form" field="confirm_password"></has-error>
                    </div>


                  </div>
                  <div class="col-md-6">

                    <div class="form-group">
                      <label> Nationality</label>
                      <select v-model="form.nationality" name="nationality" class="form-control" :class="{ 'is-invalid': form.errors.has('nationality') }">
                        <option value="" selected>Select Nationality</option>
                        <option v-for="country in countryes" :key="country.id" :value="country.id">{{country.name}}</option>
                      </select>
                      <has-error :form="form" field="nationality"></has-error>
                    </div>
                    <div class="form-group">
                      <label>License Issuing Country</label>
                      <select v-model="form.liscense_issuing_country" name="liscense_issuing_country" class="form-control" :class="{ 'is-invalid': form.errors.has('liscense_issuing_country') }">
                        <option value="" selected>Select Country</option>
                        <option v-for="country in countryes" :key="country.id" :value="country.id">{{country.name}}</option>
                      </select>
                      <has-error :form="form" field="liscense_issuing_country"></has-error>
                    </div>
                    <div class="form-group">
                      <label>License Validity </label><br>
                      <DatePicker type="date" v-model="form.license_validaty" name="license_validaty" valueType="format"></DatePicker>
                      <has-error :form="form" field="license_validaty"></has-error>
                    </div>
                    <div class="form-group">
                      <label>License Endorsement No by Flag </label>
                      <input autocomplete="off" v-model="form.license_endorsement_number" type="text" name="license_endorsement_number" class="form-control" :class="{ 'is-invalid': form.errors.has('license_endorsement_number') }">
                      <has-error :form="form" field="license_endorsement_number"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Certificate </label>
                      <input type="file" name="certificate" class="form-control des" @change="covertCertificate" accept=".png, .jpg, .jpeg">
                      <has-error :form="form" field="certificate"></has-error>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Signature </label>
                          <input type="file" name="signature" class="form-control des" @change="covertSignature" accept=".png, .jpg, .jpeg">
                          <has-error :form="form" field="signature"></has-error>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <span class="mb-3">Preview</span>
                        <img v-if="uploadedSignature" class="file-upload-image" :src="uploadedSignature" alt="your image"/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!--        show -->
      <div
        class="modal fade"
        id="showModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div>

              </div>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('printArea')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>
            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                  <tr>
                    <th>Name</th>
                    <td> {{userData?.username}}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td> {{userData?.email}}</td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td> {{userData?.role?.name}}</td>
                  </tr>
                  <tr>
                    <th>Vessel</th>
                    <td> {{userData?.vessel?.name}}</td>
                  </tr>
                  <tr>
                    <th>Vessel Type</th>
                    <td>{{userData?.vessel_type?.name}}</td>
                  </tr>
                  <tr>
                    <th>Nationality</th>
                    <td>{{userData?.user_detail?.nationalities?.name}}</td>
                  </tr>
                  <tr>
                    <th>License Number</th>
                    <td> {{userData?.user_detail?.license_number}}</td>
                  </tr>
                  <tr>
                    <th>License Issuing Country</th>
                    <td> {{userData?.user_detail?.liscense_issuing?.name}}</td>
                  </tr>
                  <tr>
                    <th>License Validity</th>
                    <td> {{userData?.user_detail?.license_validaty}}</td>
                  </tr>
                  <tr>
                    <th>License Endorsement No by Flag</th>
                    <td>{{userData?.user_detail?.license_endorsement_number}}</td>
                  </tr>

                  <tr>
                    <th>Certificate</th>
                    <td><a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="userData && userData.user_detail && userData.user_detail.certificate != null" :href="userData && userData.user_detail && userData.user_detail.certificate">View</a></td>
                  </tr>

                  <tr>
                    <th>Signature</th>
                    <td>
                      <img height="100" width="200" v-if="userData && userData.user_detail && userData.user_detail.signature != null" :src="userData && userData.user_detail && userData.user_detail.signature" alt="">
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>


              <div id="printArea" style="display: none">
                <div style="text-align: center;line-height: 20px;">
                  <h2>{{ appName }}</h2>
                  <h3>User Details </h3>

                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>

                </div>

                <div class="table">
                  <table class="table  print-table" style="width: 100%">
                    <tbody>
                    <tr>
                      <th>Name</th>
                      <td> {{userData?.username}}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td> {{userData?.email}}</td>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <td> {{userData?.role?.name}}</td>
                    </tr>
                    <tr>
                      <th>Vessel</th>
                      <td> {{userData?.vessel?.name}}</td>
                    </tr>
                    <tr>
                      <th>Vessel Type</th>
                      <td>{{userData?.vessel_type?.name}}</td>
                    </tr>
                    <tr>
                      <th>Nationality</th>
                      <td>{{userData?.user_detail?.nationalities?.name}}</td>
                    </tr>
                    <tr>
                      <th>License Number</th>
                      <td> {{userData?.user_detail?.license_number}}</td>
                    </tr>
                    <tr>
                      <th>License Issuing Country</th>
                      <td> {{userData?.user_detail?.liscense_issuing?.name}}</td>
                    </tr>
                    <tr>
                      <th>License Validity</th>
                      <td> {{userData?.user_detail?.license_validaty}}</td>
                    </tr>
                    <tr>
                      <th>License Endorsement No by Flag</th>
                      <td>{{userData?.user_detail?.license_endorsement_number}}</td>
                    </tr>

                    <tr>
                      <th>Certificate</th>
                      <td><a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="userData && userData.user_detail && userData.user_detail.certificate != null" :href="userData && userData.user_detail && userData.user_detail.certificate">View</a></td>
                    </tr>

                    <tr>
                      <th>Signature</th>
                      <td>
                        <img height="100" width="200" v-if="userData && userData.user_detail && userData.user_detail.signature != null" :src="userData && userData.user_detail && userData.user_detail.signature" alt="">
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <!--        Change password-->
      <div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog " role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{this?.changePasswordForm?.username}} Change Password</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="changePasswordStore()" @keydown="changePasswordForm.onKeydown($event)">
              <div class="modal-body">
                <!-- <alert-error :form="form"></alert-error> -->
                <div class="row">
                  <div class="col-12">

                    <div class="form-group">
                      <label>User Password </label>
                      <input autocomplete="off" v-model="changePasswordForm.password" type="password" name="password" class="form-control" :class="{ 'is-invalid': changePasswordForm.errors.has('password') }">
                      <has-error :form="changePasswordForm" field="password"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Confirm Password </label>
                      <input autocomplete="off" v-model="changePasswordForm.confirm_password" type="password" name="confirm_password" class="form-control" :class="{ 'is-invalid': changePasswordForm.errors.has('confirm_password') }">
                      <has-error :form="changePasswordForm" field="confirm_password"></has-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="changePasswordForm.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->

</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import auth from "@/auth";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import printMixin from "@/mixin/printMixin";

    export default {
        components: {DatePicker, PulseLoader},
        mixins: [goBackButtonMixin, printMixin],
        name: "User",
        data() {
            return {
                loading: false,
                editMode: false,
                query: "",
                queryFiled: "name",
                appName: process.env.VUE_APP_NAME,
                roles: [],
                countryes: [],
                vesselType: [],
                vessels: [],
                useInfos: [],
                userData: {},
                uploadedSignature: '',
                form: new Form({
                    id: "",
                    username: "",
                    role_id: '',
                    email: '',
                    password: '',
                    vessel_type: '',
                    vessel_id: '',
                    confirm_password: '',
                    license_number: '',
                    nationality: '',
                    liscense_issuing_country: '',
                    license_endorsement_number: '',
                    license_validaty: '',
                    certificate: "",
                    signature: '',
                    status: 'Active'
                }),
                changePasswordForm: new Form({
                    id: "",
                    username: '',
                    password: '',
                    confirm_password: '',
                }),
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getAllData();
        },
        methods: {
            getAllData() {
                this.loading = true;
                this.$http.get("/api/filter/user-list").then(response => {
                    this.loading = false
                    this.useInfos = response.data.success;
                }).catch(e => {
                    this.loading = false
                });
            },
            allRole() {
                //  country
                this.$http.get("/api/filter/get-active-role").then(response => {
                    this.roles = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            allCountry() {
                //  country
                this.$http.get("/api/country").then(response => {
                    this.countryes = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            vesselTypeInfo() {
                this.$http.get("/api/vessel-type").then(response => {
                    this.vesselType = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            vesselInfo() {
                this.$http.get("/api/vessel").then(response => {
                    this.vessels = response.data.success.vessel;
                }).catch(e => {
                    console.log(e);
                });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.allRole();
                this.allCountry();
                this.vesselTypeInfo();
                this.vesselInfo();
                $("#roleModal").modal("show");
            },
            covertSignature(event) {
                let file = event.target.files[0];
                this.form.signature = file;
                this.uploadedSignature = URL.createObjectURL(file);
            },
            covertCertificate(event) {
                let file = event.target.files[0];
                this.form.certificate = file;
            },
            store() {
                this.form.busy = true;
                this.form.post("/api/user").then(response => {
                    this.getAllData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("User Created Successfully ");
                    } else {
                        this.$snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                });
            },
            edit(user) {
                console.log(user);
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(user);
                this.form.license_number = this.isEmpty(user.user_detailes) ? user.user_detailes?.license_number : '';
                this.form.nationality = this.isEmpty(user.user_detailes) ? user.user_detailes?.nationality?.id : '';
                this.form.liscense_issuing_country = this.isEmpty(user.user_detailes) ? user?.user_detailes?.liscense_issuing_country : '';
                this.form.license_endorsement_number = this.isEmpty(user.user_detailes) ? user?.user_detailes?.license_endorsement_number : '';
                this.form.license_validaty = this.isEmpty(user.user_detailes) ? user?.user_detailes?.license_validaty : '';
                this.allRole();
                this.allCountry();
                this.vesselTypeInfo();
                this.vesselInfo();
                this.getVesselInfo();
                $("#roleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/user/" + this.form.id).then(response => {
                    this.getAllData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("User  Successfully Updated");
                    } else {
                        this.$snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                });
            },
            changePasswordStore() {
                this.changePasswordForm.busy = true;
                this.changePasswordForm.put("/api/update-user-password/" + this.changePasswordForm.id).then(response => {
                    this.getAllData();
                    $("#changePasswordModal").modal("hide");
                    if (this.changePasswordForm.successful) {
                        this.$snotify.success("Change Password Successfully");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later.",
                            "Error"
                        );
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                    if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                });
            },
            changePassword(user) {
                this.changePasswordForm.reset();
                this.changePasswordForm.clear();
                this.changePasswordForm.fill(user);
                $("#changePasswordModal").modal("show");
            },
            destroy(role) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/role/" + role.id).then(response => {
                        this.getData();
                        this.$snotify.success(
                            "Role Successfully Deleted",
                            "Success"
                        );
                    }).catch(error => {
                        var err = error.response.data.error;
                        this.$snotify.error(err
                        );
                    });
                }).catch(function () {

                });
            },
            show(user) {
                this.$http.get("/api/user/" + user.id).then(response => {
                    this.userData = response.data;
                }).catch(e => {
                    console.log(e)
                });
                $("#showModal").modal("show");
            },
            getVesselInfo() {
                this.$http.get("/api/vessel-info/" + this.form.vessel_type).then(response => {
                    this.vessels = [];
                    this.vessels = response.data.success.vessel;
                }).catch(e => {
                    console.log(e);
                });
            },
            isEmpty(obj) {
                if (obj != null) {
                    return true;
                } else {
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

  .des {
    height: 36% !important;
  }
</style>
