<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class>
          <div class="x_panel">
            <h2 class="float-left">Running Hour Report</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button type="button" class="btn btn-info btn-sm" @click="create">
                  Add New
                  <i class="fa fa-plus"></i>
                </button>
              </li>
            </ul>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-11"></div>
                    <div class="col-md-1">
                      <div class="form-group">
                        <button
                          @click="jobListEnable"
                          class="form-control btn-info btn btn-sm"
                        >View Job List
                        </button>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="pull-right">
                    <a
                      href="javascript:;"
                      @click="print('printableArea')"
                      class="btn btn-warning btn-sm"
                    >
                      <i class="fa fa-print"></i>
                    </a>
                  </button>
                  <div id="printableArea">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>

                      <table
                        id="datatables"
                        class="table table-sm table-striped table-bordered text-center"
                        style="width:100%"
                      >
                        <thead>
                        <tr>
                          <th colspan="2">
                            <h6>Job Information</h6>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th style="width:20% !important">
                            <b>Job Name</b>
                          </th>
                          <td>{{jobInfo.name}}</td>
                        </tr>
                        <tr>
                          <th style="width:20% !important">
                            <b>Component Name</b>
                          </th>
                          <td>{{jobInfo.component.name}}</td>
                        </tr>
                        <tr>
                          <th style="width:20% !important">
                            <b>Code</b>
                          </th>
                          <td>{{jobInfo.code}}</td>
                        </tr>

                        <tr>
                          <th style="width:20% !important">
                            <b>Counter Type</b>
                          </th>
                          <td>{{jobInfo.job_type.name}}</td>
                        </tr>
                        <!-- <tr>
                          <th style="width:20% !important">
                            <b>Current Running hours</b>
                          </th>
                          <td>{{jobInfo.current_running_hours_value}}</td>
                        </tr>-->
                        <tr>
                          <th style="width:20% !important">
                            <b>Scheduled hour</b>
                          </th>
                          <td>{{jobInfo.running_schedule_value}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-box table-responsive mt-5">
                      <h4
                        class="text-muted font-13 m-b-30 text-center text-black"
                      >Running Hour Update Report</h4>
                      <table
                        id="datatables"
                        class="table table-sm table-striped table-bordered text-center"
                        style="width:100%"
                      >
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Runnig Hour Update Date</th>
                          <th>Maintenance Date</th>
                          <th>Prev Maintenance</th>
                          <th>Current Running Hour</th>
                          <th>Runnign Hour Left</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(info,index) in runningHourUpdateInfo" :key="index" :class="info.running_hour_update_type=='Maintenance'?'bg-secondary':''">
                          <th>{{index+1}}</th>
                          <th>{{info.created_at}}</th>
                          <th>{{info.created_at}}</th>
                          <th>{{info.prev_maintanance_running_hour}}</th>
                          <th>{{info.current_running_hour_value}}</th>
                          <th>{{parseInt(parseInt(info.prev_maintanance_running_hour)+parseInt(jobInfo.running_schedule_value))-parseInt(info.current_running_hour_value)}}</th>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="runnginHourUpdate" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">Runnign Hour Update</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="checkbox" v-model="form.isEnabled"/>
                      <label>&nbsp;&nbsp; Apply this all child component and subcomponent</label>
                    </div>
                    <div class="form-group">
                      <label>Running Hours at previous Maintenance :</label>
                      <input
                        readonly
                        autocomplete="off"
                        v-model="form.prev_maintanance_running_hour"
                        name="running_hours_value"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('running_hours_value') }"
                      />
                      <has-error :form="form" field="running_hours_value"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Current Running Hour Value :</label>
                      <input
                        autocomplete="off"
                        v-model="form.current_running_hour_value"
                        name="current_running_hour_value"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('current_running_hour_value') }"
                      />
                      <has-error :form="form" field="current_running_hour_value"></has-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../auth'

    export default {
        name: 'RunningHourJob',
        components: {DatePicker},
        props: {
            jobInfo: Object,
        },
        data() {
            return {
                value: '',
                runningHourUpdateInfo: [],
                form: new Form({
                    isEnabled: false,
                    job_id: this.jobInfo.id,
                    prev_maintanance_running_hour: this.jobInfo.running_hours_value,
                    schedule_hour: this.jobInfo.running_schedule_value,
                    current_running_hour_value: this.jobInfo.current_running_hours_value,
                }),
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1,
                },
            }
        },
        mounted() {
            this.getRunningHourUpate()
        },
        methods: {
            create() {
                $('#runnginHourUpdate').modal('show')
            },

            jobListEnable() {
                this.$emit('enableListMode')
            },
            getRunningHourUpate() {
                this.$http.get('/api/running-hour-update-list/' + this.jobInfo.id).then((response) => {
                    this.runningHourUpdateInfo = response.data.success;
                }).catch((e) => {
                    console.log(e)
                })
            },

            store() {
                this.form.busy = true
                this.form.post('/api/running-hour-update').then((response) => {
                    $('.dataTables_empty').closest('tr').remove()
                    // this.getData();
                    $('#runnginHourUpdate').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success(' Running Hour Update Successfully')
                        this.$emit('enableListMode')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch((e) => {
                    var error = this.form.errors.errors.error
                    this.$snotify.error(error)
                })
            },

            //extra function for print area
            print(el) {
                this.$htmlToPaper(el)
            },
        },
    }
</script>

<style>
  .mx-datepicker-popup {
    z-index: 9000 !important;
  }

  .des {
    height: 36% !important;
  }
</style>
