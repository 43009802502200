<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Ticket List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right ">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
              </li>
            </ul>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <table id="datatables" class="table table-striped table-bordered  text-center table-sm" style="width:100%">
                      <thead>
                      <tr>
                        <th>Ticket ID</th>
                        <th> Title</th>
                        <th> Email</th>
                        <th> Vessel</th>
                        <th> Category</th>
                        <th> Status</th>
                        <th> Priority</th>
                        <th> Date</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item , index) in items" :key="item.id">
                        <td>{{item?.ticket_code}}</td>
                        <td>{{item?.title}}</td>
                        <td>{{item?.email}}</td>
                        <td>{{item?.vessel?.name}}</td>
                        <td>{{item?.category?.name}}</td>
                        <td>
                          <span v-if="item?.status == 'Open'"  class="badge badge-primary">  {{item?.status}}</span>
                          <span v-else-if="item?.status == 'In Progress'"  class="badge badge-info">  {{item?.status}}</span>
                          <span v-else-if="item?.status == 'On Hold'"  class="badge badge-danger">  {{item?.status}}</span>
                          <span v-else-if="item?.status == 'Closed'"  class="badge badge-warning">  {{item?.status}}</span>
                          <span v-else  class="badge badge-success">{{item?.status}}</span>
                        <td >
                          <span v-if="item?.priority == 'Low'"  class="badge badge-warning">  {{item?.priority}}</span>
                          <span v-else-if="item?.priority == 'Medium'"  class="badge badge-success">  {{item?.priority}}</span>
                          <span v-else-if="item?.priority == 'High'"  class="badge badge-danger">  {{item?.priority}}</span>
                          <span v-else  class="badge badge-primary">{{item?.priority}}</span>
                        </td>
                        <td>{{item?.date}}</td>
                        <td>
                          <button type="button" @click="edit(item)" class="btn btn-primary btn-sm">
                            <i class="fa fa-edit"></i>
                          </button>
                          <button type="button" @click="conversation(item)" class="btn btn-info btn-sm">
                            <i class="fa fa-reply"></i>
                          </button>
                          <button
                            type="button"
                            @click="destroy(item)"
                            class="btn btn-danger btn-sm"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="customerModalLongTitle"
            > {{ editMode ? 'Edit' : 'Add New' }} Ticket </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <alert-error :form="form"></alert-error>
              <div class="row">
                 <div class="col-md-6 col-12">
                   <div class="form-group">
                     <label>Title</label>
                     <input autocomplete="off" v-model="form.title" type="text" name="title" class="form-control" :class="{ 'is-invalid': form.errors.has('title') }">
                     <has-error :form="form" field="title"></has-error>
                   </div>
                 </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input autocomplete="off" v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                    <has-error :form="form" field="email"></has-error>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Vessel</label>
                    <select v-model="form.vessel_id" name="vessel_id" class="form-control" :class="{ 'is-invalid': form.errors.has('vessel_id') }">
                      <option value="">Select Vessel</option>
                      <option v-for="row in vessels" :key="row.id" :value="row.id">{{row.name}}</option>
                    </select>
                    <has-error :form="form" field="vessel_id"></has-error>
                  </div>
                </div>
                 <div class="col-md-6 col-12">
                   <div class="form-group">
                     <label>Category</label>
                     <select v-model="form.category_id" name="category_id" class="form-control" :class="{ 'is-invalid': form.errors.has('category_id') }">
                       <option v-for="row in ticketCategories" :key="row.id" :value="row.id">{{row.name}}</option>
                     </select>
                     <has-error :form="form" field="category_id"></has-error>
                   </div>
                 </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Status</label>
                    <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                      <option v-for="row in ticketStatus" :key="row" :value="row">{{row}}</option>
                    </select>
                    <has-error :form="form" field="status"></has-error>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Priority</label>
                    <select v-model="form.priority" name="priority" class="form-control" :class="{ 'is-invalid': form.errors.has('priority') }">
                      <option v-for="row in ticketPriority" :key="row" :value="row">{{row}}</option>
                    </select>
                    <has-error :form="form" field="priority"></has-error>
                  </div>
                </div>

                 <div class="  col-12">
                   <div class="form-group">
                     <label>Description</label>
                     <textarea  v-model="form.description" class="form-control" name="" id="" cols="30" rows="10"  :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                      <has-error :form="form" field="description"></has-error>
                   </div>
                 </div>

                <div class="  col-12">
                  <div class="form-group">
                    <label>Attachments (You can select multiple files)</label>
                    <input autocomplete="off" @change="covertFiles" multiple   type="file" name="file" class="form-control"  >
                    <has-error :form="form" field="file"></has-error>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

      <div class="modal fade" id="conversationsModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="customerModalLongTitle"
              > Conversations </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="row p-2 modal-header ">
              <div class="col-md-4 col-12">
                <label>Ticket ID: {{this.conversations?.ticket_code}}</label>
              </div>
              <div class="col-md-4 col-12">
                <label>Title: {{this.conversations?.title}}</label>
              </div>
              <div class="col-md-4 col-12">
                <label>Added By: {{this.conversations?.created_type}}</label>
              </div>
              <div class="col-12">
                <label>Description: {{this.conversations?.description}}</label>
              </div>
               <div class="col-12">
                <label>Attachments:</label>
                <div v-for="file in conversations?.files" :key="file"  >
                   <div class="row">
                     <div class="col-md-4 col-12">
                       <a :href="file" target="_blank"  >
                         <i class="fa fa-download mr-2"></i> Download
                       </a>
                     </div>
                   </div>
                </div>
              </div>
            </div>
            <div class="row p-2 modal-header " :key="conversations.id" v-for="conversations in conversations?.ticket_conversations">
              <div class="col-12">
                <label>Added By: {{conversations?.created_type}}</label>
              </div>

              <div class="col-12">
                <label>Description: {{conversations?.description}}</label>
              </div>
              <div class="col-12">
                <label>Attachments:</label>
                <div v-for="file in conversations?.files" :key="file" class="d-flex align-items-center">
                  <a :href="file" target="_blank" class="d-inline-flex align-items-center">
                    <i class="fa fa-download mr-2"></i> Download
                  </a>
                </div>
              </div>
            </div>


            <form @submit.prevent="storeConversations()" @keydown="conversationForm.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">


                  <div class="  col-12">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea  v-model="conversationForm.description" class="form-control" name="" id="" cols="30" rows="10"  :class="{ 'is-invalid': conversationForm.errors.has('description') }"></textarea>
                      <has-error :form="conversationForm" field="description"></has-error>
                    </div>
                  </div>

                  <div class="  col-12">
                    <div class="form-group">
                      <label>Attachments (You can select multiple files)</label>
                      <input autocomplete="off" @change="convertConversationsFile" multiple   type="file" name="file" class="form-control"  >
                      <has-error :form="conversationForm" field="file"></has-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    <!-- Modal -->
  </div>
  </div>
</template>

<script>

  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import auth from "@/auth";
  import {TICKET_STATUS,TICKET_PRIORITY} from "@/utils/Configuration";

  export default {
    components:{PulseLoader},
    name: 'tickets',
    data () {
      return {
        loading: false,
        editMode: false,
        query: '',
        queryFiled: 'name',
        items: [],
        conversations: null,
        ticketCategories:[],
        vessels:[],
        ticketStatus:TICKET_STATUS,
        ticketPriority:TICKET_PRIORITY,
        form: new Form({
          id: '',
          title: '',
          email: '',
          category_id:'',
          status: '',
          priority: '',
          description: '',
          vessel_id:'',
          files:''
        }),

        conversationForm: new Form({
          id: '',
          ticket_id: '',
          description: '',
          files:''
        }),
        pagination: {
          current_page: 1
        }
      }
    },
    mounted () {
      this.getData();
      this.getTicketCategory();
      this.getVessels();
    },
    methods: {

      getData () {
        this.loading = true
        this.$http.get('/api/tickets').then(response => {
          this.items = response.data.success
          this.loading = false
          setTimeout(() => $('#datatables').DataTable(), 1000)
        }).catch(e => {
          this.loading = false
          let response = e.response;
          if (response.status === 403) {
            this.$snotify.warning(response?.data?.error)
          }
        })
      },
      create () {
        this.editMode = false
        this.form.reset()
        this.form.clear()
        $('#roleModal').modal('show')
      },
      store () {
        this.form.busy = true
        const config = {
          headers: {'content-type': 'multipart/form-data'}
        };
        this.$http.post('/api/tickets',this.form,config).then(response => {
          console.log(response)
          this.getData()
          this.form.busy = false
          $('#roleModal').modal('hide')
          this.$snotify.success('Ticket Successfully Saved')
        }).catch(e => {
          console.log(e)
          let response = e.response
          let status = e.response.status
          if (status === 500){
            let msg  = response.data.error
            this.$snotify.error(msg)
          }
          if (status === 403) {
            this.$snotify.warning(response?.data?.error)
          }
          if (status === 422){
            if (response.data?.errors) {
              const errors = response.data.errors;
              for (const key in errors) {
                this.$snotify.error(errors[key][0])
              }
            }
          }
          this.form.busy = false

        })
      },


      edit (item) {
        this.editMode = true
        this.form.reset()
        this.form.clear()
        this.form.fill(item)
        $('#roleModal').modal('show')
      },



      update () {
        this.form.busy = true
        const config = {
          headers: {'content-type': 'multipart/form-data'}
        };
        this.form._method = 'put';
        this.form.post(`/api/tickets/` + this.form.id, config).then(response => {
          console.log(this.form)
          this.getData()
          console.log("ticker res",response)
          this.form.busy = false
          $('#roleModal').modal('hide')
          this.$snotify.success('Department Successfully Updated')

        }).catch(e => {
          this.form.busy = false
          let response = e.response
          if (response.status === 422){
            if (response.data?.errors) {
              const errors = response.data.errors;
              for (const key in errors) {
                this.$snotify.error(errors[key][0])
              }
            }
          }
        })
      },
      destroy (ticket) {
      this.$dialog.confirm('Do You Want to delete This ?').then(() => {
          this.$http.delete('/api/tickets/' + ticket.id).then(response => {
            this.getData()
            this.$snotify.success(
              'Ticket  Successfully Deleted',
              'Success'
            )
          }).catch(e => {
             let response = e.response?.data
            this.$snotify.error(
               response?.error,
              'error'
            )
          })
        }).catch(function () {

        })

      },

      conversation (item) {
        this.conversationForm.reset()
        this.conversationForm.clear()
        this.conversationForm.ticket_id = item.id
        this.$http.get('/api/ticket-conversations/'+item.id).then(response => {
          console.log(response)
          this.conversations = response.data.success
          $('#conversationsModal').modal('show')
          this.loading = false

        }).catch(e => {
          this.loading = false
          let response = e.response;
          if (response.status === 403) {
            this.$snotify.warning(response?.data?.error)
          }
        })
      },
      storeConversations () {
        this.conversationForm.busy = true
        const config = {
          headers: {'content-type': 'multipart/form-data'}
        };
        this.$http.post('/api/ticket-conversations',this.conversationForm,config).then(response => {
          console.log(response)
          this.getData()
          this.conversationForm.busy = false
          $('#conversationsModal').modal('hide')
          this.$snotify.success('Ticket Conversations Successfully Saved')
        }).catch(e => {
          console.log(e)
          let response = e.response
          let status = e.response.status
          if (status === 500){
            let msg  = response.data.error
            this.$snotify.error(msg)
          }
          if (status === 403) {
            this.$snotify.warning(response?.data?.error)
          }
          if (status === 422){
            if (response.data?.errors) {
              const errors = response.data.errors;
              for (const key in errors) {
                this.$snotify.error(errors[key][0])
              }
            }
          }
          this.conversationForm.busy = false

        })
      },

      getTicketCategory() {
        this.$http.get('/api/get-active-ticket-category').then(response => {
          this.ticketCategories = response.data.success

        }).catch(e => {
          console.log(e)
        })
      },

      getVessels() {
        this.$http.get('/api/get-active-vessel').then(response => {
          this.vessels = response.data.success
        }).catch(e => {
          console.log(e)
        })
      },

      covertFiles(event) {
        let files = event.target.files;
        this.form.files = Array.from(files);
      },
      convertConversationsFile(event) {
        let files = event.target.files;
        this.conversationForm.files = Array.from(files);
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>

<style scoped>

</style>
