<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">

      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <h2 class="float-left">Location</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <router-link to="/location-list">
                  <button class="btn btn-info btn-sm">Add Location <i class="fa fa-plus"></i></button>
                </router-link>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <h5 class="card-header">Location Tree</h5>
              <div class="card-body" style="min-height: 193px">
                <Tree id="my-tree-id" ref="my-tree" :custom-options="myCustomOptions" :custom-styles="myCustomStyles" :nodes="treeDisplayData"></Tree>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Modal -->
      <!-- Modal -->
      <div class="modal fade" id="roleModalEdit" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">Edit Location </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="update()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="form-group">
                  <label>Location Name</label>
                  <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label> Parent Name : </label>
                  <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
                </div>
              </div>
              <div class="modal-footer">
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade" id="roleModal" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle"> Add Location </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="pstore()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="form-group">
                  <label>Location Name</label>
                  <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label> Parent Name : </label>
                  <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
                </div>
              </div>
              <div class="modal-footer">
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    import Tree from 'vuejs-tree'
    import Select2 from 'v-select2-component'
    import auth from '../../../auth'
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'LocationTree',
        components: {
            QrcodeVue,
            Tree,
            Select2,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                myOptions: [],
                treeDisplayData: [],
                form: new Form({
                    id: '',
                    name: '',
                    parent_id: '',
                    vessel_id: auth.getVesselId()
                }),
                pagination: {
                    current_page: 1
                },
                locations: [],
                locatins: [],
                vessel_id: auth.getVesselId()
            }
        },
        computed: {
            myCustomStyles() {
                return {
                    tree: {
                        height: 'auto',
                        maxHeight: '300px',
                        overflowY: 'visible',
                        display: 'inline-block'
                    },
                    expanded: {
                        class: 'fa fa-plus',
                        style: {
                            color: '#007AD5',
                        },
                        active: {
                            class: 'fa fa-minus',
                            style: {
                                color: '#2ECC71'
                            }
                        }
                    },
                    row: {
                        width: '500px',
                        cursor: 'pointer',
                        child: {
                            height: '35px'
                        }
                    },
                    text: {
                        style: {},
                        active: {
                            style: {
                                'font-weight': 'bold',
                                color: '#2ECC71'
                            }
                        }
                    }
                }
            },
            myCustomOptions() {
                return {
                    treeEvents: {
                        expanded: {
                            state: true,
                            fn: this.addRemoveClass,
                        },
                        collapsed: {
                            state: false
                        },
                        selected: {
                            state: false,
                        },
                        checked: {
                            state: true,
                        }
                    },
                    events: {
                        expanded: {
                            state: true
                        },
                        selected: {
                            state: false
                        },
                        checked: {
                            state: false
                        },
                        editableName: {
                            state: true,
                            calledEvent: 'expanded'
                        }
                    },
                    addNode: {
                        state: true,
                        fn: this.addNodeFunction,
                        appearOnHover: true
                    },
                    editNode: {
                        state: true,
                        fn: this.editNodeFunction,
                        appearOnHover: true
                    },
                    deleteNode: {
                        state: true,
                        fn: this.deleteNodeFunction,
                        appearOnHover: true
                    },
                    showTags: true
                }
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            addRemoveClass() {
                $('.expanded').removeClass('fa-plus')
                $('.expanded').addClass('fa-minus')
            },
            getData() {
                this.loading = true
                this.$http.get('/api/filter/location/' + this.vessel_id).then(response => {
                    this.treeDisplayData = response.data.success
                    this.loading = false

                }).catch(e => {
                    this.loading = false

                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },
            async create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.myOptions = [];
                var itemList = await this.$http.get('/api/filter/location/create/' + this.form.vessel_id)
                this.results = itemList.data.success;
                var newObj = {
                    id: '',
                    text: 'No Selected'
                };
                this.myOptions.push(newObj);
                this.results.filter(item => {
                    var newObj2 = {
                        id: item.id,
                        text: item.name
                    };
                    this.myOptions.push(newObj2)
                })
            },

            //edit tree view single node
            editNodeFunction: function (node) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.create()
                this.form.name = node.text
                this.form.parent_id = node.parent_id
                this.form.id = node.id
                $('#roleModalEdit').modal('show')
            },
            //delete tree view signle node
            deleteNodeFunction: function (node) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/location/' + node.id).then(response => {
                        this.getData()
                        this.$snotify.success('Location  Successfully Deleted')
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err
                        )
                    })
                }).catch(function () {

                })
            },
            //add new node in tree view
            addNodeFunction: function (node) {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                this.create()
                this.form.parent_id = node.id
                $('#roleModal').modal('show')
            },

            //add edit delete

            store() {
                this.form.busy = true
                this.form.post('/api/location', this.form).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Location Successfully Saved')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            pstore() {
                this.form.busy = true
                this.form.post('/api/location', this.form).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Location Successfully Saved')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/location/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModalEdit').modal('hide')
                    console.log("ok")
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Location Successfully Updated')
                    } else {
                        this.$snotify.error('Something went wrong try again later.', 'Error')
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(location) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/location/' + location.id).then(response => {
                        this.getData()
                        this.create()
                        this.$snotify.success('Location Successfully Deleted')
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err
                        )
                    })
                }).catch(function () {

                })
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
