<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Main Component List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th> Name</th>
                          <th>Department Name</th>
                          <th>Code</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(smainComponent , index) in mainComponent" :key="smainComponent.id">
                          <td>{{index+1}}</td>
                          <td>{{smainComponent?.name}}</td>
                          <td>{{smainComponent?.department?.name}}</td>
                          <td>{{smainComponent?.code}}</td>
                          <td>{{smainComponent?.status}}</td>
                          <td>
                            <button type="button" @click="edit(smainComponent)" class="btn btn-primary btn-sm">
                              <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" @click="destroy(smainComponent)" class="btn btn-danger btn-sm">
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Main Component</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>

                <div class="form-group">
                  <label>Department Name</label>
                  <select v-model="form.department_id" name="department_id" class="form-control" :class="{ 'is-invalid': form.errors.has('department_id') }">
                    <option value="" selected>Select department</option>
                    <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{ sdepartment.name }}</option>
                  </select>
                  <has-error :form="form" field="department_id"></has-error>
                </div>

                <div class="form-group">
                  <label> Name</label>
                  <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                  <has-error :form="form" field="name"></has-error>
                </div>

                <div class="form-group">
                  <label> Code</label>
                  <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                  <has-error :form="form" field="code"></has-error>
                </div>

                <div class="form-group">
                  <label>Status</label>
                  <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="Activate">Activate</option>
                    <option value="Deactivate">Deactivate</option>
                  </select>
                  <has-error :form="form" field="status"></has-error>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->
    </div>
  </div>
</template>

<script>
    import auth from '../../auth'
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        components: {PulseLoader},

        name: 'MainComponent',
        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                departments: [],
                mainComponent: [],
                selectedDepartment: null,
                form: new Form({
                    id: '',
                    name: '',
                    department_id: '',
                    code: '',
                    vessel_id: auth.getVesselId(),
                    status: 'Activate'
                }),
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        watch: {
            'form.department_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.departments);
                }
            },
        },
        mounted() {
            this.departmentList()
            this.getData()
        },
        methods: {
            updateCode(itemId, components) {
                const selectedComponent = components.find(component => component.id === itemId);
                if (selectedComponent && selectedComponent.code) {
                    return selectedComponent.code + '-';
                }
                return '';
            },

            getData() {
                this.loading = true
                this.$http.get('/api/filter/main-component/' + this.vessel_id).then(response => {
                    this.loading = false
                    this.mainComponent = response.data.success
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                $('#roleModal').modal('show')
            },
            store() {
                this.form.busy = true
                this.form.post('/api/main-component').then(response => {
                    $('.dataTables_empty').closest('tr').remove()
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Main Component Created Successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        var error = this.form.errors.errors.error
                        this.$snotify.error(error)
                    }

                })
            },

            edit(component) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.form.fill(component)
                setTimeout(() => {
                    this.form.code = component.code
                }, 1000)
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true
                this.form._method = 'put';
                this.form.post('/api/main-component/' + this.form.id).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Main component successfully Update')
                    } else {
                        this.$snotify.error('Something went wrong try again later.', 'Error')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            destroy(mainComponent) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/main-component/' + mainComponent.id).then(response => {
                        this.getData()
                        this.$snotify.success('Main Component Successfully Deleted')
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            let response = e.response?.data
                            this.$snotify.error(
                                response?.error,
                                'error'
                            )
                        }
                    })
                }).catch(function () {
                    console.log(e)
                })
            },
            departmentList() {
                this.$http.get('/api/filter/department/' + this.vessel_id).then(response => {
                    this.departments = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
