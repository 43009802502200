<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>Type</th>
      <th>Status</th>
      <th>Quotation No</th>
      <th>Requisition No</th>
      <th>Created By</th>
      <th>Quotation Date</th>
      <th>Requisition Date</th>
      <th>Super. Approval</th>
<!--      <th>PM Approval</th>-->
      <th>TM Approval</th>
      <th>Amount</th>
      <th v-if="!isPrinting">Budget Info</th>
      <th v-if="!isPrinting && (isPermissionExist(PERMISSIONS.QUOTATION_VIEW) || isPermissionExist(PERMISSIONS.CREATE_SUPPLIER_QUOTATION) )">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(quotation , index) in quotationData" :key="quotation.id">
      <td>{{quotation?.type}}</td>
      <td>{{quotation?.status}}</td>
      <td>{{quotation?.quotation_no}}</td>
      <td>{{quotation?.requisition?.requisition_no}}</td>
      <td>{{quotation?.user?.username}}</td>
      <td>{{quotation?.date}}</td>
      <td>{{quotation?.requisition?.date}}</td>
      <td>
         <span v-if="quotation?.superintendent_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.SUPERINTENDING_APPROVAL)">
           <button type="button" title="Approve" class="btn btn-warning btn-sm"
                @click="approveRequest('Quotation', quotation.id, 'Approved', 'superintendent_approval')"> {{quotation?.superintendent_approval}}</button>
         </span>
        <span v-else> {{quotation?.superintendent_approval}} </span>
      </td>
<!--      <td>-->
<!--        <span v-if="quotation.purchase_manager_approval == 'Pending' && !isPrinting  && isPermissionExist(PERMISSIONS.PM_APPROVAL)">-->
<!--            <button type="button" title="Approve" class="btn btn-warning btn-sm"-->
<!--               @click="approveRequest('Quotation', quotation.id, 'Approved', 'purchase_manager_approval')"> {{quotation.purchase_manager_approval}}</button>-->
<!--        </span>-->
<!--        <span v-else> {{quotation.purchase_manager_approval}} </span>-->
<!--      </td>-->
      <td>
        <span v-if="quotation?.technical_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.TM_APPROVAL)">
             <button type="button" title="Approve" class="btn btn-warning btn-sm"
                 @click="approveRequest('Quotation', quotation.id, 'Approved', 'technical_manager_approval')"> {{quotation?.technical_manager_approval}}</button>
         </span>
        <span v-else>  {{quotation?.technical_manager_approval}} </span>
      </td>
      <td>${{quotation?.amount}}</td>
      <td v-if="!isPrinting">
        <button type="button" @click="showBudget(quotation?.requisition_id, index)" title="View" class="btn btn-info btn-sm">Budget Info</button>
        <div :class="`budget-${index}`"></div>
      </td>
      <td v-if="!isPrinting && (isPermissionExist(PERMISSIONS.QUOTATION_VIEW) || isPermissionExist(PERMISSIONS.CREATE_SUPPLIER_QUOTATION) )">
        <button type="button" v-if="isPermissionExist(PERMISSIONS.QUOTATION_VIEW)" @click="show(quotation.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i> View</button>
<!--        <span  v-if="quotation.superintendent_approval == 'Approved' && quotation.purchase_manager_approval == 'Approved' && quotation.technical_manager_approval == 'Approved'">-->
<!--                <button type="button" @click="create(quotation.id)" class="btn btn-success btn-sm" title="Approve & Create PO"><i-->
<!--                   class="fa fa-plus"></i> Create PO</button>-->
           <span  v-if="quotation?.superintendent_approval == 'Approved'   && quotation?.technical_manager_approval == 'Approved' && isPermissionExist(PERMISSIONS.CREATE_SUPPLIER_QUOTATION)">
                <button type="button" @click="createSupplierQutoation(quotation.id)" class="btn btn-success btn-sm" title="Approve & Create PO"><i
                  class="fa fa-plus"></i> Create Supplier Quotation</button>
       </span>
      </td>
    </tr>

    </tbody>
  </table>
</template>

<script>

import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    quotationData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

    showBudget: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    create: {
      type: Function,
      required: false
    },
    approveRequest: {
      type: Function,
      required: false
    },
    createSupplierQutoation: {
      type: Function,
      required: false
    }




  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
