<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class>
          <div class="x_panel">
            <h2 class="float-left">Running Hour Report</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                <button @click="print('printArea','Breakdown History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'running-hours'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
              </li>
            </ul>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div
                    v-if="loading"
                    class="col-md-12 text-center"
                    style="margin-top:25%"
                  >
                    <PulseLoader
                      :loading="loading"
                    ></PulseLoader>
                  </div>
                  <div v-if="!loading">
                    <div class="card-box table-responsive">
                      <form role="form" action="#" @submit="getRunningHourJobs" class="searchForm">
                        <div class="x_panel">
                          <div class="x_content">
                            <div class="row justify-content-center">
                              <div class="col-md-2">
                                <div class="form-group">
                                  <label>Vessel</label>
                                  <select class="form-control" v-model="formData.vessel">
                                    <option value="" selected>Select Vessel</option>
                                    <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group">
                                  <label>Date From</label>
                                  <DatePicker type="date" v-model="formData.dateFrom" valueType="format"></DatePicker>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group">
                                  <label>Date To</label>
                                  <DatePicker type="date" v-model="formData.dateTo" valueType="format"></DatePicker>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div>
                        <div class="mb-3 text-center">
                          <div class="font-weight-bold">Running Hours Report</div>
                          <div v-if="formData.vessel.name != ''">Vessel:
                            <span>{{formData.vessel.name}}  </span>
                          </div>
                          <div v-if="formData.dateFrom != '' && formData.dateTo !=''">
                            Date Period:
                            <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                            <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                          </div>
                        </div>
                        <TableData :openJobRunningHoursModal="openJobRunningHoursModal" :runningHourJobs="runningHourJobs"></TableData>
                      </div>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Running Hours Report</div>
                          <h3>Vessel: <span v-if="formData.vessel">{{formData.vessel.name}}</span></h3>
                          <h3 v-if="formData.dateFrom != '' && formData.dateTo !=''">
                            Date Period: <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                            <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                          </h3>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>

                        </div>
                        <TableData :runningHourJobs="runningHourJobs" :isPrinting="true"></TableData>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Job Running Hours Modal -->
    <div class="modal fade" id="runningHourUpdate" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="runningHourUpdateTitle"> Running Hour Details</h5>

            <div>
              <button @click="print('jobDetailsArea','Running Hour Jobs Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <div class="card-box table-responsive">
                <h4 class="text-muted font-13 text-center text-black">Running Hour Report</h4>
                <ModalData :jobRunningHourDetails="jobRunningHourDetails"></ModalData>
                <div id="jobDetailsArea" style="display: none">
                  <div style="text-align: center">
                    <h2>{{ appName }}</h2>
                    <h3> Running Hour Details</h3>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <ModalData :jobRunningHourDetails="jobRunningHourDetails" :isPrinting="true"></ModalData>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Job Running Hours Modal -->

  </div>
</template>

<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import ModalData from "./ModalData.vue";
    import {printUtil} from '@/utils/print'
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'RunningHourJobReport',
        components: {ModalData, TableData, DatePicker, PulseLoader},
        data() {
            return {
                loading: false,
                value: '',
                runningHourJobs: [],
                appName: process.env.VUE_APP_NAME,
                formData: {
                    vessel: {
                        id: auth.getVesselId(),
                        name: ''
                    },
                    dateFrom: '',
                    dateTo: '',
                },
                vessels: null,
                jobRunningHourDetails: [],
                vessel_id: auth.getVesselId(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getRunningHourJobs();
        },
        methods: {
            prepareExportData() {
                return this.runningHourJobs.map((item, key) => {
                    return {
                        '#': key + 1,
                        'Job Name': item?.name + '(' + item?.code + ')',
                        'Component Name': item?.main_components_name + '(' + item?.main_components_code + ')' + item?.components_name + '(' + item?.component_code + ')',
                        'Prev Main Date': item?.latestJobSchedule?.start_date,
                        'Est. Due Date': item?.latestJobSchedule?.est_due_date,
                        'Prev Main ': item?.running_hours_value,
                        'Maintenance Interval': item?.running_schedule_value,
                        'Current Running Hour': item?.current_running_hours_value,
                        'Current Running Hour Value Date': item?.current_running_hours_value_date,
                        'Running Hour Left': item?.running_hour_left,
                        'Daily Running Avg': item?.daily_running_average,
                        'Est. Days Left': item?.est_days_left,

                    };
                });
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'running-hours.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            checkIsNaN(result) {
                if (isNaN(result)) {
                    return 0;
                } else {
                    return result;
                }
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },
            openJobRunningHoursModal(job_id) {
                this.jobRunningHourDetails = [];
                this.$http.get('/api/job-running-hour-details?job_id=' + job_id).then((response) => {
                    this.jobRunningHourDetails = response.data.success;
                    $('#runningHourUpdate').modal('show');
                }).catch((e) => {
                    console.log(e)
                })
            },
            getRunningHourJobs: function (e) {
                if (e !== undefined) {
                    e.preventDefault()
                }
                this.listData = [];
                this.loading = true;
                if (this.formData.vessel) this.formData.vessel_id = this.formData.vessel.id;
                let params = {
                    vessel_id: this.formData.vessel_id,
                    date_from: this.formData.dateFrom,
                    date_to: this.formData.dateTo
                };
                this.$http.get('/api/running-hour-jobs', {params}).then((response) => {
                    this.runningHourJobs = response.data.success;
                    this.loading = false;
                    this.excelExport();
                }).catch((e) => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            },
        },
    }
</script>

<style>
  .mx-datepicker-popup {
    z-index: 9000 !important;
  }

  .des {
    height: 36% !important;
  }
</style>
