<template>
  <div>
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">Select Vessel </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Vessel Type </label>
                <select v-model="vesselTypeId" required name="vessel_type" class="form-control" @change="getVessel">
                  <option value="" selected>Select Vessel Type</option>
                  <option v-for="type in vesselTypes" :key="type.id" :value="type.id">{{type.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Vessel </label>
                <select v-model="vesselId" required name="vessel_type" class="form-control">
                  <option value="" selected>Select Vessel</option>
                  <option v-for="vessel in vessels" :key="vessel.id" :value="vessel">{{vessel.name}}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" @click="setVessel" class="btn btn-info">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import auth from "@/auth";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        mixins: [goBackButtonMixin],
        name: "select-vessel",
        data() {
            return {
                vesselTypes: [],
                vessels: [],
                vesselTypeId: '',
                vesselId: '',
                user_type: auth.getUserType(),
            }
        },
        mounted() {
            this.veselType();
        },
        methods: {
            veselType() {
                this.$http.get('/api/vessel-info').then(response => {
                    this.vesselTypes = response.data.success.vessel_type;
                }).catch(error => {
                    console.log(e);
                })
            },
            getVessel() {
                this.$http.get('/api/vessel-info/' + this.vesselTypeId).then(response => {
                    this.vessels = response.data.success.vessel;
                }).catch(error => {
                    console.log(e);
                })
            },
            setVessel() {
                if (this.vesselId == '' || this.vesselTypeId == '') {
                    this.$snotify.error("Vessel type and vessel is required", "Error");
                    return false;
                }
                localStorage.setItem('vessel_id', this.vesselId.id);
                localStorage.setItem('vessel_name', this.vesselId.name);
                localStorage.setItem('vessel_type_id', this.vesselTypeId);
                this.$snotify.success("Vessel selected successfully", this.vesselId.name);
                //this.$router.push({name: 'home'});
                window.location.reload();
            }
        }
    }
</script>

<style scoped>

</style>
