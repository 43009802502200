<template>
  <div>
    <table :class="getTableClasses" style="width: 100%" >
      <thead>
      <tr>
        <th><strong>SQ No: </strong>{{quotationDetails.no}}</th>
        <th><strong>Status: </strong>{{quotationDetails.status}}</th>
        <th><strong>Date: </strong>{{quotationDetails.date}}</th>
      </tr>
      <tr>
        <th colspan="3"><strong>Remark: </strong>{{quotationDetails.notes}}</th>
      </tr>
      </thead>
    </table>
    <hr>
    <table :class="getTableClasses" style="width: 100%"  >
      <thead>
      <tr>
        <th>Item name</th>
        <th>Impa code</th>
        <th>Maker Part no</th>
        <th>Maker</th>
        <th>Model</th>
        <th>Remark</th>
        <th>Qty</th>
        <th>Unit</th>
        <th class="text-right">Price</th>
        <th class="text-right">Amount</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item , index) in quotationDetails.supplier_quotation_item" :key="index">
        <td>{{item?.item?.name}}</td>
        <td>{{item?.item?.inpa_code}}</td>
        <td>{{item?.item?.maker_part_no}}</td>
        <td>{{item?.item?.maker}}</td>
        <td>{{item?.item?.model}}</td>
        <td>{{item?.remark}}</td>
        <td>{{item?.qty}}</td>
        <td>{{item?.item?.unit?.name}}</td>
        <td class="text-right">{{item?.price}}</td>
        <td class="text-right">{{item?.amount}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold text-right" colspan="9">Total Amount:</td>
        <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.sub_total}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold text-right" colspan="9">Discount:</td>
        <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.discount_amount}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold text-right" colspan="9">Grand Total:</td>
        <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.grand_total}}</td>
      </tr>
      </tbody>
    </table>


  </div>
</template>



<script>
export default {
  props: {
    quotationDetails: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>
<style scoped>

</style>
