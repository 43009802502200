<template>
  <div>
    <div class="col-md-3 left_col">
      <div class="left_col scroll-view">
        <div class="navbar nav_title" style="border: 0;">
          <router-link to="/" class="site_title">
            <i class="fa fa-ship"></i> PMS
          </router-link>
        </div>

        <div class="clearfix"></div>

        <!-- menu profile quick info -->
        <div class="profile clearfix">
          <div class="profile_pic">
            <img src="admin/images/img.png" alt="..." class="img-circle profile_img"/>
          </div>
          <div class="profile_info">
            <span>Welcome,</span>
            <h2>{{ username }}</h2>
          </div>
        </div>
        <!-- /menu profile quick info -->

        <br/>

        <!-- sidebar menu -->
        <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
          <div class="menu_section">
            <ul class="nav side-menu">
              <li><router-link to="/"><i class="fa fa-home"></i> Home</router-link></li>

              <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.MAINTENANCE)" ><router-link to="/maintenance"><i class="fa fa-wrench"></i> Maintenance  </router-link></li>
              <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.ITEM)" ><router-link to="/item"><i class="fa fa-list"></i> Inventory  </router-link></li>

              <li v-if="isSideBarpermission && (isPermissionExist(PERMISSIONS.BUDGET_HEAD) || isPermissionExist(PERMISSIONS.BUDGET) || isPermissionExist(PERMISSIONS.BUDGET_REPORT) )">
                <a>
                  <i class="fa fa-money"></i> Budget
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu">
                  <li>
                    <router-link v-if="isPermissionExist(PERMISSIONS.BUDGET_HEAD)" to="/budgethead-add">Budget Head</router-link>
                  </li>
                  <li>
                    <router-link v-if="isPermissionExist(PERMISSIONS.BUDGET)" to="/budget">Budget</router-link>
                  </li>
                  <li>
                    <router-link v-if="isPermissionExist(PERMISSIONS.BUDGET_REPORT)"  to="/budget-report">Budget Report</router-link>
                  </li>
                </ul>
              </li>
              <li v-if="isSideBarpermission &&
                  (isPermissionExist(PERMISSIONS.REQUISITION) || isPermissionExist(PERMISSIONS.AUTO_REQUISITION) ||
                   isPermissionExist(PERMISSIONS.QUOTATION) || isPermissionExist(PERMISSIONS.PURCHASE_ORDER) ||
                    isPermissionExist(PERMISSIONS.PURCHASE_RETURN) || isPermissionExist(PERMISSIONS.INVOICE) ||
                    isPermissionExist(PERMISSIONS.VENDOR_EVALUATION) || isPermissionExist(PERMISSIONS.LESS_DELIVERY_LIST)
                    || isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION)
                  )
                    ">
                <a>
                  <i class="fa fa-align-left"></i> Procurement
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu">
                  <li  v-if="isPermissionExist(PERMISSIONS.REQUISITION)" >
                    <router-link to="/requisition-items">Requisition</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.AUTO_REQUISITION)">
                    <router-link  to="/auto-requisition">Auto Requisition</router-link>
                  </li>
                  <li  v-if="isPermissionExist(PERMISSIONS.QUOTATION)" >
                    <router-link to="/quatetion-list">Request Quotations</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION)">
                    <router-link  to="/supplier-quotations">Supplier Quotations</router-link>
                  </li>
<!--                   <li>-->
<!--                      <router-link to="/delivery-list">Quote Comparison</router-link>-->
<!--                   </li>-->
                  <li v-if="isPermissionExist(PERMISSIONS.PURCHASE_ORDER)">
                    <router-link   to="/purchase-list">Purchase Order</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.PURCHASE_RETURN)">
                    <router-link   to="/purchase-return">Purchase Return</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.RECEIVE_ITEMS)">
                    <router-link  to="/delivery-list">Receive Item</router-link>
                  </li>
                  <li  v-if="isPermissionExist(PERMISSIONS.SERVICE_RECEIVE_ITEMS)">
                    <router-link  to="/return-receive-item">Service Receive Item</router-link>
                  </li>
                  <li  v-if="isPermissionExist(PERMISSIONS.LESS_DELIVERY_LIST)" >
                    <router-link to="/less-delivery-list">Less Receive Item</router-link>
                  </li>

                  <li v-if="isPermissionExist(PERMISSIONS.INVOICE)">
                    <router-link  to="/invoice">Invoice</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.VENDOR_EVALUATION)">
                    <router-link   to="/vendor-evaluation">Vendor Evaluation</router-link>
                  </li>
                  <!--
                  <li>
                    <router-link to="/opening-stock">Opening Stock</router-link>
                  </li>-->
                </ul>
              </li>
              <!--              <li v-if="isSideBarpermission">
                              <a>
                                <i class="fa fa-arrow-circle-left"></i> Return
                                <span class="fa fa-chevron-down"></span>
                              </a>
                              <ul class="nav child_menu">
                                <li><router-link to="/service-requisition">Service Requisition</router-link></li>
                                <li><router-link to="/service-quotation">Service Quotation</router-link></li>
                              </ul>
                            </li>-->

              <!-- <li v-if="isSideBarpermission"><a><i class="fa fa-cog"></i> Settings <span class="fa fa-chevron-down"></span></a> -->

              <li v-if="isPermissionExist(PERMISSIONS.DEPARTMENT) || isPermissionExist(PERMISSIONS.COMPONENT) || isPermissionExist(PERMISSIONS.JOB_TYPE) ||
                     isPermissionExist(PERMISSIONS.JOB)
                 ">
                <a>
                  <i class="fa fa-list"></i> Job & Component
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu">
                  <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.DEPARTMENT)">
                    <router-link  to="/department">Department</router-link>
                  </li>

                  <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.COMPONENT)">
                    <a>
                      <i class="fa fa-th-list"></i> Component
                      <span class="fa fa-chevron-down"></span>
                    </a>
                    <ul class="nav child_menu">
                      <li>
                        <router-link to="/main-component">Main Component</router-link>
                      </li>
                      <li>
                        <router-link to="/component">Component List</router-link>
                      </li>
                      <li>
                        <router-link to="/sub-component">Sub Component</router-link>
                      </li>
                      <li>
                        <router-link to="/sub-sub-component">Sub Sub Component</router-link>
                      </li>
                    </ul>
                  </li>
                  <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.JOB_TYPE)">
                    <router-link to="/job-type">Job Type</router-link>
                  </li>
                  <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.JOB)">
                    <router-link to="/job">Master Job List</router-link>
                  </li>
                </ul>
              </li>




              <li v-if="isPermissionExist(PERMISSIONS.VESSEL_TYPE) || isPermissionExist(PERMISSIONS.VESSEL) || isPermissionExist(PERMISSIONS.VESSEL_CERTIFICATE_TYPE) || isPermissionExist(PERMISSIONS.VESSEL_CERTIFICATE)">
                <a><i class="fa fa-ship"></i>Vessel<span class="fa fa-chevron-down"></span></a>
                <ul class="nav child_menu">
                  <li v-if="isPermissionExist(PERMISSIONS.VESSEL_TYPE)"><router-link   to="/vesseltype">Vessel Type</router-link></li>
                  <li v-if="isPermissionExist(PERMISSIONS.VESSEL)"><router-link to="/vessel">Vessel List</router-link></li>
                  <li v-if="isPermissionExist(PERMISSIONS.VESSEL_CERTIFICATE_TYPE)" ><router-link  to="/vessel-certificate-type">Vessel Certificate Type</router-link></li>
                  <li  v-if="isPermissionExist(PERMISSIONS.VESSEL_CERTIFICATE)"><router-link  to="/vessel-certificates">Vessel Certificates</router-link></li>
                </ul>
              </li>

              <li v-if="isPermissionExist(PERMISSIONS.USERS) || isPermissionExist(PERMISSIONS.ROLE_PERMISSION)">
                <a><i class="fa fa-users"></i> Users Management<span class="fa fa-chevron-down"></span></a>
                <ul class="nav child_menu">
                  <li v-if="isPermissionExist(PERMISSIONS.USERS)" ><router-link to="/users">User List</router-link></li>
                  <li v-if="isPermissionExist(PERMISSIONS.ROLE_PERMISSION)" ><router-link to="/modules">Module List</router-link></li>
                  <li v-if="isPermissionExist(PERMISSIONS.ROLE_PERMISSION)"><router-link to="/role">Role</router-link></li>
                </ul>
              </li>




              <li v-if="isPermissionExist(PERMISSIONS.VESSEL_SELECT)"> <router-link to="/select-vessel"> <i class="fa fa-ship"></i> Select Vessel</router-link>  </li>

              <li v-if="isSideBarpermission && (isPermissionExist(PERMISSIONS.REQUISITION_REPORT) || isPermissionExist(PERMISSIONS.QUOTATION_REPORT) || isPermissionExist(PERMISSIONS.PURCHASE_REPORT)
               || isPermissionExist(PERMISSIONS.INVOICE_REPORT) || isPermissionExist(PERMISSIONS.STOCK_LEDGER_REPORT) || isPermissionExist(PERMISSIONS.LOCATION_WISE_STOCK_REPORT)
               || isPermissionExist(PERMISSIONS.MAINTENANCE_HISTORY_REPORT) || isPermissionExist(PERMISSIONS.BREAKDOWN_HISTORY_REPORT) || isPermissionExist(PERMISSIONS.OVERDUE_JOB_REPORT)
               || isPermissionExist(PERMISSIONS.SPARE_PARTS_CONSUMPTION_HISTORY_REPORT) || isPermissionExist(PERMISSIONS.JOB_PLANNER_REPORT) || isPermissionExist(PERMISSIONS.INCIDENT_REPORT)
               || isPermissionExist(PERMISSIONS.RETURN_DAMAGE_RECEIVE_ITEM_REPORT) || isPermissionExist(PERMISSIONS.SERVICE_REQ_RETURN_ITEM_REPORT) || isPermissionExist(PERMISSIONS.DEFERRED_JOB_REPORT) || isPermissionExist(PERMISSIONS.RUNNING_HOUR_JOBS_REPORT)
               )">
                <a><i class="fa fa-users"></i> Reports<span class="fa fa-chevron-down"></span></a>
                <ul class="nav child_menu">
                   <li v-if="isSideBarpermission && (  isPermissionExist(PERMISSIONS.STOCK_LEDGER_REPORT) ||
                   isPermissionExist(PERMISSIONS.LOCATION_WISE_STOCK_REPORT) ||
                   isPermissionExist(PERMISSIONS.SPARE_PARTS_CONSUMPTION_HISTORY_REPORT)
                   )">
                     <a><i class="fa fa-list"></i> Inventory<span class="fa fa-chevron-down"></span></a>
                     <ul class="nav child_menu">
                       <li v-if="isPermissionExist(PERMISSIONS.STOCK_LEDGER_REPORT)"><router-link to="/reports/stock-ledger">Stock Ledger</router-link></li>
                       <li v-if="isPermissionExist(PERMISSIONS.LOCATION_WISE_STOCK_REPORT)"><router-link to="/reports/location-wise-stock">Location Wise Stock</router-link></li>
                       <li v-if="isPermissionExist(PERMISSIONS.SPARE_PARTS_CONSUMPTION_HISTORY_REPORT)"><router-link to="/reports/spare-parts">Spare Part Consumption History  </router-link></li>
                     </ul>
                   </li>
                  <li v-if="isSideBarpermission && (isPermissionExist(PERMISSIONS.REQUISITION_REPORT) || isPermissionExist(PERMISSIONS.QUOTATION_REPORT) || isPermissionExist(PERMISSIONS.PURCHASE_REPORT)
               || isPermissionExist(PERMISSIONS.INVOICE_REPORT) ||  isPermissionExist(PERMISSIONS.RETURN_DAMAGE_RECEIVE_ITEM_REPORT) || isPermissionExist(PERMISSIONS.SERVICE_REQ_RETURN_ITEM_REPORT)
               )">
                    <a><i class="fa fa-align-left"></i> Procurement<span class="fa fa-chevron-down"></span></a>
                    <ul class="nav child_menu">
                      <li v-if="isPermissionExist(PERMISSIONS.REQUISITION_REPORT)"><router-link to="/reports/requisition">Requisition Report</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.QUOTATION_REPORT)"><router-link to="/reports/quotation">Quotation Report</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.PURCHASE_REPORT)"><router-link to="/reports/purchase">Purchase Report</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.INVOICE_REPORT)"><router-link to="/reports/invoice">Invoice Report</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.RETURN_DAMAGE_RECEIVE_ITEM_REPORT)"><router-link to="/reports/return-damage-item">Return/Damage/Not Received Item Report</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.SERVICE_REQ_RETURN_ITEM_REPORT)"> <router-link to="/reports/service-req-return">Service Req Return Item</router-link> </li>
                    </ul>
                  </li>

                  <li v-if="isSideBarpermission && (  isPermissionExist(PERMISSIONS.MAINTENANCE_HISTORY_REPORT) || isPermissionExist(PERMISSIONS.BREAKDOWN_HISTORY_REPORT) || isPermissionExist(PERMISSIONS.OVERDUE_JOB_REPORT)
               ||   isPermissionExist(PERMISSIONS.JOB_PLANNER_REPORT)
                 || isPermissionExist(PERMISSIONS.DEFERRED_JOB_REPORT) || isPermissionExist(PERMISSIONS.RUNNING_HOUR_JOBS_REPORT)
               )">
                    <a><i class="fa fa-wrench"></i> Maintenance<span class="fa fa-chevron-down"></span></a>
                    <ul class="nav child_menu">
                      <li v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_HISTORY_REPORT)"><router-link to="/reports/maintenance">Maintenance Summary</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.BREAKDOWN_HISTORY_REPORT)"><router-link to="/reports/breakdown">Breakdown History  </router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.OVERDUE_JOB_REPORT)"><router-link to="/over-due-job">Overdue Job</router-link></li>
                      <li v-if="isPermissionExist(PERMISSIONS.JOB_PLANNER_REPORT)"><router-link to="/job-planner">Job Planner</router-link></li>
                      <li  v-if="isPermissionExist(PERMISSIONS.DEFERRED_JOB_REPORT)"> <router-link to="/reports/deferred-job">Deferred Job</router-link> </li>
                      <li v-if="isPermissionExist(PERMISSIONS.RUNNING_HOUR_JOBS_REPORT)"><router-link to="/running-hours-report">Running Hours Report</router-link></li>
                    </ul>
                  </li>

<!--                  <li v-if="isSideBarpermission && ( isPermissionExist(PERMISSIONS.INCIDENT_REPORT)-->
<!--               )">-->
<!--                    <a><i class="fa fa-list-alt"></i> Others<span class="fa fa-chevron-down"></span></a>-->
<!--                    <ul class="nav child_menu">-->
<!--                      <li v-if="isPermissionExist(PERMISSIONS.INCIDENT_REPORT)"><router-link to="/incident-job-report">Incident Report</router-link></li>-->
<!--                    </ul>-->
<!--                  </li>-->


                </ul>
              </li>
              <li v-if="isSideBarpermission && isPermissionExist(PERMISSIONS.DRYDOCK)"><router-link to="/dry-dock"><i class="fa fa-tasks"></i> Drydock</router-link></li>

              <li v-if=" isPermissionExist(PERMISSIONS.CURRENCY) || isPermissionExist(PERMISSIONS.UNIT) || isPermissionExist(PERMISSIONS.COUNTRY)
                      || isPermissionExist(PERMISSIONS.PORT) || isPermissionExist(PERMISSIONS.DATA_CLONE) || isPermissionExist(PERMISSIONS.SUPPLIER_TYPE) ||
                     isPermissionExist(PERMISSIONS.SUPPLIER) || isPermissionExist(PERMISSIONS.CATEGORY) || isPermissionExist(PERMISSIONS.LOCATION)
                 ">
                <a>
                  <i class="fa fa-cog"></i> Settings
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu">
                  <li class="sub_menu" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE)">
                    <router-link to="/supplier-type">Supplier Type</router-link>
                  </li>
                  <li   class="sub_menu" v-if="isPermissionExist(PERMISSIONS.SUPPLIER)">
                    <router-link to="/supplier-list">Supplier List</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.CATEGORY)" class="sub_menu">
                    <router-link to="/category-tree">Category List</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.LOCATION)" class="sub_menu">
                    <router-link to="/location-tree">Location List</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.CURRENCY)">
                    <router-link  to="/currency">Currency</router-link>
                  </li>
                  <li  v-if="isPermissionExist(PERMISSIONS.UNIT)">
                    <router-link  to="/unit">Unit</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.COUNTRY)" >
                    <router-link  to="/country">Country</router-link>
                  </li>
                  <li v-if="isPermissionExist(PERMISSIONS.PORT)">
                    <router-link   to="/port">Port</router-link>
                  </li>

                  <li v-if="isPermissionExist(PERMISSIONS.DATA_CLONE)">
                    <router-link   to="/clone">Data Clone</router-link>
                  </li>
                  <li>
                    <router-link to="/export-import">Export/Import</router-link>
                  </li>
                </ul>
              </li>

              <li v-if="isPermissionExist(PERMISSIONS.SUPPORT_TICKET)
                 ">
                <a>
                  <i class="fa fa-list"></i> Support Ticket
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu">
                  <li>
                    <router-link  to="/ticket-category">Category</router-link>
                  </li>
                  <li>
                    <router-link  to="/tickets">Tickets</router-link>
                  </li>

                </ul>
              </li>
<!--              <li v-if="isSideBarpermission"><router-link to="/coming-soon"><i class="fa fa-home"></i> Defects List</router-link></li>-->
<!--              <li v-if="isSideBarpermission"><router-link to="/coming-soon"><i class="fa fa-home"></i> Running Requisition List</router-link></li>-->
<!--              <li v-if="isSideBarpermission"><router-link to="/coming-soon"><i class="fa fa-home"></i> Incident History</router-link></li>-->
<!--              <li v-if="isSideBarpermission">-->
<!--                <a><i class="fa fa-users"></i> Knowledge Centre<span class="fa fa-chevron-down"></span></a>-->
<!--                <ul class="nav child_menu">-->
<!--                  <li><router-link to="#">Makers technical bulletin</router-link></li>-->
<!--                  <li><router-link to="#">Makers technical circular</router-link></li>-->
<!--                  <li><router-link to="#">Company circular</router-link></li>-->
<!--                </ul>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
        <!-- /sidebar menu -->

        <!-- /menu footer buttons -->
<!--        <div class="sidebar-footer hidden-small" style="color: #fff">-->
<!--          <a data-toggle="tooltip" data-placement="top" title="Settings"><span class="glyphicon glyphicon-cog" aria-hidden="true"></span></a>-->
<!--          <a data-toggle="tooltip" data-placement="top" title="FullScreen"><span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span></a>-->
<!--          <a data-toggle="tooltip" data-placement="top" title="Lock"><span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span></a>-->
<!--          <a data-toggle="tooltip" data-placement="top" title="Logout" @click="Logout"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></a>-->
<!--        </div>-->
        <!-- /menu footer buttons -->
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../../auth'
import permissionsMixin from "@/mixin/permissionsMixin";

export default {
  mixins: [permissionsMixin],
  name: "Sidebar",
  data() {
    return {
      username: "",
      user_type: auth.getUserType(),
      permissions:auth.getPermissionData()
    };
  },
  computed: {
    isSideBarpermission() {
      var status = this.$isSideBarpermission;
      if (status) {
        return true;
      } else {
        return false;
      }
    },

  },
  mounted() {
    // this.validateToken();
    this.userInfo();
  },
  methods: {
    Logout() {
      auth.logout();
    },
    userInfo () {
      this.$http.post('/api/user-details').then((response) => {
        this.username = response.data.success.username
      }).catch((error) => {
      })
    },
    removeInfo() {
      //this.$store.commit("removeDefaultVessel");
      this.$router.push({name: "select-vessel"});
    },
  },
};
</script>

<style scoped>
</style>
