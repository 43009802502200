<template>
  <div>


    <div>
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left"> Export Import</h2>
              <ul class="nav navbar-right panel_toolbox align-right">

                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box  ">
                      <h6 class="text-danger font-20 m-b-30  "></h6>
                      <form @submit.prevent="uploadFile">
                        <div class="form-group">
                          <label>Import File</label>
                          <input required type="file" ref="fileInput" class="form-control"/>
                        </div>
                        <button :disabled="loading" type="submit" class="btn btn-primary">Upload</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box  ">
                      <p class="text-muted font-13 m-b-30"></p>
                      <form @submit.prevent="importData">
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label>Vessel</label>
                              <select class="form-control" v-model="formData.vessel">
                                <option value="" selected>Select Vessel</option>
                                <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label>Type</label>
                              <select class="form-control" v-model="formData.export_type">
                                <option value="" selected>Select Export Type</option>
                                <option value="data">Data</option>
                                <option value="file">File</option>
                              </select>
                            </div>
                          </div>
                        </div>


                        <button :disabled="loading" type="submit" class="btn btn-primary">Export</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: "ExportImport",
        components: {PulseLoader},
        mixins: [goBackButtonMixin],
        data() {
            return {
                loading: false,
                vessels: null,
                formData: {
                    vessel: '',
                    export_type: ''
                },

            };
        },
        mounted() {
            this.getVessel()
        },
        methods: {
            uploadFile() {
                let formData = new FormData();
                if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                    this.loading = true;
                    formData.append("file", this.$refs.fileInput.files[0]);
                    this.$http.post("/api/import", formData).then((response) => {
                        this.loading = false;
                        this.$snotify.success("Data Imported Successfully");
                    }).catch((e) => {
                        console.log(e)
                        this.loading = false;
                        let response = e.response;
                        if (response?.status === 422) {
                            if (response.data?.errors) {
                                const errors = response.data.errors;
                                for (const key in errors) {
                                    console.log(errors)
                                    this.$snotify.error(errors[key][0])
                                }
                            }
                        } else {
                            this.$snotify.error(response?.data?.error)
                        }

                    });
                } else {
                    this.$snotify.error("File Not Selected")
                }
            },
            importData() {
                let vesselName = ''
                if (this.formData.vessel) {
                    this.formData.vessel_id = this.formData.vessel.id
                    vesselName = this.formData.vessel.name
                    vesselName = vesselName.replace(/\s/g, '_')
                    vesselName = vesselName.replace(/[^a-zA-Z0-9]/g, '')
                    vesselName = vesselName.toLowerCase()
                }

                this.loading = true
                // vessel id is required
                if (!this.formData.vessel_id) {
                    this.$snotify.error('Vessel is required')
                    this.loading = false
                    return
                }
                if (!this.formData.export_type) {
                    this.$snotify.error('Export Type  required')
                    this.loading = false
                    return
                }

                this.$http.post('/api/export', this.formData).then((response) => {
                    this.loading = false
                    if (this.formData.export_type === 'data') {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', vesselName + '_exported_shore_data.json');
                        document.body.appendChild(link);
                        link.click();
                        this.$snotify.success('Data Exported Successfully')
                    } else if (this.formData.export_type === 'file') {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', vesselName + '_exported_shore_file.zip');
                        document.body.appendChild(link);
                        link.click();
                        this.$snotify.success('File Exported Successfully')
                    }
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                    let response = error.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 500) {
                        this.$snotify.error(response?.data?.error)
                    } else {
                        this.$snotify.error('File Export Failed ')
                    }
                })
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },
        }
    }
</script>

<style scoped>
  .module-label {
    font-size: 14px;
    font-weight: bold;
  }

  .selectAllModules {
    margin-top: 10px;
  }

  .red {
    color: red;
  }
</style>
