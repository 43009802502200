<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Purchase No</th>
      <th>Title</th>
      <th>Priority</th>
      <th>Remarks</th>
      <th>Created By</th>
      <th>Quotation No</th>
      <th>Requisition No</th>
      <th>Super. Approval</th>
      <th>TM Approval</th>
      <th>Tax</th>
      <th>Shipping</th>
      <th>Sub Total</th>
      <th>Discount</th>
      <th>Grand Amount</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(row , index) in reportData" :key="index">
      <td>{{++index}}</td>
      <td>{{ row.date | moment('DD/MM/YYYY') }}</td>
      <td>{{row.po_no}}</td>
      <td>{{row.title}}</td>
      <td>{{row.priority}}</td>
      <td>{{row.remarks}}</td>
      <td>{{row.user.username}}</td>

      <td>{{row?.quotation?.quotation_no}}</td>
      <td>{{row?.requisition?.requisition_no}}</td>
      <td> {{row.superintendent_approval}}</td>
      <td> {{row.technical_manager_approval}}</td>
      <td>${{row.tax_amount}}</td>
      <td>${{row.shipping_cost}}</td>
      <td>${{row.amount}}</td>
      <td>${{row.discount_amount}}</td>
      <td>${{row.grand_amount}}</td>
      <td  v-if="!isPrinting">
        <button type="button" @click="show(row.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
      </td>
    </tr>
    <tr v-if="noDataFound">
      <td colspan="18">No data found</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Array,
      required: true
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    show: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
