<template>
  <div>


    <table :class="getTableClasses" style="width: 100%"  >
      <tbody>
      <tr>
        <td><span>Job Name: </span>{{dryDockView?.job_name}}</td>
        <td><span>Vessel Name: </span>{{dryDockView?.vessel?.name}} </td>
      </tr>
      <tr>
        <td><span>Date: </span>{{dryDockView.date}}</td>
        <td><span>LOCATION: </span>{{dryDockView?.location}}</td>
      </tr>
      <tr>
        <td><span>YARD QUOTATION & REMARKS : </span>{{dryDockView?.yard_quotation}}</td>
        <td><span>MANUFACTURER & DETAILS:  </span>{{dryDockView?.manufacturar_details}}</td>
      </tr>
      <tr >
        <td>Job Status: <span v-if="dryDockView?.status == 1">Open</span> <span v-if="dryDockView?.status == 0">Close ({{dryDockView?.job_close_date}})</span></td>
        <td><span>JOB DESCRIPTION:</span>{{dryDockView?.job_description}}</td>
      </tr>
      </tbody>
    </table>
    <hr>
    <table :class="getTableClasses" style="width: 100%" >
      <tbody>
      <tr>
        <td><span>SPEC NO: </span>{{dryDockView?.spec_no}}</td>
        <td><span>Yard No:</span>{{dryDockView?.yard_no}}</td>
        <td><span>GAS FREE CERTIFICATE:</span> {{dryDockView?.gas_free_certificate}}</td>
        <td><span>LIGHT:</span> {{dryDockView?.light}}</td>
      </tr>
      <tr>
        <td><span>VENTILATION:</span> {{dryDockView?.ventilation}}</td>
        <td><span>STAGING  Lx H:</span> {{dryDockView?.staging}}</td>
        <td><span>CLEANING BEFORE:</span> {{dryDockView?.cleaning_before}}</td>
        <td><span>CLEANING AFTER:</span> {{dryDockView?.cleaning_after}}</td>
      </tr>
      <tr>
        <td><span>CRANE:</span> {{dryDockView?.crane}}</td>
        <td><span>INTERNAL YARD TRANSPORT:</span> {{dryDockView?.internal_yard_transport}}</td>
        <td><span>TRANSPORT OUTSIDE YARD:</span> {{dryDockView?.outside_yard_transport}}</td>
        <td><span>ACCESS:</span> {{dryDockView?.access}}</td>
      </tr>
      <tr>
        <td><span>PRESSURE TESTING:</span> {{dryDockView?.preasure_testing}}</td>
        <td><span>CORROISON PROTECTION:</span> {{dryDockView?.corroision_protection}}</td>
        <td><span>PAINT WORK:</span> {{dryDockView?.paint_work}}</td>
        <td><span>MATERIAL OWNERS SUPPLY:</span> {{dryDockView?.material_owner_supply}}</td>
      </tr>
      <tr>
        <td><span>MATERIAL YARDS SUPPLY:</span> {{dryDockView?.material_yard_supply}}</td>
        <td><span>LAGGING:</span> {{dryDockView?.lagging}}</td>
        <td colspan="2"><span>FUNCTION TEST:</span> {{dryDockView?.function_test}}</td>
      </tr>
      <tr>
        <td colspan="4"><span>ENCLOSURES</span></td>
      </tr>
      <tr>
        <td><span>MANUAL/DRAWING:</span> {{dryDockView?.manual_drawing}}</td>
        <td><span>PHOTOGRAPH:</span> {{dryDockView?.photograph}}</td>
        <td><span>SKETCH:</span> {{dryDockView?.sketch}}</td>
        <td><span>SAMPLE:</span> {{dryDockView?.sample}}</td>
      </tr>
      <tr>
        <td colspan="4"><span>THE WORK TO BE SURVEYED ALSO BY</span></td>
      </tr>
      <tr>
        <td><span>CLASS  REPRESENTATIVE:</span> {{dryDockView?.class_representative}}</td>
        <td><span>MFGR'S REPRESENTATIVE:</span> {{dryDockView?.mfgr_representative}}</td>
        <td><span>STATUTORY AUtdORITIES:</span> {{dryDockView?.statutory_representative}}</td>
        <td><span>OWNERS REPRESENTATIVE.:</span> {{dryDockView?.owners_representative}}</td>
      </tr>
      <tr>
        <td colspan="4"><span>SPARE PARTS OR MATERIAL SUPPLY DETAILS</span></td>
      </tr>
      <tr>
        <td><span>SHIPS REQUISITION NO.:</span> {{dryDockView?.ship_req_no}}</td>
        <td><span>P.O. ORDER NO:</span> {{dryDockView?.po_order_no}}</td>
        <td><span>DELIVERY DATE:</span> {{dryDockView?.delivery_date}}</td>
        <td><span>DATE RECEIVED ON BOARD:</span> {{dryDockView?.date_receive_on_board}}</td>
      </tr>
      <tr>
        <td colspan="2"><span>Remarks:</span>{{dryDockView?.remarks}}</td>
        <td colspan="2"><span>Attachment:</span>
          <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="dryDockView?.attachment_view && dryDockView.attachment_view != null" :href="dryDockView.attachment_view "> View Doc</a>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>



<script>
export default {
  props: {
    dryDockView: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>
<style scoped>
   td span {
     font-size: 12px;
     font-weight: 600;
   }
</style>
