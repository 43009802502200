<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Item name</th>
      <th>Impa code</th>
      <th>Model</th>
      <th>Maker</th>
      <th>Maker Part No</th>
      <th>Drawing</th>
      <th>Other Ref</th>
      <th>Opening Stock</th>
      <th>Stock In Total</th>
      <th>Stock Out Total</th>
      <th>Closing Balance</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(stock, index) in stockLedgerData" :key="index">
      <td>{{++index}}</td>
      <td>{{stock.name}}</td>
      <td>{{stock.inpa_code}}</td>
      <td>{{stock.model}}</td>
      <td>{{stock.maker}}</td>
      <td>{{stock.maker_part_no}}</td>
      <td>{{stock.drawing}}</td>
      <td>{{stock.other_ref}}</td>
      <td> <span v-if="stock.opening_stock"> {{stock.opening_stock}}  </span>  <span v-else>-</span>  </td>
      <td> <span v-if="stock.inTotal"> {{stock.inTotal}} </span>  <span v-else>-</span>  </td>
      <td> <span v-if="stock.OutTotal"> {{stock.OutTotal}}  </span>  <span v-else>-</span> </td>
      <td> <span v-if="stock.closing_stock"> {{stock.closing_stock}} </span> <span v-else>-</span>  </td>
    </tr>
    <tr v-if="noDataFound">
      <td colspan="12">No data found</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    stockLedgerData: {
      required: true
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
