<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Supplier Quotation List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button v-if="isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_COMPARE)" type="button" class="btn btn-info btn-sm compare-btn" @click="viewCompare()"><i class="fa fa-compass"></i><span v-if="compare.length > 0" class="compare">{{compare.length}}</span>
                    View Compare
                  </button>
                </li>
                <li>
                  <button @click="print('printArea','Auto Requisition List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <TableData :quotationData="quotationData" :create="create" :addToCompare="addToCompare" :show="show" :approveRequest="approveRequest"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <div class="font-weight-bold">Supplier Quotation List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :quotationData="quotationData" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- view quotation details Modal -->
      <div class="modal fade" id="quotationModal" tabindex="-1" role="dialog" aria-labelledby="quotationModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="quotationModalTitle">Vendor Quotation Details </h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('modalDataPrint','Requisition Item')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>

              </div>

            </div>
            <div class="modal-body">
              <div v-if="quotationDetails">
                <ModalData :quotationDetails="quotationDetails"/>
                <div id="modalDataPrint" style="display: none">
                  <div style="text-align: center">
                    <h2>{{ appName }}</h2>
                    <h4>Vessel Name: {{this.vessel_name}}</h4>
                    <h3>Vendor Quotation Details </h3>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <ModalData :quotationDetails="quotationDetails" :isPrinting="true"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- view quotation details Modal -->

      <!-- Create purchase order Modal -->
      <div class="modal fade" id="purchaseOrderModal" tabindex="-1" role="dialog" aria-labelledby="purchaseOrderModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="purchaseOrderModalTitle">Create Purchase Order</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" v-if="quotationDetails">
              <!--                        <table class="table table-bordered" v-if="quotationDetails">
                                          <thead>
                                          <tr>
                                              <th><strong>Title: </strong>{{quotationDetails.requisition.title}}</th>
                                              <th><strong>Requisition No: </strong>{{quotationDetails.requisition.requisition_no}}</th>
                                              <th><strong>Status: </strong>{{quotationDetails.requisition.status}}</th>
                                              <th><strong>Date: </strong>{{quotationDetails.requisition.date}}</th>
                                          </tr>
                                          <tr>
                                              <th><strong>Remarks: </strong>{{quotationDetails.requisition.remarks}}</th>
                                              <th><strong>Priority: </strong>{{quotationDetails.requisition.priority}}</th>
                                              <th colspan="2"><strong>Address: </strong>{{quotationDetails.requisition.address}}</th>
                                          </tr>
                                          <tr>
                                              <th><strong>Supply Date: </strong>{{quotationDetails.requisition.supply_date}}</th>
                                              <th><strong>Vessel ETA: </strong>{{quotationDetails.requisition.vessel_eta}}</th>
                                              <th><strong>Vessel ETD: </strong>{{quotationDetails.requisition.vessel_etd}}</th>
                                              <th><strong>Advised Date: </strong>{{quotationDetails.requisition.advised_on_date}}</th>
                                          </tr>
                                          <tr>
                                              <th colspan="2"><strong>Country: </strong>
                                                  <span v-if="quotationDetails.requisition.country">
                                                     {{quotationDetails.requisition.country.name}}
                                                 </span>
                                              </th>
                                              <th colspan="2"><strong>Port: </strong>
                                                  <span v-if="quotationDetails.requisition.port">
                                                      {{quotationDetails.requisition.port.name}}
                                                 </span>
                                              </th>
                                          </tr>
                                          </thead>
                                      </table>-->
              <form @submit="storePo" class="requisitionForm">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Purchase Title<span class="text-danger">*</span></label>
                      <input v-model="quotationDetails.title" type="text" name="title" class="form-control" placeholder="Enter title" autocomplete="off" required>
                    </div>
                  </div>


                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Date </label>
                      <datetime format="YYYY-MM-DD" width="300px" v-model="quotationDetails.date"></datetime>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Shipping cost<span class="text-danger">*</span></label>
                      <input v-model="quotationDetails.shipping_cost" type="number" name="shipping_cost" value="0" class="form-control" min="0" step="any" required>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Discount Amount<span class="text-danger">*</span></label>
                      <input v-model="quotationDetails.discount_amount" type="number" name="discount_amount" value="0" class="form-control" min="0" step="any" required>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Tax(%)<span class="text-danger">*</span></label>
                      <input v-model="quotationDetails.tax" type="number" name="tax" value="0" class="form-control" min="0" placeholder="Enter tax" required>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Supplier Currency<span class="text-danger">*</span></label>
                      <select v-model="quotationDetails.currency_id" name="supplier_id" class="form-control" required>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{currency.name}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Currency Rate<span class="text-danger">*</span></label>
                      <input v-model="quotationDetails.currency_rate" type="number" value="0" class="form-control" min="0" step="any" required placeholder="e.g. for US Dollar 1">
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Notes</label>
                      <input v-model="quotationDetails.remark" name="remark" class="form-control" placeholder="Enter notes">
                    </div>
                  </div>
                </div>

                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>Item name</th>
                    <th>Impa code</th>
                    <th>Maker Part no</th>
                    <th>Maker</th>
                    <th>Model</th>
                    <th>Details</th>
                    <th>Unit</th>
                    <th class="text-center">Qty</th>
                    <th class="text-right">Price</th>
                    <th class="text-right">Amount</th>
                    <th>Remark</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item , index) in quotationDetails.supplier_quotation_item" :key="index">
                    <td>{{item?.item?.name}}</td>
                    <td>{{item?.item?.inpa_code}}</td>
                    <td>{{item?.item?.maker_part_no}}</td>
                    <td>{{item?.item?.maker}}</td>
                    <td>{{item?.item?.model}}</td>
                    <td>{{item?.item?.description}}</td>
                    <td>{{item?.item?.unit?.name}}</td>
                    <td class="text-center">
                      {{item?.qty}}
                      <!--                                        <input style="width: 100px;" readonly v-model="quotationDetails.supplier_quotation_item[index].qty" @keyup="calculateAmount(index)" type="number" name="qty" class="form-control" min="0" step="any">-->
                    </td>
                    <td class="text-right">
                      {{item?.price}}
                      <!--                                        <input style="width: 100px;" v-model="quotationDetails.supplier_quotation_item[index].price" type="number" name="price" class="form-control" min="0" step="any" readonly>-->
                    </td>
                    <td class="text-right">
                      {{item?.amount}}
                      <!--                                        <input style="width: 100px;" v-model="quotationDetails.supplier_quotation_item[index].amount" type="number" name="price" class="form-control" min="0" step="any" readonly>-->
                    </td>
                    <td>{{item?.remark}}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold text-right" colspan="9">Sub Total:</td>
                    <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.sub_total}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold text-right" colspan="9">Discount:</td>
                    <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.discount_amount}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold text-right" colspan="9">Grand Total:</td>
                    <td class="text-right">{{quotationDetails?.currency?.code}} {{quotationDetails?.grand_total}}</td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button :disabled="isDisabled" type="submit" class="btn btn-info">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Create purchase order Modal -->


      <div class="modal fade" id="compareModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Compare</h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('compareDataPrint','Compare Item')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-2 ml-auto text-right">
                  <!--                <button class="btn btn-primary btn-sm" @click="compareRows">Compare</button>-->
                </div>
              </div>
              <div v-if="compare">
                <CompareModalData :compare="compare" :setRate="setRate"/>
                <div id="compareDataPrint" style="display: none">
                  <div style="text-align: center">
                    <h2>{{ appName }}</h2>
                    <h4>Vessel Name: {{this.vessel_name}}</h4>
                    <h3>Compare Quotation </h3>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <CompareModalData :compare="compare" :isPrinting="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import ModalData from "./ModalData.vue";
    import CompareModalData from "@/components/supplier-quotation/CompareModalData.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PrintMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, PrintMixin],
        components: {CompareModalData, ModalData, TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                isDisabled: false,
                supplierData: [],
                quotationData: [],
                permissions: auth.getPermissionData(),
                vessel_name: auth.getVesselName(),

                currencies: null,
                quotationDetails: {
                    title: '',
                    currency: {
                        code: ''
                    },
                    date: ''
                },
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                },
                compare: []
            }
        },
        mounted() {
            this.getQuotationData()
            this.getCurrency()
        },
        methods: {
            getQuotationData() {
                this.loading = true
                this.$http.get('/api/filter/supplier-quotation/' + this.vessel_id).then(response => {
                    this.loading = false
                    this.quotationData = response.data.success
                    setTimeout(() => $('#datatables').DataTable({
                        'ordering': false,
                        'aaSorting': [],
                        'bDestroy': true
                    }), 1000)
                }).catch(e => {
                    this.loading = false

                })
            },
            removeItem(ref_id, SupplierQuotationId) {
                var txt
                var r = confirm('Are you sure you want to remove this item!')
                if (r == true) {
                    let data = {
                        ref_id: ref_id,
                        type: 'SupplierQuotationItem'
                    }
                    this.$http.post('/api/remove-item', data).then(response => {
                        this.create(SupplierQuotationId)
                    }).catch(e => {
                        console.log(e)
                    })
                }
            },
            calculateAmount(key) {
                // this.quotationDetails.supplier_quotation_item[key].amount = this.quotationDetails.supplier_quotation_item[key].qty * this.quotationDetails.supplier_quotation_item[key].price
            },
            setDateFormat(event) {
                this.form.date = moment(event.target.value).format('YYYY-MM-DD')
            },
            create(id) {
                this.$http.get('/api/supplier-quotation/' + id).then(response => {
                    this.getSupplierData();
                    this.quotationDetails = response.data
                    this.quotationDetails.supplier_id = response.data.supplier_id
                    this.quotationDetails.quotation_id = response.data.quotation_id
                    this.quotationDetails.title = ''
                    $('#purchaseOrderModal').modal('show')
                }).catch(e => {
                    console.log(e)
                })
            },


            storePo: function (e) {
                e.preventDefault()
                this.isDisabled = true;
                this.quotationDetails.quotation_item = this.quotationDetails.supplier_quotation_item
                this.$http.post('/api/purchase', this.quotationDetails).then(response => {
                    this.isDisabled = false;
                    $('#purchaseOrderModal').modal('hide')
                    if (response.data.success) {
                        this.$snotify.success('PO created successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                    this.getQuotationData()
                }).catch(e => {
                    this.isDisabled = false;
                    this.$snotify.error(e.response.data.error)
                })
            },
            getSupplierData() {
                this.$http.get('/api/supplier').then(response => {
                    this.supplierData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            show(id) {
                $('#quotationModal').modal('show')
                this.$http.get('/api/supplier-quotation/' + id).then(response => {
                    this.quotationDetails = response.data
                }).catch(e => {
                    console.log(e)
                })
            },
            showBudget(id, index) {
                this.$http.get('/api/get-budget-info/' + id).then(response => {
                    $('.budget-' + index).html('Budget:' + response.data.totalBudget + ', Budget Left:' + response.data.totalBudgetLeft)
                }).catch(e => {
                    console.log(e)
                    this.$snotify.error(e.response.data.error)
                    $('.budget-' + index).html(e.response.data.error)
                })
            },
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {
                    type: type,
                    status: status,
                    key: key,
                    ref_id: ItemId
                }).then((response) => {
                    if (response.data.success) {
                        this.getQuotationData()
                        this.$snotify.success('Request Approved Successfully.')
                    }
                }).catch((error) => {
                    if (error.response.status) {
                        this.$snotify.error('Request Approval Failed')
                    }
                })
            },
            getCurrency() {
                this.$http.get('/api/currency').then(response => {
                    this.currencies = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            addToCompare(row) {
                this.$snotify.success('Added to compare list.')
                this.compare.push(row)
            },
            viewCompare() {
                $('#compareModal').modal('show')
            },
            setRate(key) {
                var rate = $('.rate-' + key).val()
                var total = $('.rate-' + key).data('base')
                var conv = rate * total
                $('.grand-total-' + key).html(conv)
                $('.currency_rate_' + key).html(rate)

            },
            compareRows() {
                $('#compareTable').DataTable({
                    'ordering': true,
                    'aaSorting': [],
                    'bDestroy': true
                })
            }
        }
    }
</script>

<style scoped>

</style>
