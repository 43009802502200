export const PERMISSIONS = {
   DASHBOARD:'dashboard',
   MAINTENANCE:'maintenance',
   MAINTENANCE_JOB_INSTRUCTION:'maintenance-job-instruction',
   MAINTENANCE_JOB_DONE:'maintenance-job-done',
   MAINTENANCE_JOB_DONE_ADD:'maintenance-job-done-add',
   MAINTENANCE_JOB_DONE_UPDATE:'maintenance-job-done-update',
   MAINTENANCE_JOB_DONE_APPROVAL:'maintenance-job-done-approval',
   MAINTENANCE_JOB_DONE_OFFICE_APPROVAL:'maintenance-job-done-office-approval',
   MAINTENANCE_DEFER_JOB:'maintenance-defer-job',
   MAINTENANCE_DEFER_JOB_ADD:'maintenance-defer-job-add',
   MAINTENANCE_DEFER_JOB_ADD_APPROVAL:'maintenance-defer-job-add-approval',
   MAINTENANCE_DEFER_JOB_OFFICE_APPROVAL:'maintenance-defer-job-office-approval',
   MAINTENANCE_RISK_ASSESSMENT:'maintenance-risk-assessment',
   MAINTENANCE_SECOND_ENGINEER_RISK_ASSESSMENT:'maintenance-second-engineer-risk-assessment',
   MAINTENANCE_SECOND_ENGINEER_PERMIT_TO_WORK:'maintenance-second-engineer-permit-to-work',
   MAINTENANCE_PERMIT_TO_WORK:'maintenance-permit-to-work',
   MAINTENANCE_PERMIT_TO_WORK_ADD:'maintenance-permit-to-work-add',
   MAINTENANCE_PERMIT_TO_WORK_UPDATE:'maintenance-permit-to-work-update',
   MAINTENANCE_ASSIGNED_RANKED:'maintenance-assigned-ranked',

   SUPPLIER_TYPE:'supplier-type',
   SUPPLIER_TYPE_CREATE:'supplier-type-create',
   SUPPLIER_TYPE_EDIT:'supplier-type-edit',
   SUPPLIER_TYPE_DELETE:'supplier-type-delete',

  SUPPLIER:'supplier',
  SUPPLIER_CREATE:'supplier-create',
  SUPPLIER_EDIT:'supplier-edit',
  SUPPLIER_DELETE:'supplier-delete',

  JOB:'job',
  JOB_ADD:'job-add',
  JOB_EDIT:'job-edit',
  JOB_INSTRUCTION:'job-instruction',
  JOB_RUNNING_HOUR:'job-running-hour',
  JOB_QR_CODE:'job-qr-code',
  JOB_ARCHIVE_SCHEDULE:'job-archive-schedule',
  JOB_COPY_COMPONENT:'job-copy-component',
  JOB_DELETE:'job-delete',
  JOB_ADD_ASSIGNED_RANK:'job-add-assigned-rank',
  JOB_AUTO_APPROVAL:'job-auto-approval',
  JOB_PENDING_APPROVE:'approve-pending-job',
  JOB_ALL_SHOW:'job-show-all',


  ITEM:'item',
  ITEM_ADD:'item-add',
  ITEM_EDIT:'item-edit',
  ITEM_DELETE:'item-delete',
  ITEM_SHOW:'item-show',
  ITEM_QR_CODE:'item-qr-code',
  ITEM_STOCK_ADJUST:'item-stock-adjust',

  REQUISITION:'requisition',
  REQUISITION_ADD:'requisition-add',
  REQUISITION_EDIT:'requisition-edit',
  REQUISITION_SHOW:'requisition-show',
  REQUISITION_DELETE:'requisition-delete',
  REQUISITION_COPY:'requisition-copy',
  REQUISITION_RFQ:'requisition-rfq',
  REQUISITION_DATA_LIST:'requisition-data-list',

  AUTO_REQUISITION:'auto-requisition',

  QUOTATION:'quotation',
  QUOTATION_LIST:'quotation-list',
  QUOTATION_VIEW:'quotation-view',
  CREATE_SUPPLIER_QUOTATION:'create-supplier-quotation',

  SUPPLIER_QUOTATION:'supplier-quotation',
  SUPPLIER_QUOTATION_LIST:'supplier-quotation-list',
  SUPPLIER_QUOTATION_VIEW:'supplier-quotation-view',
  SUPPLIER_QUOTATION_COMPARE:'supplier-quotation-compare',
  SUPPLIER_QUOTATION_PO_CREATE:'supplier-quotation-po-create',

  PURCHASE_ORDER:'purchase-order',
  PURCHASE_RETURN_LIST:'purchase-return-list',
  PURCHASE_ORDER_LIST:'purchase-order-list',
  PURCHASE_ORDER_CREATE:'purchase-order-create',
  PURCHASE_ORDER_EDIT:'purchase-order-edit',
  PURCHASE_ORDER_VIEW:'purchase-order-view',
  RECEIVE_ITEMS:'receive-items',
  SERVICE_RECEIVE_ITEMS:'service-receive-items',
  LESS_DELIVERY_LIST:'less-delivery-list',

  INVOICE:'invoice',
  VENDOR_EVALUATION:'vendor-evaluation',

  CHIEF_ENGINEER_APPROVAL:'chief-engineer-approval',
  SUPERINTENDING_APPROVAL:'superintending-approval',
  PM_APPROVAL:'pm-approval',
  TM_APPROVAL:'tm-approval',


  PURCHASE_RETURN:'purchase-return',
  PURCHASE_RETURN_CREATE:'purchase-return-create',
  PURCHASE_RETURN_DELETE:'purchase-return-delete',
  PURCHASE_RETURN_VIEW:'purchase-return-view',
  PURCHASE_RETURN_ADJUST:'purchase-return-adjust',



  DEPARTMENT:'department',
  DEPARTMENT_VIEW:'department-view',
  COMPONENT:'component',
  COMPONENT_VIEW:'component-view',
  JOB_TYPE:'job-type',
  JOB_TYPE_VIEW:'job-type-view',
  CURRENCY:'currency',
  UNIT:'unit',
  COUNTRY:'country',
  PORT:'port',
  DATA_CLONE:'data-clone',

  VESSEL:'vessel',
  VESSEL_TYPE:'vessel-type',
  VESSEL_CERTIFICATE:'vessel-certificate',
  VESSEL_CERTIFICATE_TYPE:'vessel-certificate-type',

  USERS:'users',
  VESSEL_SELECT:'vessel-select',
  DRYDOCK:'drydock',

  REQUISITION_REPORT:'requisition-reports',
  QUOTATION_REPORT:'quotation-reports',
  PURCHASE_REPORT:'purchase-reports',
  INVOICE_REPORT:'invoice-reports',
  STOCK_LEDGER_REPORT:'stock-ledger-reports',
  LOCATION_WISE_STOCK_REPORT:'location-wise-stock-report',
  MAINTENANCE_HISTORY_REPORT:'maintenance-history-report',
  BREAKDOWN_HISTORY_REPORT:'breakdown-history-report',
  OVERDUE_JOB_REPORT:'overdue-job-report',
  SPARE_PARTS_CONSUMPTION_HISTORY_REPORT:'spare-parts-consumption-history-report',
  JOB_PLANNER_REPORT:'job-planner-report',
  INCIDENT_REPORT:'incident-report',
  RETURN_DAMAGE_RECEIVE_ITEM_REPORT:'return-damage-and-not-received-item-reports',
  SERVICE_REQ_RETURN_ITEM_REPORT:'service-req-return-item-report',
  RUNNING_HOUR_JOBS_REPORT:'running-hour-jobs-report',
  DEFERRED_JOB_REPORT:'deferred-job-report',

  LOCATION:'location',
  CATEGORY:'category',
  BUDGET_HEAD:'budget-head',
  BUDGET:'budget',
  BUDGET_REPORT:'budget-report',
  ROLE_PERMISSION:'role-permission',

  SUPPORT_TICKET:'ticket',









};

