<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <div class="x_title">
              <h2>Module List</h2>
              <ul class="nav navbar-right panel_toolbox">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">
                    Add New
                    <i class="fa fa-plus"></i>
                  </button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
            <div class="x_content table-responsive">
              <table id="datatable" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th> Name</th>
                  <th> Parent Module</th>
                  <th> Slug</th>
                  <th> Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in data" :key="item.id">
                  <td>{{index+1}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item?.parent?.name }}</td>
                  <td>{{item?.slug }}</td>
                  <td>
                    <span v-if="item.status == 1" class="badge badge-success">Activate</span>
                    <span v-else class="badge badge-danger">Deactivate</span>

                  </td>
                  <td>
                    <button type="button" @click="edit(item)" class="btn btn-primary btn-sm">
                      <i class="fa fa-edit"></i>
                    </button>
                    <!--                                        <button-->
                    <!--                                            type="button"-->
                    <!--                                            @click="destroy(item)"-->
                    <!--                                            class="btn btn-danger btn-sm"-->
                    <!--                                        >-->
                    <!--                                            <i class="fa fa-trash"></i>-->
                    <!--                                        </button>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="moduleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="customerModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="customerModalLongTitle"
              >{{ editMode ? "Edit" : "Add New" }} Module </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="form-group">
                  <label> Name</label>
                  <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('name') }"
                  >
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label>Parent Module </label>
                  <select v-model="form.parent_id" name="parent_id" class="form-control" :class="{ 'is-invalid': form.errors.has('parent_id') }">
                    <option value="">Select Category</option>
                    <option v-for="item in parentData" :key="item.id" :value="item.id">{{item.name}}</option>
                  </select>
                  <has-error :form="form" field="parent_id"></has-error>
                </div>
                <div class="form-group">
                  <label>Status </label>
                  <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="1">Activate</option>
                    <option value="0">Deactivate</option>
                  </select>

                  <has-error :form="form" field="status"></has-error>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <!-- Modal -->
  </div>
</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: "Module",
        components: {PulseLoader},
        mixins: [goBackButtonMixin],
        data() {
            return {
                loading: false,
                editMode: false,
                query: "",
                queryFiled: "name",
                data: [],
                parentData: [],
                form: new Form({
                    id: "",
                    name: "",
                    parent_id: "",
                    status: 1,
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                this.loading = true
                this.$http
                    .get("/api/permission/modules")
                    .then(response => {
                        this.loading = false
                        this.data = response.data.success.module;
                        this.parentData = response.data.success.parent_module;
                    })
                    .catch(e => {
                        this.loading = false
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        }
                    });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#moduleModal").modal("show");
            },
            store() {
                this.form.busy = true;
                this.form.post("/api/permission/modules").then(response => {
                    this.getData();
                    $("#moduleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Module Successfully Saved");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later.",
                            "Error"
                        );
                    }
                }).catch(e => {
                    let response = e.response;
                    let status = e.response.status;
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },

            edit(item) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(item);
                $("#moduleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/permission/modules/" + this.form.id).then(response => {
                    this.getData();
                    $("#moduleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Module Successfully Updated", "Success");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later.",
                            "Error"
                        );
                    }
                }).catch(e => {
                    console.log(e)
                    let response = e.response
                    let status = e.response.status
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            destroy(item) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/permission/modules/" + item.id).then(response => {
                        this.getData();
                        this.$snotify.success(
                            "Module Successfully Deleted",
                            "Success"
                        );
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error(
                                "Something went wrong try again later.",
                                "Error"
                            );
                        }
                    });
                }).catch(function () {

                });

            },
        }
    }
</script>

<style scoped>

</style>
