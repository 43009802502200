<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Defer Job Report</h2>
          <div>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
          </div>
        </div>

        <div class="">
          <div class="x_content ">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <form role="form" action="#" @submit="getAllData" class="searchForm">
                      <div class="x_panel">
                        <div class="x_content">
                          <div class="row justify-content-center">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Vessel</label>
                                <select class="form-control" v-model="formData.vessel">
                                  <option value="" selected>Select Vessel</option>
                                  <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-2 mx-2">
                              <div class="form-group">
                                <label>Date From</label>
                                <DatePicker type="date" v-model="formData.dateFrom" valueType="format"></DatePicker>
                              </div>
                            </div>
                            <div class="col-md-2 mx-2">
                              <div class="form-group">
                                <label>Date To</label>
                                <DatePicker type="date" v-model="formData.dateTo" valueType="format"></DatePicker>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
                      <PulseLoader :loading="loading"></PulseLoader>
                    </div>
                    <div class="card-box table-responsive" v-if="listData && !loading">
                      <button @click="print('printArea','Defer Job Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                      <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                      <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'deferred-job'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                      <div class="clearfix"></div>
                      <div>
                        <div class="mb-3 text-center">
                          <div class="font-weight-bold">Defer Job Report</div>
                          <div v-if="formData.vessel.name != ''">Vessel:
                            <span>{{formData.vessel.name}}  </span>
                          </div>
                          <div v-if="formData.dateFrom != '' && formData.dateTo !=''">
                            Date Period:
                            <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                            <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                          </div>
                        </div>
                        <TableData :listData="listData" :ApprovedByChiefEngineer="ApprovedByChiefEngineer" :ApproveByOffice="ApproveByOffice"/>
                      </div>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Deferred Job Report</div>
                          <h3>Vessel: <span v-if="formData.vessel">{{formData.vessel.name}}</span></h3>
                          <h3 v-if="formData.dateFrom != '' && formData.dateTo !=''">
                            Date Period: <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                            <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                          </h3>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>

                        </div>
                        <TableData :listData="listData" :isPrinting="true"/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import auth from '../../../auth'
    import Pagination from "@/components/Pagination.vue";
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import DatePicker from "vue2-datepicker";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'deferred-job-report',
        components: {
            Pagination,
            TableData,
            PulseLoader,
            DatePicker
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                loading: false,
                color: '#1ABB9C',
                size: '10px',
                listData: [],
                vessel_id: auth.getVesselId(),
                vessels: null,
                formData: {
                    vessel: {
                        id: auth.getVesselId(),
                        name: ''
                    },
                    dateFrom: '',
                    dateTo: '',
                },
                pagination: {
                    current_page: 1,
                    offset: 5
                },
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getAllData();
        },
        methods: {
            prepareExportData() {
                return this.listData.map((item, key) => ({
                    '#': key + 1,
                    'Department Name': item?.department + '(' + item?.department_code + ')',
                    'Component Name': item?.main_component + '-' + item?.component + ' -' + item?.sub_component + '-' + item?.sub_sub_component,
                    'Job Name(Code)': item?.job_name + '(' + item?.job_code + ')',
                    'Counter Type': item?.counter_type,
                    'Prev Maintenance Date': item?.prev_start_date,
                    'Next Maintenance Due Date': item?.prev_end_date,
                    'Defer Rescheduled Date': item?.current_end_date,
                    'Reason Deffer': item?.reason_deffer,
                    'Status': item?.status,
                    'Office Approval': item?.office_approval,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'deferred-job.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },
            ApprovedByChiefEngineer(item) {
                // this.form1.busy = true;
                this.$http.get('/api/job-done-permission/' + item?.schedule_id + '/' + item?.id).then(response => {
                    this.$snotify.success('your permission Successfully Completed.');
                    this.getAllData();
                }).catch(e => {
                    console.log(e);
                    // var error=this.form.errors.errors.error;
                    this.$snotify.error('your permission Successfully is not  Completed.')
                })
            },
            ApproveByOffice(item) {
                // this.form1.busy = true;
                this.$http.get('/api/defer-reschedule-approve-office/' + item?.schedule_id + '/' + item?.id).then(response => {
                    this.$snotify.success('your permission Successfully Completed.');
                    this.getAllData();
                }).catch(e => {
                    console.log(e);
                    // var error=this.form.errors.errors.error;
                    this.$snotify.error('your permission Successfully is not  Completed.')
                })
            },
            getAllData: function (e) {
                if (e !== undefined) {
                    e.preventDefault()
                }
                this.listData = [];
                this.loading = true;
                if (this.formData.vessel) this.formData.vessel_id = this.formData.vessel.id;
                let params = {
                    vessel_id: this.formData.vessel_id,
                    date_from: this.formData.dateFrom,
                    date_to: this.formData.dateTo
                };
                this.$http.get('/api/filter/deferred-job/', {params}).then((response) => {
                    this.listData = response.data.success;
                    this.loading = false;
                    this.excelExport();
                }).catch((e) => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        // this.$snotify.error(response?.data?.error)
                    }
                })
            },
        }
    }
</script>

<style scoped>


</style>
