<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'"  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Purchase No</th>
      <th>Vendor Name</th>
      <th>Email</th>
      <th>Contact</th>
      <th>Evaluation Date</th>
      <th>Evaluated By</th>
      <th>Comm. Skill</th>
      <th>Delivery time</th>
      <th>Response time</th>
      <th>Item Quality</th>
      <th>Item Pricing</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row , index) in data" :key="row.id">
      <td>{{++index}}</td>
      <td>{{row?.purchase?.po_no}}</td>
      <td>{{row?.supplier?.name}}</td>
      <td>{{row?.supplier?.email}}</td>
      <td>{{row?.supplier?.contact_no}}</td>
      <td>{{row?.date}}</td>
      <td>{{row?.user?.username}}</td>
      <td>{{row?.communication_skill}}</td>
      <td>{{row?.delivery_time}}</td>
      <td>{{row?.response_time}}</td>
      <td>{{row?.item_quality}}</td>
      <td>{{row?.item_pricing}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
