<template>
  <div v-if="user_type<3">
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left"> Incident Report</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="form-group">
            <label for="">Select Job <span class="text-danger"><sup>*</sup></span></label>
            <select v-model="jobId" class="form-control" @change="searchIncidentReport()">
              <option value="">Please Select Job</option>
              <option v-for=" (job,index) in jobList" :key="index" :value="job.id">{{job.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div
            v-if="loading"
            class="col-md-12 text-center"
            style="margin-top:25%"
          >
            <PulseLoader
              :loading="loading"
            ></PulseLoader>
          </div>
          <div v-if="!loading" class="card">
            <h5 class="card-header">Incident Report List</h5>
            <div class="card-body">
              <table id="datatable" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Report Name</th>
                  <th>Job Name</th>
                  <th>Department</th>
                  <th>Main Component</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for=" (report,index) in reportList" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{report.name}}</td>
                  <td>{{report.job_name}}</td>
                  <td>{{report.department_name}}</td>
                  <td>{{report.main_component_name}}</td>
                  <td>
                    <button type="button" @click="show(report)" class="btn btn-info btn-sm">
                      <i class="fa fa-eye"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Modal -->
          <div class="card">
            <h5 class="card-header" id="customerModalLongTitle">Submit Incident Report </h5>
            <div class="card-body">
              <div class="modal-dialog" role="document">
                <div class="modal-content modal-content2">
                  <form @submit.prevent="store()" @keydown="form.onKeydown($event)">
                    <div class="modal-body">
                      <alert-error :form="form"></alert-error>
                      <div class="form-group">
                        <label>Name of Incident</label>
                        <input v-model="form.name" type="text" class="form-control" placeholder="Name ">
                      </div>
                      <div class="form-group">
                        <label> Brief Details of Incident :</label>
                        <vue-editor v-model="form.details_of_incident"></vue-editor>
                        <!-- <textarea v-model="form.job_remarks" class="resizable_textarea form-control" placeholder="Enter Description" rows="8"></textarea> -->
                        <has-error :form="form" field="job_remarks"></has-error>
                      </div>

                      <div class="form-group">
                        <label> Remarks </label>
                        <textarea name="remarks" v-model="form.remarks" class="form-control" rows="3"></textarea>
                      </div>
                      <div class="form-group">
                        <label> Upload Incident Report </label>
                        <input type="file" @change="previewFiles" class="form-control">
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button :disabled="form.busy || jobId == ''" type="submit" class="btn btn-info" @click="store()">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div id="printableArea" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel">Incident Report Information</h5>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <button @click="print('printArea','Incident Report Information')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>

          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive ">
                  <TableData :reportInfo="reportInfo"/>

                  <div id="printArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <div class="font-weight-bold">Incident Report Information</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                    </div>
                    <TableData :reportInfo="reportInfo" :isPrinting="true"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import {VueEditor} from 'vue2-editor'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";

    export default {
        name: 'IncidentReport',
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        components: {TableData, VueEditor, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                query: '',
                queryFiled: 'name',
                myOptions: [],
                jobList: [],
                reportList: [],
                jobId: '',
                reportInfo: [],
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                form: new Form({
                    id: '',
                    name: '',
                    job_id: '',
                    details_of_incident: '',
                    incident_report: '',
                    remarks: ''
                }),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getAllBrakDownJob()
            // this.create();
        },
        methods: {
            show(reportData) {
                this.reportInfo = reportData;
                $('#showModal').modal('show')
            },
            store() {
                this.form.busy = true;
                this.form.post('/api/incident-report').then(response => {
                    if (this.form.successful) {
                        this.searchIncidentReport();
                        this.$snotify.success('Incident Report Successfully Created');
                        this.form.reset();
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }

                })
            },
            previewFiles(event) {
                var file = event.target.files[0];
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        var imgSrc = event.target.result;
                        this.form.incident_report = imgSrc
                    };
                    reader.readAsDataURL(file)
                } else {
                    alert('Sorry, FileReader API not supported')
                }
            },
            getAllBrakDownJob() {
                this.$http.get('/api/filter/breackdown-job/' + this.vessel_id).then(response => {
                    this.jobList = response.data.success;
                    setTimeout(() => $('#datatable').DataTable(), 1000)
                }).catch(e => {
                    console.log(e)
                })
            },
            searchIncidentReport() {
                this.loading = true;
                this.form.job_id = this.jobId;
                if (this.jobId != '') {
                    this.$http.get('/api/filter/breackdown-job-report/' + this.jobId).then(response => {
                        this.reportList = response.data.success;
                        this.loading = false;
                        setTimeout(() => $('#datatable').DataTable(), 1000)
                    }).catch(e => {
                        this.loading = false;
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error(response?.data?.error)
                        }
                    })
                }
            },

        }
    }
</script>

<style scoped>

</style>
