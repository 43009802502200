<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Clone Data</h2>
             <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <select
                      v-model="clone_to_vessel_id"
                      class="form-control category-tree" style="width: auto; float:left">
                      <option value="">Clone To</option>
                      <option v-for="row in vesselList" :key="row.id" :value="row.id">{{row.name}}</option>
                    </select>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm pull-right"
                      @click="cloneData"
                    >
                      Start Cloning
                    </button>
                    <table id="datatables" class="table table-striped table-hover table-bordered table-sm " style="width:100%">
                      <thead>
                      <tr>
                        <th>Table</th>
                        <th class="text-center">
                          <input type="checkbox" v-model="selectAll" @click="selectTableToggle">  Action
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row , index) in tableList" :key="index" v-if="row.table == 'categories' || row.table == 'components'
                                                    || row.table == 'departments'
                                                    || row.table == 'items' || row.table == 'job_types'
                                                    ||   row.table == 'locations'
                                                    || row.table == 'budget_heads'
                                                    || row.table == 'main_components'
                                                    ||    row.table == 'vessel_certificate_types'">
                        <td>{{row.name}}</td>

                        <td class="text-center">
                             <span>
                                <input class="cloning-table" checked type="checkbox" v-model="selected" :value="`${row.table}`">
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm pull-right"
                      @click="cloneData"
                    >
                      Start Cloning
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import auth from '../../auth'
  import goBackButtonMixin from "@/mixin/goBackButtonMixin";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";

  export default {
    mixins:[goBackButtonMixin],
    name: 'clone',
    components: {PulseLoader},

    data () {
      return {
        loading:false,
        selectAll: false,
        selected: [],
        tableList: [],
        vesselList: [],
        vesselId: auth.getVesselId(),
        clone_to_vessel_id: '',
      }
    },
    mounted () {
      this.getTableList()
      this.getVesselList()
    },
    methods: {
      getTableList () {
        this.loading = true;
        this.$http.get('/api/get-table-list').then(response => {
          this.tableList = response.data.success
          //console.log(this.tableList);
          this.loading = false;

          //   setTimeout(() => $('#datatables').DataTable({"ordering": false, "aaSorting": [], "bDestroy": true}), 1000);
        }).catch(e => {
          this.loading = false;

          let response = e.response;
          if (response.status === 403) {
            this.$snotify.warning(response?.data?.error)
          }
        })
      },
      selectTableToggle () {
        console.log(this.selectAll)
        this.selected = []
        if (!this.selectAll) {
          for (let i in this.tableList) {
            this.selected.push(this.tableList[i].table)
          }
        }
        console.log(this.selected)
      },
      getVesselList () {
        this.$http.get('/api/vessel').then(response => {
          let vesselList = response.data.success.vessel
          vesselList = vesselList.filter(vessel => vessel.id !== this.vesselId);
          this.vesselList = vesselList
          //   setTimeout(() => $('#datatables').DataTable({"ordering": false, "aaSorting": [], "bDestroy": true}), 1000);
        }).catch(e => {
          console.log(e)
        })
      },
      cloneData () {
        if (!this.clone_to_vessel_id) {
          this.$snotify.error('Please select clone to')
        }
        var tables = []
        $.each($('.cloning-table:checked'), function () {
          tables.push($(this).val())
        })

        var formData = {
          tables: tables,
          clone_to_vessel_id: this.clone_to_vessel_id,
          vessel_id: this.vesselId
        }

        this.$http
          .post(`/api/cloneData`, formData)
          .then(response => {
            this.$snotify.success("Data cloning successfully.")
            // if (response.data) {
            // this.$snotify.success("Stock adjusted successfully.");
            // $("#stockAdjustModal").modal("hide");
            // }
          })
          .catch(error => {
            let status = error.response.status
            if (status === 403) {
              this.$snotify.warning(error?.response?.data?.error)
            }else if (status === 422) {
              if (error.response.data?.errors) {
                const errors = error.response.data?.errors;
                for (const key in errors) {
                  this.$snotify.error(errors[key][0])
                }
              }
            }else{
              if (error.response) {
                this.$snotify.error('Request failed')
              }
            }

          })

      },


    }
  }
</script>

