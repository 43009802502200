<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'"  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Job Name(Code)</th>
      <th>Dept Name (Code)</th>
      <th>Component Name</th>
      <th>Job Type name</th>
      <th>Counter Type</th>
      <th>Prev Maintenance Date</th>
      <th>Next Maintenance Due Date</th>
      <th>Assigned By</th>
      <th>Status</th>
      <th v-if="!isPrinting">Instruction Manual</th>
      <th v-if="!isPrinting  && (
      isPermissionExist(PERMISSIONS.JOB_PENDING_APPROVE) ||
      isPermissionExist(PERMISSIONS.JOB_RUNNING_HOUR) ||
      isPermissionExist(PERMISSIONS.JOB_EDIT) ||
      isPermissionExist(PERMISSIONS.JOB_ARCHIVE_SCHEDULE) ||
      isPermissionExist(PERMISSIONS.JOB_ADD) ||
      isPermissionExist(PERMISSIONS.JOB_DELETE) )
      ">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(job, index) in jobs" :key="job.id">
      <td>{{ index + 1 }}</td>
      <td>{{ job?.name }} ({{job?.code}})</td>
      <td>{{ job?.department?.name }} ({{job?.department?.code}})</td>
      <td>{{ job?.main_component?.name }} ({{job?.main_component?.code}}) >>
        {{job?.component?.name}}({{job?.component?.code}})
        <span v-if="job?.sub_component != null"> >> {{job?.sub_component?.name}}({{job?.sub_component?.code}})</span>
        <span v-if="job?.sub_sub_component != null">>> {{job?.sub_sub_component?.name}}({{job?.sub_sub_component?.code}}) </span>
       </td>
      <td>{{job?.job_type.name}}</td>
      <td>{{ job?.counter_type }}</td>
      <td>{{job?.latestJobSchedule?.start_date | moment('DD-MM-YYYY')   }}</td>
      <td>{{job?.latestJobSchedule?.end_date | moment('DD-MM-YYYY')   }}</td>
      <td>{{ job?.rank_fixed_role?.name }}</td>
      <td>{{ job?.status }}</td>
      <td v-if="!isPrinting">
        <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="job &&  job?.instruction_manual != null" :href="job &&  job?.instruction_manual">View </a>
      </td>
      <td v-if="!isPrinting  && (
      isPermissionExist(PERMISSIONS.JOB_PENDING_APPROVE) ||
      isPermissionExist(PERMISSIONS.JOB_RUNNING_HOUR) ||
      isPermissionExist(PERMISSIONS.JOB_EDIT) ||
      isPermissionExist(PERMISSIONS.JOB_ARCHIVE_SCHEDULE) ||
      isPermissionExist(PERMISSIONS.JOB_COPY_COMPONENT) ||
      isPermissionExist(PERMISSIONS.JOB_DELETE) )
      ">
        <button   type="button" @click="show(job)" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
        <button v-if="job.status == 'Pending' &&  isPermissionExist(PERMISSIONS.JOB_PENDING_APPROVE)" title="Approved Job " type="button" @click="approvedJob(job)" class="btn btn-warning btn-sm text-white"><i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
        <button v-if="job.counter_type == 'Running Hours' && isPermissionExist(PERMISSIONS.JOB_RUNNING_HOUR)" title="Update Running Hour" type="button" @click="runningHourUpdate(job)" class="btn btn-info btn-sm text-white"><i class="fa fa-wheelchair-alt" aria-hidden="true"></i></button>
<!--        <button    title="Item QR Code" type="button" @click="showQRCode(job)" class="btn btn-success btn-sm"><i class="fa fa-qrcode"></i></button>-->
        <button v-if="isPermissionExist(PERMISSIONS.JOB_EDIT)" title="Edit Job" type="button" @click="edit(job)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
        <router-link v-if="isPermissionExist(PERMISSIONS.JOB_ARCHIVE_SCHEDULE)" :to="{ name: 'Advanced-Perform' , params: { jobId: job.id} }">
          <button title="Maintenance History List" type="button" class="btn btn-success btn-sm"><i class="fa fa-search-plus"></i></button>
        </router-link>
        <button title="Copy Component" v-if="isPermissionExist(PERMISSIONS.JOB_ADD)" type="button" @click="copyComponent(job)" class="btn btn-success btn-sm"><i class="fa fa-clone" aria-hidden="true"></i></button>
        <button title="Delete Job" v-if="isPermissionExist(PERMISSIONS.JOB_DELETE)" type="button" @click="destroy(job)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import permissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins: [permissionsMixin],
  data () {
    return {
      permissions:auth.getPermissionData(),
    }
  },
  props: {
    jobs: {
      required: true
    },
    user_type: {
      required: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    show: {
      type: Function,
      required: false
    },
    edit: {
      type: Function,
      required: false
    },
    approvedJob: {
      type: Function,
      required: false
    },
    runningHourUpdate: {
      type: Function,
      required: false
    },
    showQRCode: {
      type: Function,
      required: false
    },
    copyComponent: {
      type: Function,
      required: false
    },
    destroy: {
      type: Function,
      required: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
