<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <tbody>
    <tr>
      <th colspan="2" class="text-center"><b>Basic Informtation</b></th>
    </tr>
    <tr>
      <th width="20%">Name</th>
      <td>{{ itemDetails.name }}</td>
    </tr>
    <tr>
      <th width="20%">Category</th>
      <td> {{ itemDetails.category.name }}</td>
    </tr>
    <tr>
      <th width="20%">Location</th>
      <td> {{ itemDetails.location.name }}</td>
    </tr>
    <tr>
      <th>Unit</th>
      <td> {{ itemDetails.unit.name }}</td>
    </tr>
    <tr>
      <th>Other Ref</th>
      <td> {{ itemDetails.other_ref }}</td>
    </tr>
    <tr>
      <th>Model</th>
      <td>{{ itemDetails.model }}</td>
    </tr>
    <tr>
      <th>Size</th>
      <td>{{ itemDetails.size }}</td>
    </tr>
    <tr>
      <th>Maker Part No</th>
      <td> {{ itemDetails.maker_part_no }}</td>
    </tr>
    <tr>
      <th>Maker</th>
      <td>{{ itemDetails.maker }}</td>
    </tr>
    <tr>
      <th>Class</th>
      <td>{{ itemDetails.class }}</td>
    </tr>
    <tr>
      <th>Budget Head</th>
      <td> {{ itemDetails.budgethead.name }}</td>
    </tr>
    <tr>
      <th>Components</th>
      <td><span class="badge badge-info" v-for="component in itemDetailsData.component" :key="component.id">{{ component?.component?.name }}</span></td>
    </tr>
    <tr style="border:none">
      <th colspan="2" class="text-center">
        <b> Stock Information</b>
      </th>
    </tr>
    <tr>
      <th>Min</th>
      <td> {{ itemDetails.min_order }}</td>
    </tr>
    <tr>
      <th>Max</th>
      <td> {{ itemDetails.max_order }}</td>
    </tr>
    <tr>
      <th>Re-order Level</th>
      <td> {{ itemDetails.reorder_level }}</td>
    </tr>
    <tr>
      <th>Re-order Quantity</th>
      <td> {{ itemDetails.reorder_qty }}</td>
    </tr>
    <tr>
      <th>Opening Stock</th>
      <td> {{ itemDetails.opening_stock }}</td>
    </tr>
    <tr>
      <th>Opening Stock Date</th>
      <td> {{ itemDetails.opening_stock_date }}</td>
    </tr>
    <tr>
      <th>Current Stock</th>
      <td> {{ itemDetailsData?.currentStock?.rob }}</td>
    </tr>

    <tr style="border:none">
      <th colspan="2" class="text-center"><b> Price Information</b></th>
    </tr>
    <tr>
      <th>Average Price</th>
      <td>{{ itemDetails.price }}</td>
    </tr>
    <tr>
      <th>Opening Stock Value</th>
      <td> {{ itemDetails.opening_stock * itemDetails.price }}</td>
    </tr>
    <tr>
      <th>Current Stock Value</th>
      <td> {{ itemDetailsData.currentStock?.rob * itemDetails.price }}</td>
    </tr>
    <tr style="border:none">
      <th colspan="2" class="text-center">
        <b> Image & Document</b>
      </th>
    </tr>
    <tr>
      <th>Image</th>
      <td>
        <span v-if="itemDetails.image !==0 && itemDetails.image !==''"> <img :src="itemDetails.image" height="50" width="50"/>  </span>
        <span v-else>  <img src="noimage.png" height="50" width="50"/>  </span>
      </td>
    </tr>
    <tr>
      <th>Documents</th>
      <td>
        <span v-if="itemDetails.doc !== null && itemDetails.doc !==''"> <a :href="itemDetails.doc" target="_blank" download>View</a></span>
        <span v-else>No document found</span>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
    export default {
        props: {
            itemDetails: {
                required: true
            },
            itemDetailsData: {
                required: true
            },
            isPrinting: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            getTableClasses() {
                return {
                    'table': true,
                    'showTable': true,
                    'text-center': true,
                    'table-striped': !this.isPrinting,
                    'table-bordered': !this.isPrinting,
                    'table-sm': !this.isPrinting,
                    'print-table': this.isPrinting,
                };
            }
        }
    };
</script>

<style scoped>
  .print-table {
    /* Define styles for printing if needed */
  }
</style>
