<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Quotation List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">

              <li>
                <button @click="print('printArea','Auto Requisition List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <TableData
                      :quotationData="quotationData"
                      :createSupplierQutoation="createSupplierQuotation"
                      :showBudget="showBudget"
                      :show="show"
                      :approveRequest="approveRequest"
                    />
                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <div class="font-weight-bold">Quotation List</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      </div>
                      <TableData :quotationData="quotationData" :isPrinting="true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- view quotation details Modal -->
    <div class="modal fade" id="quotationModal" tabindex="-1" role="dialog" aria-labelledby="quotationModalTitle" aria-hidden="true">
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="quotationModalTitle">Quotation Details </h5>
            <div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <button @click="print('modalPrint','Quotation Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            </div>

          </div>
          <div class="modal-body">
            <div v-if="quotationDetails">
              <ModalData :quotationDetails="quotationDetails"/>
              <div id="modalPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Quotation Details </h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <ModalData :quotationDetails="quotationDetails" :isPrinting="true"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- view quotation details Modal -->

    <!-- Create purchase order Modal -->
    <div class="modal fade" id="purchaseOrderModal" tabindex="-1" role="dialog" aria-labelledby="purchaseOrderModalTitle" aria-hidden="true">
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="purchaseOrderModalTitle">Create Purchase Order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered" v-if="quotationDetails">
              <thead>
              <tr>
                <th><strong>Title: </strong>{{quotationDetails.requisition.title}}</th>
                <th><strong>Requisition No: </strong>{{quotationDetails.requisition.requisition_no}}</th>
                <th><strong>Status: </strong>{{quotationDetails.requisition.status}}</th>
                <th><strong>Type: </strong>{{quotationDetails.requisition.type}}</th>
                <th><strong>Date: </strong>{{quotationDetails.requisition.date}}</th>
              </tr>
              <tr>
                <th><strong>Remarks: </strong>{{quotationDetails.requisition.remarks}}</th>
                <th><strong>Priority: </strong>{{quotationDetails.requisition.priority}}</th>
                <th colspan="3"><strong>Address: </strong>{{quotationDetails.requisition.address}}</th>
              </tr>
              <tr>
                <th><strong>Supply Date: </strong>{{quotationDetails.requisition.supply_date}}</th>
                <th><strong>Vessel ETA: </strong>{{quotationDetails.requisition.vessel_eta}}</th>
                <th><strong>Vessel ETD: </strong>{{quotationDetails.requisition.vessel_etd}}</th>
                <th><strong>Advised Date: </strong>{{quotationDetails.requisition.advised_on_date}}</th>
                <th></th>
              </tr>
              <tr>
                <th colspan="2"><strong>Country: </strong>
                  <span v-if="quotationDetails.requisition.country">
                                       {{quotationDetails.requisition.country.name}}
                                   </span>
                </th>
                <th colspan="3"><strong>Port: </strong>
                  <span v-if="quotationDetails.requisition.port">
                                        {{quotationDetails.requisition.port.name}}
                                   </span>
                </th>
              </tr>
              </thead>
            </table>
            <hr>
            <form @submit="storePo" class="requisitionForm">
              <div class="row" v-if="quotationDetails">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Select Supplier<span class="text-danger">*</span></label>
                    <select v-model="quotationDetails.supplier_id" name="supplier_id" class="form-control" required>
                      <option value="" selected>Select supplier</option>
                      <option v-for="supplier in supplierData" :key="supplier.id" v-if="supplier.status == 'Activate'" :value="supplier.id">{{supplier.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Title<span class="text-danger">*</span></label>
                    <input v-model="quotationDetails.title" type="text" name="title" class="form-control" placeholder="Enter title" autocomplete="off" required>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Date</label>
                    <datetime format="YYYY-MM-DD" data-placeholder="Pick date" width="300px" v-model="quotationDetails.date"></datetime>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Shipping cost<span class="text-danger">*</span></label>
                    <input v-model="quotationDetails.shipping_cost" type="number" name="shipping_cost" value="0" class="form-control" min="0" step="any" required>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Tax(%)<span class="text-danger">*</span></label>
                    <input v-model="quotationDetails.tax" type="number" name="tax" value="0" class="form-control" min="0" placeholder="Enter tax" required>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea v-model="quotationDetails.remark" name="remark" class="form-control" placeholder="Enter description"></textarea>
                  </div>
                </div>
              </div>

              <table class="table table-bordered" v-if="quotationDetails">
                <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Item name</th>
                  <th>Impa code</th>
                  <th>Maker Part no</th>
                  <th>Maker</th>
                  <th>Model</th>
                  <th>Unit</th>
                  <th>Opening stock</th>
                  <th>Details</th>
                  <th class="text-center">Qty</th>
                  <th class="text-center">Price</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in quotationDetails.quotation_item" :key="index">
                  <td>
                    <button type="button" @click="removeItem(item.id, quotationDetails.id)" class="btn btn-danger btn-sm" title="Remove Item"><i class="fa fa-remove"></i></button>
                  </td>
                  <td>{{item.status}}</td>
                  <td>{{item.item.name}}</td>
                  <td>{{item.item.inpa_code}}</td>
                  <td>{{item.item.maker_part_no}}</td>
                  <td>{{item.item.maker}}</td>
                  <td>{{item.item.model}}</td>
                  <td>{{item.item.unit.name}}</td>
                  <td>{{item.item.opening_stock}}</td>
                  <td>{{item.item.description}}</td>
                  <td>
                    <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].qty" type="number" name="qty" class="form-control text-center" min="0" step="any">
                  </td>
                  <td>
                    <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].price" type="number" name="price" class="form-control text-right" min="0"
                           step="any">
                  </td>
                  <td class="text-right">
                    {{rowAmount(index)}}
                    <!--                        <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].amount" value="1" type="number" name="price" class="form-control" min="0" step="any">-->
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">Sub Total:</td>
                  <td class="text-right">
                    {{quotationDetails.amount}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">
                    <div class="d-flex flex-row-reverse">
                      <div class="discount_type_value_wrap" v-if="discount_type_value">
                        <input name="discount_type_value" v-model="quotationDetails.discount_type_value" @keyup="calculateDiscount" type="number" style="width: 150px;" step="any"
                               min="0" class="form-control pull-right discount_type_value" placeholder="Enter % value">
                      </div>
                      <div class="mr-2">
                        <select v-model="quotationDetails.discount_type" class="form-control discount_type" name="discount_type"
                                v-on:change='discount_type_value = !discount_type_value'>
                          <option value="Amount">Amount</option>
                          <option value="Percent">Percent</option>
                        </select>
                      </div>
                      <div class="mr-2">Discount:</div>
                    </div>
                  </td>
                  <td>
                    <input name="discount_amount" @keyup="discountChange" v-model="quotationDetails.discount_amount" style="width: 100px;"
                           class="form-control discount_amount pull-right text-right">
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">Grand Total:</td>
                  <td class="text-right">
                    {{grandAmount}}
                  </td>
                </tr>
                </tbody>
              </table>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-info">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Create purchase order Modal -->

    <!-- Create purchase order Modal -->
    <div class="modal fade" id="supplierQuotationModal" tabindex="-1" role="dialog" aria-labelledby="purchaseOrderModalTitle" aria-hidden="true">
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="purchaseOrderModalTitle">Create Supplier Quotation Order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered" v-if="quotationDetails">
              <thead>
              <tr>
                <th><strong>Title: </strong>{{quotationDetails.requisition.title}}</th>
                <th><strong>Requisition No: </strong>{{quotationDetails.requisition.requisition_no}}</th>
                <th><strong>Status: </strong>{{quotationDetails.requisition.status}}</th>
                <th><strong>Type: </strong>{{quotationDetails.requisition.type}}</th>
                <th><strong>Date: </strong>{{quotationDetails.requisition.date}}</th>
              </tr>
              <tr>
                <th><strong>Remarks: </strong>{{quotationDetails.requisition.remarks}}</th>
                <th><strong>Priority: </strong>{{quotationDetails.requisition.priority}}</th>
                <th colspan="3"><strong>Address: </strong>{{quotationDetails.requisition.address}}</th>
              </tr>
              <tr>
                <th><strong>Supply Date: </strong>{{quotationDetails.requisition.supply_date}}</th>
                <th><strong>Vessel ETA: </strong>{{quotationDetails.requisition.vessel_eta}}</th>
                <th><strong>Vessel ETD: </strong>{{quotationDetails.requisition.vessel_etd}}</th>
                <th><strong>Advised Date: </strong>{{quotationDetails.requisition.advised_on_date}}</th>
                <th></th>
              </tr>
              <tr>
                <th colspan="2"><strong>Country: </strong>
                  <span v-if="quotationDetails.requisition.country">
                                       {{quotationDetails.requisition.country.name}}
                                   </span>
                </th>
                <th colspan="3"><strong>Port: </strong>
                  <span v-if="quotationDetails.requisition.port">
                                        {{quotationDetails.requisition.port.name}}
                                   </span>
                </th>
              </tr>
              </thead>
            </table>
            <hr>
            <form @submit="storeSupplierQuotation" class="requisitionForm">
              <div class="row" v-if="quotationDetails">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Select Supplier<span class="text-danger">*  </span></label>
                    <select v-model="quotationDetails.supplier_id" name="supplier_id" class="form-control" required>
                      <option value="" selected>Select supplier</option>
                      <option v-for="supplier in supplierData" :key="supplier.id" v-if="supplier.status == 'Activate'" :value="supplier.id">{{supplier.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Supplier Currency <span class="text-danger">*</span></label>
                    <select v-model="quotationDetails.currency_id" name="supplier_id" class="form-control" required>
                      <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{currency.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea v-model="quotationDetails.notes" name="remark" class="form-control" placeholder="Enter description"></textarea>
                  </div>
                </div>

                <!--                <div class="col-md-2">-->
                <!--                  <div class="form-group">-->
                <!--                    <label>Title<span class="text-danger">*</span></label>-->
                <!--                    <input v-model="quotationDetails.title" type="text" name="title" class="form-control" placeholder="Enter title" autocomplete="off" required>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="col-md-2">-->
                <!--                  <div class="form-group">-->
                <!--                    <label>Date</label>-->
                <!--                    <datetime format="YYYY-MM-DD" data-placeholder="Pick date" width="300px" v-model="quotationDetails.date"></datetime>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="col-md-2">-->
                <!--                  <div class="form-group">-->
                <!--                    <label>Shipping cost<span class="text-danger">*</span></label>-->
                <!--                    <input v-model="quotationDetails.shipping_cost" type="number" name="shipping_cost" value="0" class="form-control" min="0" step="any" required>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="col-md-2">-->
                <!--                  <div class="form-group">-->
                <!--                    <label>Tax(%)<span class="text-danger">*</span></label>-->
                <!--                    <input v-model="quotationDetails.tax" type="number" name="tax" value="0" class="form-control" min="0" placeholder="Enter tax" required>-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>

              <table class="table table-bordered" v-if="quotationDetails">
                <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Item name</th>
                  <th>Impa code</th>
                  <th>Maker Part no</th>
                  <th>Maker</th>
                  <th>Model</th>
                  <th>Unit</th>
                  <th>Opening stock</th>
                  <th>Details</th>
                  <th class="text-center">Qty</th>
                  <th class="text-center">Price</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in quotationDetails.quotation_item" :key="index">
                  <td>
                    <button type="button" @click="removeItem(item.id, quotationDetails.id)" class="btn btn-danger btn-sm" title="Remove Item"><i class="fa fa-remove"></i></button>
                  </td>
                  <td>{{item.status}}</td>
                  <td>{{item.item.name}}</td>
                  <td>{{item.item.inpa_code}}</td>
                  <td>{{item.item.maker_part_no}}</td>
                  <td>{{item.item.maker}}</td>
                  <td>{{item.item.model}}</td>
                  <td>{{item.item.unit.name}}</td>
                  <td>{{item.item.opening_stock}}</td>
                  <td>{{item.item.description}}</td>
                  <td>
                    <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].qty" type="number" name="qty" class="form-control text-center" min="0" step="any">
                  </td>
                  <td>
                    <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].price" type="number" name="price" class="form-control text-right" min="0"
                           step="any">
                  </td>
                  <td class="text-right">
                    {{rowAmount(index)}}
                    <!--                        <input style="width: 100px;" v-model="quotationDetails.quotation_item[index].amount" value="1" type="number" name="price" class="form-control" min="0" step="any">-->
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">Sub Total:</td>
                  <td class="text-right">
                    {{quotationDetails.amount}}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">
                    <div class="d-flex flex-row-reverse">
                      <div class="discount_type_value_wrap" v-if="discount_type_value">
                        <input name="discount_type_value" v-model="quotationDetails.discount_type_value" @keyup="calculateDiscount" type="number" style="width: 150px;" step="any"
                               min="0" class="form-control pull-right discount_type_value" placeholder="Enter % value">
                      </div>
                      <div class="mr-2">
                        <select v-model="quotationDetails.discount_type" class="form-control discount_type" name="discount_type"
                                v-on:change='discount_type_value = !discount_type_value'>
                          <option value="Amount">Amount</option>
                          <option value="Percent">Percent</option>
                        </select>
                      </div>
                      <div class="mr-2">Discount:</div>
                    </div>
                  </td>
                  <td>
                    <input name="discount_amount" @keyup="discountChange" v-model="quotationDetails.discount_amount" style="width: 100px;"
                           class="form-control discount_amount pull-right text-right">
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-right" colspan="12">Grand Total:</td>
                  <td class="text-right">
                    {{grandAmount}}
                  </td>
                </tr>
                </tbody>
              </table>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="isDisabled" type="submit" class="btn btn-info">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Create purchase order Modal -->
  </div>
</template>

<script>

    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'
    import {printUtil} from "@/utils/print";
    import TableData from "./TableData.vue";
    import ModalData from "./ModalData.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PrintMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, PrintMixin],
        components: {ModalData, TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                isDisabled: false,
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                grandAmount: 0,
                currencies: null,
                editMode: false,
                discount_type_value: false,
                supplierData: [],
                quotationData: [],
                quotationDetails: null,
                permissions: auth.getPermissionData(),
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getQuotationData()
        },
        methods: {
            getQuotationData() {
                this.loading = true;
                this.$http.get('/api/filter/quotation/' + this.vessel_id).then(response => {
                    this.loading = false
                    this.quotationData = response.data.success
                    setTimeout(() => $('#datatables').DataTable({
                        'ordering': false,
                        'aaSorting': [],
                        'bDestroy': true
                    }), 1000)
                }).catch(e => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            removeItem(ref_id, quotationId) {
                var txt
                var r = confirm('Are you sure you want to remove this item!')
                if (r == true) {
                    let data = {
                        ref_id: ref_id,
                        type: 'QuotationItem'
                    }
                    this.$http.post('/api/remove-item', data).then(response => {
                        this.create(quotationId)
                    }).catch(e => {
                        console.log(e)
                    })
                }

            },
            rowAmount: function (key) {
                let amount = this.quotationDetails.quotation_item[key].price * this.quotationDetails.quotation_item[key].qty
                this.quotationDetails.quotation_item[key].amount = amount
                this.subTotal()
                this.discountChange()
                return amount
            },
            calculateDiscount() {
                this.quotationDetails.discount_amount = (this.quotationDetails.amount * this.quotationDetails.discount_type_value) / 100
                this.discountChange()
            },
            discountChange() {
                this.grandAmount = this.quotationDetails.amount - this.quotationDetails.discount_amount
            },
            subTotal() {
                var total = 0
                var items = this.quotationDetails.quotation_item
                $.each(items, function (key, row) {
                    total = total + row.amount
                })
                this.quotationDetails.amount = total
            },
            setDateFormat(event) {
                this.form.date = moment(event.target.value).format('YYYY-MM-DD')
            },
            create(id) {
                this.getSupplierData()
                this.$http.get('/api/quotation/' + id).then(response => {
                    this.quotationDetails = response.data
                    this.quotationDetails.supplier_id = ''
                    this.quotationDetails.quotation_id = id
                    this.quotationDetails.title = ''
                    this.quotationDetails.shipping_cost = ''
                    this.quotationDetails.tax = ''
                    this.quotationDetails.date = ''
                    this.quotationDetails.remark = ''
                    this.quotationDetails.discount_type = 'Amount'
                    this.quotationDetails.discount_amount = 0
                    this.grandAmount = response.data.amount
                    $('#purchaseOrderModal').modal('show')
                }).catch(e => {
                    console.log(e)
                })
            },

            createSupplierQuotation(id) {
                this.getSupplierData()
                this.getCurrency()
                this.$http.get('/api/quotation/' + id).then(response => {
                    this.quotationDetails = response.data
                    this.quotationDetails.supplier_id = ''
                    this.quotationDetails.quotation_id = id
                    this.quotationDetails.title = ''
                    this.quotationDetails.shipping_cost = ''
                    this.quotationDetails.tax = ''
                    this.quotationDetails.date = ''
                    this.quotationDetails.remark = ''
                    this.quotationDetails.discount_type = 'Amount'
                    this.quotationDetails.discount_amount = 0
                    this.grandAmount = response.data.amount
                    $('#supplierQuotationModal').modal('show')
                }).catch(e => {
                    console.log(e)
                })
            },

            getCurrency() {
                this.$http.get('/api/currency').then(response => {
                    this.currencies = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },

            storeSupplierQuotation: function (e) {
                e.preventDefault();
                this.isDisabled = true;
                this.quotationDetails.grand_amount = this.grandAmount
                $('#supplierQuotationModal').modal('show')
                this.$http.post('/api/supplier-quotation', this.quotationDetails).then(response => {
                    this.isDisabled = false
                    this.getQuotationData()
                    $('#supplierQuotationModal').modal('hide')
                    if (response.data.success) {
                        this.$snotify.success('Supplier Quotation created successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    console.log(e)
                    this.isDisabled = false

                    let response = e.response
                    let status = e.response.status
                    if (status === 500) {
                        let msg = response.data.error
                        this.$snotify.error(msg)
                    }
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }

                })
            },
            storePo: function (e) {
                e.preventDefault()
                this.quotationDetails.grand_amount = this.grandAmount
                $('#purchaseOrderModal').modal('show')
                this.$http.post('/api/purchase', this.quotationDetails).then(response => {
                    this.getQuotationData()
                    $('#purchaseOrderModal').modal('hide')
                    if (response.data.success) {
                        this.$snotify.success('PO created successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    this.$snotify.error(error.response.data.error)
                })
            },
            getSupplierData() {
                this.$http.get('/api/active-supplier/').then(response => {
                    this.supplierData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            show(id) {
                $('#quotationModal').modal('show')
                this.$http.get('/api/quotation/' + id).then(response => {
                    this.quotationDetails = response.data
                }).catch(e => {
                    console.log(e)
                })
            },
            showBudget(id, index) {
                this.$http.get('/api/get-budget-info/' + id).then(response => {
                    $('.budget-' + index).html('Budget:' + response.data.totalBudget + ', Budget Left:' + response.data.totalBudgetLeft)
                }).catch(e => {
                    console.log(e)
                    this.$snotify.error(e.response.data.error)
                    $('.budget-' + index).html(e.response.data.error)
                })
            },
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {
                    type: type,
                    status: status,
                    key: key,
                    ref_id: ItemId
                }).then((response) => {
                    if (response.data.success) {
                        this.getQuotationData()
                        this.$snotify.success('Request Approved Successfully.')
                    }
                }).catch((error) => {
                    if (error.response.status) {
                        this.$snotify.error('Request Approval Failed')
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
