<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Critical Equipment Item List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <router-link to="/item" class="btn btn-success btn-sm"> Item List</router-link>
              <!--              <li>-->
              <!--                <button  type="button"  v-if="isPermissionExist(PERMISSIONS.ITEM_ADD)"  class="btn btn-info btn-sm"  @click="create" > Add Item <i class="fa fa-plus"></i> </button>-->
              <!--              </li>-->
              <li>
                <button @click="print('printArea','Item List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 ">
                      <div class="justify-content-center">
                        <ul class="nav navbar-left  align-left">

                          <li v-for="(item, index) in locations" :key="item.id">
                            <button @click="selectLocation(item.id)"
                                    :class="['btn btn-outline-info btn-sm', { active: item.id === selectedLocation }]"
                            > {{item?.name}} <span class="badge badge-success">{{item?.items_count}}</span></button>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <ItemTable :items="items" :show="show" :showQRCode="showQRCode" :edit="edit" :destroy="destroy" :stockModal="stockModal" :isEmpty="isEmpty"/>


                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <div class="font-weight-bold">Item List</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        <ItemTable :items="items" :isEmpty="isEmpty" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="stockAdjustModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle"> Stock Adjust </h5>
            <button type="button" class="close float-right" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered font-weight-bold">
              <tr>
                <td class="text-right">Receive QTY:</td>
                <td><input :class="`form-control qty text-right`" type="text" v-model="receive_qty"/></td>
              </tr>
              <tr>
                <td class="text-right ">Current Stock:</td>
                <td class="text-right">{{closing_stock}}</td>
              </tr>
              <tr>
                <td class="text-right">Consumption:</td>
                <td><input :class="`form-control qty text-right`" type="text" v-model="deductQty" @keyup="quantityDeducted()"/></td>
              </tr>
              <tr>
                <td class="text-right">Damage/Sled/Expired :</td>
                <td><input :class="`form-control qty text-right`" type="text" v-model="damage_qty" @keyup="quantityDeducted()"/></td>
              </tr>
              <tr>
                <td class="text-right">ROB:</td>
                <td class="text-right">{{next_closing_stock}}</td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" @click="stockAdjust"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="roleModal" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)" role="form">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ editMode ? 'Edit' : 'Add New' }} Item
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section>
                <div class="wizard">
                  <div class="wizard-inner">
                    <div class="connecting-line"></div>
                    <ul class="nav nav-tabs" role="tablist">
                      <li role="presentation">
                        <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" title="Step 1">
                          <span class="round-tab"><i class="glyphicon glyphicon-pencil"></i></span>
                        </a>
                        <h6 class="text-center"> Basic information </h6>
                      </li>
                      <li role="presentation">
                        <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab" title="Step 2">
                          <span class="round-tab"><i class="glyphicon glyphicon-folder-open"></i></span>
                        </a>
                        <h6 class="text-center"> Details </h6>
                      </li>
                      <li role="presentation">
                        <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab" title="Step 3">
                          <span class="round-tab"><i class="glyphicon glyphicon-list-alt"> </i></span>
                        </a>
                        <h6 class="text-center">
                          Component Assign
                        </h6>
                      </li>
                      <li role="presentation">
                        <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab" title="step4">
                          <span class="round-tab"><i class="glyphicon glyphicon-th-list"></i></span>
                        </a>
                        <h6 class="text-center"> Description </h6>
                      </li>
                      <li role="presentation">
                        <a href="#step5" data-toggle="tab" aria-controls="step5" role="tab" title="step5">
                          <span class="round-tab"><i class="glyphicon glyphicon-picture"></i></span>
                        </a>
                        <h6 class="text-center"> Images </h6>
                      </li>
                      <li role="presentation" class="disabled">
                        <a href="#step6" data-toggle="tab" aria-controls="step6" role="tab" title="step5">
                          <span class="round-tab"><i class="glyphicon glyphicon-file"></i></span>
                        </a>
                        <h6 class="text-center"> Documents </h6>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" role="tabpanel" id="step1">
                      <div class="panel-heading">
                        <h3 class="panel-title text-center"> Basic Information </h3>
                      </div>
                      <div class="row">
                        <div class="col-md-1 col-sm-1"></div>
                        <div class="col-md-5 col-sm-5">
                          <div class="form-group">
                            <label> Name<span class="required">*</span></label>
                            <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has( 'name' ) }"/>
                            <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group">
                            <label> Category:<span class="required">*</span></label>
                            <select v-model="form.category_id"
                                    name="category_id"
                                    class="form-control category-tree"
                                    :class="{ 'is-invalid': form.errors.has('category_id')}"
                            >
                              <option
                                v-for="category in categoryList" :key="category.id" :value="category.id">{{category.name}}
                              </option>
                            </select>
                            <has-error :form="form" field="category_id"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Unit:<span class="required">*</span></label>
                            <select v-model="form.unit_id"
                                    name="unit_id"
                                    class="form-control"
                                    :class="{'is-invalid': form.errors.has('unit_id')}"
                            >
                              <option v-for="row in units" :key="row.id" :value="row.id">{{row.name }}</option>
                            </select>
                            <has-error :form="form" field="unit_id"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Budget Head:<span class="required">*</span></label>
                            <Select2
                              v-model="form.budget_head_id"
                              name="budget_head_id"
                              :options="myOptions"
                              :class="{'is-invalid': form.errors.has('budget_head_id')}"
                            />
                            <has-error :form="form" field="budget_head_id"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Other Ref :</label>
                            <input
                              autocomplete="off"
                              v-model="form.other_ref"
                              type="text"
                              name="other_ref"
                              class="form-control"
                              :class="{'is-invalid': form.errors.has('other_ref')}"
                            />
                            <has-error :form="form" field="other_ref"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Model :</label>
                            <input
                              autocomplete="off"
                              v-model="form.model"
                              type="text"
                              name="model"
                              class="form-control"
                              :class="{'is-invalid': form.errors.has('model')}"
                            />
                            <has-error :form="form" field="model"></has-error>
                          </div>
                        </div>
                        <div class="col-md-5 col-sm-5">
                          <div class="form-group">
                            <label>Impa Code:<span class="required">*</span></label>
                            <input
                              autocomplete="off"
                              v-model="form.inpa_code"
                              type="text"
                              name="model"
                              class="form-control"
                              :class="{'is-invalid': form.errors.has('inpa_code')}"
                            />
                            <has-error :form="form" field="inpa_code"></has-error>
                          </div>
                          <div class="form-group">
                            <label>Location:<span class="required">*</span></label>
                            <select v-model="form.location_id"
                                    name="location_id"
                                    class="form-control location-tree"
                                    :class="{ 'is-invalid': form.errors.has('location_id')}"
                            >
                              <option
                                v-for="location in locationList"
                                :key="location.id"
                                :value="location.id"
                              >{{ location.name }}
                              </option>
                            </select>
                            <has-error :form="form" field="location_id"></has-error>
                          </div>

                          <div class="form-group">
                            <label> Average Price:</label>
                            <input
                              v-model="form.price"
                              autocomplete="off"
                              type="number"
                              step="any"
                              min="0"
                              name="price"
                              class="form-control"
                              :class="{ 'is-invalid': form.errors.has( 'price' ) }"
                            />
                            <has-error
                              :form="form"
                              field="price"
                            ></has-error>
                          </div>

                          <div class="form-group">
                            <label>Supplier:</label>
                            <select
                              v-model=" form.supplier_id "
                              name="supplier_id"
                              class="form-control"
                              :class="{ 'is-invalid': form.errors.has( 'supplier_id' ) }"
                            >
                              <option
                                v-for="row in suppliers"
                                :key="row.id"
                                :value="row.id"
                              >{{ row.name }}
                              </option>
                            </select>
                            <has-error
                              :form="form"
                              field="supplier_id"
                            ></has-error>
                          </div>

                          <div class="form-group">
                            <label>Size:</label>
                            <input
                              autocomplete="off"
                              v-model="form.size"
                              type="text"
                              name="size"
                              class="form-control"
                              :class="{ 'is-invalid': form.errors.has('size')}"
                            />
                            <has-error
                              :form="form"
                              field="size"
                            ></has-error>
                          </div>

                          <div class="form-group">
                            <label
                            >Maker Part No:</label>
                            <input
                              autocomplete="off"
                              v-model="form.maker_part_no"
                              type="text"
                              name="maker_part_no"
                              class="form-control"
                              :class="{'is-invalid': form.errors.has('maker_part_no')}"
                            />
                            <has-error
                              :form="form"
                              field="maker_part_no"
                            ></has-error>
                          </div>
                        </div>
                        <div
                          class="col-md-1 col-sm-1"
                        ></div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <a
                            href="#step2"
                            data-toggle="tab"
                            aria-controls="step2"
                            role="tab"
                            class="btn btn-info nextBtn pull-right"
                          >Continue
                            <i
                              class="fa fa-arrow-right"
                            ></i
                            ></a>
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>
                    <div
                      class="tab-pane"
                      role="tabpanel"
                      id="step2"
                    >
                      <div class="panel-heading">
                        <h3
                          class="panel-title text-center"
                        >
                          Item Details
                        </h3>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-1 col-sm-1"
                        ></div>
                        <div
                          class="col-md-10 col-sm-10"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <div
                                class="form-group"
                              >
                                <label>
                                  Maker
                                </label>
                                <input
                                  autocomplete="off"
                                  v-model="form.maker"
                                  type="text"
                                  name="maker"
                                  class="form-control"
                                  :class="{'is-invalid': form.errors.has('maker')}"
                                />
                                <has-error
                                  :form="form"
                                  field="maker"
                                ></has-error>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                class="form-group"
                              >
                                <label>
                                  Class
                                </label>
                                <input
                                  autocomplete="off"
                                  v-model="form.class"
                                  type="text"
                                  name="name"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div
                                class="form-group"
                              >
                                <label
                                >Drawing:</label
                                >
                                <input
                                  autocomplete="off"
                                  type="text"
                                  v-model="form.drawing"
                                  name="drawing"
                                  class="form-control"
                                  :class="{'is-invalid': form.errors.has('drawing')}"
                                />
                                <has-error
                                  :form="form"
                                  field="drawing"
                                ></has-error>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-1 col-sm-1"
                        ></div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-md-1 col-sm-1"></div>
                        <div class="col-md-10 col-sm-10">
                          <div class="card">
                            <div class="card-body">
                              <p class="card-title font-weight-bold text-black"> Stock </p>
                              <div class="row">
                                <div class="col-md-3 col-sm-3">
                                  <div class="form-group">
                                    <label> Min : </label>
                                    <input autocomplete="off" v-model=" form.min_order" type="number" name="min_order" class="form-control" :class="{'is-invalid': form.errors.has('min_order')}"/>
                                    <has-error :form="form" field="min_order"></has-error>
                                  </div>
                                  <div class="form-group">
                                    <label> Opening Stock : </label>
                                    <input autocomplete="off" v-model="form.opening_stock" type="number" name="opening_stock" class="form-control" :class="{ 'is-invalid': form.errors.has('opening_stock')}"/>
                                    <has-error :form="form" field="opening_stock"></has-error>
                                  </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                  <div class="form-group">
                                    <label> Max : </label>
                                    <input autocomplete="off" v-model="form.max_order" type="number" name="max_order" class="form-control" :class="{'is-invalid': form.errors.has('max_order')}"/>
                                    <has-error :form="form" field="max_order"></has-error>
                                  </div>
                                  <div class="form-group">
                                    <label> Opening Stock Date: </label>
                                    <DatePicker type="date" width="300px" v-model="form.opening_stock_date" valueType="format"></DatePicker>
                                    <has-error :form="form" field="opening_stock_date"></has-error>
                                  </div>
                                </div>
                                <div v-if="editMode" class="col-md-3 col-sm-3">

                                  <div class="form-group">
                                    <label> Current Stock : </label>
                                    <input autocomplete="off" type="text" name="name" class="form-control"/>
                                  </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                  <!--                                  <div class="form-group" >-->
                                  <!--                                    <label> Reorder Qty : </label>-->
                                  <!--                                    <input autocomplete="off" v-model="form.reorder_qty" type="number" name="reorder_qty" class="form-control" />-->
                                  <!--                                    <has-error :form="form" field="reorder_qty" ></has-error>-->
                                  <!--                                  </div>-->
                                  <div class="form-group">
                                    <label> Reorder Level : </label>
                                    <input autocomplete="off" v-model="form.reorder_level" type="number" name="reorder_level" class="form-control" :class="{'is-invalid': form.errors.has('reorder_level')}"/>
                                    <has-error :form="form" field="reorder_level"></has-error>
                                  </div>
                                  <div class="form-group" v-if="editMode">
                                    <label>Requisition : </label>
                                    <input autocomplete="off" type="text" name="name" class="form-control"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-1 col-sm-1"
                        ></div>
                      </div>
                      <!--                                            <div class="row mt-2" v-if="editMode">
                          <div class="col-md-1 col-sm-1"></div>
                          <div class="col-md-10 col-sm-10">
                              <div class="card">
                                  <div class="card-body">
                                      <p class="card-title font-weight-bold text-black">Price : </p>
                                      <div class="row">
                                          <div class="col-md-3 col-sm-3">
                                              <div class="form-group">
                                                  <label> Opening Stock Value : </label>
                                                  <input autocomplete="off" type="text" name="name" class="form-control">

                                              </div>
                                          </div>
                                          <div class="col-md-3 col-sm-3">

                                              <div class="form-group">
                                                  <label> Current Stock Value : </label>
                                                  <input autocomplete="off" type="text" name="name" class="form-control">

                                              </div>
                                          </div>

                                          <div class="col-md-3 col-sm-3"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-1 col-sm-1"></div>
                      </div>-->
                      <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <a
                            href="#step3"
                            data-toggle="tab"
                            aria-controls="step3"
                            role="tab"
                            class="btn btn-info nextBtn pull-right"
                          >Continue
                            <i
                              class="fa fa-arrow-right"
                            ></i
                            ></a>
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>
                    <div
                      class="tab-pane"
                      role="tabpanel"
                      id="step3"
                    >
                      <div class="panel-heading">
                        <h3
                          class="panel-title text-center"
                        >
                          Component Assign
                        </h3>
                      </div>
                      <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-6 col-sm-6">
                          <div class="form-group">
                            <label
                              class="typo__label"
                            >Simple select /
                              dropdown</label
                            >
                            <multiselect
                              v-model="form.component_id"
                              :options="options"
                              :multiple="true"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true"
                              placeholder="Select Component"
                              label="name"
                              track-by="name"
                              :preselect-first="true">
                              <template slot="selection" slot-scope="{values}">
                                <span class="multiselect__single" v-if="values.length">{{values.length}} options selected</span>
                              </template>
                            </multiselect>
                            <has-error
                              :form="form"
                              field="component_id"
                            ></has-error>
                          </div>
                        </div>
                        <div class="col-md-3"></div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <a
                            href="#step4"
                            data-toggle="tab"
                            aria-controls="step4"
                            role="tab"
                            class="btn btn-info nextBtn pull-right"
                          >Continue
                            <i
                              class="fa fa-arrow-right"
                            ></i
                            ></a>
                        </div>
                        <div class="col-md-1"></div>
                        <!-- <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab" class="btn btn-info nextBtn pull-right active" aria-selected="true">Continue <i class="fa fa-arrow-right"></i></a> -->
                      </div>
                    </div>
                    <div
                      class="tab-pane"
                      role="tabpanel"
                      id="step4"
                    >
                      <div class="panel-heading">
                        <h3
                          class="panel-title text-center"
                        >
                          Description
                        </h3>
                      </div>
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-md-1"></div>
                          <div class="col-md-10">
                            <div class="form-group">
                              <textarea v-model="form.description" class="resizable_textarea form-control" placeholder="Enter Description" rows="8"></textarea>
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-1"></div>
                          <div class="col-md-10">
                            <a
                              href="#step5"
                              data-toggle="tab"
                              aria-controls="step5"
                              role="tab"
                              class="btn btn-info nextBtn pull-right"
                            >Continue
                              <i
                                class="fa fa-arrow-right"
                              ></i
                              ></a>
                            <!-- <li role="presentation" class="disabled">
                                <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab" title="step4">
                    <span class="round-tab">
                        <i class="glyphicon glyphicon-ok"></i>
                    </span>
                                </a>
                                <h6 class="text-center">Description</h6>
                            </li> -->
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane"
                      role="tabpanel"
                      id="step5"
                    >
                      <div class="panel-heading">
                        <h3
                          class="panel-title text-center"
                        >
                          Images
                        </h3>
                      </div>
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-md-1"></div>
                          <div class="col-md-10">
                            <!-- <div v-if="!image">
                                <h2>Select an image</h2>
                                <input type="file" @change="onFileChange">
                            </div>
                            <div v-else>
                                <img :src="image" />
                                <button @click="removeImage">Remove image</button>
                            </div> -->

                            <div
                              class="file-upload"
                            >
                              <!-- <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )">Add Image</button> -->

                              <div
                                class="image-upload-wrap"
                              >
                                <input
                                  class="file-upload-input"
                                  type="file"
                                  onchange="readURL(this);"
                                  accept="image/*"
                                />
                                <input
                                  type="hidden"
                                  value=""
                                  id="itemImage"
                                />
                                <div
                                  class="drag-text"
                                >
                                  <h3>
                                    Drag and
                                    drop a
                                    file or
                                    select
                                    add
                                    Image
                                  </h3>
                                </div>
                              </div>
                              <div
                                class="file-upload-content"
                              >
                                <img
                                  class="file-upload-image"
                                  src="#"
                                  alt="your image"
                                />
                                <div
                                  class="image-title-wrap"
                                >
                                  <button
                                    type="button"
                                    onclick="removeUpload()"
                                    class="remove-image"
                                  >
                                    Remove
                                    <span
                                      class="image-title"
                                    >Uploaded
                                                                            Image</span
                                    >
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                        <a
                          href="#step6"
                          data-toggle="tab"
                          aria-controls="step6"
                          role="tab"
                          class="btn btn-info nextBtn pull-right"
                        >Continue
                          <i
                            class="fa fa-arrow-right"
                          ></i
                          ></a>
                      </div>
                    </div>
                    <div
                      class="tab-pane"
                      role="tabpanel"
                      id="step6"
                    >
                      <div class="panel-heading">
                        <h3
                          class="panel-title text-center"
                        >
                          Documents
                        </h3>
                      </div>
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <div class="file-upload">
                              <div class="image-upload">
                                <label for="file-input">
                                  <img src="http://www.hanselman.com/blog/content/binary/Windows-Live-Writer/There-is-only-one-Cloud-Icon-in-the-Enti_137BD/image_d64843a5-92db-44cd-98ec-cc1f74c05526.png"/>
                                </label>
                              </div>
                              <!-- <input type="hidden"  value="" id="itemDoc"> -->
                              <input id="file-input" type="file" @change="previewFiles"/>
                            </div>
                          </div>
                        </div>
                        <div class="float-right">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            :disabled="form.busy"
                            type="submit"
                            class="btn btn-info"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="componenttModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="customerModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">Item QR Code</h5>

            <button
              type="button"
              class="close float-right"
              data-dismiss="modal"
              aria-label="Close"
            >
              <a
                href="javascript:;"
                @click="print('printableArea')"
                class="btn btn-warning btn-sm "
              ><i class="fa fa-print"></i
              ></a>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              id="printableArea"
              class="container justify-content-center"
            >
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <qrcode-vue
                    class="mt-1"
                    :value="value"
                    :size="size"
                    level="H"
                  ></qrcode-vue>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  item Id : {{ form.id }} Item Name :
                  {{ form.name }} &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-if="itemDetails">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel">
              Item Details
            </h5>
            <div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <button @click="print('printShowItemArea','Item Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive ">
                  <ItemShowModal :itemDetails="itemDetails" :itemDetailsData="itemDetailsData"/>
                  <div id="printShowItemArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <h4>Vessel Name: {{this.vessel_name}}</h4>
                      <div class="font-weight-bold">Item Details</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      <ItemShowModal :itemDetails="itemDetails" :itemDetailsData="itemDetailsData" :isPrinting="true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import Select2 from 'v-select2-component'
    import QrcodeVue from 'qrcode.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../auth'
    import ItemTable from "@/components/Item/ItemTable.vue";
    import {printUtil} from "@/utils/print";
    import ItemShowModal from "@/components/Item/ItemShowModal.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import printMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, printMixin],
        name: 'CriticalEquipmentItem',
        components: {
            ItemShowModal,
            ItemTable,
            Multiselect,
            QrcodeVue,
            DatePicker,
            Select2,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                value: '',
                permissions: auth.getPermissionData(),
                adjustingItem: '',
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                deductQty: 0,
                closing_stock: 0,
                next_closing_stock: 0,
                damage_qty: 0,
                receive_qty: 0,
                size: 200,
                options: [],
                editMode: false,
                items: [],
                categoryList: [],
                locationList: [],
                myOptions: [],
                budgetHead: '',
                componentList: [],
                selectedLocation: '',
                locations: [],
                form: new Form({
                    id: '',
                    category_id: '',
                    location_id: '',
                    supplier_id: '',
                    vessel_id: auth.getVesselId(),
                    other_ref: '',
                    name: '',
                    maker: '',
                    maker_part_no: '',
                    model: '',
                    drawing: '',
                    size: '',
                    unit_id: '',
                    description: '',
                    min_order: '',
                    max_order: '',
                    reorder_level: '',
                    reorder_qty: '',
                    opening_stock: 0,
                    class: '',
                    opening_stock_date: '',
                    image: '',
                    doc: '',
                    inpa_code: '',
                    status: '',
                    component_id: [],
                    budget_head_id: '',
                    price: 0
                }),
                componentForm: new Form({
                    item_id: '',
                    component_id: ''
                }),
                itemDetailsData: null,
                itemDetails: null,
                units: null,
                suppliers: null
            }
        },
        mounted() {
            this.getData()
            this.locationListInfo()
            this.categoryListInfo()
            this.getUnit()
            this.getSupplier()
            this.getLocation()

        },
        methods: {
            stockModal(item) {
                $('#stockAdjustModal').modal('show')
                this.getItemStock(item)
            },
            stockAdjust() {
                var formData = {
                    item_id: this.adjustingItem,
                    vessel_id: this.vesselId,
                    deductQty: this.deductQty,
                    damage_qty: this.damage_qty,
                    receive_qty: this.receive_qty

                }

                this.$http.post(`/api/stock-adjust`, formData).then(response => {
                    if (response.data) {
                        this.getData()
                        this.deductQty = 0;
                        this.damage_qty = 0;
                        this.receive_qty = 0;
                        this.item_id = '';
                        this.$snotify.success('Stock adjusted successfully.')
                        $('#stockAdjustModal').modal('hide')
                    }
                }).catch(e => {
                    console.log(e)
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(e.response?.data?.error)
                    }
                })
            },

            selectLocation(locationId) {
                if (this.selectedLocation === locationId) {
                    this.selectedLocation = '';
                } else {
                    this.selectedLocation = locationId;
                }
                this.getData();
            },
            getLocation() {
                let params = {
                    vessel_id: this.vesselId,
                    critical_equipment: "Yes"
                };
                this.$http.get('/api/filter/get-location-wise-item', {params}).then(response => {
                    this.locations = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            quantityDeducted() {
                if (parseFloat(this.deductQty) > parseFloat(this.closing_stock)) {
                    this.next_closing_stock = 0
                    this.deductQty = this.closing_stock
                    this.damage_qty = 0
                    return false
                }
                if ((parseFloat(this.deductQty) + parseFloat(this.damage_qty)) > parseFloat(this.closing_stock)) {
                    this.next_closing_stock = 0
                    this.deductQty = this.closing_stock - this.damage_qty
                    if (this.deductQty < 0) {
                        this.deductQty = 0;
                    }
                    if (this.damage_qty > this.closing_stock) {
                        this.damage_qty = this.closing_stock;
                    }
                    return false
                }

                var newBalance = parseFloat(this.closing_stock) - parseFloat(this.deductQty) - this.damage_qty
                this.next_closing_stock = newBalance
            },
            getItemStock(item) {
                this.adjustingItem = item.id
                this.$http.post('/api/getItemStock', {
                    item_id: item.id,
                    vessel_id: this.vesselId
                }).then(response => {
                    this.closing_stock = response.data
                    this.next_closing_stock = response.data
                }).catch(e => {
                    this.CategoriesItemData = []
                    console.log(e)
                })
            },
            getUnit() {
                this.$http.get('/api/unit').then(response => {
                    this.units = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getSupplier() {
                this.$http.get('/api/supplier').then(response => {
                    this.suppliers = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getData() {
                this.loading = true;
                let params = {
                    vessel_id: this.form.vessel_id,
                    location_id: this.selectedLocation
                };
                this.$http.get('/api/filter/critical-equipment-item/', {params}).then(response => {
                    this.items = response.data.success;
                    this.loading = false
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            async getBudgetHeadInfo() {
                this.myOptions = []
                var itemList = await this.$http.get(
                    'api/filter/budget-head/' + this.form.vessel_id
                )
                this.results = itemList.data.success
                var newObj = {
                    id: '',
                    text: 'No Selected'
                }
                this.myOptions.push(newObj)
                this.results.filter(item => {
                    var newObj = {
                        id: item.id,
                        text: item.name
                    }
                    this.myOptions.push(newObj)
                })
            },
            //create method call for only item created
            create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                this.options = []
                //remove imge information
                $('.file-upload-input').replaceWith(
                    $('.file-upload-input').clone()
                )
                $('.file-upload-content').hide()
                $('.image-upload-wrap').show()

                //component list
                this.componentListInfo()
                this.getBudgetHeadInfo()
                $('#roleModal').modal('show')
            },
            //item store operation
            store() {
                this.form.busy = true
                this.form.image = $('#itemImage').val()
                this.form.post('/api/item').then(response => {
                    //call againg all data for table list
                    $('.dataTables_empty').closest('tr').remove()
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Item Created Successfully')
                    } else {
                        this.$snotify.error(
                            'Please check form again and fillup all required filed.'
                        )
                    }
                }).catch(e => {
                    var response = e.response
                    if (response.data?.errors) {
                        const errors = response.data.errors;
                        for (const key in errors) {
                            this.$snotify.error(errors[key][0]);
                        }
                    } else if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(
                            'Please check form again and fillup all required filed.'
                        )
                    }

                })
            },

            //store item to component data
            showQRCode(item) {
                this.form.clear()
                this.form.reset()
                this.form.fill(item)
                this.value = item.id + item.name
                $('#componenttModel').modal('show')
            },

            storeComponent() {
                this.componentForm.busy = true
                this.componentForm.post('/api/item-to-component').then(response => {
                    this.getData()
                    $('#componenttModel').modal('hide')
                    if (this.componentForm.successful) {
                        this.$snotify.success(
                            'Item Component Assign Successfully'
                        )
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    var error = this.componentForm.errors.errors.error
                    console.log(error.name)
                    this.$snotify.error(
                        'Sorry Item Component Assign successfully'
                    )
                })
            },
            edit(item) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.form.fill(item)
                this.options = []
                //component list
                this.selectedComponent(item.id)
                this.getBudgetHeadInfo()
                this.componentListInfo()
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.image = $('#itemImage').val();
                this.form.post('/api/item/' + this.form.id).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Item successfully Update')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    var response = e.response
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            /*            show(item) {
                                this.form.reset();
                                this.form.reset();
                                this.form.clear();
                                this.form.fill(item);
                                this.options=[];
                                //component list
                               this.selectedComponent(item.id);
                                this.itemViewGenerate(item.id);

                            },*/
            show(row) {
                this.$http.get('/api/item/' + row.id).then(response => {
                    console.log(response.data)
                    $('#showModal').modal('show')
                    this.itemDetailsData = response.data
                    this.itemDetails = response.data.item
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(item) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/item/' + item.id).then(response => {
                        this.getData()
                        this.getLocation();
                        this.$snotify.success('Item Successfully Deleted')
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error(response.data.error)
                        }
                    })
                }).catch(function () {
                })
            },
            //get location list for dropdown form
            locationListInfo() {
                this.$http.get('/api/locationTree/' + this.form.vessel_id).then(response => {
                    $('.location-tree').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            //get category list for dropdown form
            categoryListInfo() {
                this.$http.get('/api/categoryTree/' + this.form.vessel_id).then(response => {
                    $('.category-tree').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            //*selected component list get and assign in component_id in form 8 *//
            selectedComponent(id) {
                this.$http.get('/api/item/' + id).then(response => {
                    var componentList = response.data.component
                    var self = this
                    var ComponentInfo = []
                    componentList.filter(item => {
                        // console.log(item);
                        ComponentInfo.push(item.component)
                    })
                    this.form.component_id = ComponentInfo
                }).catch(e => {
                    console.log(e)
                })
            },
            /*
                //item assign depend on component
                */
            componentListInfo() {
                this.$http.get('/api/filter/all-component/' + this.form.vessel_id).then(response => {
                    this.componentList = response.data.success
                    this.componentList.filter(item => {
                        var itemObject = {
                            id: item.id,
                            name: item.name
                        }
                        this.options.push(itemObject)
                    })
                }).catch(e => {
                    console.log(e)
                })
            },

            previewFiles(event) {
                var file = event.target.files[0]
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = event => {
                        var docSrc = event.target.result
                        this.doc = docSrc
                    }
                    reader.readAsDataURL(file)
                } else {
                    alert('Sorry, FileReader API not supported')
                }
            },
            isEmpty(obj) {
                if (obj != null) {
                    return true
                } else {
                    return false
                }
            },

        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .wizard {
    margin: 20px auto;
    background: #fff;
  }

  .wizard .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    border-bottom-color: #e0e0e0;
  }

  .wizard > div.wizard-inner {
    position: relative;
  }

  .connecting-line {
    height: 1px;
    background: #e0e0e0;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
  }

  .wizard .nav-tabs > li.active > a,
  .wizard .nav-tabs > li.active > a:hover,
  .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: #ffffff;
  }

  span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
  }

  span.round-tab i {
    color: #555555;
  }

  .wizard li.active span.round-tab {
    background: #fff;
    border: 2px solid #5bc0de;
  }

  .wizard li.active span.round-tab i {
    color: #5bc0de;
  }

  span.round-tab:hover {
    color: #333;
    border: 2px solid #333;
  }

  .wizard .nav-tabs > li {
    width: 16%;
  }

  .wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #5bc0de;
    transition: 0.1s ease-in-out;
  }

  .wizard .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
  }

  .wizard .nav-tabs > li a:hover {
    background: transparent;
  }

  .wizard .tab-pane {
    position: relative;
    padding-top: 50px;
  }

  .wizard h3 {
    margin-top: 0;
  }

  @media (max-width: 585px) {
    .wizard {
      width: 90%;
      height: auto !important;
    }

    span.round-tab {
      font-size: 16px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    .wizard .nav-tabs > li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }

  .wizard .nav-tabs {
    border-bottom-color: #ffffff;
  }

  input[data-v-4bd11526] {
    min-width: 26px !important;
    width: 100%;
    height: 38px;
    padding: 3px;
    border: 1px solid #ddd;
  }

  .datetime-picker {
    width: 100% !important;
  }
</style>
