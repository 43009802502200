import { render, staticRenderFns } from "./BreakdownHistoryReport.vue?vue&type=template&id=0c2bee38&scoped=true"
import script from "./BreakdownHistoryReport.vue?vue&type=script&lang=js"
export * from "./BreakdownHistoryReport.vue?vue&type=script&lang=js"
import style0 from "./BreakdownHistoryReport.vue?vue&type=style&index=0&id=0c2bee38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2bee38",
  null
  
)

export default component.exports