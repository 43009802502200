<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left"> Invoice List</h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i> </button>

          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Invoice No</th>
                        <th>Purchase No</th>
                        <th>Created By</th>
                        <th>Paid Amount</th>
                        <th>Date Created</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row, index) in listData" :key="row.id">
                        <td>{{++index}}</td>
                        <td>{{row?.invoice_no}}</td>
                        <td>{{row?.purchase?.po_no}}</td>
                        <td>{{row?.user?.username}}</td>
                        <td>${{row?.amount}}</td>
                        <td>{{row?.date}}</td>
                        <td>
                          <span v-if="row?.payment_status === 1" class="badge badge-success">Paid</span>
                          <span v-else class="badge badge-danger">Unpaid</span>
                        </td>
                        <td>
                          <button v-if="row?.payment_status === 0" type="button" @click="changeStatus(row, 1)" class="btn btn-success btn-sm" title="Paid"><i
                            class="fa fa-check"></i></button>
                          <button @click="invoiceView(row)" class="btn btn-secondary btn-sm" title="View Invoice"><i class="fa fa-eye"></i> View Invoice</button>

                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import datetime from 'vuejs-datetimepicker'
  import auth from '../../auth'
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";

  export default {
    name: 'InvoiceList',
    components: { datetime,PulseLoader },
    data () {
      return {
        loading: false,
        editMode: false,
        supplierData: [],
        purchaseData: null,
        listData: [],
        vessel_id: auth.getVesselId(),
        pagination: {
          current_page: 1
        },
        form: new Form({
          'id': '',
          'status': '',
        }),
      }
    },
    mounted () {
      this.getListData()
    },
    methods: {
      getListData () {
        this.loading = true
        this.$http.get('/api/filter/invoice/' + this.vessel_id).then(response => {
          this.loading = false
          this.listData = response.data.success
          setTimeout(() => $('#datatables').DataTable({
            'ordering': false,
            'aaSorting': [],
            'bDestroy': true
          }), 1000)
        }).catch(e => {
          this.loading = false
        })
      },
      changeStatus (supplier, payment_status) {
        this.form.id = supplier.id
        this.form.payment_status = payment_status
        this.form
          .post('/api/invoice-status/update')
          .then(response => {
            this.getListData()
            if (this.form.successful) {
              this.$snotify.success('Invoice Successfully Updated')
            } else {
              this.$snotify.error(
                'Something went wrong try again later.'
              )
            }
          })
          .catch(e => {
            let response = e.response
            let status = e.response.status
            if (status === 500){
              let msg  = response.data.error
              if (msg){
                this.$snotify.error(msg)
              }else{
                this.$snotify.error('Something went wrong try again later.')
              }
            }
            if (status === 422){
              if (response.data?.errors) {
                const errors = response.data.errors;
                for (const key in errors) {
                  this.$snotify.error(errors[key][0])
                }
              }
            }
          })
      },
      invoiceView (row) {
        this.$router.push({
          name: 'InvoiceView',
          params: {
            purchaseID: row.purchase_id,
            invoiceID: row.id
          }
        })
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>

<style scoped>

</style>
