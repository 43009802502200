<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <button v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB_ADD)  && jobInfo.job_schedules[Schedule].job_done_report == null" class="btn btn-sm btn-info pull-right" @click="showDeferJobModal"> Add Defer <i class="fa fa-plus"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead class="text-center">
              <tr>
                <th># ID</th>
                <th>Prev Maintenance Date</th>
                <th>Next Maintenance Due Date</th>
                <th> Defer Rescheduled Date</th>
                <th>Reason Deffer</th>
                <th>Document</th>
                <th>Done By</th>
                <th>Verified By</th>
                <th>Status</th>
                <th>Office Approval</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(scheduleInfo,index) in deferRescheduleInfo" :key="scheduleInfo.id">
                <td>{{index+1}}</td>

                <td>
                  <div class="col-10">
                    <p>{{scheduleInfo.prev_start_date | moment('DD-MM-YYYY')}}</p>
                  </div>
                </td>
                <td>
                  <div class="col-10">
                    <p>{{scheduleInfo.prev_end_date | moment('DD-MM-YYYY')}}</p>
                  </div>
                </td>
                <td>
                  <div class="col-10">
                    <p>{{scheduleInfo.current_end_date | moment('DD-MM-YYYY')}}</p>
                  </div>
                </td>
                <td>
                  <div class="col-10">
                    <p>{{scheduleInfo.reason_deffer}}</p>
                  </div>
                </td>
                <td>
                  <div class="col-10">
                    <p>
                      <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="scheduleInfo &&  scheduleInfo?.image != null" :href="scheduleInfo &&  scheduleInfo?.image">View</a>

                    </p>
                  </div>
                </td>
                <!--                <td>-->
                <!--                  <div class="col-10">-->
                <!--                    <p>{{scheduleInfo.current_end_date | moment('DD-MM-YYYY')}}</p>-->
                <!--                  </div>-->
                <!--                </td>-->
                <td>
                  <div class="col-10">
                    <p>
                      {{scheduleInfo?.done_by_rank?.name}}
                    </p>
                  </div>
                </td>

                <td>
                  <div class="col-10">
                    {{scheduleInfo?.verified_by_rank?.name}}
                  </div>
                </td>
                <td>
                  <div class="col-10">
                   <span v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB_ADD_APPROVAL) && scheduleInfo.status =='Pending'">
                      <button @click="AapprovedBychiefEngineer(scheduleInfo.id)" class="btn btn-sm btn-warning">Pending Approval</button>
                   </span>
                    <span v-else>{{scheduleInfo.status}}</span>
                  </div>
                </td>
                <td>
                  <div class="col-10">
                   <span v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB_OFFICE_APPROVAL) && scheduleInfo?.office_approval =='Pending'">
                      <button @click="ApproveByOffice(scheduleInfo.id)" class="btn btn-sm btn-warning">Pending Approval</button>
                   </span>
                    <span v-else>{{scheduleInfo?.office_approval}}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">Approved Deffer Reschedule</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="AapprovedBychiefEngineer">
            <div class="modal-body">
              <div class="form-group">
                <label> Job Start Date </label>
                <DatePicker :disabled="true" type="date" v-model="deferInfo.prev_start_date" valueType="format"></DatePicker>
              </div>
              <div class="form-group">
                <label> Job End Date </label>
                <DatePicker :disabled="true" type="date" v-model="deferInfo.prev_end_date" valueType="format"></DatePicker>
              </div>
              <div class="form-group">
                <label>New Schedule Date </label>
                <DatePicker v-model="deferInfo.current_end_date" type="date" valueType="format"></DatePicker>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Job defer request Modal  -->
    <!-- job Defer Modal  -->
    <!-- Modal -->
    <div class="modal fade" id="deferJob" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel"> Defer Job</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="deferRequest()" @keydown="deferInfo.onKeydown($event)">
            <alert-error :form="deferInfo"></alert-error>
            <div class="modal-body">
              <div class="form-group">
                <label> Prev Maintenance Date </label>
                <DatePicker type="date" v-model="deferInfo.prev_start_date" :disabled="true" valueType="format"></DatePicker>
              </div>
              <div class="form-group">
                <label>Next Maintenance Due Date </label>
                <DatePicker type="date" v-model="deferInfo.prev_end_date" :disabled="true" valueType="format"></DatePicker>
              </div>

              <div class="form-group">
                <label> Job Reschedule Date : </label><br>
                <span class="text-danger">Note : Reschedule Date Not Greater Than 2 Month from Current Schedule </span>
                <DatePicker type="date" v-model="deferInfo.current_end_date" name="current_end_date" valueType="format"></DatePicker>
                <has-error :form="deferInfo" field="current_end_date"></has-error>
              </div>
              <div class="form-group" v-if="counter_type == 'Running Hours'">
                <label>Extend Running Hours: </label>
                <input type="number" class="form-control" v-model="deferInfo.extended_running_hours">
              </div>
              <div class="form-group">
                <label>Defer Reason : </label>
                <textarea class="form-control" rows="5" v-model="deferInfo.reason_deffer"></textarea>
              </div>
              <div class="form-group">
                <span class="mb-3">Document</span>
                <input type="file" class="form-control" @change="covertDocument" accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.ppt,.csv,.xls,.xlsx">
                <input type="hidden" value="" id="itemImage">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="deferInfo.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '@/auth'
    import permissionsMixin from "@/mixin/permissionsMixin";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        name: 'DeferReschedule',
        mixins: [permissionsMixin, printMixin, goBackButtonMixin],
        components: {DatePicker},
        props: {
            jobInfo: {},
            Schedule: '',
        },
        data() {
            return {
                deferInfo: new Form({
                    prev_start_date: '',
                    prev_end_date: '',
                    current_end_date: '',
                    reason_deffer: '',
                    image: '',
                    done_by: '',
                    extended_running_hours: 0,
                    schedule_id: this.jobInfo.job_schedules[this.Schedule].id
                }),
                counter_type: null,
                permissions: auth.getPermissionData(),
                deferRescheduleInfo: [],
                user_type: auth.getUserType(),
                scheduler_id: this.Schedule,
            }
        },
        mounted() {
            this.getScheduleList()
        },
        methods: {
            getScheduleList() {
                var ScheduleId = this.jobInfo.job_schedules[this.Schedule].id;
                this.$http.get('/api/defer-reschedule/' + ScheduleId).then(response => {
                    this.deferRescheduleInfo = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            covertDocument(event) {
                let file = event.target.files[0];
                this.deferInfo.image = file;
            },
            //defer job related all method
            showDeferJobModal() {
                this.deferInfo.prev_start_date = this.jobInfo.job_schedules[this.Schedule].start_date;
                this.deferInfo.prev_end_date = this.jobInfo.job_schedules[this.Schedule].end_date;
                this.counter_type = this.jobInfo.job_schedules[this.Schedule].counter_type;
                $('#deferJob').modal('show');
            },
            deferRequest() {
                this.$http.post('/api/defer-reschedule', this.deferInfo).then(response => {
                    $('.dataTables_empty').closest('tr').remove();
                    this.getScheduleList();
                    $('#deferJob').modal('hide');
                    this.$snotify.success('Defer Request Sended');
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },

            AapprovedBychiefEngineer(deferId) {
                var ScheduleId = this.jobInfo.job_schedules[this.Schedule].id;
                // this.form1.busy = true;
                this.$http.get('/api/job-done-permission/' + ScheduleId + '/' + deferId).then(response => {
                    this.$snotify.success('your permission Successfully Completed.');
                    this.$emit('enableListMode');
                    $('#roleModal').modal('hide')
                }).catch(e => {
                    console.log(e);
                    // var error=this.form.errors.errors.error;
                    this.$snotify.error('your permission Successfully is not  Completed.')
                })
            },
            ApproveByOffice(deferId) {
                var ScheduleId = this.jobInfo.job_schedules[this.Schedule].id;
                // this.form1.busy = true;
                this.$http.get('/api/defer-reschedule-approve-office/' + ScheduleId + '/' + deferId).then(response => {
                    this.$snotify.success('your permission Successfully Completed.');
                    this.$emit('enableListMode');
                    $('#roleModal').modal('hide')
                }).catch(e => {
                    console.log(e);
                    // var error=this.form.errors.errors.error;
                    this.$snotify.error('your permission Successfully is not  Completed.')
                })
            },
        }
    }
</script>

<style scoped>
  .test1 {
    padding: 0.40rem !important;
  }

  .mx-datepicker {
    margin-top: 5px !important;
    width: 100% !important;
  }
</style>
