<template>
  <div>
    <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
      <PulseLoader :loading="loading"></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <div class="x_title">
            <h2> Budget Head List Tree </h2>
            <ul class="nav navbar-right panel_toolbox">
              <li>
                <router-link to="/budgethead-add">
                  <button class="btn btn-info btn-sm">Add Budget Head <i class="fa fa-plus"></i></button>
                </router-link>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <!--                    <div id="treeview"  style="margin-top: 4%;"></div>-->
          <Tree id="my-tree-id" ref="my-tree" :nodes="budgetHeads"></Tree>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </div>
</template>

<script>
    import Select2 from 'v-select2-component';
    import auth from "@/auth";
    import Tree from "vuejs-tree";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: "BudgetHead",
        components: {Select2, Tree, PulseLoader},
        data() {
            return {
                loading: false,
                editMode: false,
                budgetHeads: [],
                form: new Form({
                    id: "",
                    name: "",
                    vessel_id: auth.getVesselId(),
                    parent_id: ""
                }),
                query: '',
                results: [],
                myOptions: []
            }
        },
        mounted() {
            this.budgetMainHead();
            this.create();
        },
        methods: {
            async create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.myOptions = [];
                var itemList = await this.$http.get("/api/budget-head/create");
                this.results = itemList.data.success;
                var newObj = {id: "", text: "No Selected"};
                this.myOptions.push(newObj);
                this.results.filter(item => {
                    var newObj = {id: item.id, text: item.name + ' (' + item.code + ')'};
                    this.myOptions.push(newObj);
                })
            },
            budgetMainHead() {
                this.loading = true;
                this.$http.get("/api/budget-head-parent/" + this.form.vessel_id).then(response => {
                    this.budgetHeads = response.data.success;
                    $('#treeview').treeview({data: this.budgetHeads});
                    $('#treeview').treeview('collapseAll', {silent: true});
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                });
            },
            store() {
                this.form.busy = true;
                this.form
                    .post("/api/budget-head")
                    .then(response => {
                        this.budgetMainHead();
                        if (this.form.successful) {
                            this.create();
                            this.$snotify.success("Budget Head Created Successfully");
                        } else {
                            this.$snotify.error(
                                "Something went wrong try again later."
                            );
                        }
                    })
                    .catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error(response?.data?.error)
                        }
                    });
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.form.parent_id = id;
            },
            goBack() {
                this.$router.go(-1);
            }
        },

    }
</script>

<style scoped>
  /* .list-group{
      display: block !important;
  } */
  .x_title {
    border-bottom: none !important;
  }

  .easy-autocomplete {
    width: 100% !important;
  }
</style>
