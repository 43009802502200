<template>
  <div>
    <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
      <PulseLoader :loading="loading"></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Supplier Type</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_CREATE)" type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_DELETE) || isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_EDIT)">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row , index) in dataList" :key="index">
                        <td>{{index+1}}</td>
                        <td>{{row.name}}</td>
                        <td v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_DELETE) || isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_EDIT)">
                          <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_EDIT)" @click="edit(row)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                          <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_TYPE_DELETE)" @click="destroy(row)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                        </td>

                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add New' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <!-- <alert-error :form="form"></alert-error> -->
              <div class="form-group">
                <label>Name </label>
                <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }" autocomplete="off">
                <has-error :form="form" field="name"></has-error>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->

  </div>
</template>

<script>
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import auth from "@/auth";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin],
        components: {PulseLoader},
        name: 'SupplierType',
        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                dataList: null,
                permissions: auth.getPermissionData(),
                form: new Form({
                    id: '',
                    name: '',
                }),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.loading = true;
                this.$http.get('/api/supplier-type').then(response => {
                    this.dataList = response.data.success;
                    this.loading = false;
                    $('.dataTables_empty').closest('tr').remove();
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $('#roleModal').modal('show')
            },
            store() {
                this.form.busy = true;
                this.form.post('/api/supplier-type').then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Type Created Successfully')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },

            edit(row) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(row);
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/supplier-type/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Type Successfully Updated')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            destroy(role) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/supplier-type/' + role.id).then(response => {
                        this.getData();
                        this.$snotify.success(
                            'Unit Successfully Deleted',
                            'Success'
                        )
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error("something went wrong")
                        }
                    })
                }).catch(function () {
                    this.$snotify.error("something went wrong")
                })
            }

        }
    }
</script>

<style scoped>

</style>
