<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th class="text-center"># </th>
      <th class="text-center" v-if="!isPrinting"> Image </th>
      <th class="text-center">  Name </th>
      <th class="text-center"> IMPA Code </th>
      <th class="text-center">  Other Ref  </th>
      <th class="text-center"> Maker  </th>
      <th class="text-center"> Maker Part No </th>
      <th class="text-center"> Model </th>
      <th class="text-center"> Category </th>
      <th class="text-center"> Budget Head </th>
      <th class="text-center"> Location </th>

      <th class="text-center"> Unit </th>
      <th class="text-center"> Opening Stock </th>
      <th class="text-center"> Received Qty </th>
      <th class="text-center"> Consumed Qty </th>
      <th class="text-center"> Damage Qty </th>
      <th class="text-center"> ROB  </th>
      <th class="text-center">  Min Required </th>
      <th class="text-center"> Max Order </th>
      <th class="text-right"> Price </th>
      <th  width="15%" class="text-center" v-if="!isPrinting && (
         isPermissionExist(PERMISSIONS.ITEM_SHOW) ||
         isPermissionExist(PERMISSIONS.ITEM_QR_CODE) ||
         isPermissionExist(PERMISSIONS.ITEM_EDIT) ||
         isPermissionExist(PERMISSIONS.ITEM_DELETE) ||
         isPermissionExist(PERMISSIONS.ITEM_STOCK_ADJUST)
          )"  >  Action  </th>
    </tr>
    </thead>
    <tbody>


    <tr  v-for="(item, index) in items"  :key="item.id" >
      <td class="text-center">  {{ index + 1 }}  </td>
      <td class="text-center" v-if="!isPrinting">
        <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="item && item.image != null" :href="item && item.image ">View</a>
      </td>
      <td class="text-center"> {{ item?.name }} </td>
      <td class="text-center"> {{ item?.inpa_code }} </td>
      <td class="text-center"> {{ item?.other_ref }} </td>
      <td class="text-center"> {{ item?.maker }} </td>
      <td class="text-center">  {{ item?.maker_part_no }} </td>
      <td class="text-center"> {{ item?.model }} </td>
      <td class="text-center"> {{ isEmpty(item.category) ? item.category?.name : '' }} </td>
      <td class="text-center"> {{ isEmpty(item.budgethead) ? item.budgethead?.name + '(' + item.budgethead?.code + ')'  : '' }} </td>
      <td class="text-center"> {{ isEmpty(item.location) ? item.location?.name : '' }}  </td>

      <td class="text-center">{{ isEmpty(item.unit) ? item.unit?.name : '' }}  </td>
      <td class="text-center"> {{ item?.opening_stock }} </td>
      <td class="text-center"> {{ item?.received_qty }} </td>
      <td class="text-center"> {{ item?.consumed_qty }} </td>
      <td class="text-center"> {{ item?.damage_qty }} </td>
      <td class="text-center"> {{ item?.rob }} </td>
      <td class="text-center"> {{ item?.reorder_level }} </td>
      <td class="text-center"> {{ item?.max_order }} </td>
      <td class="text-right"> ${{ item?.price }} </td>
      <td class="text-center"  v-if="!isPrinting && (
         isPermissionExist(PERMISSIONS.ITEM_SHOW) ||
         isPermissionExist(PERMISSIONS.ITEM_QR_CODE) ||
         isPermissionExist(PERMISSIONS.ITEM_EDIT) ||
         isPermissionExist(PERMISSIONS.ITEM_DELETE) ||
         isPermissionExist(PERMISSIONS.ITEM_STOCK_ADJUST)
          )"  >
        <button title="Item View"  type="button" v-if="isPermissionExist(PERMISSIONS.ITEM_SHOW)"  @click="show(item)"  class="btn btn-info btn-sm"  > <i class="fa fa-eye" ></i> </button>
<!--        <button  title="Item QR Code" type="button" v-if="isPermissionExist(PERMISSIONS.ITEM_QR_CODE)"  @click="showQRCode(item) " class="btn btn-success btn-sm" > <i class="fa fa-qrcode"></i> </button>-->
        <button title="Item Edit" type="button"  v-if="isPermissionExist(PERMISSIONS.ITEM_EDIT)" @click="edit(item)"  class="btn btn-primary btn-sm" > <i class="fa fa-edit" ></i> </button>
        <button title="Item Delete" type="button" v-if="isPermissionExist(PERMISSIONS.ITEM_DELETE)" @click="destroy(item)" class="btn btn-danger btn-sm" > <i class="fa fa-trash" ></i> </button>
        <button title="Monthly stock adjustment" v-if="isPermissionExist(PERMISSIONS.ITEM_STOCK_ADJUST)" type="button" @click="stockModal(item)" class="btn btn-warning btn-sm" > <i class="fa fa-retweet" ></i> </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    items: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    showQRCode: {
      type: Function,
      required: false
    },
    stockModal: {
      type: Function,
      required: false
    },
    destroy: {
      type: Function,
      required: false
    },
    isEmpty:{
      required:false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
