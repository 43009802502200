<template>
  <div>
    <div class="container body">
      <div class="main_container">
        <Sidebar></Sidebar>
        <Header></Header>
        <div class="right_col" role="main" style="min-height: 987px;">
          <router-view></router-view>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/common/Footer.vue";
import Sidebar from "@/components/common/Sidebar.vue";
import Header from "@/components/common/Header.vue";

export default {
  name: 'DashboardLayout',
  components: {Header, Sidebar, Footer}
}
</script>
