<template>
  <div>
    <div class="top_nav">
      <div class="nav_menu">
        <div class="nav toggle">
          <a id="menu_toggle"><i class="fa fa-bars"></i></a>
        </div>

        <nav class="nav navbar-nav">
          <ul class=" navbar-right">

            <li  v-if="isPermissionExist(PERMISSIONS.VESSEL_SELECT)" class="nav-item float-left">
              <div class="input-group mb-0">
                <div class="input-group-prepend">
                  <span class="input-group-text " style="background-color: #030148c2;color:white;" id="inputGroup-sizing-default">Select Vessel</span>
                </div>
                <select class="form-control" v-model="vesselId" required name="vessel_id" @change="setVessel">
                  <option v-for="vessel in vessels" :key="vessel.id" :value="vessel.id">{{vessel.name}}</option>
                </select>
              </div>
            </li>

            <li class="nav-item dropdown open" style="padding-left: 15px;">
              <a href="javascript:;" class="user-profile" id="navbarDropdown" @click="showToggoleMenu1">
                {{ username }} &nbsp;<i class=" logouticon fa fa-angle-down" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu dropdown-usermenu pull-right " aria-labelledby="navbarDropdown">

                <router-link class="dropdown-item" to="/change-password">Change Password</router-link>
                <a class="dropdown-item" @click="Logout"><i class="fa fa-sign-out pull-right"></i> Log Out</a>
              </div>
            </li>

            <li role="presentation" class="nav-item dropdown open">


            <li role="presentation" class="nav-item dropdown open mr-3" v-if="totalLowItem">
              <a href="javascript:;" @click="showStockNotifications" title="Low stock items" class="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-envelope-o"></i>
                <span class="badge bg-danger text-white">{{ totalLowItem }}</span>
              </a>
              <ul class="dropdown-menu list-unstyled low-stock-list" role="menu" aria-labelledby="navbarDropdown1" x-placement="bottom-start">
                <li v-for="(row, key) in lowStockItems" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                  {{ row.name }}({{ row.inpa_code }})
                  <span class="badge badge-danger badge-pill">{{ row.closing_stock }}</span>
                </li>
              </ul>
            </li>

            <li role="presentation" class="nav-item dropdown open mr-3" v-if="totalExpire">
              <a href="javascript:;" @click="showExpireNotifications" title="Expiring certificates" class="dropdown-toggle info-number"
                 id="navbarDropdown2" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="badge bg-danger text-white" style="right: 3px">{{ totalExpire }}</span>
              </a>
              <ul class="dropdown-menu list-unstyled expire-info" role="menu" aria-labelledby="navbarDropdown2" x-placement="bottom-start">
                <li v-for="(row, key) in vesselExpireList" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                  {{ row.name }}-{{ row.certificate_no }}
                  <span class="badge badge-danger badge-pill"> {{ row.date_of_expire | moment("MMM Do, YY") }}</span>
                </li>
              </ul>
            </li>
            <li role="presentation" class="nav-item dropdown open mr-3" v-if="totalExpire">
              <a href="javascript:;" @click="showVesselCertificateNotification" title="Low stock items" class="dropdown-toggle info-number"
                 id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-bell"></i>
                <span class="badge bg-danger text-white"> {{totalExpire}}</span>
              </a>
              <ul class="dropdown-menu list-unstyled vessel-certificate-list" role="menu" aria-labelledby="navbarDropdown1" x-placement="bottom-start">
                <li v-for="(row, key) in vesselExpireList" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                  {{ row.name }} Certificate type of {{ row?.vessel_certificate_type?.name }} is expiring on {{ row.date_of_expire | moment("MMM Do, YY") }}
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
</template>

<script>
    import auth from "@/auth";
    import permissionsMixin from "@/mixin/permissionsMixin";

    export default {
         mixins: [permissionsMixin],
        name: "Header",
        data() {
            return {
                show: false,
                vessel_type_id: auth.getVesselTypeId(),
                vesselTypes: [],
                vessels: [],
                vesselId: auth.getVesselId(),
                vesselExpireList: null,
                lowStockItems: null,
                username: "",
                totalLowItem: null,
                totalExpire: null,
                permissions:auth.getPermissionData()
            }
        },
        mounted() {
            this.getVessels();
            this.getExpireCertificateList();
            this.getLowStockItems();
            this.userInfo();
        },
        methods: {
            toggleNavbar() {
                this.show = !this.show
            },
            showStockNotifications() {
                $('.low-stock-list').toggle();
                $('.vessel-certificate-list').hide()
                $('.expire-info').hide()
                $(".dropdown-usermenu").removeClass("show");
                $(".dropdown").removeClass("show");
                $(".logouticon").removeClass("fa-angle-up");
                $(".logouticon").addClass("fa-angle-down");
                this.show = false;
            },
            showVesselCertificateNotification() {
                $('.vessel-certificate-list').toggle();
                $('.expire-info').hide()
                $('.low-stock-list').hide()
                $(".dropdown-usermenu").removeClass("show");
                $(".dropdown").removeClass("show");
                $(".logouticon").removeClass("fa-angle-up");
                $(".logouticon").addClass("fa-angle-down");
                this.show = false;
            },
            showExpireNotifications() {
                $('.expire-info').toggle();
                $('.vessel-certificate-list').hide()
                $('.low-stock-list').hide()
                $(".dropdown-usermenu").removeClass("show");
                $(".dropdown").removeClass("show");
                $(".logouticon").removeClass("fa-angle-up");
                $(".logouticon").addClass("fa-angle-down");
                this.show = false;
            },
            getVessels() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel;
                }).catch(error => {
                    this.vessels = [];
                    console.log(error);
                })
            },
            setVessel() {
                const vessel_data = this.vessels.find(vessel => vessel.id === this.vesselId);
                localStorage.setItem('vessel_id', this.vesselId);
                localStorage.setItem('vessel_name', vessel_data.name);
                localStorage.setItem('vessel_type_id', vessel_data.vessel_type_id);
                this.$snotify.success("Vessel updated successfully", this.vesselId.name);
                setTimeout(() => {
                   window.location.reload();
                },1000)
            },
            getExpireCertificateList() {
                this.$http.get('/api/vessel-expire-certificates/' + this.vesselId).then(response => {
                    this.vesselExpireList = response.data;
                    this.totalExpire = this.vesselExpireList.length
                    // console.log(this.vesselExpireInfo);
                }).catch(error => {
                    console.log(error)
                })
            },
            getLowStockItems() {
                this.$http.get('/api/get-low-stock-items/' + this.vesselId).then(response => {
                    this.lowStockItems = response.data;
                    //  console.log(this.lowStockItems);
                    this.totalLowItem = this.lowStockItems.length;
                }).catch(error => {
                    //console.log(error);
                })
            },
            userInfo() {
                this.$http.post('/api/user-details').then(response => {
                    this.username = response.data.success.username
                }).catch(error => {
                })
            },
            Logout() {
                this.$http.post('/api/user-logout').then(response => {
                    auth.logout();
                }).catch(error => {
                    console.log(error)
                })

            },
            //toggole menu section
            showToggoleMenu1() {
                if (this.show) {
                    $(".dropdown-usermenu").removeClass("show");
                    $(".dropdown").removeClass("show");
                    $(".logouticon").removeClass("fa-angle-up");
                    $(".logouticon").addClass("fa-angle-down");
                    this.show = false;
                } else {
                    $(".dropdown").addClass("show");
                    $(".dropdown-usermenu").addClass("show");
                    $(".logouticon").removeClass("fa-angle-down");
                    $(".logouticon").addClass("fa-angle-up");
                    this.show = true;
                    $('.expire-info').hide();
                    $('.vessel-certificate-list').hide()
                    $('.low-stock-list').hide()
                }
            }
        }
    }
</script>

<style scoped>
  .alarm {
    font-size: 19px;
    color: black;
  }
</style>
