<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Maintenance History List</h2>
          <ul class="nav navbar-right panel_toolbox">
            <li v-if="!listMode">
              <button type="button" class="btn btn-info btn-sm" @click="changeListMode">View Job List <i class="fa fa-eye"></i></button>
            </li>
            <li v-if="listMode">
              <button @click="print('printArea','Master Job List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            </li>
            <li v-if="listMode">
              <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            </li>
          </ul>
        </div>

        <div class="">
          <div class="x_content ">
            <div class="row" v-if="listMode && jobList">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <TableData
                        :jobList="jobList"
                        :user_type="user_type"
                        :readJobRemark="readJobRemark"
                        :createRA="createRA"
                        :createRaAleart="createRaAleart"
                        :createP2W="createP2W"
                        :createP2WAleart="createP2WAleart"
                        :createP2WChiefMode="createP2WChiefMode"
                        :jobDoneFunction="jobDoneFunction"
                        :viewDeferSchedule="viewDeferSchedule"
                      />
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <div class="font-weight-bold">Maintenance History List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :jobList="jobList" :isPrinting="true"/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <RiskAssessment v-if="IRR" :jobInfo="selectedJob" :Schedule="scheduleId" :mode="permitWorkMode" @enableListMode="changeListMode"/>
            <PermitToWork v-if="P2W" :jobInfo="selectedJob" :chiefMode='chiefPermisionMode' :mode="permitWorkMode" :Schedule="scheduleId" @enableListMode="changeListMode"/>
            <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :Schedule="scheduleId" @enableListMode="changeListMode"/>
            <DeferReschedule v-if="DeferReschedule" :jobInfo="selectedJob" :Schedule="scheduleId" @enableListMode="changeListMode"/>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel"> Job Instruction</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <a href="javascript:;" @click="print('jobRemarks')" style="background: #08c;color: white;" class="btn  btn-sm "><i class="fa fa-print"></i> Print</a>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div id="jobRemarks">
              <span v-html="jobRemarks"></span>
            </div>

            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import Tree from 'vuejs-tree'
    import {VueEditor} from 'vue2-editor'
    import RiskAssessment from '../../Maintenance/RiskAssessment.vue'
    import PermitToWork from '../../Maintenance/PermitToWork.vue'
    import JobDone from '../../Maintenance/JobDone.vue'
    import DeferReschedule from '../../Maintenance/DeferReschedule.vue'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";

    export default {
        name: 'JobScheduleList',
        components: {
            TableData,
            VueEditor,
            RiskAssessment,
            PermitToWork,
            JobDone,
            DeferReschedule,
            Tree,
            DatePicker,
            PulseLoader
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                loading: false,
                reportList: false,
                color: '#1ABB9C',
                size: '10px',
                jobId: this.$route.params.jobId,
                selectedComponent: '',
                selectedJob: Object,
                treeDisplayData: [],
                scheduleId: '',
                jobType: '',
                query: '',
                content: '',
                queryFiled: 'name',
                components: [],
                jobList: [],
                jobRemarks: '',
                listMode: true,
                IRR: false,
                P2W: false,
                JobDoneStatus: false,
                DeferReschedule: false,
                editMode: false,
                permitWorkMode: false,
                tableEnableMode: false,
                chiefPermisionMode: false,
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    status: 'Activate'
                }),
                filter: new Form({
                    dateRange: '',
                    jobType: '',
                    jobId: '',
                    vessel_id: auth.getVesselId(),
                }),
                scheduleInfo: new Form({
                    vesselId: auth.getVesselId(),
                    jobId: this.$route.params.jobId,
                    filterDate: '',
                })
            }
        },
        mounted() {
            this.getAllJobList();
        },
        methods: {

            getAllJobList() {
                this.jobList = [];
                this.scheduleInfo.post('/api/filter/job-maintenance-history-list').then(response => {
                    this.jobList = response.data;
                }).catch(e => {
                    console.log(e);
                });
            },
            getJobAllScheduleList() {
                this.jobList = []
                this.$http.get('/api/filter/job/' + this.$route.params.jobId + '/' + this.vessel_id).then(response => {
                    this.jobList = response.data.success;
                }).catch(e => {
                    console.log(e)
                });
            },
            searchSchedule() {
                this.filter.busy = true
                this.filter.post('/api/filter/search-jobschedule').then(response => {
                    if (this.filter.successful) {
                        this.jobList = [];
                        this.jobList = response.data.success;
                    } else {
                        this.jobList = [];
                    }
                }).catch(e => {
                    var error = this.filter.errors.errors.error;
                    console.log(error.name);
                    this.$snotify.warning('Sorry Item Is Not Found')
                })
            },
            createRA(job, key) {
                this.listMode = false
                this.IRR = true
                this.selectedJob = job
                let RiskAssessmentLenght = job.job_schedules[key]?.risk_assessment.length
                if (RiskAssessmentLenght > 0) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.scheduleId = key
            },
            viewDeferSchedule(job, key) {
                this.listMode = false;
                this.DeferReschedule = true;
                this.selectedJob = job?.defer_rescheduleds;
                this.scheduleId = key;
            },
            createRaAleart() {
                this.$snotify.warning('Risk Assesment Is not Submited')
            },

            createP2WAleart() {
                this.$snotify.warning('Work Permission Not Sended')
            },
            createCL() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                $('#roleModalCL').modal('show')
            },
            createP2W(job, key) {
                if (job.job_schedules[key]?.permit_to_work != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.listMode = false
                this.IRR = false
                this.P2W = true
                this.selectedJob = job
                this.scheduleId = key
            },
            createP2WChiefMode(job, key) {
                if (job.job_schedules[key]?.permit_to_work != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.chiefPermisionMode = true
                this.listMode = false
                this.IRR = false
                this.P2W = true
                this.selectedJob = job
                this.scheduleId = key
            },
            readJobRemark(job) {
                this.jobRemarks = job.job_remarks
                $('#showModal').modal('show')
            },
            jobDoneFunction(job, key) {
                // if(job.job_schedules[i].permit_to_work==null && job.job_schedules[i].risk_assessment!=null){
                if (job.job_dones != null && job.job_dones?.job_done_report != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                if (this.user_type <= 3 && job.job_dones?.job_done_report == null) {
                    this.$snotify.warning('Job Done Report is not Submitted')
                } else {
                    this.listMode = false
                    this.IRR = false
                    this.P2W = false
                    this.JobDoneStatus = true
                    this.selectedJob = job
                    this.scheduleId = key
                }

            },
            addNodeFunction: function (info) {
                this.jobList = []
                switch (info.level) {
                    case 1:
                        this.filter.jobType = 'department_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 2:
                        this.filter.jobType = 'main_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 3:
                        this.filter.jobType = 'component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 4:
                        this.filter.jobType = 'sub_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 5:
                        this.filter.jobType = 'sub_sub_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                }
            },

            //this method create for assign ranked its can be access only chief engineer means role ==3
            assignRanked(event, jobId) {
                var rankedId = event.target.value
                this.$http.get('/api/job/' + jobId + '/' + rankedId).then(response => {
                    this.getAllJobList()
                    this.$snotify.success('Ranked Assigned Successfully');
                }).catch(e => {
                    this.$snotify.error('Ranked Can Not be Assign')
                })
            },

            //this function use for change list mode
            changeListMode() {
                this.IRR = false
                this.P2W = false
                this.JobDoneStatus = false
                this.DeferReschedule = false
                this.listMode = true
                this.getAllJobList()
            },
            print(id, title = '') {
                printUtil(id, title);
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

</style>
