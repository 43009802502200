<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead class="text-center"  >
    <tr>
      <th>Month Name</th>
      <th>Total Critical Job</th>
      <th>Critical Overdue Job (%)</th>
      <th>Total Non Critical Job</th>
      <th>Non Critical Overdue Job(%)</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(job,index) in jobList?.data" :key="index">
      <td>{{job?.month_name}}</td>
      <td>{{job?.total_critical_job}}</td>
      <td>{{job?.critical_overdue}}
        ({{job?.total_critical_overdue_percentage}}%)
      </td>
      <td>{{job?.total_non_critical_job}}</td>
      <td>{{job?.non_critical_overdue}} ({{job?.total_non_critical_overdue_percentage}}%)</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import auth from "@/auth";
import permissionsMixin from "@/mixin/permissionsMixin";

export default {

  props: {
    jobList : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
