<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <h2 class="float-left">Auto Requisition List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li v-if="requisitionData.length > 0">
                <button type="button" class="btn btn-info btn-sm" @click="processReq()"><i class="fa fa-forward"></i> Add To Requisition</button>
              </li>
              <li>
                <button @click="print('printArea','Auto Requisition List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-box table-responsive">
                  <p class="text-muted font-13 m-b-30"></p>
                  <TableData :requisitionData="requisitionData" :destroy="destroy"/>
                  <div id="printArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <h4>Vessel Name: {{this.vessel_name}}</h4>
                      <div class="font-weight-bold">Auto Requisition List</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                    </div>
                    <TableData :requisitionData="requisitionData" :isPrinting="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import Select2 from 'v-select2-component'
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'AutoRequisition',
        components: {
            TableData,
            Select2,
            datetime,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                isDisabled: false,
                locationList: null,
                units: null,
                myOptions: [],
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                CategoryData: [],
                category_id: '',
                country_id: '',
                port_id: '',
                RequisitionTitle: '',
                RequisitionPriority: '',
                RequisitionRemarks: '',
                SupplyAdvised: '',
                itemId: '',
                itemName: '',
                itemStatus: '',
                itemQuantity: '',
                itemTotalPrice: '',
                CategoriesItemData: [],
                CountryData: [],
                portData: [],
                supplierData: [],
                requisitionData: [],
                requisitionItem: [],
                requisitionItems: [],
                requestRequisitionId: '',
                selected: [],
                selectAll: false
            }
        },
        mounted() {
            this.getRequisitionData()
        },
        methods: {
            getRequisitionData() {
                this.loading = true
                this.$http.get('/api/filter/requisition-queue/' + this.vesselId).then(response => {
                    this.loading = false;
                    this.requisitionData = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false
                })
            },
            processReq() {
                var ids = ''
                $('.item:checked').each(function (index) {
                    var parent = $(this).data('parent')
                    var catParent = localStorage.getItem('catParent')
                    if (!catParent) {
                        localStorage.setItem('catParent', parent)
                    } else {
                        var catParent = parent
                    }
                    if (catParent == parent) {
                        ids += $(this).val() + '~' + $(this).data('name') + ','
                    }
                })

                ids = ids.slice(0, -1)
                if (ids == '') {
                    this.$snotify.warning('Please select at least one item')
                    return
                }
                window.location.href = 'auto-requisition/process/' + ids
            },
            destroy(item) {
                this.$dialog.confirm('Are you sure to delete?')
                    .then(() => {
                        this.$http.delete('/api/requisition-queue/' + item).then(response => {
                            this.getRequisitionData()
                            this.$snotify.success('Requisition deleted successfully')
                        }).catch(error => {
                            var err = error.response.data.error
                            this.$snotify.error(err)
                        })
                    })
                    .catch(function () {
                    })
            },
            print(id, title = '') {
                printUtil(id, title)
            },
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>

</style>
