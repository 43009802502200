<template>
  <div>
    <div v-if="type=='pending' || type=='due'" class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center mt-4">{{ type == 'pending' ? 'Running' : 'Due' }} Job List </h4>
          <span></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead class="text-center">
              <tr>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Job Code</th>
                <th>Counter Type</th>
                <th>Assigned Ranked</th>
                <th v-if="type=='pending' ">Go TO</th>
              </tr>
              </thead>
              <tbody v-for="(job) in jobList" :key="job.id">
              <tr v-for="(job_schedule) in job.job_schedules" :key="job_schedule.id">
                <td>{{ job.name }}</td>
                <td>{{ job_schedule.start_date }}</td>
                <td>{{ job_schedule.end_date }}</td>
                <td>{{ job.code }}</td>
                <td>{{ job.counter_type }}</td>
                <td>{{ job.rank_fixed == 4 ? 'Second Engineer' : 'Chief Engineer' }}</td>
                <td v-if="type=='pending' ">
                  <router-link :to="{ name: 'maintaince' , params: { jobId:job.id} }"><i class=" btn btn-sm btn-info fa fa-arrow-right"></i>
                  </router-link>
                </td>
              </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type=='done'" class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center mt-4">Done Job List </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead class="text-center">
              <tr>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Job Code</th>
                <th>Counter Type</th>
                <th>Assigned Ranked</th>
                <th v-if="type=='done' ">Go TO</th>
              </tr>
              </thead>
              <tbody v-for="(job) in jobListDone" :key="job.id">
              <tr v-for="(job_schedule) in job.job_schedules" :key="job_schedule.id">
                <td>{{ job.name }}</td>
                <td>{{ job_schedule.start_date }}</td>
                <td>{{ job_schedule.end_date }}</td>
                <td>{{ job.code }}</td>
                <td>{{ job.counter_type }}</td>
                <td>{{ job.rank_fixed == 4 ? 'Second Engineer' : 'Chief Engineer' }}</td>
                <td v-if="type=='done' ">
                  <router-link :to="{ name: 'maintaince' , params: { jobId:job.id} }"><i class=" btn btn-sm btn-info fa fa-arrow-right"></i>
                  </router-link>
                </td>
              </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type=='defer'" class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center mt-4">Defer Rescheduled List </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead class="text-center">
              <tr>
                <th># ID</th>
                <th> Defer Rescheduled Date</th>
                <th>Reason Deffer</th>
                <th>Image</th>
                <th>Start Date</th>
                <th>Prev End Date</th>
                <th>Current End Date</th>
                <th>Done By</th>
                <th>Verified By</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for=" (scheduleInfo,index) in deferJob" :key="scheduleInfo.id">
                <td>{{ index + 1 }}</td>
                <td>{{ scheduleInfo.created_at }}</td>
                <td>{{ scheduleInfo.reason_deffer }}</td>
                <td><p><img :src="scheduleInfo.image" alt=""></p></td>
                <td>{{ scheduleInfo.prev_start_date }}</td>
                <td>{{ scheduleInfo.prev_end_date }}</td>
                <td>{{ scheduleInfo.current_end_date }}</td>
                <td>
                  {{ scheduleInfo.done_by == 3 ? 'Chief Engineer' : '' }}
                  {{ scheduleInfo.done_by == 4 ? 'Second Engineer' : '' }}
                  {{ scheduleInfo.done_by == 5 ? 'Third Engineer' : '' }}
                </td>
                <td>
                  {{ scheduleInfo.verified_by == 3 ? 'Chief Engineer' : '' }}
                  {{ scheduleInfo.verified_by == 2 ? 'Admin' : '' }}
                  {{ scheduleInfo.verified_by == 1 ? 'Super Admin' : '' }}
                </td>
                <td>
                                        <span v-if="user_type<4 && scheduleInfo.status =='Pending'">
                                            <button @click="AapprovedBychiefEngineer(scheduleInfo)" class="btn btn-sm btn-warning">Accept</button>
                                        </span>
                  <span v-else>{{ scheduleInfo.status }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: "DashboardJob",
        data() {
            return {
                i: 0,
                jobList: [],
                deferJob: [],
                jobListDone: [],
                type: this.$route.params.type,
                //vesselId: this.$store.state.vessal_id,
                //user_type: this.$store.state.user_type,
            };
        },
        mounted() {
            var type = this.$route.params.type;
            switch (type) {
                case 'pending':
                    this.getAllPeandingJob();
                    break;
                case 'due':
                    this.getAllOverDueJob();
                    break;
                case 'defer':
                    this.getAllDeferJob();
                case 'done':
                    this.getAllDoneJob();
                    break;
            }
        },
        methods: {
            getAllPeandingJob() {
                this.$http.get("/api/get-pending-job/" + this.vesselId + '/List').then(response => {
                    this.jobList = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllOverDueJob() {
                this.$http.get("/api/get-due-job/" + this.vesselId + '/List').then(response => {
                    this.jobList = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllDeferJob() {
                this.$http.get("/api/get-defer-job/" + this.vesselId + '/List').then(response => {

                    this.deferJob = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllDoneJob() {
                this.$http.get("/api/get-done-job/" + this.vesselId + '/List').then(response => {
                    this.jobListDone = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            AapprovedBychiefEngineer(scheduleInfo) {
                // this.form1.busy = true;
                this.$http.get("/api/job-done-permission/" + scheduleInfo.schedule_id + '/' + scheduleInfo.id).then(response => {
                    this.getAllDeferJob();
                    this.$snotify.success("your permission Successfully Completed.");
                }).catch(e => {
                    // var error=this.form.errors.errors.error;
                    this.$snotify.error("your permission Successfully is not  Completed.");
                });
            },
        }
    }
</script>

<style scoped>
  .test1 {
    padding: 0.40rem !important;
  }
</style>
