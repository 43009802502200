<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
        <th>Status</th>
        <th>Return No</th>
        <th>Purchase no</th>
        <th>Title</th>
        <th>Priority</th>
        <th>Remarks</th>
        <th>Date</th>
        <th>Created By</th>
        <th>Super. Approval</th>
<!--        <th>PM Approval</th>-->
        <th>TM Approval</th>
      <th   v-if="!isPrinting && ( isPermissionExist(PERMISSIONS.PURCHASE_RETURN_VIEW) || isPermissionExist(PERMISSIONS.PURCHASE_RETURN_DELETE) || isPermissionExist(PERMISSIONS.PURCHASE_RETURN_ADJUST))">
        Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(requisition , index) in requisitionData" :key="index">
      <td>{{requisition.status}}</td>
      <td>{{requisition?.return_no}}</td>
      <td>{{requisition?.purchase?.po_no}}</td>
      <td>{{requisition?.title}}</td>
      <td>{{requisition?.priority}}</td>
      <td>{{requisition?.remarks}}</td>
      <td>{{requisition?.date}}</td>
      <td>{{requisition?.user?.username}}</td>
      <td>
                      <span v-if="requisition?.superintendent_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.SUPERINTENDING_APPROVAL)">
                        <button type="button" title="Approve" class="btn btn-warning btn-sm"
                                @click="approveRequest('PurchaseReturn', requisition.id, 'Approved', 'superintendent_approval')"> {{requisition?.superintendent_approval}}</button>
                         </span>
        <span v-else> {{requisition?.superintendent_approval}} </span>
      </td>
<!--      <td>-->
<!--                      <span v-if="requisition.purchase_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.PM_APPROVAL)">-->
<!--                           <button type="button" title="Approve" class="btn btn-warning btn-sm"-->
<!--                                   @click="approveRequest('PurchaseReturn', requisition.id, 'Approved', 'purchase_manager_approval')"> {{requisition.purchase_manager_approval}}</button>-->
<!--                          </span>-->
<!--        <span v-else> {{requisition.purchase_manager_approval}}  </span>-->
<!--      </td>-->
      <td>
                        <span v-if="requisition?.technical_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.TM_APPROVAL)">
                            <button type="button" title="Approve" class="btn btn-warning btn-sm"
                                    @click="approveRequest('PurchaseReturn', requisition.id, 'Approved', 'technical_manager_approval')"> {{requisition?.technical_manager_approval}}</button>
                       </span>
        <span v-else> {{requisition?.technical_manager_approval}} </span>
      </td>
      <td v-if="!isPrinting && ( isPermissionExist(PERMISSIONS.PURCHASE_RETURN_VIEW) || isPermissionExist(PERMISSIONS.PURCHASE_RETURN_DELETE) || isPermissionExist(PERMISSIONS.PURCHASE_RETURN_ADJUST))">
        <button type="button" @click="show(requisition.id) && isPermissionExist(PERMISSIONS.PURCHASE_RETURN_VIEW)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
        <span
          v-if="requisition?.superintendent_approval != 'Approved'   && requisition?.technical_manager_approval != 'Approved' && isPermissionExist(PERMISSIONS.PURCHASE_RETURN_DELETE)">
<!--                        <button type="button" @click="edit(requisition.id)" title="Edit" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>-->
                             <button type="button" @click="destroy(requisition.id)" title="Delete" class="btn btn-danger btn-sm"><i
                               class="fa fa-trash"></i></button>
                          </span>
        <span
          v-if="requisition?.superintendent_approval == 'Approved'   && requisition?.technical_manager_approval == 'Approved' && requisition?.is_adjusted != 'Yes' && isPermissionExist(PERMISSIONS.PURCHASE_RETURN_ADJUST)"><button
          class="btn btn-warning btn-sm" title="Adjust Stock" @click="adjustReturn(requisition.id)"><i class="fa fa-money"></i></button></span>
      </td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    requisitionData : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    approveRequest: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    adjustReturn: {
      type: Function,
      required: false
    },
    destroy: {
      type: Function,
      required: false
    },


  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
