<template>
  <div>
    <div class="row">
      <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
        <PulseLoader :loading="loading"></PulseLoader>
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="x_panel">
            <div class>
              <div class="row">
                <div class="col-md-2">
                  <div>
                    <div class="form-group">
                      <select v-model="budget_year" name="budget_year" class="form-control">
                        <option v-for="year in years" :key="year" selected>{{year}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <div class="form-group">
                      <label></label>
                      <button class="btn btn-info" @click="budgetYearInfo()">Search</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-7"></div>
                <div class="col-md-2">
                  <ul class="nav navbar-right panel_toolbox">
                    <li>
                      <router-link to="/budget">
                        <button class="btn btn-info btn-sm">
                          View Budget
                          <i class="fa fa-eye"></i>
                        </button>
                      </router-link>
                    </li>
                    <li>
                      <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="card">
              <div class="card-header text-center">
                <h5 class="text-center">{{vessel_name}}</h5>
                <p>Budget of {{selectedYear}}</p>
              </div>
              <div class="card-body">
                <div class="table-responsive tableStyle">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Budget Name</th>
                        <th scope="col">Amount US($)</th>
                        <th scope="col">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(budgetInfo,index) in  budgetInfoList"
                        :key="budgetInfo.id"
                        v-bind:class="{'bg-secondary text-white font-weight-bold': budgetInfo.parent_id==0}"
                      >
                        <td class="text-left">{{budgetInfo.code}}</td>
                        <td>{{budgetInfo.name}}</td>

                        <td class="text-right">
                          <div class="row">
                            <div class="col-md-8">
                              <input
                                type="hidden"
                                class="form-control"
                                :id="index+'headId'"
                                :value="budgetInfo.id"
                              />
                            </div>
                            <div class="col-md-4">
                              <input
                                type="number"
                                class="form-control"
                                :id="index+'headAmount'"
                                :value="objectAmount(budgetInfo.budget)"
                              />
                            </div>
                          </div>
                        </td>
                        <td>{{budgetInfo.remarks}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <button
                  class="btn btn-sm btn-info pull-right"
                  :disabled="form.busy"
                  @click="yearBudgetInfoSave"
                >Save Budget</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import auth from "@/auth";
export default {
  name: "BudgetCreate",
  components: {
    PulseLoader,
  },

  data() {
    return {
      loading: false,
      color: "#1ABB9C",
      size: "20px",
      isActive: "bg-secondary",
      vessel_name: "",
      budgetHeads: [],
      selectedYear: "",
      budget_year: new Date().getFullYear(),
      vessel_id: auth.getVesselId(),
      budgetInfoList: [],
      budgetSaveInfo: [],
      years: [],
      form: new Form({
        budgetInfo: "",
      }),
    };
  },

  mounted() {
    this.yearsGenerate();
    this.veselInfo();
    this.budgetYearInfo();
  },

  methods: {
    yearsGenerate() {
      let i = new Date().getFullYear();
      for (i; i >= 2000; i--) {
        this.years.push(i);
      }
    },

    async budgetYearInfo() {
      this.loading = true;
      this.selectedYear = this.budget_year;
      var budgetInfo = await this.$http.get(
        "/api/budget-year/" + this.budget_year + "/" + this.vessel_id
      );
      this.budgetInfoList = [];
      this.budgetInfoList = budgetInfo.data.success;
      this.loading = false;
    },

    veselInfo() {
      this.$http
        .get("/api/vessel/" + auth.getVesselId())
        .then((response) => {
          this.vessel_name = response.data.success.name;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    objectAmount(obj) {
      if (obj[0] == null) {
        return "0.0";
      } else {
        return obj[0].amount;
      }
    },

    async yearBudgetInfoSave() {
      let self = this;
      var saveSelectedYear = this.selectedYear;
      self.budgetSaveInfo = [];
      await self.budgetInfoList.filter((item, index) => {
        var index = index.toString();
        let headid = $("#" + index + "headId").val();
        let budgetAmount = $("#" + index + "headAmount").val();
        self.budgetSaveInfo.push({
          budget_head_id: headid,
          amount: budgetAmount,
          budget_year: saveSelectedYear,
        });
      });
      this.form.budgetInfo = this.budgetSaveInfo;
      this.form.vessel_id = this.vessel_id;
      // var saveAllinfo =this.budgetSaveInfo;
      this.form.busy = true;
      this.form
        .post("/api/budget")
        .then((response) => {
          if (this.form.successful) {
            this.$snotify.success("Budget  Successfully Created");
          } else {
            this.$snotify.error(
              "Something went wrong try again later.",
              "Error"
            );
          }
        })
        .catch((e) => {
          let response = e.response;
          if (response.status === 403) {
            this.$snotify.warning(response?.data?.error)
          }else{
            this.$snotify.error(response?.data?.error)
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>
/* .list-group{
    display: block !important;
} */
.bg-secondary {
  background-color: #a0a2a5 !important;
}
.tableStyle {
  max-height: 450px;
  overflow: auto;
}
</style>
