<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Vendor Evaluation</h2>
            <div>
              <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              <button @click="print('printArea','Vendor Evaluation List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <TableData :data="data"/>
                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <div class="font-weight-bold">Vendor Evaluation</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      </div>
                      <TableData :data="data" :isPrinting="true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Create purchase order Modal -->
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'VendorEvaluation',
        components: {TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                editMode: false,
                supplierData: [],
                data: [],
                form: null,
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.loading = true;
                this.$http.get('/api/filter/supplier-evaluation/' + this.vessel_id).then(response => {
                    this.loading = false;
                    this.data = response.data.success;
                    setTimeout(() => $('#datatables').DataTable({
                        'ordering': false,
                        'aaSorting': [],
                        'bDestroy': true
                    }), 1000)
                }).catch(e => {
                    this.loading = false;

                })
            },
            removeItem(ref_id, SupplierQuotationId) {
                var txt
                var r = confirm('Are you sure you want to remove this item!')
                if (r == true) {
                    let data = {
                        ref_id: ref_id,
                        type: 'SupplierQuotationItem'
                    }
                    this.$http.post('/api/remove-item', data).then(response => {
                        this.create(SupplierQuotationId)
                    }).catch(e => {
                        console.log(e)
                    })
                }

            },
            print(id, title = '') {
                printUtil(id, title);
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
