<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <a v-if="mode" href="javascript:;" @click="print('printableArea')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print">Print</i></a>
                </div>
            </div>
            <div id="printableArea" class="row">
                <div  class="col-md-12">
                    <h4 class="text-center mt-4">Risk Assessment</h4>
                    <div class="modal-body text-center">
                        <div class="col-md-4"><p>Name of Vessel:  {{vessel_name}}</p></div>
                        <div class="col-md-4"><p>Activity:{{jobInfo.name}} </p></div>
                        <div class="col-md-4">
                            <p v-if="!mode" >Sr No: {{inputHead.risk_assessment_head.sr_no}}</p>
                            <p v-if="mode">Sr No: {{riskAssessmentHead.sr_no}}</p>

                        </div>
                    </div>
                    <div class="modal-body text-center">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-md-2">
                                    <p>DATE:</p>
                                </div>
                                <div class="col-md-3">
                                      <DatePicker v-if="!mode"    type="date"  v-model="inputHead.risk_assessment_head.date" valueType="format" ></DatePicker>
                                        <p>{{riskAssessmentHead.date}}</p>

                                </div>
                                 <div class="col-md-4"></div>
                            </div>
                        </div>
                        <div class="col-md-4"><p>LOCATION: {{jobInfo.component.maincomponent.name}},{{jobInfo.component.maincomponent.department.name}}</p></div>
                        <div class="col-md-4"></div>
                    </div>
                </div>

                <div  class="col-md-12 ">
                    <div class="modal-body">
                        <div class="table-responsive" v-if="!mode">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="text-center">
                                        <th>Haz. ID</th>
                                        <th>Hazard</th>
                                        <th>Consequences</th>
                                        <th colspan="4">Initial Risk</th>
                                        <th></th>
                                        <th colspan="4">Residual Risk</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Description of Identified Hazards associated to activity </th>
                                        <th>Possible result of hazard</th>
                                        <th>Cat.</th>
                                        <th >Sx.</th>
                                        <th>Fx.</th>
                                        <th>Risk Ranking</th>
                                        <th>Existing Control / Safeguards</th>
                                        <th>Cat.</th>
                                        <th>Sx.</th>
                                        <th>Fx.</th>
                                        <th>Risk Ranking</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  v-for="(input,k) in inputs" :key="k">
                                        <td>{{k+1}}</td>
                                        <td >
                                            <div class="col-10">
                                                <input type="text" v-model="inputs[k].hazard_description" style="max-width: 110px">
                                            </div>
                                        </td>
                                        <td >
                                            <div class="col-10">
                                                <input type="text" v-model="inputs[k].hazard_possible_result"  class="" style="max-width: 110px">
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <table>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox" v-model="inputs[k].initial_cat" :name="'inlineRadioOptions'+k" :id="'inlineRadio'+k+k" value="People">
                                                            <label class="form-check-label" :for="'inlineRadio'+k+k">People</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox" v-model="inputs[k].initial_cat" :name="'inlineRadioOptions'+k" :id="'inlineRadio'+k+k+1" value="Property">
                                                            <label class="form-check-label" :for="'inlineRadio'+k+k+1" >Property</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox" v-model="inputs[k].initial_cat"  :name="'inlineRadioOptions'+k" :id="'inlineRadio'+k+k+2" value="Environment">
                                                            <label class="form-check-label" :for="'inlineRadio'+k+k+2">Environment</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox" v-model="inputs[k].initial_cat" :name="'inlineRadioOptions'+k" :id="'inlineRadio'+k+k+3" value="Business">
                                                            <label class="form-check-label" :for="'inlineRadio'+k+k+3">Business</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" :type="inputs[k].initial_cat.includes('People')?'number':'hidden'" v-model="inputs[k].initial_sx[0]"   value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" :type="inputs[k].initial_cat.includes('Property')?'number':'hidden'"  v-model="inputs[k].initial_sx[1]"  value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" :type="inputs[k].initial_cat.includes('Environment')?'number':'hidden'" v-model="inputs[k].initial_sx[2]"   value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-4">
                                                                <input class="" :type="inputs[k].initial_cat.includes('Business')?'number':'hidden'" v-model="inputs[k].initial_sx[3]"   value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="test1">
                                                                <input class="" :type="inputs[k].initial_cat.includes('People')?'number':'hidden'" v-model="inputs[k].initial_fx[0]"   value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div>
                                                                <input class="" :type="inputs[k].initial_cat.includes('Property')?'number':'hidden'" v-model="inputs[k].initial_fx[1]"  value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div>
                                                                <input class="" :type="inputs[k].initial_cat.includes('Environment')?'number':'hidden'" v-model="inputs[k].initial_fx[2]"  value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div>
                                                                <input class="" :type="inputs[k].initial_cat.includes('Business')?'number':'hidden'" v-model="inputs[k].initial_fx[3]"  value="" style="max-width: 70px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-6">
                                                                <select  v-model="inputs[k].initial_risk_rank[0]" v-if="inputs[k].initial_cat.includes('People')"  style="max-width: 75px" >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].initial_risk_rank[1]"  v-if="inputs[k].initial_cat.includes('Property')" >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].initial_risk_rank[2]" v-if="inputs[k].initial_cat.includes('Environment')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].initial_risk_rank[3]" v-if="inputs[k].initial_cat.includes('Business')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="col-10">
                                                <input type="text" v-model="inputs[k].existing_control" class="" style="max-width: 110px">
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <table>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input v-model="inputs[k].residual_cat" class="form-check-input" type="checkbox" :name="'inlineradioResidual'+k" :id="'inlineradioResidual'+k+k"   value="People">
                                                            <label class="form-check-label" :for="'inlineradioResidual'+k+k" >People</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input v-model="inputs[k].residual_cat" class="form-check-input" type="checkbox" :name="'inlineradioResidual'+k" :id="'inlineradioResidual'+k+k+1" value="Property">
                                                            <label class="form-check-label" :for="'inlineradioResidual'+k+k+1" >Property</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input v-model="inputs[k].residual_cat" class="form-check-input" type="checkbox" :name="'inlineradioResidual'+k" :id="'inlineradioResidual'+k+k+2" value="Environment">
                                                            <label class="form-check-label" :for="'inlineradioResidual'+k+k+2" >Environment</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="initaial_risk">
                                                        <div class="form-check form-check-inline">
                                                            <input v-model="inputs[k].residual_cat" class="form-check-input" type="checkbox" :name="'inlineradioResidual'+k" :id="'inlineradioResidual'+k+k+3" value="Business">
                                                            <label class="form-check-label" :for="'inlineradioResidual'+k+k+3">Business</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" v-model="inputs[k].residual_sx[0]" :type="inputs[k].residual_cat.includes('People')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" v-model="inputs[k].residual_sx[1]" :type="inputs[k].residual_cat.includes('Property')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" v-model="inputs[k].residual_sx[2]" :type="inputs[k].residual_cat.includes('Environment')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" v-model="inputs[k].residual_sx[3]" :type="inputs[k].residual_cat.includes('Business')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input class="" v-model="inputs[k].residual_fx[0]" :type="inputs[k].residual_cat.includes('People')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input v-model="inputs[k].residual_fx[1]" class="" :type="inputs[k].residual_cat.includes('Property')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input v-model="inputs[k].residual_fx[2]" class="" :type="inputs[k].residual_cat.includes('Environment')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <input v-model="inputs[k].residual_fx[3]" class="" :type="inputs[k].residual_cat.includes('Business')?'number':'hidden'" value="" style="max-width: 75px">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td style="padding: 0">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].residual_risk_rank[0]" v-if="inputs[k].residual_cat.includes('People')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].residual_risk_rank[1]" v-if="inputs[k].residual_cat.includes('Property')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].residual_risk_rank[2]" v-if="inputs[k].residual_cat.includes('Environment')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 10px; height:46px">
                                                            <div class="col-10">
                                                                <select v-model="inputs[k].residual_risk_rank[3]" v-if="inputs[k].residual_cat.includes('Business')"  >
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger btn-sm" @click="remove(k)" v-show="k || ( !k && inputs.length > 1)"><i class="fa fa-minus-square"></i></button>
                                            <button type="button" class="btn btn-success btn-sm ml-1" @click="add(k)" v-show="k == inputs.length-1"><i class="fa fa-plus-square"></i></button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <button class="btn btn-sm btn-info pull-right" @click="store">Save Risk Assessment</button>

                        </div>

                        <div class="table-responsive" v-else>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Haz. ID</th>
                                        <th>Hazard</th>
                                        <th>Consequences</th>
                                        <th colspan="4">Initial Risk</th>
                                        <th></th>
                                        <th colspan="4">Residual Risk</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Description of Identified Hazards associated to activity </th>
                                        <th>Possible result of hazard</th>
                                        <th>Cat.</th>
                                        <th >Sx.</th>
                                        <th>Fx.</th>
                                        <th>Risk Ranking</th>
                                        <th>Existing Control / Safeguards</th>
                                        <th>Cat.</th>
                                        <th>Sx.</th>
                                        <th>Fx.</th>
                                        <th>Risk Ranking</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr v-for=" (riskAssessment,k) in riskAssessmentinfo" :key="riskAssessment.id" >
                                    <td>{{k+1}}</td>
                                    <td>
                                        <div class="col-10">
                                            <p>{{riskAssessment.hazard_description}} </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-10">
                                            <p>{{riskAssessment.hazard_possible_result}}</p>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table" style="margin-bottom: 0rem">

                                                <tr style="height:35px" v-for="initial_cat in catList" :key="initial_cat">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkinitialType(initial_cat,riskAssessment.initial_cat)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="initial_sx in riskAssessment.initial_sx">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(initial_sx)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="initial_fx in riskAssessment.initial_fx">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(initial_fx)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="initial_risk_rank in riskAssessment.initial_risk_rank">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(initial_risk_rank)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-10">
                                            <p>{{riskAssessment.existing_control}}</p>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="residual_cat in catList">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkinitialType(residual_cat,riskAssessment.residual_cat)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="residual_sx in riskAssessment.residual_sx">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(residual_sx)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px" v-for="residual_fx in riskAssessment.residual_fx">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(residual_fx)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                    <td style="padding: 0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tr style="height:35px"  v-for="residual_risk_rank in riskAssessment.residual_risk_rank">
                                                    <td style="padding: 0px;">
                                                        <div class="col-10">
                                                            <p>{{checkType(residual_risk_rank)}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import Validator from 'validatorjs'
  import auth from '../../auth'

  export default {
    name: 'RiskAssessment',
    components: { DatePicker },
    props: {
      jobInfo: Object,
      Schedule: '',
      mode: Boolean,

    },
    data () {
      return {
        id: this.jobInfo.id,
        vessel_id: auth.getVesselId(),
        vessel_name: '',
        inputs: [
          {
            hazard_description: '',
            hazard_possible_result: '',
            initial_cat: [],
            initial_sx: [{}, {}, {}, {}],
            initial_fx: [{}, {}, {}, {}],
            initial_risk_rank: [{}, {}, {}, {}],
            existing_control: '',
            residual_cat: [],
            residual_sx: [{}, {}, {}, {}],
            residual_fx: [{}, {}, {}, {}],
            residual_risk_rank: [{}, {}, {}, {}],
            scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
          }
        ],
        inputHead: {
          id: this.jobInfo.job_schedules[this.Schedule].id,
          risk_assessment_head: {
            date: '',
            sr_no: Date.now()
          },
          status: 'In_Progress'
        },
        riskAssessmentinfo: [],
        riskAssessmentHead: [],
        catList: ['People', 'Property', 'Environment', 'Business']

      }

    },
    mounted () {
      this.veselInfo()
      if (this.mode) {
        this.riskInfoOrderign()
        //  v-for="(risk_assessment,k)in  jobInfo.job_schedules[Schedule].risk_assessment" :key="risk_assessment.id"
      }
    },
    methods: {

      add (index) {
        var riskAssessmentObject = {
          hazard_description: '',
          hazard_possible_result: '',
          initial_cat: [],
          initial_sx: [{}, {}, {}, {}],
          initial_fx: [{}, {}, {}, {}],
          initial_risk_rank: [{}, {}, {}, {}],
          existing_control: '',
          residual_cat: [],
          residual_sx: [{}, {}, {}, {}],
          residual_fx: [{}, {}, {}, {}],
          residual_risk_rank: [{}, {}, {}, {}],
          scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
        }
        this.inputs.push(riskAssessmentObject)
      },
      remove (index) {
        this.inputs.splice(index, 1)
      },

      //rist assessment save option
      store () {
        var inputInfo = this.inputs
        var inputHead = this.inputHead
        var postData = {
          inputInfo,
          inputHead
        }
        this.$http.post('/api/risk-assessment', postData)
          .then(response => {
            this.$snotify.success('your Risk Assessment Successfully Saved.')
            this.$emit('enableListMode')
          })
          .catch(e => {

          })
      },
      //set initial risk rank
      changeInitialRiskRank (e, index) {
        alert(e.target.value)
      },
      veselInfo () {
        this.$http.get('/api/vessel/' + this.vessel_id)
          .then(response => {
            this.vessel_name = response.data.success.name
          })
          .catch(e => {
            console.log(e)
          })
      },
      async riskInfoOrderign () {

        this.riskAssessmentinfo = this.jobInfo.job_schedules[this.Schedule].risk_assessment
        this.riskAssessmentHead = JSON.parse(this.jobInfo.job_schedules[this.Schedule].risk_assessment_head)
        this.riskAssessmentinfo.map(item => {
          var initial_cat = JSON.parse(item.initial_cat)
          item.initial_cat = initial_cat
          var initial_sx = JSON.parse(item.initial_sx)
          item.initial_sx = initial_sx
          var initial_fx = JSON.parse(item.initial_fx)
          item.initial_fx = initial_fx
          var initial_risk_rank = JSON.parse(item.initial_risk_rank)
          item.initial_risk_rank = initial_risk_rank
          var residual_cat = JSON.parse(item.residual_cat)
          item.residual_cat = residual_cat
          var residual_sx = JSON.parse(item.residual_sx)
          item.residual_sx = residual_sx
          var residual_fx = JSON.parse(item.residual_fx)
          item.residual_fx = residual_fx
          var residual_risk_rank = JSON.parse(item.residual_risk_rank)
          item.residual_risk_rank = residual_risk_rank
        })

      },

      checkInitialCat (event, k) {
        if (event.target.checked == true) {
          var object = {
            sx: '',
            fx: '',
            rank: ''
          }
          this.inputs[k].initial_cat_sx_fx_rank.push(object)

        } else {
          var value = event.target.value
          var index = initial_cat.indexOf(value)
          this.inputs[k].initial_cat_sx_fx_rank.splice(index, 1)
        }
      },
      checkType (value) {
        var type = typeof value
        if (type == 'object') {
          return ''
        } else {
          return value
        }
      },
      checkinitialType (value, initial_cat) {
        var index = initial_cat.indexOf(value)
        if (index == -1) {
          return ''
        } else {
          return value
        }
      },
      print (el) {
        this.$htmlToPaper(el)
      },
    }
  }
</script>

<style scoped>
  .initaial_risk {
    height: 46px;
  }
</style>
