<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Breakdown History Maintenance List  </h2>
          <ul class="nav navbar-right panel_toolbox">
            <li v-if="!listMode">
              <button type="button" class="btn btn-info btn-sm" @click="changeListMode">View Job List <i class="fa fa-eye"></i></button>
            </li>
            <li>
              <button v-if="listMode" @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
            </li>
            <li>
              <vue-excel-xlsx v-if="listMode" class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'breakdown'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
            </li>
            <li v-if="listMode">
              <button @click="print('printArea','Breakdown History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            </li>
            <li>
              <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            </li>
          </ul>
        </div>

        <div class="">
          <div class="x_content ">
            <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
              <PulseLoader :loading="loading"></PulseLoader>
            </div>
            <div class="row" v-if="listMode && jobList && !loading">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Vessel</label>
                          <select class="form-control" v-model="scheduleInfo.vesselId">
                            <option value="" selected>Select Vessel</option>
                            <option v-for="(row, key) in vessels" :value="row.id">{{row.name}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Search </label>
                          <input type="text" v-model="scheduleInfo.searchInput" name="searchInput" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-2 mt-2 ">
                        <div class="form-group">
                          <label for=""></label>
                          <button class="form-control btn-info btn btn-sm" @click="getAllJobList()">Search</button>
                        </div>
                      </div>
                    </div>
                    <div class="card-box table-responsive">
                      <TableData :jobList="jobList?.data" :jobDoneFunction="jobDoneFunction"/>
                      <Pagination :pagination="pagination" :offset="pagination.offset" @paginate="getAllJobList"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Breakdown History List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :jobList="jobPrintListData" :isPrinting="true"/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :componentName="componentName" :Schedule="scheduleData" @enableListMode="changeListMode"/>
  </div>
</template>

<script>

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import Tree from 'vuejs-tree'
    import {VueEditor} from 'vue2-editor'
    import RiskAssessment from "@/components/Maintenance/RiskAssessment.vue";
    import PermitToWork from "@/components/Maintenance/PermitToWork.vue";
    import DeferReschedule from "@/components/Maintenance/DeferReschedule.vue";
    import auth from '../../../auth'
    import Pagination from "@/components/Pagination.vue";
    import JobDone from '../JobDone.vue'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'breakdown-report',
        components: {
            Pagination,
            VueEditor,
            RiskAssessment,
            PermitToWork,
            JobDone,
            DeferReschedule,
            Tree,
            DatePicker,
            PulseLoader,
            TableData
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                loading: false,
                reportList: false,
                color: '#1ABB9C',
                size: '10px',
                jobId: 25,
                selectedComponent: '',
                selectedJob: Object,
                treeDisplayData: [],
                scheduleId: '',
                scheduleData: '',
                componentName: '',
                jobType: '',
                query: '',
                content: '',
                queryFiled: 'name',
                jobRemarks: '',
                listMode: true,
                IRR: false,
                P2W: false,
                JobDoneStatus: false,
                DeferReschedule: false,
                editMode: false,
                permitWorkMode: false,
                tableEnableMode: false,
                chiefPermisionMode: false,
                vessels: null,
                departments: [],
                mainComponents: [],
                components: [],
                subcomponents: [],
                subsubcomponents: [],
                jobPrintListData: [],
                jobList: [],
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    status: 'Activate'
                }),
                pagination: {
                    current_page: 1,
                    offset: 5
                },
                filter: new Form({
                    dateRange: '',
                    jobType: '',
                    jobId: '',
                    vessel_id: auth.getVesselId(),
                }),
                scheduleInfo: new Form({
                    vesselId: auth.getVesselId(),
                    searchInput: '',
                    filterDate: '',
                    print_enable: false
                }),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getAllJobList();
        },
        methods: {
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },
            prepareExportData() {
                return this.jobPrintListData.map((item, key) => ({
                    '#': key + 1,
                    'Job Name': item?.job_schedules?.job?.name + '(' + item?.job_schedules?.job?.code + ')',
                    'Department Name': item?.job_schedules?.job?.department?.name + '(' + item?.job_schedules?.job?.department?.code + ')',
                    'Component Name': item?.job_schedules?.job?.component?.name + '(' + item?.job_schedules?.job?.component?.code + ')',
                    'Completion Date': item?.date,
                    'Done By': item?.done_by_rank?.name,
                    'Verified By': item?.verified_by_rank?.name,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'breakdown.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getAllJobPrintList() {
                this.scheduleInfo.print_enable = true;
                let params = {
                    vesselId: this.scheduleInfo.vesselId,
                    searchInput: this.scheduleInfo.searchInput,
                    print_enable: this.scheduleInfo.print_enable
                };
                this.$http.get('/api/filter/all-job-breakdown-history-list', {
                    params
                }).then(response => {
                    this.jobPrintListData = response.data;
                }).catch(e => {

                });
            },
            getAllJobList() {
                this.jobList = [];
                this.loading = true;
                let params = {
                    vesselId: this.scheduleInfo.vesselId,
                    searchInput: this.scheduleInfo.searchInput
                };
                this.$http.get('/api/filter/all-job-breakdown-history-list?page=' + this.pagination.current_page, {params}).then(response => {
                    this.jobList = response.data.data;
                    this.jobPrintListData = response?.data?.print;
                    this.loading = false;
                    this.excelExport();
                    let per_page = response.data.per_page;
                    let total = response.data.total;
                    let calculation = total / per_page;
                    let offset = 5;
                    if (parseInt(calculation) < this.pagination.offset) {
                        offset = parseInt(calculation) + 1;
                    }
                    this.pagination = response.data;
                    this.pagination.offset = offset;

                }).catch(e => {
                    console.log("e",e)
                    this.loading = false
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        // this.$snotify.error(response?.data?.error)
                    }
                });
            },
            jobDoneFunction(job) {
                if (job?.job_done_reports && job?.job_done_reports != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                if (this.user_type <= 3 && job?.job_done_reports == null) {
                    this.$snotify.warning('Job Done Report is not Submitted')
                } else {
                    this.listMode = false
                    this.IRR = false
                    this.P2W = false
                    this.JobDoneStatus = true
                    this.selectedJob = job?.job_schedules?.job
                    this.scheduleData = job?.job_schedules
                    this.componentName = job?.job_schedules?.job?.component?.name
                }

            },
            //this function use for change list mode
            changeListMode() {
                this.IRR = false
                this.P2W = false
                this.JobDoneStatus = false
                this.DeferReschedule = false
                this.listMode = true
                this.getAllJobList()
            }
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

</style>
