<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_content">
          <div class="x_title">
            <h2>Item Wise Component List</h2>
            <ul class="nav navbar-right panel_toolbox">
              <li>
                <button type="button" class="btn btn-info btn-sm" @click="create">
                  Add New
                  <i class="fa fa-plus"></i>
                </button>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box table-responsive">
                <p class="text-muted font-13 m-b-30">
                </p>
                <table id="datatable" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th> Item Name</th>
                    <th> Component Name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(itemCom , index) in itemToComponent" :key="itemCom.id">
                    <td>{{index+1}}</td>
                    <td>{{itemCom.item_name}}</td>
                    <td>{{itemCom.component_name}}</td>
                    <td>

                      <button type="button" @click="edit(itemCom)" class="btn btn-primary btn-sm">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        @click="destroy(itemCom)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="componenttModel" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="customerModalLongTitle"
            >{{ editMode ? "Edit" : "Add " }} Item To Component </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : storeComponent()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <alert-error :form="form"></alert-error>
              <div class="form-group">
                <label>Select Item :</label>
                <select v-model="form.item_id" name="item_id" class="form-control" :class="{ 'is-invalid': form.errors.has('item_id') }">
                  <option v-for="item in items" :key="item.id" :value="item.id">{{item.name}}</option>
                </select>
                <has-error :form="form" field="item_id"></has-error>
              </div>

              <div class="form-group">
                <label>Select Component :</label>
                <select v-model="form.component_id" name="component_id" class="form-control" :class="{ 'is-invalid': form.errors.has('component_id') }">
                  <option v-for="component in componentList" :key="component.id" :value="component.id">{{component.name}}</option>
                </select>
                <has-error :form="form" field="component_id"></has-error>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
    export default {
        name: "ItemToComponent",
        data() {
            return {
                editMode: false,
                query: "",
                queryFiled: "name",
                items: [],
                componentList: [],
                itemToComponent: [],
                form: new Form({
                    id: '',
                    item_id: "",
                    component_id: "",
                }),
                vessel_id: this.$store.state.vessal_id,
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
        },
        methods: {

            getData() {
                this.$http.get("/api/filter/item-to-component/" + this.vessel_id)
                    .then(response => {
                        this.itemToComponent = response.data.success;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                this.componentListInfo();
                this.itemListInfo();
                $("#componenttModel").modal("show");
            },

            storeComponent() {
                this.form.busy = true;
                this.form
                    .post("/api/item-to-component")
                    .then(response => {
                        this.getData();
                        $("#componenttModel").modal("hide");
                        if (this.form.successful) {
                            this.$snotify.success("Item Component Assign Successfully");
                        } else {
                            this.$snotify.error(
                                "Something went wrong try again later."
                            );
                        }
                    })
                    .catch(e => {
                        var error = this.form.errors.errors.error;
                        this.$snotify.error("Sorry Item Component Assign successfully");
                    });
            },
            edit(item) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.componentListInfo();
                this.itemListInfo();
                this.form.fill(item);
                $("#componenttModel").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/item-to-component/" + this.form.id).then(response => {
                    this.getData();
                    $("#componenttModel").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Item successfully Update");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    console.log(this.form);
                });
            },
            destroy(item) {
                this.$dialog.confirm('Do You Want to delete This ?')
                    .then(() => {
                        this.$http.delete("/api/item-to-component/" + item.id)
                            .then(response => {
                                this.getData();
                                this.$snotify.success(
                                    "Item to Componemt  Successfully Deleted"
                                );
                            })
                            .catch(error => {
                                var err = error.response.data.error;
                                this.$snotify.error(err
                                );
                            });
                    })
                    .catch(function () {

                    });
            },
            /*
            //item assign depend on component
            */
            componentListInfo() {
                this.$http.get("/api/filter/component/" + this.vessel_id)
                    .then(response => {
                        this.componentList = response.data.success;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            itemListInfo() {
                this.$http.get("/api/filter/item/" + this.vessel_id)
                    .then(response => {
                        this.items = response.data.success;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
