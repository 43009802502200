<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>Type</th>
      <th>Status</th>
      <th>Requisition no</th>
      <th>Title</th>
      <th>Priority</th>
      <th>Remarks</th>
      <th>Date</th>
      <th>Created By</th>
      <th>Chief Engr. Approval</th>
      <th>Super. Approval</th>
<!--      <th>PM Approval</th>-->
      <th>TM Approval</th>
      <th>Amount</th>
      <th v-if="!isPrinting">Budget Info</th>
      <th v-if="!isPrinting &&
      (isPermissionExist(PERMISSIONS.REQUISITION_SHOW) ||
      isPermissionExist(PERMISSIONS.REQUISITION_EDIT) ||
      isPermissionExist(PERMISSIONS.REQUISITION_DELETE) ||
      isPermissionExist(PERMISSIONS.REQUISITION_COPY) ||
      isPermissionExist(PERMISSIONS.REQUISITION_RFQ))
   ">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(requisition , index) in requisitionData" :key="index">
      <td>{{requisition.type}}</td>
      <td>{{requisition.status}}</td>
      <td>{{requisition.requisition_no}}</td>
      <td>{{requisition.title}}</td>
      <td>{{requisition.priority}}</td>
      <td>{{requisition.remarks}}</td>
      <td>{{requisition.date}}</td>
      <td>
        <span v-if="requisition?.created_type == 'shore'">
           {{requisition?.user?.username}}
        </span>
        <span v-else>
          Ship
        </span>

      </td>
      <td>
        <span v-if="requisition.chief_engr_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.CHIEF_ENGINEER_APPROVAL)">
           <button type="button" title="Approve" class="btn btn-warning btn-sm"
                @click="approveRequest('Requisition', requisition.id, 'Approved', 'chief_engr_approval')"> {{requisition.chief_engr_approval}}</button>
       </span>
        <span v-else> {{requisition.chief_engr_approval}}  </span>
      </td>
      <td> <span v-if="requisition.superintendent_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.SUPERINTENDING_APPROVAL)  ">
             <button type="button" title="Approve" class="btn btn-warning btn-sm"
                @click="approveRequest('Requisition', requisition.id, 'Approved', 'superintendent_approval')"> {{requisition.superintendent_approval}}</button>
              </span>
        <span v-else> {{requisition.superintendent_approval}} </span>
      </td>
<!--      <td> <span v-if="requisition.purchase_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.PM_APPROVAL)">-->
<!--               <button type="button" title="Approve" class="btn btn-warning btn-sm"-->
<!--                   @click="approveRequest('Requisition', requisition.id, 'Approved', 'purchase_manager_approval')"> {{requisition.purchase_manager_approval}}</button>-->
<!--               </span>-->
<!--        <span v-else>  {{requisition.purchase_manager_approval}}  </span>-->
<!--      </td>-->
      <td> <span v-if="requisition.technical_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.TM_APPROVAL)">
              <button type="button" title="Approve" class="btn btn-warning btn-sm"
                       @click="approveRequest('Requisition', requisition.id, 'Approved', 'technical_manager_approval')"> {{requisition.technical_manager_approval}}</button>
               </span>
        <span v-else> {{requisition.technical_manager_approval}}  </span>
      </td>
      <td>${{requisition.amount}}</td>
      <td v-if="!isPrinting">
        Budget: {{requisition?.budget_info?.totalBudget}} &nbsp; &nbsp;
        Budget Left:  {{requisition?.budget_info?.totalBudgetLeft }}
<!--        <button type="button" @click="showBudget(requisition.id, index)" title="View" class="btn btn-info btn-sm">Budget Info</button>-->
<!--        <div :class="`budget-${index}`"></div>-->
      </td>
      <td v-if="!isPrinting &&
        (isPermissionExist(PERMISSIONS.REQUISITION_SHOW) ||
        isPermissionExist(PERMISSIONS.REQUISITION_EDIT) ||
        isPermissionExist(PERMISSIONS.REQUISITION_DELETE) ||
        isPermissionExist(PERMISSIONS.REQUISITION_COPY) ||
        isPermissionExist(PERMISSIONS.REQUISITION_RFQ))
        ">
        <button v-if="isPermissionExist(PERMISSIONS.REQUISITION_SHOW)" type="button" @click="show(requisition.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
        <span v-if="requisition.chief_engr_approval == 'Approved' &&
         requisition.superintendent_approval == 'Approved' &&
          requisition.technical_manager_approval == 'Approved' &&
          isPermissionExist(PERMISSIONS.REQUISITION_RFQ)
           "> <button :disabled="requisition.purchase_order_exist" type="button" @click="viewQuotation(requisition.id)" title="Request for quotation"
                      class="btn btn-warning btn-sm">{{requisition.purchase_order_exist ? 'RFQ Already Created' : 'RFQ'}} </button>
         </span>
        <span v-else>
          <button type="button" v-if="isPermissionExist(PERMISSIONS.REQUISITION_EDIT)" @click="edit(requisition.id)" title="Edit" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
          <button type="button" v-if="isPermissionExist(PERMISSIONS.REQUISITION_DELETE)" @click="destroy(requisition.id)" title="Delete" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
        </span>
        <button type="button"  v-if="isPermissionExist(PERMISSIONS.REQUISITION_COPY)" @click="copy(requisition.id)" title="Copy" class="btn btn-secondary btn-sm"><i class="fa fa-copy"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import auth from "@/auth";
import PermissionsMixin from "@/mixin/permissionsMixin";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    requisitionData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    approveRequest: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    showBudget: {
      type: Function,
      required: false
    },
    viewQuotation: {
      type: Function,
      required: false
    },
    edit: {
      type: Function,
      required: false
    },
    destroy: {
      type: Function,
      required: false
    },
    copy: {
      type: Function,
      required: false
    }



  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
