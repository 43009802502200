<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Stock Ledger Report(Historical Summary)</h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>

          </div>
          <div class="row">
            <div class="col-sm-12">
              <form role="form" action="#" method="POST" @submit="formSubmit" class="searchForm">
                <div class="x_panel">
                  <div class="x_content">
                    <div class="row justify-content-center">

                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Category <span class="text-danger font-weight-bold">*</span></label>
                          <select class="form-control category select2" v-model="category_id" @change="getCategoriesItem">
                            <option value="" selected>Select category</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Item <span class="text-danger font-weight-bold">*</span></label>
                          <select v-model="item" class="form-control">
                            <option value="" selected>Select item</option>
                            <option v-for="(row, key) in CategoriesItemData" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                          </select>
                        </div>
                      </div>


                      <div class="col-md-2">
                        <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div
                v-if="loading"
                class="col-md-12 text-center"
                style="margin-top:25%"
              >
                <PulseLoader
                  :loading="loading"
                ></PulseLoader>
              </div>
              <div class="card-box table-responsive" v-if="stockLedgerData && !loading">
                <button @click="print('printArea','Stock Ledger Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'stock-ledger'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                <div class="clearfix"></div>
                <div id="printableArea">
                  <div class="mb-3 text-center">
                    <div class="font-weight-bold">Stock Ledger Report(Historical Summary)</div>
                    <div>Item: {{item?.name}}</div>
                    <div v-if="item?.inpa_code">Impa Code: {{item?.inpa_code}}</div>
                    <div v-if="item?.model">Model: {{item?.model}}</div>
                    <div v-if="item?.maker">Maker: {{item?.maker}}</div>
                  </div>
                  <TableData :stockLedgerData="stockLedgerData" :item="item" :stockClosingData="stockClosingData"/>
                </div>
              </div>
              <div id="printArea" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Stock Ledger Report(Historical Summary)</h3>
                  <h3>Item: {{item?.name}}</h3>
                  <div v-if="item?.inpa_code">Impa Code: {{item?.inpa_code}}</div>
                  <div v-if="item?.model">Model: {{item?.model}}</div>
                  <div v-if="item?.maker">Maker: {{item?.maker}}</div>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <TableData :stockLedgerData="stockLedgerData" :item="item" :stockClosingData="stockClosingData" :isPrinting="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        components: {TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                item: null,
                category_id: null,
                CategoryData: null,
                CategoriesItemData: null,
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                portData: null,
                stockLedgerData: null,
                stockClosingData: 0,
                vesselId: auth.getVesselId(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getCategoryData()
        },
        methods: {
            prepareExportData() {
                const openingStock = {
                    '#': '',
                    'Date': this.item?.opening_stock_date,
                    'Item Name': 'Opening Stock',
                    'Impa Code': ' ',
                    'Model': ' ',
                    'Maker': ' ',
                    'Stock In': this.item?.opening_stock,
                    'Stock Out': '',
                };
                const stockLedgerData = this.stockLedgerData?.map((item, key) => ({
                    '#': key + 1,
                    'Date': item?.date,
                    'Item Name': item?.name,
                    'Impa Code': item?.inpa_code,
                    'Model': item?.model,
                    'Maker': item?.maker,
                    'Stock In': item?.in_qty,
                    'Stock Out': item?.out_qty,
                })) || [];

                const closingStock = {
                    '#': '',
                    'Date': '',
                    'Item Name': 'Closing Stock',
                    'Impa Code': ' ',
                    'Model': ' ',
                    'Maker': ' ',
                    'Stock In': '',
                    'Stock Out': this.stockClosingData,
                };

                let exportData = [openingStock, ...stockLedgerData];

                exportData = [...exportData, closingStock];

                // Return the combined array
                return exportData;
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'stock-ledger.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getCategoryData() {
                this.$http.get('/api/categoryTree/' + this.vesselId).then(response => {
                    $('.category').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            getCategoriesItem() {
                this.$http.post('/api/item-filter', {
                    category_id: this.category_id,
                    vessel_id: this.vesselId
                }).then(response => {
                    this.CategoriesItemData = response.data
                }).catch(e => {
                    this.CategoriesItemData = [];
                    console.log(e)
                })
            },
            formSubmit: function (e) {
                e.preventDefault();
                if (!this.item) {
                    this.$snotify.error('Please select category & item');
                    return false
                }
                this.itemId = this.item.id;
                var formData = {
                    'item_id': this.itemId,
                    'vessel_id': this.vesselId,
                    'location_id': this.location_id,
                };
                this.loading = true;
                this.$http.post(`/api/stock-ledger`, formData).then((response) => {
                    if (response.data) {
                        this.item = response.data.item;
                        this.stockClosingData = response.data.closingStock;
                        this.stockLedgerData = response.data.ledgerData
                    }
                    this.loading = false;
                    this.excelExport();
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    let response = error.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            }
        }
    }
</script>
