<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left"> Monthly Overdue Job </h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            <button @click="print('printArea','Over Due Job Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <select class="form-control" v-model="form.vessel">
              <option value="" selected>Select Vessel</option>
              <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <div class="form-group">
              <select
                v-model="form.year"
                name="budget_year"
                class="form-control"
              >
                <option
                  v-for="year in years"
                  :key="year"
                  selected
                >{{ year }}
                </option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-1">
          <button :disabled='form.year==""  || form.vessel =="" ' class="btn btn-sm btn-info" @click="getAllOverDueJob">Search</button>
        </div>
        <div class="col-md-8"></div>

      </div>
      <div>
        <div>

          <div class="row">
            <div class="col-md-12">
              <div
                v-if="loading"
                class="col-md-12 text-center"
                style="margin-top:25%"
              >
                <PulseLoader
                  :loading="loading"
                ></PulseLoader>
              </div>
              <div v-if="!loading" class="table-responsive">
                <MonthlyOverdueTableData :jobList="jobList"/>
                <div id="printArea" style="display: none">
                  <div style="text-align: center;line-height: 20px;">
                    <h2>{{ appName }}</h2>
                    <div class="font-weight-bold">Monthly Over Due Job List</div>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <MonthlyOverdueTableData :jobList="jobList" :isPrinting="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import auth from '../../../auth'
    import TableData from "./TableData.vue";

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import fa from "../../../../public/admin/vendors/moment/src/locale/fa";
    import DeferReschedule from "@/components/Maintenance/DeferReschedule.vue";
    import MonthlyOverdueTableData from "@/components/reports/OverDueJob/MonthlyOverdueTableData.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin],
        name: 'MonthlyOverdueJob',
        components: {MonthlyOverdueTableData, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                vessels: null,
                form: new Form({
                    year: new Date().getFullYear(),
                    vessel: {
                        id: auth.getVesselId(),
                        name: ''
                    }
                }),
                pagination: {
                    current_page: 1
                },
                i: 0,
                jobList: [],
                years: [],
                vesselId: auth.getVesselId(),
                user_type: auth.getUserType(),
            }
        },
        mounted() {
            this.getVessel();
            this.yearsGenerate();
            this.getAllOverDueJob();
        },
        methods: {
            yearsGenerate() {
                let i = new Date().getFullYear();
                for (i; i >= 2015; i--) {
                    this.years.push(i);
                }
            },
            notAfterToday(date) {
                return date > today
            },
            getAllOverDueJob(e) {
                if (e !== undefined) {
                    e.preventDefault()
                }
                this.loading = true
                if (this.form.vessel) this.form.vessel_id = this.form.vessel.id
                let params = {
                    year: this.form.year,
                    vessel_id: this.form.vessel.id
                }
                this.$http.get('/api/get-overdue-job-monthly-report', {params}).then(response => {
                    this.jobList = response.data;
                    this.loading = false;
                }).catch(e => {
                    console.log(e)
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (e.response.data?.errors) {
                            const errors = e.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },
            // getAllOverDueJob(){
            //     this.$http.get("/api/get-due-job/" + this.vesselId+'/List').then(response => {
            //         this.jobList=response.data.success;
            //     }).catch(e => {
            //         console.log(e);
            //     });
            // },
            getAllDeferJob() {
                this.$http.get('/api/get-defer-job/' + this.vesselId + '/List').then(response => {

                    this.deferJob = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getAllDoneJob() {
                this.$http.get('/api/get-done-job/' + this.vesselId + '/List').then(response => {
                    this.jobListDone = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },

            viewDeferSchedule(job, i) {
                this.listMode = false
                this.DeferReschedule = true
                this.selectedJob = job
                this.scheduleId = i
            },

            changeListMode() {
                this.DeferReschedule = false
                this.listMode = true
                this.getAllOverDueJob()
            },
            getVessel() {
                this.$http.get('/api/vessel')
                    .then(response => {
                        this.vessels = response.data.success.vessel
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

        }
    }
</script>

<style scoped>
  .mx-datepicker-range {
    width: 100%;
  }
</style>
