import { render, staticRenderFns } from "./CategoryTree.vue?vue&type=template&id=735d03f4"
import script from "./CategoryTree.vue?vue&type=script&lang=js"
export * from "./CategoryTree.vue?vue&type=script&lang=js"
import style0 from "./CategoryTree.vue?vue&type=style&index=0&id=735d03f4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports