<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left"> Component List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Image</th>
                          <th>Component Name</th>
                          <th>Main Component Name</th>
                          <th>Department Name</th>
                          <th>Code</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(component , index) in components" :key="component.id">
                          <td>{{index+1}}</td>
                          <td>
                            <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="component && component.image != null" :href="component && component.image ">View</a>
                          </td>
                          <td>{{component.name}}</td>
                          <td>{{isEmpty(component.maincomponent)?component.maincomponent.name:''}}</td>
                          <td>{{component?.department?.name}}</td>
                          <td>{{component.code}}</td>
                          <td>{{component.status}}</td>
                          <td>
                            <button type="button" @click="show(component)" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                            <button type="button" @click="edit(component)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                            <button type="button" @click="destroy(component)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                            <button title="Copy Component" type="button" @click="copyComponent(component)" class="btn btn-success btn-sm"><i class="fa fa-clone"
                                                                                                                                             aria-hidden="true"></i></button>

                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Component </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-4 col-sm-4 col-4">

                    <div class="form-group">
                      <label>Department Name</label>
                      <select v-model="form.department_id" name="department_id" class="form-control" :class="{ 'is-invalid': form.errors.has('department_id') }"
                              @change="mainComponent(form.department_id)">
                        <option value="" selected>Select department</option>
                        <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{sdepartment.name}}</option>
                      </select>
                      <has-error :form="form" field="department_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Main Component </label>
                      <select v-model="form.main_component_id" name="main_component_id" class="form-control" :class="{ 'is-invalid': form.errors.has('main_component_id') }">
                        <option value="" selected>Select component</option>
                        <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{mainComponent.name}}</option>
                      </select>
                      <has-error :form="form" field="main_component_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Name</label>
                      <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                      <has-error :form="form" field="name"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Code</label>
                      <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                      <has-error :form="form" field="code"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Maker Ref Info</label>
                      <input autocomplete="off" v-model="form.maker_ref_info" type="text" name="maker_ref_info" class="form-control"
                             :class="{ 'is-invalid': form.errors.has('maker_ref_info') }">
                      <has-error :form="form" field="maker_ref_info"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Budget Head</label>
                      <select v-model="form.budget_head_id" name="budget_head_id" class="form-control" :class="{ 'is-invalid': form.errors.has('budget_head_id') }">
                        <option v-for="budgetHead in budgetHeads" :key="budgetHead.id" :value="budgetHead.id">{{budgetHead.name}}</option>
                      </select>
                      <has-error :form="form" field="budget_head_id"></has-error>
                    </div>

                  </div>

                  <div class="col-md-4 col-sm-4 col-4">
                    <div class="form-group">
                      <label>Manual </label>
                      <input autocomplete="off" v-model="form.manual_id" type="text" name="maker" class="form-control" :class="{ 'is-invalid': form.errors.has('manual_id') }">
                      <has-error :form="form" field="manual_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Maker</label>
                      <input autocomplete="off" v-model="form.maker" type="text" name="maker" class="form-control" :class="{ 'is-invalid': form.errors.has('maker') }">
                      <has-error :form="form" field="maker"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Serial No</label>
                      <input autocomplete="off" v-model="form.serial_no" type="text" name="serial_no" class="form-control" :class="{ 'is-invalid': form.errors.has('serial_no') }">
                      <has-error :form="form" field="serial_no"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Bearing No</label>
                      <input autocomplete="off" v-model="form.bearing_no" type="text" name="bearing_no" class="form-control" :class="{ 'is-invalid': form.errors.has('bearing_no') }">
                      <has-error :form="form" field="bearing_no"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Model</label>
                      <input autocomplete="off" v-model="form.model" type="text" name="model" class="form-control" :class="{ 'is-invalid': form.errors.has('model') }">
                      <has-error :form="form" field="model"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Rating</label>
                      <input autocomplete="off" v-model="form.rating" type="text" name="rating" class="form-control" :class="{ 'is-invalid': form.errors.has('rating') }">
                      <has-error :form="form" field="rating"></has-error>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-4 col-4">

                    <div class="form-group">
                      <label>Misc Info</label>
                      <input autocomplete="off" v-model="form.misc_info" type="text" name="misc_info" class="form-control" :class="{ 'is-invalid': form.errors.has('misc_info') }">
                      <has-error :form="form" field="misc_info"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Image </label>
                      <input type="file" class="form-control" @change="covertImage" accept="image/*">
                    </div>

                    <div class="form-group">
                      <label>Status</label>
                      <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                        <option value="Activate">Activate</option>
                        <option value="Deactivate">Deactivate</option>
                      </select>
                      <has-error :form="form" field="status"></has-error>
                    </div>

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="copyModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">Copy Component </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-6">

                    <div class="form-group">
                      <label> Name</label>
                      <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                      <has-error :form="form" field="name"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Code</label>
                      <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                      <has-error :form="form" field="code"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Maker Ref Info</label>
                      <input autocomplete="off" v-model="form.maker_ref_info" type="text" name="maker_ref_info" class="form-control"
                             :class="{ 'is-invalid': form.errors.has('maker_ref_info') }">
                      <has-error :form="form" field="maker_ref_info"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Budget Head</label>
                      <select v-model="form.budget_head_id" name="budget_head_id" class="form-control" :class="{ 'is-invalid': form.errors.has('budget_head_id') }">
                        <option v-for="budgetHead in budgetHeads" :key="budgetHead.id" :value="budgetHead.id">{{budgetHead.name}}</option>
                      </select>
                      <has-error :form="form" field="budget_head_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Bearing No</label>
                      <input autocomplete="off" v-model="form.bearing_no" type="text" name="bearing_no" class="form-control" :class="{ 'is-invalid': form.errors.has('bearing_no') }">
                      <has-error :form="form" field="bearing_no"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Model</label>
                      <input autocomplete="off" v-model="form.model" type="text" name="model" class="form-control" :class="{ 'is-invalid': form.errors.has('model') }">
                      <has-error :form="form" field="model"></has-error>
                    </div>

                  </div>

                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <label>Manual </label>
                      <input autocomplete="off" v-model="form.manual_id" type="text" name="maker" class="form-control" :class="{ 'is-invalid': form.errors.has('manual_id') }">
                      <has-error :form="form" field="manual_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label> Maker</label>
                      <input autocomplete="off" v-model="form.maker" type="text" name="maker" class="form-control" :class="{ 'is-invalid': form.errors.has('maker') }">
                      <has-error :form="form" field="maker"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Serial No</label>
                      <input autocomplete="off" v-model="form.serial_no" type="text" name="serial_no" class="form-control" :class="{ 'is-invalid': form.errors.has('serial_no') }">
                      <has-error :form="form" field="serial_no"></has-error>
                    </div>


                    <div class="form-group">
                      <label>Rating</label>
                      <input autocomplete="off" v-model="form.rating" type="text" name="rating" class="form-control" :class="{ 'is-invalid': form.errors.has('rating') }">
                      <has-error :form="form" field="rating"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Misc Info</label>
                      <input autocomplete="off" v-model="form.misc_info" type="text" name="misc_info" class="form-control" :class="{ 'is-invalid': form.errors.has('misc_info') }">
                      <has-error :form="form" field="misc_info"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Image </label>
                      <input type="file" class="form-control" @change="covertImage" accept="image/*">
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="showModalLabel">{{ form.name }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{{ form.name }}</td>
                  </tr>
                  <tr>
                    <th>Budget Code</th>
                    <td>{{ form.code}}</td>
                  </tr>
                  <tr>
                    <th>Manual Id</th>
                    <td>{{ form.manual_id }}</td>
                  </tr>
                  <tr>
                    <th>Code</th>
                    <td>{{ form.code }}</td>
                  </tr>
                  <tr>
                    <th>Maker</th>
                    <td>{{ form.maker }}</td>
                  </tr>
                  <tr>
                    <th>Model</th>
                    <td>{{ form.model }}</td>
                  </tr>
                  <tr>
                    <th>Maker Ref Info</th>
                    <td>{{ form.maker_ref_info }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import auth from '../../auth'
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        components: {PulseLoader},
        mixins: [goBackButtonMixin],
        name: 'MainComponent',
        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                departments: [],
                departmentId: '',
                mainComponents: [],
                selectedMainComponent: null,
                components: [],
                budgetHeads: [],
                form: new Form({
                    id: '',
                    name: '',
                    main_component_id: '',
                    department_id: '',
                    budget_head_id: '',
                    manual_id: '',
                    code: '',
                    maker: '',
                    model: '',
                    maker_ref_info: '',
                    serial_no: '',
                    bearing_no: '',
                    rating: '',
                    misc_info: '',
                    image: '',
                    vessel_id: auth.getVesselId(),
                    status: 'Activate'
                }),
                showInfo: {
                    budget_heade_name: '',
                    manual_name: '',
                },
                pagination: {
                    current_page: 1
                },
                vessel_id: auth.getVesselId(),
            }
        },
        mounted() {
            this.departmentList()
            this.budgetHeadsList()
            this.getData()
        },
        watch: {
            'form.main_component_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.mainComponents);
                }
            },
        },
        methods: {
            updateCode(parent_id, components) {
                const selectedComponent = components.find(component => component.id === parent_id);
                if (selectedComponent && selectedComponent.code) {
                    return selectedComponent.code + '-';
                }
                return '';
            },
            getData() {
                this.loading = true;
                this.$http.get('/api/filter/component/' + this.vessel_id).then(response => {
                    this.loading = false;
                    this.components = response.data.success
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $('#roleModal').modal('show')
            },
            covertImage(event) {
                let file = event.target.files[0];
                this.form.image = file;
            },
            store() {
                this.form.busy = true;
                this.form.post('/api/component').then(response => {
                    $('.dataTables_empty').closest('tr').remove();
                    this.getData();
                    $('#roleModal').modal('hide');
                    $('#copyModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success(' Component Created Successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error('Sorry Component can not create successfully', 'Error')
                    }

                })
            },
            async edit(component) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                await this.mainComponent(component.department_id);
                this.form.fill(component);
                setTimeout(() => {
                    this.form.code = component.code
                }, 1000);
                $('#roleModal').modal('show');
            },
            copyComponent(component) {
                this.form.reset();
                this.form.clear();
                this.form.fill(component);
                $('#copyModal').modal('show');
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/component/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success(' component successfully Update')
                    } else {
                        this.$snotify.error('Something went wrong try again later.', 'Error')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            destroy(Component) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/component/' + Component.id).then(response => {
                        this.getData();
                        this.$snotify.success(' Component Successfully Deleted')
                    }).catch(error => {
                        let response = error.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            var err = error.response.data.error
                            this.$snotify.error(err)
                        }

                    })
                }).catch(function () {

                })

            },
            departmentList() {
                this.$http.get('/api/filter/department/' + this.vessel_id).then(response => {
                    this.departments = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            mainComponent(department_id) {
                if (department_id == '') {
                    this.mainComponents = [];
                    return Promise.resolve();
                } else {
                    return this.$http.get('/api/filter/main-component/' + this.vessel_id + '/' + department_id)
                        .then(response => {
                            this.mainComponents = response.data.success;
                        })
                        .catch(e => {
                            console.log(e);
                            return Promise.reject(e);
                        });
                }
            },
            budgetHeadsList() {
                this.$http.get('/api/filter/budget-head/' + this.vessel_id).then(response => {
                    this.budgetHeads = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            show(component) {
                this.form.reset();
                this.form.fill(component);
                $('#showModal').modal('show')
            },
            isEmpty(obj) {
                if (obj != null) {
                    return true
                } else {
                    return false
                }
            }

        }
    }
</script>

<style scoped>
  .des {
    height: 36% !important;
  }
</style>
