<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_content">
          <div class="x_title">
            <h2>Purchase Invoice</h2>
            <button   @click="print('printArea','Invoice Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            <div class="clearfix"></div>
          </div>
          <div v-if="purchaseData && invoiceData" class="row justify-content-center">
            <TableData  :purchaseData="purchaseData" :invoiceData="invoiceData" />

          </div>



              <section class="content invoice p-2" id="printArea" style="display: none">
                <TableData  :purchaseData="purchaseData" :invoiceData="invoiceData" :isPrinting="true" />



              </section>


        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import datetime from 'vuejs-datetimepicker'
  import TableData from "./TableData.vue";

  export default {
    name: 'Invoice',
    components: {TableData, datetime },
    data () {
      return {
        editMode: false,
        invoiceData: null,
        purchaseData: null,
      }
    },
    mounted () {
      this.getInvoiceData()
      this.getPurchaseData()
    },
    methods: {
      print (id, title = '') {
        const table = document.getElementById(id);
        //display block
        table.style.display = 'block';
        const printWindow = window.open();
        printWindow.document.open();
        printWindow.document.write('<html><head><title>'+ title + '</title>');
        printWindow.document.write('<style>.print-table { border-collapse: collapse; }  .print-table th, .print-table td { border: 1px solid black; padding: 8px; }</style>');
        printWindow.document.write('</style></head><body>');
        printWindow.document.write(table.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        table.style.display = 'none';
        printWindow.addEventListener('afterprint', () => {
          printWindow.close();
        });
      },
      getPurchaseData () {
        this.$http.get('/api/purchase/' + this.$route.params.purchaseID).then(response => {
          this.purchaseData = response.data
        }).catch(e => {
          console.log(e)
        })
      },
      getInvoiceData () {
        this.$http.get('/api/invoice/' + this.$route.params.invoiceID).then(response => {
          this.invoiceData = response.data
        }).catch(e => {
          console.log(e)
        })
      },
    }
  }
</script>

<style scoped>

</style>
