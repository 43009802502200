<template>
  <div>
    <table :class="getTableClasses" v-if="quotationDetails">
      <thead>
      <tr>
        <th><strong>Title: </strong>{{quotationDetails.requisition.title}}</th>
        <th><strong>Requisition No: </strong>{{quotationDetails.requisition.requisition_no}}</th>
        <th><strong>Status: </strong>{{quotationDetails.requisition.status}}</th>
        <th><strong>Date: </strong>{{quotationDetails.requisition.date}}</th>
      </tr>
      <tr>
        <th><strong>Remarks: </strong>{{quotationDetails.requisition.remarks}}</th>
        <th><strong>Priority: </strong>{{quotationDetails.requisition.priority}}</th>
        <th><strong>Address: </strong>{{quotationDetails.requisition.address}}</th>
        <th><strong>Required By: </strong>{{quotationDetails.requisition.supply_date}}</th>
      </tr>
      <tr>
        <th><strong>Vessel ETA: </strong>{{quotationDetails.requisition.vessel_eta}}</th>
        <th><strong>Vessel ETD: </strong>{{quotationDetails.requisition.vessel_etd}}</th>
        <th><strong>Country: </strong>
          <span v-if="quotationDetails.requisition.country">
                                       {{quotationDetails.requisition.country.name}}
                                   </span>
        </th>
        <th><strong>Port: </strong>
          <span v-if="quotationDetails.requisition.port">
                        {{quotationDetails.requisition.port.name}}
                   </span>
        </th>
      </tr>
      </thead>
    </table>
    <hr>
    <table :class="getTableClasses" v-if="quotationDetails">
      <thead>
      <tr>
        <th>Status</th>
        <th>Item name</th>
        <th>Impa code</th>
        <th>Maker Part no</th>
        <th>Maker</th>
        <th>Model</th>
        <th class="text-center">Qty</th>
        <th class="text-center">Unit</th>
        <th class="text-center">Opening stock</th>
        <th>Details</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item , index) in quotationDetails.quotation_item" :key="index">
        <td>{{item?.status}}</td>
        <td>{{item.item?.name}}</td>
        <td>{{item.item?.inpa_code}}</td>
        <td>{{item.item?.maker_part_no}}</td>
        <td>{{item.item?.maker}}</td>
        <td>{{item.item?.model}}</td>
        <td class="text-center">{{item?.qty}}</td>
        <td class="text-center">{{item.item?.unit.name}}</td>
        <td class="text-center">{{item.item?.opening_stock}}</td>
        <td>{{item.item?.description}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>




<script>
export default {
  props: {
    quotationDetails: {
      type: Object,
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>
<style scoped>

</style>
