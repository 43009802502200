<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Stock Adjustment</h2>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <form
                role="form"
                action="#"
                method="POST"
                @submit="formSubmit"
                class="searchForm"
              >
                <div class="x_panel">
                  <div class="x_content">
                    <div class="row justify-content-center">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label
                          >Category
                            <span
                              class="text-danger font-weight-bold"
                            >*</span
                            ></label
                          >
                          <select
                            class="form-control category select2"
                            v-model="category_id"
                            @change="
                                                            getCategoriesItemWithStock
                                                        "
                          >
                            <option
                              value=""
                              selected
                            >Select
                              category
                            </option
                            >
                          </select>
                        </div>
                      </div>

                      <!-- <div class="col-md-2">
                          <div class="form-group">
                              <label>Item <span class="text-danger font-weight-bold">*</span></label>
                              <select v-model="item" class="form-control">
                                  <option value="" selected>Select item</option>
                                  <option v-for="(row, key) in CategoriesItemData" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                              </select>
                          </div>
                      </div> -->

                      <!-- <div class="col-md-2">
                          <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                      </div> -->
                    </div>
                  </div>
                </div>
              </form>

              <form @submit.prevent="updateStock">
                <div
                  class="card-box table-responsive"
                  v-if="CategoriesItemData"
                >
                  <!-- <a href="javascript:;" @click="print('printableArea')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</a> -->
                  <div class="clearfix"></div>
                  <div id="printableArea">
                    <table
                      id="datatables"
                      class="table table-striped table-bordered table-sm  text-center"
                      style="width:100%"
                    >
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Item name</th>
                        <th>Impa code</th>
                        <th>Model</th>
                        <th>Maker</th>
                        <th>Current Stock</th>
                        <th>Used in Job</th>
                        <th>Used in Schedule</th>
                        <th>Deduct Stock</th>
                        <th>Stock Balance</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="(item,
                                                    index) in CategoriesItemData"
                        :key="index"
                      >
                        <td>{{ ++index }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                          {{ item.inpa_code }}
                        </td>
                        <td>{{ item.model }}</td>
                        <td>{{ item.maker }}</td>
                        <td
                          :class="
                                                            `row-closing-${index}`
                                                        "
                        >
                          {{ item.closing_stock }}
                        </td>
                        <td>
                          <select
                            :class="
                                                                `form-control row-job-${index}`
                                                            "
                            @change="
                                                                getJobScheduleByJob(
                                                                    index
                                                                )
                                                            "
                          >
                            <option value=""
                                    selected>Select
                              Job
                            </option
                            >
                            <option
                              v-for="(row,
                                                                index) in jobList"
                              :key="index"
                              :value="
                                                                    `${row.id}`
                                                                "
                            >{{
                              row.name
                              }}
                            </option
                            >
                          </select>
                        </td>
                        <td>
                          <select
                            :class="
                                                                `form-control row-job-sche-${index}`
                                                            "
                          >
                          </select>
                        </td>
                        <td>
                          <input
                            :class="
                                                                `adj-qty row-qty-${index}`
                                                            "
                            type="text"
                            :max="
                                                                `${item.closing_stock}`
                                                            "
                            placeholder="Amount to be deducted"
                            @keyup="
                                                                quantityDeducted(
                                                                    index
                                                                )
                                                            "
                            :data-item_id="
                                                                `${item.id}`
                                                            "
                            :data-key="index"
                          />
                        </td>
                        <td
                          :class="
                                                            `row-balance-${index}`
                                                        "
                        >
                          {{ item.closing_stock }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button
                        type="submit"
                        class="btn btn-success btn-sm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import datetime from "vuejs-datetimepicker";

    export default {
        components: {
            datetime
        },
        data() {
            return {
                item: null,
                category_id: null,
                CategoryData: null,
                CategoriesItemData: null,
                portData: null,
                stockLedgerData: null,
                stockOpeningData: null,
                stockClosingData: null,
                vesselId: this.$store.state.vessal_id,
                adjustedItems: [],
                jobList: null,
                job_id: '',
                scheduleList: null
            };
        },
        mounted() {
            this.getCategoryData();
            this.getJobList();
        },
        methods: {
            print(el) {
                this.$htmlToPaper(el);
            },
            getJobList() {
                console.log("a");
                this.$http.get("/api/filter/job/" + this.vesselId).then(response => {
                    this.jobList = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getCategoryData() {
                this.$http.get("/api/categoryTree/" + this.vesselId).then(response => {
                    $(".category").html(response.data);
                }).catch(e => {
                    console.log(e);
                });
            },
            getCategoriesItemWithStock() {
                this.$http.post("/api/getCategoriesItemWithStock", {
                    category_id: this.category_id,
                    vessel_id: this.vesselId
                }).then(response => {
                    this.CategoriesItemData = response.data;
                }).catch(e => {
                    this.CategoriesItemData = [];
                    console.log(e);
                });
            },
            quantityDeducted(key) {
                var closing = $(".row-closing-" + key).text();
                var qty = $(".row-qty-" + key).val();
                if (parseFloat(qty) > parseFloat(closing)) {
                    $(".row-balance-" + key).text(0);
                    $(".row-qty-" + key).val(parseFloat(closing));
                    return false;
                }
                var balance = $(".row-balance-" + key).text();
                var newBalance = parseFloat(closing) - parseFloat(qty);
                $(".row-balance-" + key).text(newBalance);
            },
            getJobScheduleByJob(key) {
                var job_id = $(".row-job-" + key).val();

                this.$http
                    .post("/api/getJobScheduleByJob", {
                        job_id: job_id,
                        vessel_id: this.vesselId
                    })
                    .then(response => {
                        // console.log(response.data);
                        // this.scheduleList = response.data;
                        var html = '<option value="">Select JobSchedule</option>';
                        $.each(response.data, function (i, item) {
                            html += '<option>' + moment(item.start_date).format("YYYY-MM-DD") + '-' + moment(item.end_date).format("YYYY-MM-DD") + '</option>';
                        });
                        $(".row-job-sche-" + key).html(html);
                        //                     <option value=""
                        //     >Select Job
                        //     Schedule</option
                        // >
                        // <option
                        //     selected
                        //     v-for="(row,
                        //     index) in scheduleList"
                        //     :key="index"
                        //     :value="
                        //         `${row.id}`
                        //     "
                        //     >{{ row.start_date | moment("DD/MM/YYYY") }} - {{ row.end_date | moment("DD/MM/YYYY") }}</option
                        // >

                    })
                    .catch(e => {
                        this.CategoriesItemData = [];
                        console.log(e);
                    });
            },
            formSubmit: function (e) {
                e.preventDefault();

                if (!this.item) {
                    this.$snotify.error("Please select category & item");
                    return false;
                }
                this.itemId = this.item.id;

                var formData = {
                    item_id: this.itemId,
                    vessel_id: this.vesselId,
                    location_id: this.location_id
                };

                this.$http
                    .post(`/api/stock-ledger`, formData)
                    .then(response => {
                        if (response.data) {
                            this.stockOpeningData = response.data.openingStock;
                            this.stockClosingData = response.data.closingStock;
                            this.stockLedgerData = response.data.ledgerData;
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            this.$snotify.error("Request failed");
                        }
                    });
            },
            updateStock: function (e) {
                e.preventDefault();

                var that = this;
                $(".adj-qty").each(function () {
                    if ($(this).val() > 0 && $(this).val() != "") {

                        var key = $(this).data("key");
                        var job_id = $('.row-job-' + key).val();
                        var job_schedule_id = $('.row-job-sche-' + key).val();

                        var obj = {
                            qty: $(this).val(),
                            item_id: $(this).data("item_id"),
                            job_id: job_id,
                            job_schedule_id: job_schedule_id,
                        };

                        that.adjustedItems.push(obj);
                    }
                });

                var formData = {
                    items: this.adjustedItems,
                    vessel_id: this.vesselId
                };

                this.$http
                    .post(`/api/stock-adjust`, formData)
                    .then(response => {
                        if (response.data) {
                            this.$snotify.success("Stock adjusted successfully.");
                            window.setTimeout(function () {
                                location.reload()
                            }, 2000)
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            this.$snotify.error("Request failed");
                        }
                    });
            }
        }
    };
</script>
