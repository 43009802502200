<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_content">
          <div class="x_panel">
            <h2 class="float-left">Location Wise Stock Report </h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>

          </div>
          <div class="row">
            <div class="col-sm-12">

              <form role="form" action="#" @submit="formSubmit" class="searchForm">
                <div class="x_panel">
                  <div class="x_content">
                    <div class="row justify-content-center">

                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Select Location</label>
                          <select class="form-control location-tree" v-model="location_id">
                            <option value="" selected>Select location</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
                <PulseLoader :loading="loading"></PulseLoader>
              </div>
              <div class="card-box table-responsive" v-if="stockLedgerData && !loading">
                <button @click="print('printArea','Stock Ledger Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'location-wise-stock'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                <div class="clearfix"></div>
                <div id="printableArea">
                  <div class="mb-3 text-center">
                    <div class="font-weight-bold">Location Wise Stock Report</div>
                    <div>Location: <span class="location"></span></div>
                  </div>
                  <TableData :stockLedgerData="stockLedgerData" :noDataFound="noDataFound"/>
                </div>
              </div>

              <div id="printArea" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Stock Ledger Report</h3>
                  <h3>Location: <span class="location"></span></h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <TableData :stockLedgerData="stockLedgerData" :isPrinting="true" :noDataFound="noDataFound"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        components: {TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                noDataFound: false,
                locations: null,
                location_id: null,
                vessel_name: auth.getVesselName(),
                item: null,
                category_id: null,
                CategoryData: null,
                CategoriesItemData: null,
                appName: process.env.VUE_APP_NAME,
                portData: null,
                stockLedgerData: null,
                stockOpeningData: null,
                stockClosingData: null,
                vesselId: auth.getVesselId(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getCategoryData();
            this.getLocations()
        },
        methods: {
            prepareExportData() {
                return this.stockLedgerData.map((item, key) => ({
                    '#': key + 1,
                    'Item Name': item?.name,
                    'Impa Code': item?.inpa_code,
                    'Model': item?.model,
                    'Maker': item?.maker,
                    'Maker Part No': item?.maker_part_no,
                    'Drawing': item?.drawing,
                    'Other Ref': item?.other_ref,
                    'Opening Stock': item?.opening_stock ? item?.opening_stock : '',
                    'Stock In Total': item?.inTotal ? item?.inTotal : '',
                    'Stock Out Total': item?.OutTotal ? item?.OutTotal : '',
                    'Closing Balance': item?.closing_stock ? item?.closing_stock : '',
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'location-wise-stock.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getLocations() {
                this.$http.get('/api/locationTree/' + this.vesselId).then(response => {
                    this.locations = response.data;
                    $('.location-tree').html(this.locations)
                }).catch(e => {
                    console.log(e)
                });
            },
            getCategoryData() {
                this.$http.get('/api/categoryTree/' + this.vesselId).then(response => {
                    $('.category').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            getCategoriesItem() {
                this.$http.post('/api/item-filter', {
                    category_id: this.category_id,
                    vessel_id: this.vesselId
                }).then(response => {
                    this.CategoriesItemData = response.data
                }).catch(e => {
                    this.CategoriesItemData = [];
                    console.log(e)
                })
            },
            formSubmit: function (e) {
                if (e !== undefined) {
                    e.preventDefault()
                }
                this.noDataFound = false;
                var location = $('.location-tree option:selected').text()
                $('.location').html(location);

                let params = {
                    'location_id': this.location_id,
                    'vessel_id': this.vesselId,
                };

                if (this.location_id === null) {
                    this.$snotify.error('Please fill required field')
                    return false
                }

                this.loading = true;
                this.$http.get('/api/report/location-wise-stock', {params}).then((response) => {
                    this.loading = false;
                    this.stockLedgerData = response.data;
                    this.excelExport();
                    if (this.stockLedgerData.length < 1) this.noDataFound = true
                }).catch((error) => {
                    this.loading = false;
                    let response = error.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            }
        }
    }
</script>
