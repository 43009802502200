<!-- TableComponent.vue -->
<template>

    <table :class="getTableClasses" style="width:100%">
      <tbody>
      <tr>
        <th colspan="2" class="text-right">Date:{{reportInfo.create_date}}</th>
      </tr>
      <tr>
        <th width="20%">Name</th>
        <td>{{ reportInfo.name }}</td>
      </tr>
      <tr>
        <th width="20%">Job Name</th>
        <td>{{ reportInfo.job_name }}</td>
      </tr>
      <tr>
        <th width="20%">Department Name</th>
        <td>{{ reportInfo.department_name }}</td>
      </tr>
      <tr>
        <th width="20%">Main Component Name</th>
        <td>{{ reportInfo.main_component_name }}</td>
      </tr>
      <tr>
        <th width="20%">Brief Details of Incident</th>
        <td><span v-html="reportInfo.details_of_incident"></span></td>
      </tr>
      <tr>
        <th width="20%">Remarks</th>
        <td>{{ reportInfo.remarks }}</td>
      </tr>
      <tr>
        <th>Incident Report</th>
        <td>
          <span v-if="reportInfo.incident_report != 0 && reportInfo.incident_report !=''">
                <img :src="reportInfo.incident_report" height="50" width="50">
           </span>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  props: {
    reportInfo : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
