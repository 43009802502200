<template>
  <div>
    <table :class="getTableClasses" style="width: 100%" >
      <thead>
      <tr>
        <th>Requisition no </th>
        <th>Quotation No</th>
        <th>Supplier name</th>
        <th>Supplier email</th>
        <th>Supplier contact</th>
        <th class="text-center">Currency</th>
        <th class="text-right">Original Price</th>
        <th>Currency Rate</th>
        <th class="text-right">Converted Value</th>
      </tr>
      </thead>
      <tbody class="comp-row">
      <tr v-for="(item , index) in compare" :key="index">
        <td>{{item?.requisition?.requisition_no}}</td>
        <td>{{item?.quotation?.quotation_no}}</td>
        <td>{{item.supplier?.name}}</td>
        <td>{{item.supplier?.email}}</td>
        <td>{{item.supplier?.contact_no}}</td>
        <td class="text-center">{{item?.currency?.code}}</td>
        <td class="text-center">{{item?.grand_total}}</td>
        <td>
          <input v-if="!isPrinting" @keyup="setRate(index)" value="" type="number" step="any" :data-base="`${item?.grand_total}`" :class="`form-control rate-${index}`" placeholder="Enter currency rate"
                 autocomplete="off">
          <span v-if="isPrinting" :class="`currency_rate_${index}`"></span>
        </td>
        <td :class="`text-right conv-value grand-total-${index}`"></td>
      </tr>
      </tbody>
    </table>



  </div>
</template>



<script>
export default {
  props: {
    compare: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    setRate: {
      type: Function,
      required: false
    }

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>
<style scoped>

</style>
