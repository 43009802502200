<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead :set="sl = 1">
    <tr>
      <th>#</th>
      <th>Status</th>
      <th>SQ No</th>
      <th>Requisition No</th>
      <th>Quotation No</th>
      <th>Vendor Name</th>
      <th>Vendor Email</th>
      <th>Vendor Contact</th>
      <th>Submit Date</th>
      <th>Super. Approval</th>
<!--      <th>PM Approval</th>-->
      <th>TM Approval</th>
      <th>Subtotal</th>
      <th>Discount</th>
      <th>Grand Total</th>
      <th v-if="!isPrinting && (isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_VIEW) || isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_COMPARE) ||  isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_PO_CREATE) )">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(quotation , index) in quotationData" :key="quotation.id">
      <td>{{sl++}}</td>
      <td>{{quotation?.status}}</td>
      <td>{{quotation?.no}}</td>
      <td>{{quotation?.requisition?.requisition_no}}</td>
      <td>{{quotation?.quotation?.quotation_no}}</td>
      <td>{{quotation?.supplier?.name}}</td>
      <td>{{quotation?.supplier?.email}}</td>
      <td>{{quotation?.supplier?.contact_no}}</td>
      <td>{{quotation?.date}}</td>
      <td>
        <span v-if="quotation.superintendent_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.SUPERINTENDING_APPROVAL)">
           <button type="button" title="Approve" class="btn btn-warning btn-sm"
                @click="approveRequest('SupplierQuotation', quotation.id, 'Approved', 'superintendent_approval')"> {{quotation.superintendent_approval}}</button>
          </span>
        <span v-else>{{quotation?.superintendent_approval}}</span>
      </td>
<!--      <td>-->
<!--        <span v-if="quotation.purchase_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.PM_APPROVAL)">-->
<!--             <button type="button" title="Approve" class="btn btn-warning btn-sm"-->
<!--                  @click="approveRequest('SupplierQuotation', quotation.id, 'Approved', 'purchase_manager_approval')"> {{quotation.purchase_manager_approval}}</button>-->
<!--         </span>-->
<!--        <span v-else>{{quotation.purchase_manager_approval}} </span>-->
<!--      </td>-->
      <td> <span v-if="quotation.technical_manager_approval == 'Pending' && !isPrinting &&   isPermissionExist(PERMISSIONS.TM_APPROVAL)">
          <button type="button" title="Approve" class="btn btn-warning btn-sm"
              @click="approveRequest('SupplierQuotation', quotation.id, 'Approved', 'technical_manager_approval')"> {{quotation.technical_manager_approval}}</button>
            </span>
        <span v-else> {{quotation?.technical_manager_approval}}  </span>
      </td>
      <td>{{quotation?.currency?.code}} {{quotation?.sub_total}}</td>
      <td>{{quotation?.currency?.code}} {{quotation?.discount_amount}}</td>
      <td>{{quotation?.currency?.code}} {{quotation?.grand_total}}</td>
      <td v-if="!isPrinting && (isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_VIEW) || isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_COMPARE) ||  isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_PO_CREATE) )">
        <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_VIEW)" @click="show(quotation.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i> View</button>
        <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_COMPARE)" @click="addToCompare(quotation)" class="btn btn-secondary btn-sm" title="Add to Compare"><i class="fa fa-compass"></i> Compare  </button>
        <span v-if="quotation?.superintendent_approval == 'Approved' &&    quotation?.technical_manager_approval == 'Approved' &&  isPermissionExist(PERMISSIONS.SUPPLIER_QUOTATION_PO_CREATE)">
             <button :disabled="quotation?.purchase_order_exist" type="button" @click="create(quotation.id)" class="btn btn-success btn-sm" title="Approve & Create PO"><i class="fa fa-plus"></i>
             {{quotation?.purchase_order_exist ? 'PO Already Created ' : 'Create PO'}}
              </button>
              </span>

        <!--                          <span v-if="quotation.superintendent_approval == 'Approved' && quotation.purchase_order_exist == true && quotation.purchase_manager_approval == 'Approved' && quotation.technical_manager_approval == 'Approved'">-->
        <!--                               <button type="button" @click="create(quotation.id)" class="btn btn-success btn-sm" title="Approve & Edit PO"><i class="fa fa-pencil"></i> Edit PO</button>-->
        <!--                           </span>-->
      </td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    quotationData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

    showBudget: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    create: {
      type: Function,
      required: false
    },
    approveRequest: {
      type: Function,
      required: false
    },
    addToCompare: {
      type: Function,
      required: false
    }


  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
