<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card mt-3 tab-card">
            <div class="card-header tab-card-header">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center">Job Name: {{this?.jobName }}</h2>
                  <h2 class="text-center">Component: {{this?.componentName }}</h2>
                </div>
                <div class="col-md-6">
                  <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                    <li class="nav-item" style="margin-right: 3px;">
                      <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true" style="margin-top: 3px;background-color: wheat;">Basic Info</a>
                    </li>
                    <li class="nav-item d-none">
                      <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false" style="margin-top: 3px;background-color: wheat;">Report Form</a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <div class="pull-right">
                    <router-link class="btn btn-sm btn-info" to="/dry-dock">Drydock</router-link>
                    <router-link class="btn btn-sm btn-info" to="/requisition-items">Requisition</router-link>
                    <button v-if="editMode && !item_mode" @click="changeMode" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                <div class="row">
                  <div class="col-md-6 offset-md-3">
                    <div v-if="!item_mode" class="row">

                      <div class="col-md-12">
                        <div class="x_panel">
                          <div class="x_title">Completed Date :
                            <date-picker type="date" v-model="form.date" name="date" valueType="format"></date-picker>
                            <div class="clearfix"></div>
                          </div>
                          <div class="x_content">
                            <br/>
                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Items & Quantity:</label>
                              <div class="col-md-9 col-sm-9">
                                <div class="row" v-for="(input,k) in form.sparse_consumed" :key="k">
                                  <div class="col-md-5">
                                    <div class="form-group">
                                      <label>Spares consumed</label>
                                      <select class="form-control" v-model="input.spares_consumed">
                                        <option value>Select Item</option>
                                        <option v-for=" item in items" :key="item.id" :value="item.id">{{item.name}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="form-group">
                                      <label>Quantity</label>
                                      <input type="number" class="form-control" v-model="input.spares_qty"/>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <button type="button" class="btn btn-danger btn-sm mt-4" @click="remove(k)" v-show="k || ( !k && form.sparse_consumed.length > 1)"><i class="fa fa-minus-square"></i></button>
                                    <button type="button" class="btn btn-success btn-sm mt-4" @click="add(k)" v-show="k == form.sparse_consumed.length-1"><i class="fa fa-plus-square"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Maintenance Type</label>
                              <div class="col-md-9 col-sm-9">
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="form-check">
                                      <input class="form-check-input" type="radio" v-model="form.maintenance_type" name="plannedRadio" id="plannedRadios" value="planned">
                                      <label style="margin-top: 3px" class="form-check-label" for="plannedRadios">Planned</label>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="form-check">
                                      <input class="form-check-input" type="radio" v-model="form.maintenance_type" name="breakdownRadio" id="breakdownRadios" value="breakdown">
                                      <label style="margin-top: 3px" class="form-check-label" for="breakdownRadios">Breakdown</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row" v-if="jobType=='Running Hours'">
                              <label class="control-label col-md-3 col-sm-3">Running Maintenance Hour</label>
                              <div class="col-md-9 col-sm-9">
                                <input type="text" class="form-control" v-model="form.running_hours_maintanance"/>
                              </div>
                            </div>
                            <!--Yes Section -->
                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Done By</label>
                              <div class="col-md-9 col-sm-9">
                                <select class="form-control" v-model="form.done_by">
                                  <option value>Select User</option>
                                  <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Remarks</label>
                              <div class="col-md-9 col-sm-9">
                                <textarea class="form-control" v-model="form.remarks" rows="3" placeholder="Remarks"></textarea>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Image</label>
                              <div class="col-md-9 col-sm-9">
                                <input @change="covertFiles" type="file" class="form-control" placeholder="Remarks" accept=".png, .jpg, .jpeg"/>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="control-label col-md-3 col-sm-3">Document</label>
                              <div class="col-md-9 col-sm-9">
                                <input @change="covertDocument" type="file" class="form-control" placeholder="Remarks" accept='.pdf,.doc,.docx,.ppt,.csv,.xls,.xlsx'/>
                              </div>
                            </div>
                            <div class="ln_solid"></div>
                            <div class="form-group">
                              <div class="col-md-9 col-sm-9 offset-md-3">
                                <button type="submit" :disabled="form.busy" class="btn btn-success" @click="editMode ? update() : store()">Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7"></div>
                </div>
                <div v-if="item_mode" class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead class="text-center">
                        <tr>
                          <th rowspan="2"># ID</th>
                          <th rowspan="2">Date completed</th>
                          <th colspan="3">Spares Consumed Quantity</th>
                          <th rowspan="2">Document</th>
                          <th rowspan="2">Image</th>
                          <th rowspan="2">Remarks</th>
                          <th rowspan="2">Done By</th>
                          <th rowspan="2">Status</th>
                          <th rowspan="2">Verified By</th>
                          <th rowspan="2">Verified By Office</th>
                          <th rowspan="2">Action</th>
                        </tr>
                        <tr>
                          <th>Spares Consumed</th>
                          <th>Quantity</th>
                          <th>ROB</th>
                        </tr>
                        </thead>

                        <tbody class="text-center">
                        <tr>
                          <td :rowspan='calculateRowspan()'>1</td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <p>Date : {{form.date | moment('DD-MM-YYYY')}}</p>
                            </div>
                          </td>

                          <td class="text-center"> {{ getItemName(form?.sparse_consumed[0]?.spares_consumed)}}</td>
                          <td class="text-center">{{form?.sparse_consumed[0]?.spares_qty}}</td>
                          <td class="text-center"> {{ getItemRob(form?.sparse_consumed[0]?.spares_consumed)}}</td>


                          <!-- <tr  v-for="(input,k) in form.sparse_consumed" :key="k" style="padding: 0">
                                                      <td style="min-width: 166px" >{{input.spares_consumed}}</td>
                                                      <td style="min-width: 166px">{{input.spares_qty}}</td>
                          </tr>-->
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10" v-if="form.document != null">
                              <a :href="form.document" target="_blank"> <i class="fa fa-file-text"></i> </a>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10" v-if="form.image != null">
                              <a :href="form.image" target="_blank"> <i class="fa   fa-picture-o"></i> </a>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <p>{{form.remarks}}</p>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <p>
                                {{ this.jobInfo.job_schedules[this.Schedule].job_done_report?.done_by_rank?.name}}
                              </p>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <span v-if="form.status=='Peanding'" class="badge badge-warning">Pending</span>
                              <span v-if="form.status=='Accept'" class="badge badge-success">Accept</span>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <!-- <p>{{form.verified_by}}</p> -->
                              <div v-if="form.chief_status=='pending'  && isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE_APPROVAL)">
                                <button class="btn btn-sm btn-success" @click="AapprovedBychiefEngineer">Pending Approval</button>
                              </div>
                              <span v-else class="badge badge-success">
                                   {{ this.jobInfo.job_schedules[this.Schedule].job_done_report?.verified_by_rank?.name}}
                                </span>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <!-- <p>{{form.verified_by}}</p> -->
                              <div v-if="form.status=='Peanding' && form.chief_status == 'approve' && isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE_OFFICE_APPROVAL)">
                                <button class="btn btn-sm btn-success" @click="ApprovedByOffice">Pending Approval</button>
                              </div>
                              <span v-else >
                                   <span v-if="form.status=='Peanding'" class="badge badge-warning">Pending</span>
                                   <span v-else class="badge badge-success">Approve</span>
                                </span>
                            </div>
                          </td>
                          <td :rowspan='calculateRowspan()'>
                            <div class="col-10">
                              <button type="button" v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE_UPDATE) &&  form.status=='Peanding'" @click="edit(form)" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="(input,k) in form.sparse_consumed" v-show="k != 0" :key="k" style="padding: 0">

                          <td style="min-width: 166px"> {{getItemName(input?.spares_consumed)}}</td>
                          <td style="min-width: 166px">{{input.spares_qty}}</td>
                          <td style="min-width: 166px"> {{getItemRob(input?.spares_consumed)}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">

                <div class="table-responsive" v-if="!item_mode">
                  <table class="table table-bordered">
                    <thead>
                    <tr align="center">
                      <th rowspan="2">Date</th>
                      <th rowspan="2">Place Of Inspection</th>
                      <th rowspan="2">M/E Running HRS .</th>
                      <th rowspan="2">Specific CYL. Oil Feed Rate Setting</th>
                      <th colspan="6">CL Groove Depth (UNIT: mm)</th>
                      <th rowspan="2">Remarks</th>
                      <th rowspan="2"></th>
                    </tr>
                    <tr>
                      <th>NO.1</th>
                      <th>NO.2</th>
                      <th>NO.3</th>
                      <th>NO.4</th>
                      <th>NO.5</th>
                      <th>NO.6</th>
                    </tr>
                    <tr style="padding:15px" v-for="(input,k) in form.job_done_report" :key="k" class="input-format">
                      <td>
                        <date-picker type="date" v-model="input.date" valueType="format"></date-picker>
                      </td>
                      <td><input v-model="input.place_of_inspection" type="text" class="form-control" placeholder="Place Of Inspection "/></td>
                      <td><input v-model="input.m_e_running_hours" type="text" class="form-control" placeholder="M/E Running HRS "/></td>
                      <td><input v-model="input.feet_rate_setting" type="text" class="form-control" placeholder="Specific Cyl. Oil Feed Rate Setting "/></td>
                      <td><input v-model="input.cl_groove_depth_no_1" type="number" class="form-control"/></td>
                      <td><input v-model="input.cl_groove_depth_no_2" type="number" class="form-control"/></td>
                      <td><input v-model="input.cl_groove_depth_no_3" type="number" class="form-control"/></td>
                      <td><input v-model="input.cl_groove_depth_no_4" type="number" class="form-control"/></td>
                      <td><input v-model="input.cl_groove_depth_no_5" type="number" class="form-control"/></td>
                      <td><input v-model="input.cl_groove_depth_no_6" type="number" class="form-control"/></td>
                      <td><input v-model="input.remarks" type="text" class="form-control" placeholder="Remarks "/></td>
                      <td>
                        <button type="button" class="btn btn-danger btn-sm" @click="jdremove(k)" v-show="k || ( !k && inputs.length > 1)"><i class="fa fa-minus-square"></i></button>
                        <button type="button" class="btn btn-success btn-sm ml-1" @click="jdadd(k)" v-show="k == inputs.length-1"><i class="fa fa-plus-square"></i></button>
                      </td>
                    </tr>
                    </thead>
                  </table>
                </div>
                <div class="table-responsive" v-if="item_mode">
                  <table class="table table-bordered">
                    <thead>
                    <tr align="center">
                      <th rowspan="2">Date</th>
                      <th rowspan="2">Place Of Inspection</th>
                      <th rowspan="2">M/E Running HRS .</th>
                      <th rowspan="2">Specific CYL. Oil Feed Rate Setting</th>
                      <th colspan="6">CL Groove Depth (UNIT: mm)</th>
                      <th rowspan="2">Remarks</th>
                    </tr>
                    <tr>
                      <th>NO.1</th>
                      <th>NO.2</th>
                      <th>NO.3</th>
                      <th>NO.4</th>
                      <th>NO.5</th>
                      <th>NO.6</th>
                    </tr>
                    <tr style="padding:15px" v-for="(input,k) in jobDoneReportList" :key="k" class="input-format">
                      <td>{{input.date}}</td>
                      <td>{{input.place_of_inspection}}</td>
                      <td>{{input.m_e_running_hours}}</td>
                      <td>{{input.feet_rate_setting}}</td>
                      <td>{{input.cl_groove_depth_no_1}}</td>
                      <td>{{input.cl_groove_depth_no_2}}</td>
                      <td>{{input.cl_groove_depth_no_3}}</td>
                      <td>{{input.cl_groove_depth_no_4}}</td>
                      <td>{{input.cl_groove_depth_no_5}}</td>
                      <td>{{input.cl_groove_depth_no_6}}</td>
                      <td>{{input.remarks}}</td>

                    </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../auth'
    import permissionsMixin from "@/mixin/permissionsMixin";

    export default {
        mixins: [permissionsMixin],
        name: 'JobDone',
        components: {DatePicker},
        props: {
            jobInfo: Object,
            Schedule: '',
            jobName: '',
            componentName: '',
            mode: Boolean,
        },
        data() {
            return {
                item_mode: this.mode,
                editMode: false,
                roles: [],
                vessel_id: auth.getVesselId(),
                permissions: auth.getPermissionData(),
                jobType: this.jobInfo.counter_type,
                document: '',
                form: new Form({
                    id: '',
                    date: '',
                    sparse_consumed: [{
                        spares_consumed: '',
                        spares_qty: ''
                    }],
                    done_by: '',
                    verified_by: '',
                    image: '',
                    document: '',
                    job_done_report: [
                        {
                            date: '',
                            place_of_inspection: '',
                            m_e_running_hours: '',
                            feet_rate_setting: '',
                            cl_groove_depth_no_1: '',
                            cl_groove_depth_no_2: '',
                            cl_groove_depth_no_3: '',
                            cl_groove_depth_no_4: '',
                            cl_groove_depth_no_5: '',
                            cl_groove_depth_no_6: '',
                            remarks: '',
                            job_done_id: '',
                            scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
                        },
                    ],
                    remarks: '',
                    running_hours_maintanance: '',
                    maintenance_type: '',
                    status: 'Peanding',
                    chief_status: 'pending',
                    scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
                }),

                jobDoneReportList: '',

                form1: new Form({
                    id: '',
                    date: '',
                    sparse_consumed: [{
                        spares_consumed: '',
                        spares_qty: ''
                    }],
                    reshedule: '',
                    reason_deffer: '',
                    image: '',
                    document: '',
                    done_by: '',
                    verified_by: '',
                    remarks: '',
                    new_schedule_date: '',
                    vessel_id: auth.getVesselId(),
                    scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
                }),
                items: null,
                user_type: auth.getUserType(),
                inputs: [
                    {
                        date: '',
                        place_of_inspection: '',
                        m_e_running_hours: '',
                        feet_rate_setting: '',
                        cl_groove_depth_no_1: '',
                        cl_groove_depth_no_2: '',
                        cl_groove_depth_no_3: '',
                        cl_groove_depth_no_4: '',
                        cl_groove_depth_no_5: '',
                        cl_groove_depth_no_6: '',
                        remarks: '',
                        job_done_id: '',
                        scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
                    },
                ],
            }
        },
        mounted() {
            if (this.item_mode == true) {
                this.jobDoneView()
            }
            this.geItems();
            this.allRole();
        },
        methods: {
            allRole() {
                this.$http.get("/api/filter/get-active-role").then(response => {
                    this.roles = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            calculateRowspan() {
                return this.form?.sparse_consumed.length;
            },
            geItems() {
                let params = {
                    vessel_id: this.vessel_id
                };
                this.$http.get('/api/get-items', {params}).then((response) => {
                    this.items = response.data.success
                }).catch((e) => {
                    console.log("e", e)
                })
            },
            getItemName(itemId) {
                if (this.items != null && this.items.length > 0) {
                    let item = this.items?.find(item => item.id == itemId)?.name;
                    return item;
                }
                return "";
            },
            getItemRob(itemId) {
                if (this.items != null && this.items.length > 0) {
                    let item = this.items?.find(item => item.id == itemId)?.rob;
                    return item;
                }
                return "";
            },
            showPermissionModal() {
                this.end_date = this.jobInfo.job_schedules[this.Schedule].end_date;
                this.start_date = this.jobInfo.job_schedules[this.Schedule].start_date;
                $('#roleModal').modal('show');
            },
            edit(item) {
                this.editMode = true;
                this.item_mode = false;
            },
            store() {
                this.form.busy = true;
                this.$http.post('/api/job-done-report', this.form).then((response) => {
                    this.$snotify.success('your job done report sent successfully.');
                    this.$emit('enableListMode');
                    this.form.busy = false
                }).catch((e) => {
                    this.form.busy = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (e.response.data?.errors) {
                            const errors = e.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        this.$snotify.error('your job done report can not send.');
                    }
                });
            },
            update() {
                this.$http.post('/api/job-done-report/update', this.form).then((response) => {
                    this.$snotify.success('your job done report updated successfully.');
                }).catch((e) => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (e.response.data?.errors) {
                            const errors = e.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        this.$snotify.error(e.response.data?.errors);
                    }
                });
            },
            add(index) {
                var object = {
                    spares_consumed: '',
                    spares_qty: ''
                };
                this.form.sparse_consumed.push(object)
            },
            remove(index) {
                this.form.sparse_consumed.splice(index, 1)
            },
            jobDoneView() {
              console.log("job done view ")
                var info = this.jobInfo.job_schedules[this.Schedule].job_done_report
                var jobDoneReportList = this.jobInfo.job_schedules[this.Schedule].job_done_report.job_done_reports;
                this.jobDoneReportList = jobDoneReportList;
                this.form.fill(info);
                this.form1.fill(info);
              console.log("job done view ",this.form)

                var sparse_consumed = JSON.parse(info.sparse_consumed);
                this.form.sparse_consumed = sparse_consumed;
                this.form1.sparse_consumed = sparse_consumed;
                this.form1.vessel_id = this.vessel_id;
                this.form.job_done_report = jobDoneReportList;
            },
          ApprovedByOffice() {
                // this.form1.busy = true;
                this.$http.post('/api/job-done-permission', this.form1).then((response) => {
                    this.$snotify.success('your permission successfully completed.');
                    this.$emit('enableListMode');
                    $('#roleModal').modal('hide');
                }).catch((e) => {
                    console.log("errors", e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error);
                    }
                })
            },
          AapprovedBychiefEngineer() {
            // this.form1.busy = true;
            this.$http.post('/api/job-done-chief-engineer-permission', this.form1).then((response) => {
              this.$snotify.success('your permission successfully completed.');
              this.$emit('enableListMode');
              $('#roleModal').modal('hide');
            }).catch((e) => {
              console.log("errors", e);
              let response = e.response;
              if (response.status === 403) {
                this.$snotify.warning(response?.data?.error)
              } else {
                this.$snotify.error(response?.data?.error);
              }
            })
          },
            //add remove for job done extra report
            jdadd(index) {
                var object = {
                    date: '',
                    place_of_inspection: '',
                    m_e_running_hours: '',
                    feet_rate_setting: '',
                    cl_groove_depth_no_1: '',
                    cl_groove_depth_no_2: '',
                    cl_groove_depth_no_3: '',
                    cl_groove_depth_no_4: '',
                    cl_groove_depth_no_5: '',
                    cl_groove_depth_no_6: '',
                    remarks: '',
                    job_done_id: '',
                    scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
                };
                this.form.job_done_report.push(object)
            },
            changeMode() {
                return this.item_mode = !this.item_mode;
            },
            jdremove(index) {
                this.form.job_done_report.splice(index, 1)
            },
            covertFiles(event) {
                let file = event.target.files[0];
                this.form.image = file;
            },
            covertDocument(event) {
                let file = event.target.files[0];
                this.form.document = file;
            },
        },
    }
</script>

<style scoped>
  .test1 {
    padding: 0.4rem !important;
  }

  .input-format input {
    margin-top: 5px;
  }

  .mx-datepicker {
    margin-top: 5px !important;
  }
</style>
