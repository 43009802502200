<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Service Req Return Item</h2>
          <div>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            <button @click="print('printArea','Breakdown History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
            <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'service-req-return'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
          </div>
        </div>

        <div class="">
          <div class="x_content ">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div v-if="loading" class="col-md-12 text-center" style="margin-top:25%">
                      <PulseLoader :loading="loading"></PulseLoader>
                    </div>
                    <div class="card-box table-responsive" v-if="listData && !loading ">
                      <TableData :listData="listData?.data"/>
                      <Pagination :pagination="pagination" :offset="pagination.offset" @paginate="getAllData"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Service Req Return Item</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :listData="printListData" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import auth from '../../../auth'
    import Pagination from "@/components/Pagination.vue";
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'service-req-return-report',
        components: {
            Pagination,
            TableData,
            PulseLoader
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                loading: false,
                color: '#1ABB9C',
                size: '10px',
                listData: [],
                printListData: [],
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1,
                    offset: 5
                },
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getAllData();
            this.getAllPrintData();
        },

        methods: {
            prepareExportData() {
                return this.printListData.map((item, key) => ({
                    '#': key + 1,
                    'Purchase No': item?.purchase?.po_no,
                    'Item Name': item?.item?.name,
                    'Order Qty': item?.order_qty,
                    'Qty': item?.qty,
                    'Receive Date': item?.date,
                    'Expire Date': item?.expire_date,
                    'Port': item?.port?.name,
                    'Country': item?.country?.name,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'service-req-return.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getAllPrintData() {
                this.printListData = [];
                let print_enable = true;
                this.$http.get(`api/filter/service-req-return-item/${this.vessel_id}?print_enable=${print_enable}`).then((response) => {
                    this.printListData = response.data.success;
                    this.excelExport();
                }).catch((e) => {
                    console.log(e)
                })
            },
            getAllData() {
                this.loading = true;
                this.listData = [];
                this.$http.get(`api/filter/service-req-return-item/${this.vessel_id}?page=${this.pagination.current_page}`).then((response) => {
                    this.listData = response.data.success;
                    let per_page = response.data.per_page;
                    let total = response.data.total;
                    let calculation = total / per_page;
                    let offset = 5;
                    if (parseInt(calculation) < this.pagination.offset) {
                        offset = parseInt(calculation) + 1;
                    }
                    this.pagination = response.data.success;
                    this.pagination.offset = offset;
                    this.loading = false;
                }).catch((e) => {
                    this.loading = false;
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            },
            goBack() {
                this.$router.go(-1);
            }


        }
    }
</script>

<style scoped>


</style>
