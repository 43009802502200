<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left"> Change Password</h2>
              <ul class="nav navbar-right panel_toolbox align-right">

                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card">
                  <div class="card-body">
                    <form @submit.prevent="changePasswordStore()" @keydown="changePasswordForm.onKeydown($event)">
                      <div class="modal-body">
                        <!-- <alert-error :form="form"></alert-error> -->
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>Old Password </label>
                              <input autocomplete="off" v-model="changePasswordForm.old_password" type="password" name="old_password" class="form-control" :class="{ 'is-invalid': changePasswordForm.errors.has('old_password') }">
                              <has-error :form="changePasswordForm" field="password"></has-error>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label>User Password </label>
                              <input autocomplete="off" v-model="changePasswordForm.password" type="password" name="password" class="form-control" :class="{ 'is-invalid': changePasswordForm.errors.has('password') }">
                              <has-error :form="changePasswordForm" field="password"></has-error>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label>Confirm Password </label>
                              <input autocomplete="off" v-model="changePasswordForm.confirm_password" type="password" name="confirm_password" class="form-control" :class="{ 'is-invalid': changePasswordForm.errors.has('confirm_password') }">
                              <has-error :form="changePasswordForm" field="confirm_password"></has-error>
                            </div>
                          </div>


                        </div>
                      </div>

                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button :disabled="changePasswordForm.busy" type="submit" class="btn btn-info">Save</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
  <!-- Modal -->

</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import auth from "@/auth";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        mixins: [goBackButtonMixin],
        name: "ChangePassword",
        data() {
            return {
                loading: false,
                changePasswordForm: new Form({
                    old_password: '',
                    password: '',
                    confirm_password: '',
                }),
            };
        },
        mounted() {

        },
        methods: {
            changePasswordStore() {
                this.changePasswordForm.busy = true;
                this.changePasswordForm.post("/api/change-login-user-password").then(response => {
                    if (this.changePasswordForm.successful) {
                        this.$snotify.success("Change Password Successfully");
                    } else {
                        this.$snotify.error(
                            "Something went wrong try again later.",
                            "Error"
                        );
                    }
                    this.changePasswordForm.reset()
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                    if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                    this.changePasswordForm.reset()
                });
            },


        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

  .des {
    height: 36% !important;
  }
</style>
