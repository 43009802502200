<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Invoice No</th>
      <th>Purchase No</th>
      <th>Created By</th>
      <th>Paid Amount</th>
      <th>Date Created</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(row , index) in reportData" :key="index">
      <td>{{++index}}</td>
      <td>{{row?.invoice_no}}</td>
      <td>
        {{row?.purchase.po_no}}
      </td>
      <td>{{row?.user?.username}}</td>
      <td>${{row?.amount}}</td>
      <td>{{row?.date}}</td>
      <td v-if="!isPrinting">
        <button type="button" target="_blank" @click="invoiceView(row)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
      </td>
    </tr>
    <tr v-if="noDataFound">
      <td colspan="7">No data found</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Array,
      required: true
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    invoiceView: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
