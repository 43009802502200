<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left"> Location </h2>
          <ul class="nav navbar-right panel_toolbox align-right">
            <li>
              <router-link to="/location-tree">
                <button class="btn btn-info btn-sm">View Tree <i class="fa fa-eye"></i></button>
              </router-link>
            </li>
            <li>
              <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i> </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <h5 class="card-header">Location List</h5>
            <div class="card-body">
              <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Location Name</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(location , index) in locations" :key="location.id">
                  <td>{{index+1}}</td>
                  <td>{{location.name}}</td>
                  <td>
                    <button title="Edit Location" type="button" @click="edit(location)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                    <button title="Delete Location" type="button" @click="destroy(location)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Modal -->
          <div class="card">
            <h5 class="card-header" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Location </h5>
            <div class="card-body">
              <div class="modal-dialog" role="document">
                <div class="modal-content modal-content2">
                  <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                    <div class="modal-body">
                      <alert-error :form="form"></alert-error>
                      <div class="form-group">
                        <label>Location Name</label>
                        <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name"></has-error>
                      </div>
                      <div class="form-group">
                        <label> Parent Name : </label>
                        <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import auth from '../../../auth'

  export default {
    name: 'LocationTree',
    components: { Select2 },
    data () {
      return {
        editMode: false,
        query: '',
        queryFiled: 'name',
        myOptions: [],
        locations: [],
        form: new Form({
          id: '',
          name: '',
          parent_id: '',
          vessel_id: auth.getVesselId()
        }),
        pagination: {
          current_page: 1
        }
      }
    },
    mounted () {
      this.getData()
      this.create()
    },
    methods: {
      getData () {
        this.$http.get('/api/filter/location/create/' + this.form.vessel_id).then(response => {
          this.locations = response.data.success
          setTimeout(() => $('#datatables').DataTable(), 1000)
        }).catch(e => {
          let response = e.response;
          if (response.status === 403) {
            this.$snotify.warning(response?.data?.error)
          }else{
            this.$snotify.error(response?.data?.error)
          }
        })
      },
      async create () {
        this.editMode = false
        this.form.reset()
        this.form.clear()
        this.myOptions = []
        var itemList = await this.$http.get('/api/filter/location/create/' + this.form.vessel_id)
        this.results = itemList.data.success

        var newObj = {
          id: '',
          text: 'No Selected'
        }
        this.myOptions.push(newObj)
        this.results.filter(item => {
          var newObj = {
            id: item.id,
            text: item.name
          }
          this.myOptions.push(newObj)
        })
      },
      store () {
        this.form.busy = true
        this.form.post('/api/location', this.form).then(response => {
          this.getData()
          $('#roleModal').modal('hide')
          if (this.form.successful) {
            this.create()
            this.$snotify.success('Location Successfully Saved')
          } else {
            this.$snotify.error('Something went wrong try again later.')
          }
        }).catch(e => {

        })
      },

      edit (locations) {
        this.editMode = true
        this.form.reset()
        this.form.clear()
        this.form.fill(locations)
        $('#roleModal').modal('show')
      },
      update () {
        this.form.busy = true;
          this.form._method = 'put';
        this.form.post('/api/location/' + this.form.id).then(response => {
          this.getData()
          $('#roleModal').modal('hide')
          if (this.form.successful) {
            this.create()
            this.$snotify.success('Location Successfully Updated')
          } else {
            this.$snotify.error(
              'Something went wrong try again later.',
              'Error'
            )
          }
        }).catch(e => {

        })
      },
      destroy (category) {
      this.$dialog.confirm('Do You Want to delete This ?').then(() => {
          this.$http.delete('/api/location/' + category.id).then(response => {
            this.getData()
            this.create()
            this.$snotify.success(
              'Location  Successfully Deleted'
            )
          }).catch(error => {
            var err = error.response.data.error
            this.$snotify.error(err
            )
          })
        }).catch(function () {

        })
      },
      myChangeEvent (val) {
      },
      mySelectEvent ({ id, text }) {
        this.form.parent_id = id
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>

<style scoped>

</style>
