<template>
  <div>

    <table :class="getTableClasses"  style="width: 100%"  >
      <thead>
      <tr>
        <th><strong>Title: </strong>{{requisitionItem.title}}</th>
        <th><strong>Requisition No: </strong>{{requisitionItem.requisition_no}}</th>
        <th><strong>Status: </strong>{{requisitionItem.status}}</th>
        <th><strong>Date: </strong>{{requisitionItem.date}}</th>
      </tr>
      <tr>
        <th><strong>Remarks: </strong>{{requisitionItem.remarks}}</th>
        <th><strong>Priority: </strong>{{requisitionItem.priority}}</th>
        <th><strong>Address: </strong>{{requisitionItem.address}}</th>
        <th><strong>Required Date: </strong>{{requisitionItem.supply_date}}</th>
      </tr>
      <tr>
        <th><strong>Vessel ETA: </strong>{{requisitionItem.vessel_eta}}</th>
        <th><strong>Vessel ETD: </strong>{{requisitionItem.vessel_etd}}</th>
        <th><strong>Country: </strong>
          <span v-if="requisitionItem.country"> {{requisitionItem.country.name}}  </span>
        </th>
        <th><strong>Port: </strong>
          <span v-if="requisitionItem.port"> {{requisitionItem.port.name}} </span>
        </th>
      </tr>
      </thead>
    </table>
    <hr>

    <table :class="getTableClasses" style="width: 100%" >
      <thead>
      <tr>
        <th>Status</th>
        <th>Item name</th>
        <th>Impa code</th>
        <th>Maker Part no</th>
        <th>Maker</th>
        <th>Model</th>
        <th>Details</th>
        <th>Opening stock</th>
        <th>Qty</th>
        <th>Unit</th>
        <th>Price</th>
        <th>Cost Price</th>
        <th>Budget</th>
        <th>Budget Left</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item , index) in requisitionItem.requisition_item" :key="index">
        <td>{{item.status}}</td>
        <td>{{item.item.name}}</td>
        <td>{{item.item.inpa_code}}</td>
        <td>{{item.item.maker_part_no}}</td>
        <td>{{item.item.maker}}</td>
        <td>{{item.item.model}}</td>
        <td>{{item.item.description}}</td>
        <td>{{item.item.opening_stock}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.item.unit.name}}</td>
        <td>${{item.item.price}}</td>
        <td>${{item.item.price*item.qty}}</td>
        <td>
             <span v-if="item.item.budgethead && item.item.budgethead.budget[0]">  ${{item.item.budgethead.budget[0].amount}} </span>
        </td>
        <td> <span v-if="item.item.budgethead && item.item.budgethead.journal[0]"> ${{item.item.budgethead.journal[0].dr}} </span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>



<script>
export default {
  props: {
    requisitionItem: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>
<style scoped>

</style>
