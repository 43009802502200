export default {
  methods: {
    exportCSVData(exportData,filename) {
      const csvContent = this.convertArrayOfObjectsToCSV(exportData);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertArrayOfObjectsToCSV(array) {
      let result = '';
      const columnDelimiter = ',';
      const lineDelimiter = '\n';
      const keys = Object.keys(array[0]);

      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
          if (ctr > 0) result += columnDelimiter;
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    },

    exportEXCELData(exportData,filename){
      const content = this.generateExcelContent(exportData);
      this.downloadExcelFile(content, filename);
    },
    generateExcelContent(data) {
      const header = Object.keys(data[0]).join('\t') + '\n';
      const rows = data.map(item => Object.values(item).join('\t')).join('\n');
      return header + rows;
    },
    downloadExcelFile(content, filename) {
      const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
