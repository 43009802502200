<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Updated Date</th>
      <th>Previous Maintenance</th>
      <th>Current Running Hour</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(items, index) in jobRunningHourDetails" :key="index" class="">
      <th>{{index+1}}</th>
      <th>{{items.created_at | moment('DD/MM/YYYY')}}</th>
      <th>{{items.prev_maintanance_running_hour}}</th>
      <th>{{items.current_running_hour_value}}</th>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobRunningHourDetails : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
