export const TICKET_STATUS = {
  open:'Open',
  inProgress:'In Progress',
  onHold:'On Hold',
  closed:'Closed',
  resolved:'Resolved',
}


export const TICKET_PRIORITY = {
  low:'Low',
  medium:'Medium',
  high:'High',
  urgent:'Urgent',
}
