import Vue from "vue";

export default {

  logout() {
    localStorage.removeItem('adminLoginData');
    localStorage.removeItem('vessel_id');
    localStorage.removeItem('vessel_name');
    localStorage.removeItem('vessel_type_id');
    window.location.reload();
  },

  loggedIn() {
    let loginData = false;
    if (localStorage.hasOwnProperty('adminLoginData')) {
      var token = this.getToken();
      var userId = this.getUserId();
      //check database to token and user id match or not
      if (token && userId) {
        Vue.prototype.$http.post('/api/check-valid-token', {
          token: token,
          user_id: userId
        }).then(response => {
        }).catch(error => {
          this.logout();
        });
      }
      loginData = true;
    }

    return loginData;
  },

  getLoginData() {
    let adminData = localStorage.getItem('adminLoginData');
    adminData = JSON.parse(adminData);
    return adminData;
  },

  getToken() {
    const adminData = this.getLoginData();
    return adminData ? adminData.token : '';
  },

  getUserType() {
    const adminData = this.getLoginData();
    return adminData ? adminData.role_id : '';
  },

  getPermissionData() {
    const adminData = this.getLoginData();
    return adminData ? adminData.permissions : '';
  },

  getVesselId() {
    let vessel_id = localStorage.getItem('vessel_id');
    return vessel_id ? vessel_id : '';
  },

  getVesselName() {
    let vessel_name = localStorage.getItem('vessel_name');
    return vessel_name ? vessel_name : '';
  },

  getVesselTypeId() {
    let vessel_type_id = localStorage.getItem('vessel_type_id');
    return vessel_type_id ? vessel_type_id : '';
  },

  getUserId() {
    const adminData = this.getLoginData();
    return adminData ? adminData.id : '';
  },

}
