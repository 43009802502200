<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Requisition no</th>
      <th>Title</th>
      <th>Priority</th>
      <th>Remarks</th>
      <th>Created By</th>
      <th>Engr. Approval</th>
      <th>Super. Approval</th>
      <th>TM Approval</th>
      <th>Amount</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(requisition, index) in reportData" :key="index">
      <td>{{ index + 1 }}</td>
      <td>{{ requisition.date | moment('DD/MM/YYYY') }}</td>
      <td>{{ requisition.requisition_no }}</td>
      <td>{{ requisition.title }}</td>
      <td>{{ requisition.priority }}</td>
      <td>{{ requisition.remarks }}</td>
      <td>{{ requisition.user.username }}</td>
      <td>{{ requisition.chief_engr_approval }}</td>
      <td>{{ requisition.superintendent_approval }}</td>
      <td>{{ requisition.technical_manager_approval }}</td>
      <td>{{ '$' + requisition.amount }}</td>
      <td v-if="!isPrinting">
        <button type="button" @click="show(requisition.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
      </td>
    </tr>
    <tr v-if="noDataFound">
      <td :colspan="isPrinting ? 12 : 13">No data found</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Array,
      required: true
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    show: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
