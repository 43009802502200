<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Category</h2>
          <ul class="nav navbar-right panel_toolbox align-right">
            <li>
              <router-link to="/category-list">
                <button class="btn btn-info btn-sm">Add Category <i class="fa fa-plus"></i></button>
              </router-link>
            </li>
            <li>
              <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <h5 class="card-header">Category Tree</h5>
              <div class="card-body">
                <p class="text-muted font-13 m-b-30"></p>
                <Tree id="my-tree-id" ref="my-tree" :custom-options="myCustomOptions" :custom-styles="myCustomStyles" :nodes="treeDisplayData"></Tree>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card" style="">
              <h5 class="card-header">List</h5>
              <div class="card-body">
                <div class="card-box table-responsive">
                  <p class="text-muted font-13 m-b-30"></p>
                  <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th> Name</th>
                      <th> Maker</th>
                      <th> Maker Part No</th>
                      <th> Model</th>
                      <th>Barcode</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item , index) in items" :key="item.id">
                      <td>{{index+1}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.maker}}</td>
                      <td>{{item.maker_part_no}}</td>
                      <td>{{item.model}}</td>
                      <td>
                        <button title="" type="button" @click="showQRCode(item)" class="btn btn-success btn-sm">
                          <i class="fa fa-qrcode"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="roleModal" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add New' }} Sub Category</h5>
            <button title="Close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <alert-error :form="form"></alert-error>
              <div class="form-group">
                <label>category Name</label>
                <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label> Parent Name : </label>
                <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
              </div>
            </div>
            <div class="modal-footer">
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="roleModalEdit" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">Edit Category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent=" update()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <alert-error :form="form"></alert-error>
              <div class="form-group">
                <label>category Name</label>
                <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label> Parent Name : </label>
                <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
              </div>
            </div>
            <div class="modal-footer">
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="componenttModel" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle"> Item QR Code </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container justify-content-center">
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <qrcode-vue class="mt-1" :value="value" :size="size" level="H"></qrcode-vue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>

    import Tree from 'vuejs-tree'
    import QrcodeVue from 'qrcode.vue'
    import Select2 from 'v-select2-component'
    import auth from '../../../auth'
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'CategoryTree',
        components: {
            Tree,
            Select2,
            QrcodeVue,
            PulseLoader
        },
        data: function () {
            return {
                loading: false,
                value: '',
                size: 200,
                treeDisplayData: [],
                editMode: false,
                tableMode: false,
                myOptions: [],
                categorys: [],
                items: [],
                form: new Form({
                    id: '',
                    name: '',
                    parent_id: '',
                    vessel_id: auth.getVesselId()
                }),
            }
        },
        computed: {
            myCustomStyles() {
                return {

                    tree: {
                        height: 'auto',
                        maxHeight: '300px',
                        overflowY: 'visible',
                        display: 'inline-block'
                    },
                    expanded: {
                        class: 'fa fa-plus',
                        style: {
                            color: '#007AD5',
                        },
                        active: {
                            class: 'fa fa-minus',
                            style: {
                                color: '#2ECC71'
                            }
                        }
                    },
                    selectIcon: {
                        class: 'fa fa-eye',
                        style: {
                            color: '#007AD5'
                        },
                        active: {
                            class: 'fa fa-eye',
                            style: {
                                color: '#2ECC71'
                            }
                        }
                    },
                    row: {
                        width: '500px',
                        cursor: 'pointer',
                        child: {
                            height: '35px'
                        }
                    },
                    text: {
                        style: {},
                        active: {
                            style: {
                                'font-weight': 'bold',
                                color: '#2ECC71'
                            }
                        }
                    }
                }
            },
            myCustomOptions() {
                return {
                    treeEvents: {
                        expanded: {
                            state: true,
                            fn: this.addRemoveClass,
                        },
                        collapsed: {
                            state: false
                        },
                        selected: {
                            state: true,
                            fn: this.getCategoryAllData
                        },
                        checked: {
                            state: true,
                            fn: this.myCheckedFunction
                        }
                    },
                    events: {

                        expanded: {
                            state: true
                        },
                        selected: {
                            state: true
                        },
                        checked: {
                            state: false
                        },
                        editableName: {
                            state: true,
                            calledEvent: 'expanded'
                        }
                    },
                    addNode: {
                        state: true,
                        fn: this.addNodeFunction,
                        appearOnHover: true
                    },
                    editNode: {
                        state: true,
                        fn: this.editNodeFunction,
                        appearOnHover: true
                    },
                    deleteNode: {
                        state: true,
                        fn: this.deleteNodeFunction,
                        appearOnHover: true
                    },
                    showTags: true
                }
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            addRemoveClass() {
                // .expanded_icon.expanded[data-v-7d528fea]
                $('.expanded').removeClass('fa-plus')
                $('.expanded').addClass('fa-minus')
            },
            //get all category info tree view
            getData() {
                this.loading = true
                this.$http.get('/api/filter/category/' + this.form.vessel_id).then(response => {
                    this.loading = false
                    this.treeDisplayData = response.data.success
                }).catch(e => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },
            //edit tree view single node
            editNodeFunction: function (node) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.create()
                this.form.name = node.text
                this.form.parent_id = node.parent_id
                this.form.id = node.id
                $('#roleModalEdit').modal('show')
            },
            goBack() {
                this.$router.go(-1);
            },
            //delete tree view signle node
            deleteNodeFunction: function (node) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/category/' + node.id).then(response => {
                        this.getData()
                        this.$snotify.success(
                            'category  Successfully Deleted'
                        )
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err
                        )
                    })
                }).catch(function () {

                })
            },
            //add new node in tree view
            addNodeFunction: function (node) {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                this.create()
                this.form.parent_id = node.id
                $('#roleModal').modal('show')
            },
            //get category all information
            getCategoryAllData: function (categoryId, state) {
                this.tableMode = state
                if (this.tableMode) {
                    var data = {
                        category_id: categoryId,
                        vessel_id: this.form.vessel_id
                    }
                    this.$http.post('/api/item-filter', data).then(response => {
                        this.items = response.data
                        setTimeout(() => $('#datatables').DataTable(), 1000)
                    }).catch(e => {
                    })
                }
            },

            //create option all node list
            async create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                this.myOptions = []
                var itemList = await this.$http.get('/api/filter/category/create/' + this.form.vessel_id)
                this.results = itemList.data.success
                var newObj = {
                    id: '',
                    text: 'No Selected'
                }
                this.myOptions.push(newObj)
                this.results.filter(item => {
                    var newObj = {
                        id: item.id,
                        text: item.name
                    }
                    this.myOptions.push(newObj)
                })
            },
            //get node parent id
            store() {
                this.form.busy = true
                this.form.post('/api/category').then(response => {
                    this.getData()
                    $('#roleModalEdit').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Category Successfully Saved')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },

            // edit(category) {
            //     this.editMode = true;
            //     this.form.reset();
            //     this.form.clear();
            //     this.create();
            //     this.form.fill(category);
            //     $("#roleModal").modal("show");
            // },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/category/' + this.form.id).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('category Successfully Updated')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(category) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/category/' + category.id).then(response => {
                        this.getData()
                        this.$snotify.success(
                            'category  Successfully Deleted'
                        )
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err
                        )
                    })
                }).catch(function () {

                })
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.form.parent_id = id
            },
            showQRCode(item) {
                this.value = item.id + item.name
                $('#componenttModel').modal('show')
            },
        }
    }
</script>

<style>
  .add_icon {
    color: rgb(255, 255, 255) !important;
    background: #17a2b8 !important;
    padding: 4px 7px 5px 6px !important;
    border-radius: 80% !important;
    text-align: center
  }

  .delete_icon {
    color: rgb(255, 255, 255) !important;
    background: #bd2130 !important;
    padding: 5px 8px 6px 7px !important;
    border-radius: 80% !important;
    text-align: center
  }

  .edit_icon {
    color: rgb(255, 255, 255) !important;
    background: #19d4a0 !important;
    padding: 4px 3px 6px 4px !important;
    border-radius: 80% !important;
    text-align: center
  }

</style>
