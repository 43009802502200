<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Job Name(Code)</th>
      <th>Component Name</th>
      <th>Prev Main Date</th>
      <th>Est. Due Date</th>
      <th>Prev Main</th>
      <th>Maintenance Interval</th>
      <th>Current Running Hour</th>
      <th>Current Running Hour Value Date</th>
      <th>Run Hour Left</th>
      <th>Daily Running Avg.</th>
      <th>Est. Days Left</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(info, index) in runningHourJobs" :key="index" class="">
      <th>{{index+1}}</th>
      <th v-if="!isPrinting"><a href="javascript:void(0);" @click="openJobRunningHoursModal(info.id)">{{info.name}}({{info?.code}})</a></th>
      <th v-else > {{info.name}}({{info?.code}}) </th>
      <th>{{info.main_components_name}}({{(info?.main_components_code)}}) >> {{info.components_name}}({{(info?.components_code)}})</th>
      <th>{{info?.latestJobSchedule?.start_date | moment('DD-MM-YYYY')}}</th>
      <th>{{info?.est_due_date | moment('DD-MM-YYYY')}}</th>
      <th>{{info.running_hours_value}}</th>
      <th>{{info.running_schedule_value}}</th>
      <th>{{info.current_running_hours_value}}</th>
      <th>{{info?.current_running_hours_value_date | moment('DD-MM-YYYY')}}</th>
      <th >

        <span v-if="info?.running_hour_left >= 0">
           {{info?.running_hour_left}}
        </span>
        <span v-else style="color: red">
           {{info?.running_hour_left}}
        </span>

      </th>
      <th>
        {{info?.daily_running_average}}
      </th>
      <th>
        {{info?.est_days_left}}
      </th>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    runningHourJobs : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    openJobRunningHoursModal: {
      type: Function,
    }
  },

  methods:{
    checkIsNaN(result){
      if (isNaN(result)) {
         return 0;
      } else {
         return result;
      }
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
