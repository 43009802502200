<!-- TableComponent.vue -->
<template>
  <table :id="isPrinting ? '' : 'datatables'"  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Item Name</th>
      <th>Purchase No</th>
      <th>Quotation No</th>
      <th>Requisition No</th>
      <th>Created By</th>
      <th>Receive Date</th>
      <th>Quotation Date</th>
      <th>Requisition Date</th>
      <th>Supplier Name</th>
      <th>Order Qty </th>
      <th>Receive Qty</th>
      <th>Damage Qty</th>
      <th>Return Qty</th>
      <th>Less Qty</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(delivery , index) in deliveryData" :key="delivery.id">
      <td>{{index++}}</td>
      <td>{{delivery?.item?.name}}</td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.po_no}} </span>  </td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.quotation?.quotation_no}} </span>  </td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.requisition?.requisition_no}} </span>  </td>
      <td>{{delivery?.user?.username}}</td>
      <td>{{delivery?.date}}</td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.quotation?.date}} </span>  </td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.requisition?.date}} </span>  </td>
      <td> <span v-if="delivery.purchase"> {{delivery.purchase?.supplier?.name}} </span>  </td>
      <td>{{delivery?.order_qty}}</td>
      <td>{{delivery?.qty}}</td>
      <td>{{delivery?.damage_qty}}</td>
      <td>{{delivery?.returned_qty}}</td>
      <td>{{delivery?.less_qty}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    deliveryData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
