<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <h2 class="float-left">Purchase Return</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button type="button" class="btn btn-info btn-sm" @click="showRequisitionModal()"> Add Return <i class="fa fa-plus"></i></button>
              </li>
              <li>
                <button @click="print('printArea','Purchase Return ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-box table-responsive">
                  <p class="text-muted font-13 m-b-30"></p>
                  <TableData :requisitionData="requisitionData" :approveRequest="approveRequest" :show="show" :adjustReturn="adjustReturn" :destroy="destroy"/>
                  <div id="printArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <h4>Vessel Name: {{this.vessel_name}}</h4>
                      <div class="font-weight-bold">Purchase Return List</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                    </div>
                    <TableData :requisitionData="requisitionData" :isPrinting="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Requisition view Modal -->
      <div class="modal fade" id="addRequisitionModal" tabindex="-1" role="dialog" aria-labelledby="addRequisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addRequisitionModalTitle">Add New Return </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <form role="form" action="#" method="POST" @submit="formSubmit" class="requisitionForm">

                <div class="row">

                  <div class="form-group col-md-6">
                    <label>PO NO<span class="required">*</span></label>
                    <div class="input-group ">
                      <input type="text" class="form-control form-control-sm" v-model="formData.po_no" placeholder="Enter po no" aria-label="Enter po no"
                             aria-describedby="basic-addon2" required>
                      <div class="input-group-append">
                        <span class="input-group-text cp" id="basic-addon2" @click="getPoDetails">Get PO Items</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Title<span class="required">*</span></label>
                      <input v-model="formData.title" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off" required>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="remark">Remarks</label>
                      <textarea v-model="formData.remarks" class="form-control form-control-sm" name="remark" rows="2" id="remark"></textarea>
                    </div>
                  </div>

                  <div class="col-md-12" v-if="poData">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>Status</th>
                        <th>Item name</th>
                        <th>Impa code</th>
                        <th>Maker Part no</th>
                        <th>Maker</th>
                        <th>Model</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Unit</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row , index) in poData.purchase_item" :key="index">
                        <td>{{row?.status}}</td>
                        <td>{{row?.item?.name}}</td>
                        <td>{{row?.item?.inpa_code}}</td>
                        <td>{{row?.item?.maker_part_no}}</td>
                        <td>{{row?.item?.maker}}</td>
                        <td>{{row?.item?.model}}</td>
                        <td>
                          <input :value="`${row?.qty}`" style="width:100px" type="number" step="any" min="0" name="name" class="form-control qty" :data-id="row?.item?.id"
                                 :data-purchase_items_id="row.id" placeholder="Enter quantity" autocomplete="off">
                        </td>
                        <td>{{row?.item?.price}}</td>
                        <td>{{row?.item?.unit?.name}}</td>
                        <td>
                          <button type="button" class="btn btn-round btn-danger" @click="removeItem(index)"><i class="fa fa-trash-o"></i></button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-12">
                    <button :disabled="isDisabled" type="submit" class="btn btn-primary pull-right">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Requisition view Modal -->

      <div class="modal fade" id="requisitionModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Purchase Return Details</h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('modalPrint','Purchase Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>

            </div>
            <div class="modal-body" v-if="requisitionItem">
              <ModalData :requisitionItem="requisitionItem"/>
              <div id="modalPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Purchase Return Details </h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <ModalData :requisitionItem="requisitionItem" :isPrinting="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

    import Select2 from 'v-select2-component'
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import ModalData from "./ModalData.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin],
        name: 'ServiceRequisition',
        components: {
            ModalData,
            TableData,
            Select2,
            datetime,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                isDisabled: false,
                vesselId: auth.getVesselId(),
                permissions: auth.getPermissionData(),
                vessel_name: auth.getVesselName(),
                formData: {
                    vessel_id: auth.getVesselId(),
                    po_id: null,
                    po_no: null,
                    title: null,
                    remarks: null,
                    priority: 'Normal',
                    type: 'Service',
                    items: [],
                },
                requisitionData: null,
                requisitionItem: null,
                poData: {
                    purchase_item: [],
                },
            }
        },
        mounted() {
            this.getRequisitionData()
        },
        methods: {
            getRequisitionData() {
                this.loading = true
                this.$http.get('/api/filter/purchase-return/' + this.vesselId).then(response => {
                    this.requisitionData = response.data.success
                    this.loading = false
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false

                })
            },
            showRequisitionModal() {
                this.poData = {
                    purchase_item: [],
                }
                $('#addRequisitionModal').modal('show')
            },
            removeItem(key) {
                let that = this
                if (confirm('Do you really want to delete?')) {
                    that.poData.purchase_item.splice(key, 1)
                }
            },
            getPoDetails() {
                this.formData.po_id = parseInt(this.formData.po_no.substr(6))
                this.$http.get('/api/purchase/' + this.formData.po_id).then(response => {
                    this.poData = response.data
                }).catch(e => {
                    this.$snotify.error('PO not found')
                })
            },
            formSubmit: function (e) {
                e.preventDefault()
                let that = this
                this.isDisabled = true
                $('.qty').each(function () {
                    var qty = $(this).val()
                    var id = $(this).data('id')
                    var purchase_items_id = $(this).data('purchase_items_id')
                    var obj = {
                        'qty': qty,
                        'item_id': id,
                        'purchase_items_id': purchase_items_id,
                    };
                    that.formData.items.push(obj)
                });
                this.$http.post(`/api/purchase-return`, this.formData).then((response) => {
                    this.isDisabled = false;
                    $('.dataTables_empty').closest('tr').remove();
                    this.$snotify.success('Purchase return has been created successfully');
                    this.requisitionForm = {};
                    this.getRequisitionData();
                    $('#addRequisitionModal').modal('hide')
                }).catch((error) => {
                    this.formData.items = [];
                    this.isDisabled = false;
                    let status = error.response.status;
                    if (status === 422) {
                        this.$snotify.error('Validation failed, please fill all required fields.')
                    }
                    if (status === 500) {
                        this.$snotify.error(error.response.data.error)
                    }
                    if (status === 403) {
                        this.$snotify.error(error.response.data.error)
                    }
                })

            },
            destroy(item) {
                this.$dialog.confirm('Are you sure to delete?').then(() => {
                    this.$http.delete('/api/purchase-return/' + item).then(response => {
                        this.getRequisitionData();
                        this.$snotify.success('Purchase return deleted successfully')
                    }).catch(error => {
                        var err = error.response.data.error;
                        this.$snotify.error(err)
                    })
                }).catch(function () {
                })
            },
            show(item) {
                $('#requisitionModal').modal('show');
                this.$http.get('/api/purchase-return/' + item).then(response => {
                    this.requisitionItem = response.data;
                    console.log(this.requisitionItem)
                }).catch(e => {
                    console.log(e)
                })
            },
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {
                    type: type,
                    status: status,
                    key: key,
                    ref_id: ItemId
                }).then((response) => {
                    if (response.data.success) {
                        this.getRequisitionData();
                        this.$snotify.success('Request Approved Successfully.')
                    }
                }).catch((error) => {
                    if (error.response.status) {
                        var err = error.response.data.error;
                        this.$snotify.error(err)
                    }
                })
            },
            adjustReturn(purchase_return_id) {
                //set confirm message
                this.$dialog.confirm('Are you sure to want to stock adjust?').then(() => {
                    this.$http.post(`/api/adjust-return`, {purchase_return_id: purchase_return_id}).then((response) => {
                        if (response.data.success) {
                            this.getRequisitionData();
                            this.$snotify.success('Purchase return adjusted successfully.')
                        }
                    }).catch((error) => {
                        var err = error.response.data.error;
                        this.$snotify.error(err)
                    })
                }).catch(function () {
                })
            },
            print(id, title = '') {
                printUtil(id, title);
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
