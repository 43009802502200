<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <div class="x_title">
              <h2>Dry Dock Job List</h2>
              <ul class="nav navbar-right panel_toolbox">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create"> Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="print('printArea','Dry Dock Job List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
            <div class="x_content table-responsive">
              <table id="datatable" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th> Date</th>
                  <th>Job Name</th>
                  <th>Spec No</th>
                  <th> Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in data" :key="item.id">
                  <td>{{index+1}}</td>
                  <td>{{item?.date}}</td>
                  <td>{{item?.job_name}}</td>
                  <td>{{item?.spec_no }}</td>
                  <td>
                    <span v-if="item.status == 1" class="badge badge-success">Open</span>
                    <span v-else class="badge badge-danger">Close</span>
                  </td>
                  <td>
                    <button type="button" @click="show(item.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>
                    <button type="button" @click="edit(item)" class="btn btn-primary btn-sm">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      @click="destroy(item)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div id="printArea" style="display: none">
        <div v-for="(item , index) in data" :key="item.id" class="print-page-break">
          <div style="text-align: center">
            <h2>{{appName}}</h2>
            <h4>Vessel Name: {{item?.vessel?.name}}</h4>
            <h3>Dry Dock Job </h3>
            <h4>Job Name: {{item?.job_name}}</h4>
            <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
          </div>
          <ModalData :dryDockView="item" :isPrinting="true"/>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="moduleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="customerModalLongTitle"
              >{{ editMode ? "Edit" : "Add New" }} Dry Dock Job </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-3 col-12">
                    <div>
                      <label>Date </label>
                      <br>
                      <date-picker type="date" v-model="form.date" name="date" valueType="format"></date-picker>
                      <has-error :form="form" field="date"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="form-group">
                      <label>Job Name: </label>
                      <input type="text" class="form-control" v-model="form.job_name" name="spec_no">
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="form-group">
                      <label>Spec No: </label>
                      <input type="text" class="form-control" v-model="form.spec_no" name="spec_no">
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="form-group">
                      <label>Yard No: </label>
                      <input type="text" class="form-control" v-model="form.yard_no" name="yard_no">
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Yard Quotation and Remarks </label>
                      <textarea v-model="form.yard_quotation" class="form-control" name="yard_quotation" id=""></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Location: </label>
                      <input type="text" class="form-control" v-model="form.location" name="location">
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Manufacturer & details: </label>
                      <textarea v-model="form.manufacturar_details" name="manufacturar_details" class="form-control"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Job Description: </label>
                      <textarea v-model="form.job_description" name="job_description" class="form-control"></textarea>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Gas Free Certificate: </label>
                      <select v-model="form.gas_free_certificate" name="gas_free_certificate" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Light: </label>
                      <select v-model="form.light" name="light" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ventilation: </label>
                      <select v-model="form.ventilation" name="ventilation" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Staging: </label>
                      <select v-model="form.staging" name="staging" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Cleaning Before: </label>
                      <select v-model="form.cleaning_before" name="cleaning_before" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Cleaning After: </label>
                      <select v-model="form.cleaning_after" name="cleaning_after" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Crane: </label>
                      <select v-model="form.crane" name="crane" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Internal Yard Transport: </label>
                      <select v-model="form.internal_yard_transport" name="internal_yard_transport" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Outside Yard Transport: </label>
                      <select v-model="form.outside_yard_transport" name="outside_yard_transport" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Access: </label>
                      <select v-model="form.access" name="access" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Preasure Testing: </label>
                      <select v-model="form.preasure_testing" name="preasure_testing" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Corroision Protection: </label>
                      <select v-model="form.corroision_protection" name="corroision_protection" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Paint Work: </label>
                      <select v-model="form.paint_work" name="paint_work" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Material Owner Supply: </label>
                      <select v-model="form.material_owner_supply" name="material_owner_supply" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Material Yard Supply: </label>
                      <select v-model="form.material_yard_supply" name="material_yard_supply" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Lagging: </label>
                      <select v-model="form.lagging" name="lagging" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Function Test: </label>
                      <select v-model="form.function_test" name="function_test" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Manual Drawing: </label>
                      <select v-model="form.manual_drawing" name="manual_drawing" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Photograph: </label>
                      <select v-model="form.photograph" name="photograph" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Sketch: </label>
                      <select v-model="form.sketch" name="sketch" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Sample: </label>
                      <select v-model="form.sample" name="sample" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Class Representative: </label>
                      <select v-model="form.class_representative" name="class_representative" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>MFGR Representative: </label>
                      <select v-model="form.mfgr_representative" name="mfgr_representative" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Statutory Representative: </label>
                      <select v-model="form.statutory_representative" name="statutory_representative" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Owners Representative: </label>
                      <select v-model="form.owners_representative" name="owners_representative" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ship Req No: </label>
                      <input type="text" name="ship_req_no" v-model="form.ship_req_no" class="form-control">
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>PO Order No: </label>
                      <input type="text" name="po_order_no" v-model="form.po_order_no" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Delivery Date: </label>
                      <date-picker type="date" v-model="form.delivery_date" name="delivery_date" valueType="format"></date-picker>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Date Receive On Board: </label>
                      <date-picker type="date" v-model="form.date_receive_on_board" name="date_receive_on_board" valueType="format"></date-picker>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Remarks: </label>
                      <input type="text" name="remarks" v-model="form.remarks" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Job Attachment :</label>
                      <img v-if="attachment_view" :src="attachment_view" alt="attachment" style="width: 100px; height: 100px;"/>
                      <input autocomplete="off" type="file" name="attachment" class="form-control des" @change="covertImage" accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.ppt,.csv,.xls,.xlsx"/>
                      <has-error :form="form" field="attachment"></has-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Status: </label>
                      <select v-model="form.status" name="status" class="form-control">
                        <option value=""> Select Value</option>
                        <option value="1">Open</option>
                        <option value="0">Close</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="form.status == 0" class="col-md-3 col-12">
                    <div>
                      <label>Job Close Date </label>
                      <br>
                      <date-picker type="date" v-model="form.job_close_date" name="job_close_date" valueType="format"></date-picker>
                      <has-error :form="form" field="date"></has-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->

      <div class="modal fade" id="dryDockViewModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Dry Dock Job </h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('dryDockModalPrint','Dry Dock')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>

            </div>
            <div class="modal-body">
              <ModalData :dryDockView="dryDockView"/>

              <div id="dryDockModalPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{appName}}</h2>
                  <h4>Vessel Name: {{dryDockView?.vessel?.name}}</h4>
                  <h3>Dry Dock Job </h3>
                  <h4>Job Name: {{dryDockView?.job_name}}</h4>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <ModalData :dryDockView="dryDockView" :isPrinting="true"/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import auth from "@/auth";
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import ModalData from "./ModalData.vue";
    import {printUtil} from "@/utils/print";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import printMixin from "@/mixin/printMixin";

    export default {
        name: "dry_dock",
        components: {
            ModalData,
            DatePicker,
            PulseLoader
        },
        mixins: [goBackButtonMixin, printMixin],
        data() {
            return {
                loading: false,
                editMode: false,
                data: [],
                dryDockView: '',
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                vessel_id: auth.getVesselId(),
                attachment_view: '',
                form: new Form({
                    id: "",
                    vessel_id: auth.getVesselId(),
                    job_name: "",
                    date: "",
                    yard_quotation: "",
                    manufacturar_details: "",
                    location: "",
                    job_description: "",
                    spec_no: "",
                    yard_no: "",
                    gas_free_certificate: "",
                    light: "",
                    ventilation: "",
                    staging: "",
                    cleaning_before: "",
                    cleaning_after: "",
                    crane: "",
                    internal_yard_transport: "",
                    outside_yard_transport: "",
                    access: "",
                    preasure_testing: "",
                    corroision_protection: "",
                    paint_work: "",
                    material_owner_supply: "",
                    material_yard_supply: "",
                    lagging: "",
                    function_test: "",
                    manual_drawing: "",
                    photograph: "",
                    sketch: "",
                    sample: "",
                    class_representative: "",
                    mfgr_representative: "",
                    statutory_representative: "",
                    owners_representative: "",
                    ship_req_no: "",
                    po_order_no: "",
                    delivery_date: "",
                    date_receive_on_board: "",
                    remarks: "",
                    status: 1,
                    job_close_date: ""
                }),
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                this.loading = true;
                this.$http.get('/api/filter/dry-docks/' + this.vessel_id).then((response) => {
                    this.data = response.data.success;
                    this.loading = false
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch((e) => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#moduleModal").modal("show");
            },
            covertImage(event) {
                let file = event.target.files[0];
                this.form.image = file;
                this.attachment_view = URL.createObjectURL(file);
            },
            store() {
                this.form.busy = true;
                this.form.post("/api/dry-docks").then(response => {
                    this.getData();
                    $("#moduleModal").modal("hide");
                    if (this.form.successful) {
                        this.attachment_view = '';
                        this.$snotify.success("DryDock Successfully Saved");
                    } else {
                        this.$snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    let response = e.response;
                    let status = e.response.status;
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            show(item) {
                $('#dryDockViewModal').modal('show')
                this.$http.get('/api/dry-docks/' + item).then(response => {
                    this.dryDockView = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            edit(item) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(item);
                $("#moduleModal").modal("show");
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post("/api/dry-docks/" + this.form.id).then(response => {
                    this.getData();
                    $("#moduleModal").modal("hide");
                    if (this.form.successful) {
                        this.attachment_view = '';
                        this.$snotify.success("Dru Docks Successfully Updated", "Success");
                    } else {
                        this.$snotify.error("Something went wrong try again later.", "Error");
                    }
                }).catch(e => {
                    let response = e.response;
                    let status = e.response.status;
                    if (status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            destroy(item) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/dry-docks/" + item.id).then(response => {
                        this.getData();
                        this.$snotify.success("Dry Docks Successfully Deleted", "Success");
                    }).catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error("Dry Docks  Not Deleted", "error");
                        }

                    });
                }).catch(function () {

                });

            }
        }
    }
</script>

<style>
  @media print {
    .print-page {
      page-break-after: always;
    }
  }
</style>
