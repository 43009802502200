<template>
  <div>
    <div class="row">
      <div
        v-if="loading"
        class="col-md-12 text-center"
        style="margin-top:25%"
      >
        <PulseLoader
          :loading="loading"
          :color="color"
          :size="size"
        ></PulseLoader>
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class>
            <div class="x_panel">
              <div class="row">
                <div class="col-md-2">
                  <div>
                    <div class="form-group">
                      <select
                        v-model="budget_year"
                        name="budget_year"
                        class="form-control"
                      >
                        <option
                          v-for="year in years"
                          :key="year"
                          selected
                        >{{ year }}
                        </option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div>
                    <div class="form-group">
                      <label></label>
                      <button
                        title="Search Budget"
                        class="btn btn-info"
                        @click="budgetYearInfo()"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-5">
                  <ul class="nav navbar-right panel_toolbox">
                    <li>
                      <router-link to="/budget-create">
                        <button
                          title="Create Budget"
                          class="btn btn-info btn-sm"
                        >
                          Add Budget
                          <i class="fa fa-plus"></i>
                        </button>
                      </router-link>
                    </li>
                    <li>
                      <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="x_title">
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="card">
              <div
                class="card-header text-center"
                style="
                                    background: #192677;
                                    color: white;
                                    font-weight: 900;
                                    font-family: inherit;
                                    "
              >
                <h5
                  class="text-center"
                  style="font-weight: 800;"
                >
                  {{ vessel_name }}
                </h5>
                <p>
                  Budget Year : {{ selectedYear }} - {{ parseInt(selectedYear) + 1 }}
                </p>
              </div>
              <div class="card-body">
                <div class="table-responsive tableStyle">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Budget Name</th>
                      <th scope="col" class="text-right"> Amount</th>
                      <th scope="col" class="text-right"> Prev year({{ parseInt(budget_year) - 1 }}) Amount</th>
                      <th scope="col" class="text-right"> Budget variance</th>
                      <th scope="col" class="text-right"> Budget variance(%)</th>
                      <th scope="col">Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="budgetInfo in budgetInfoList"
                      :key="budgetInfo.id"
                      v-bind:class="{'bg-secondary text-white': budgetInfo.parent_id ===0}"
                    >
                      <td class="text-left">{{ budgetInfo.code }}</td>
                      <td>{{ budgetInfo.name }}</td>
                      <td class="text-right"> {{isEmpty(budgetInfo.budget) ? budgetInfo.budget[0].amount : "0.0"}}</td>
                      <td class="text-right">{{isEmpty(budgetInfo.budget) ? budgetInfo.prev_amount : "0.0" }}</td>
                      <td class="text-right"><span v-if="isEmpty(budgetInfo.budget)">
                           <i v-if="parseInt(parseInt(isEmpty(budgetInfo.budget)? budgetInfo.budget[0].amount : '0.0' ) - parseInt(budgetInfo.prev_amount) ) > 0 "
                              class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                            <i v-if="parseInt(parseInt(isEmpty(budgetInfo.budget) ? budgetInfo.budget[0].amount : '0.0' ) - parseInt(budgetInfo.prev_amount) ) < 0 "
                               class="fa fa-arrow-down text-danger" aria-hidden="true"></i> </span>
                        {{ parseInt( isEmpty(budgetInfo.budget) ? budgetInfo.budget[0].amount : "0.0" ) - parseInt(budgetInfo.prev_amount) }}
                      </td>
                      <td>
                        <span v-if="isEmpty(budgetInfo.budget)">
                           <i v-if="parseInt(parseInt(isEmpty(budgetInfo.budget)? budgetInfo.budget[0].amount : '0.0' ) - parseInt(budgetInfo.prev_amount) ) > 0 "
                              class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                         <i v-if="parseInt(parseInt(isEmpty(budgetInfo.budget)  ? budgetInfo.budget[0].amount : '0.0'  ) - parseInt(budgetInfo.prev_amount) ) < 0 "
                            class="fa fa-arrow-down text-danger" aria-hidden="true"></i>
                       </span>
                        {{ parseInt( budgetInfo.variance) }}%
                      </td>
                      <td>
                        {{ budgetInfo.remarks }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import auth from "@/auth";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        mixins: [goBackButtonMixin],
        name: "Budget",
        components: {
            PulseLoader
        },

        data() {
            return {
                loading: false,
                color: "#1ABB9C",
                size: "20px",
                isActive: "bg-secondary",
                vessel_name: "",
                budgetHeads: [],
                selectedYear: "",

                budget_year: new Date().getFullYear(),
                budgetInfoList: [],
                years: [],
                vessel_id: auth.getVesselId()
            };
        },

        mounted() {
            this.yearsGenerate();
            this.veselInfo();
            this.budgetYearInfo();
        },

        methods: {
            yearsGenerate() {
                let i = new Date().getFullYear();
                for (i; i >= 2015; i--) {
                    this.years.push(i);
                }
            },

            async budgetYearInfo() {
                this.loading = true;
                this.selectedYear = this.budget_year;
                var budgetInfo = await this.$http.get(
                    "/api/budget-year/" + this.budget_year + "/" + this.vessel_id
                );
                this.budgetInfoList = [];
                this.budgetInfoList = budgetInfo.data.success;
                this.loading = false;
            },
            veselInfo() {
                //  this.$store.state.vessal_id vessel
                this.$http.get("/api/vessel/" + auth.getVesselId()).then(response => {
                    this.vessel_name = response.data.success.name;
                }).catch(e => {
                    console.log(e);
                });
            },

            isEmpty(obj) {
                if (obj.length < 1) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    };
</script>

<style scoped>
  /* .list-group{
      display: block !important;
  } */
  .x_title {
    border-bottom: none !important;
  }

  .tableStyle {
    max-height: 450px;
    overflow: auto;
  }
</style>
