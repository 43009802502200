<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div v-if="listMode" class="row">
        <div class="col-md-12 col-sm-12">
          <div class>
            <div class="x_panel">
              <h2 class="float-left">Master Job List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" v-if="isPermissionExist(PERMISSIONS.JOB_ADD)" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="print('printArea','Master Job List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-3 col-md-4 col-6">
                        <div class="form-group">
                          <select class="form-control" v-model="listType">
                            <option value>Select Job Interval</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Two-Yearly">2 yearly</option>
                            <option value="Thirty-Monthly">2.5 yearly</option>
                            <option value="Three-Yearly">3 yearly</option>
                            <option value="Four-Yearly">4 yearly</option>
                            <option value="Five-Yearly">5 yearly</option>
                            <option value="Ten-Yearly">10 yearly</option>
                            <option value="Fifteen-Yearly">15 yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Three-Monthly">3 Monthly</option>
                            <option value="Six-Monthly">6 Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Running Hours">Running Hour</option>
                            <!--                          <option value="Unscheduled">Unscheduled</option>-->
                            <!--                          <option value="BreakDown">BreakDown</option>-->
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-4 col-6">
                        <div class="form-group">
                          <button class="form-control btn-info btn btn-sm" @click="getData()">Search</button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 ">
                        <div class="justify-content-center">
                          <ul class="nav navbar-left  align-left">

                            <li v-for="(department, index) in departmentWiseJob" :key="department.id">
                              <button @click="selectDepartment(department.id)"
                                      :class="['btn btn-outline-info btn-sm', { active: department.id === selectedDepartment }]"
                              > {{department?.name}}({{department?.code}}) <span class="badge badge-success">{{department?.job_count}}</span></button>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <TableData
                        :jobs="jobs"
                        :show="show"
                        :approvedJob="approvedJob"
                        :runningHourUpdate="runningHourUpdate"
                        :showQRCode="showQRCode"
                        :edit="edit"
                        :copyComponent="copyComponent"
                        :destroy="destroy"
                        :user_type="user_type"
                      />

                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{ this.vessel_name }}</h4>
                          <div class="font-weight-bold">Master Job List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :jobs="jobs" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="jobModal" role="dialog" aria-labelledby="jobModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="jobModalLongTitle">{{ editMode ? 'Edit' : 'Add' }} Job</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Department Name</label>
                      <select required v-model="form.department_id" name="department_id" class="form-control" :class="{'is-invalid': form.errors.has('department_id'),}" @change="getMainComponent(form.department_id)">
                        <option value selected>Select department</option>
                        <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{ sdepartment.name }}</option>
                      </select>
                      <has-error :form="form" field="department_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Main Component</label>
                      <select required v-model="form.main_component_id" name="main_component_id" class="form-control" :class="{'is-invalid': form.errors.has('main_component_id'),}" @change="getComponentList(form.main_component_id)">
                        <option value selected>Select main component</option>
                        <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{ mainComponent.name }}</option>
                      </select>
                      <has-error :form="form" field="main_component_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Component Name :</label>
                      <select v-model="form.component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('component_id'),}" @change="getSubComponent(form.component_id)">
                        <option value selected>Select component</option>
                        <option v-for="component in components" :key="component.id" :value="component.id">{{ component.name }}</option>
                      </select>
                      <has-error :form="form" field="component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Sub Component </label>
                      <select v-model="form.sub_component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('sub_component_id'),}" @change="getSubSubComponent(form.sub_component_id)">
                        <option value="0" selected>Select component</option>
                        <option v-for="subcomponent in subcomponents" :key="subcomponent.id" :value="subcomponent.id">{{ subcomponent.name }}</option>
                      </select>
                      <has-error :form="form" field="sub_component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Sub Sub Component </label>
                      <select v-model="form.sub_sub_component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('sub_sub_component_id'),}">
                        <option value="0" selected>Select component</option>
                        <option v-for="subsubcomponent in subsubcomponents" :key="subsubcomponent.id" :value="subsubcomponent.id">{{ subsubcomponent.name }}</option>
                      </select>
                      <has-error :form="form" field="sub_sub_component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Job Name</label>
                      <input required autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{'is-invalid': form.errors.has('name'),}"/>
                      <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Job Type Name :</label>
                      <select required v-model="form.job_type_id" name="job_type_id" class="form-control" :class="{'is-invalid': form.errors.has('job_type_id'),}">
                        <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                      </select>
                      <has-error :form="form" field="job_type_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Job Code :</label>
                      <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{'is-invalid': form.errors.has('code'),}"/>
                      <has-error :form="form" field="code"></has-error>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Counter Type :</label>
                      <select required v-model="form.counter_type" name="counter_type" class="form-control" :class="{'is-invalid': form.errors.has('counter_type'),}">
                        <option value>Select Counter Type</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Two-Yearly">2 yearly</option>
                        <option value="Thirty-Monthly">2.5 yearly</option>
                        <option value="Three-Yearly">3 yearly</option>
                        <option value="Four-Yearly">4 yearly</option>
                        <option value="Five-Yearly">5 yearly</option>
                        <option value="Ten-Yearly">10 yearly</option>
                        <option value="Fifteen-Yearly">15 yearly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Three-Monthly">3 Monthly</option>
                        <option value="Six-Monthly">6 Monthly</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Running Hours">Running Hours</option>
                      </select>
                      <has-error :form="form" field="counter_type"></has-error>
                    </div>

                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label> Running Hours at previous Maintenance : </label>
                      <input autocomplete="off" v-model="form.running_hours_value" name="running_hours_value" class="form-control" :class="{'is-invalid': form.errors.has('running_hours_value'),}"/>
                      <has-error :form="form" field="running_hours_value"></has-error>
                    </div>
                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label>Schedule Hour :</label>
                      <input autocomplete="off" v-model="form.running_schedule_value" name="running_schedule_value" class="form-control" :class="{'is-invalid': form.errors.has('running_schedule_value'),}"/>
                      <has-error :form="form" field="running_schedule_value"></has-error>
                    </div>
                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label>Current Running Hour Value :</label>
                      <input autocomplete="off" v-model="form.current_running_hours_value" name="current_running_hours_value" class="form-control" :class="{'is-invalid': form.errors.has('current_running_hours_value'),}"/>
                      <has-error :form="form" field="current_running_hours_value"></has-error>
                    </div>
                    <!--                  <div class="form-group" v-if="form.counter_type != 'Running Hours'">-->
                    <!--                    <label>Start Date (Last Maintenance Date)</label>-->
                    <!--                    <br/>-->
                    <!--                    <date-picker :disabled="editMode" type="date" v-model="form.start_date" name="start_date" valueType="format"></date-picker>-->
                    <!--                    <has-error :form="form" field="start_date"></has-error>-->
                    <!--                  </div>-->
                    <!--                  <div class="form-group" v-if="form.counter_type != 'Running Hours'">-->
                    <!--                    <label>End Date</label>-->
                    <!--                    <br/>-->
                    <!--                    <date-picker :disabled="editMode" type="date" v-model="form.end_date" name="end_date" valueType="format"></date-picker>-->
                    <!--                    <has-error :form="form" field="end_date"></has-error>-->
                    <!--                  </div>-->
                    <div class="form-group">
                      <label>Start Date (Last Maintenance Date)</label>
                      <br/>
                      <date-picker type="datetime" v-model="form.start_date" name="start_date" valueType="format" :class="{'is-invalid': form.errors.has('start_date'),}"></date-picker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>
                    <!--                  <div class="form-group"  >-->
                    <!--                    <label>End Date</label>-->
                    <!--                    <br/>-->
                    <!--                    <date-picker :disabled="editMode" type="datetime" v-model="form.end_date" name="end_date" valueType="format"></date-picker>-->
                    <!--                    <has-error :form="form" field="end_date"></has-error>-->
                    <!--                  </div>-->

                    <div class="form-group">
                      <label>Instruction Manual :</label>
                      <input autocomplete="off" type="file" name="instruction_manual" class="form-control des" :class="{'is-invalid': form.errors.has('instruction_manual'),}" @change="previewFiles" accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.ppt,.csv,.xls,.xlsx"/>
                      <has-error :form="form" field="instruction_manual"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Assign Ranked Fixed :</label>
                      <select :disabled="!isPermissionExist(PERMISSIONS.JOB_ADD_ASSIGNED_RANK)" v-model="form.rank_fixed" name="rank_fixed" class="form-control" :class="{'is-invalid': form.errors.has('rank_fixed'),}" @change="clearRankedFixed">
                        <option v-for="rank in ranked" :key="rank.id" :value="rank.id">{{ rank.name }}</option>
                      </select>
                      <has-error :form="form" field="rank_fixed"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Assign Permit to Work/Risk Assessment </label>
                      <select v-model="form.pw_ra_assign" name="pw_ra_assign" class="form-control" :class="{'is-invalid': form.errors.has('pw_ra_assign'),}">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <has-error :form="form" field="pw_ra_assign"></has-error>
                    </div>

                    <!-- <div class="form-group" v-if="form.rank_fixed =='' || form.rank_fixed !=''" >
                                        <label>Assign Ranked Variable :</label>
                                          <select v-model="form.rank_variable" name="rank_variable" class="form-control" :class="{ 'is-invalid': form.errors.has('rank_variable') }">
                                            <option value=''>Select Variable Rank</option>
                                            <option v-for="rank in ranked" :key="rank.id" :value="rank.id">{{rank.name}}</option>
                                        </select>
                                        <has-error :form="form" field="rank_variable"></has-error>
                    </div>-->

                    <!--<div class="form-group">
                                          <label> Status </label>
                                          <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                              <option value="Activate">Activate</option>
                                              <option value="Deactivate">Deactivate</option>
                                          </select>
                                          <has-error :form="form" field="status"></has-error>
                    </div>-->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Job Instruction :</label>
                      <vue-editor v-model="form.job_remarks"></vue-editor>
                      <!-- <textarea v-model="form.job_remarks" class="resizable_textarea form-control" placeholder="Enter Description" rows="8"></textarea> -->
                      <has-error :form="form" field="job_remarks"></has-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->

      <!-- Modal -->
      <div
        class="modal fade" id="copyModal" role="dialog" aria-labelledby="copyModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="copyModalLongTitle">Copy Job</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <form @submit.prevent="store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Department Name</label>
                      <select v-model="form.department_id" required name="department_id" class="form-control" :class="{'is-invalid': form.errors.has('department_id'),}" @change="getMainComponent(form.department_id)">
                        <option value selected>Select department</option>
                        <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{ sdepartment.name }}</option>
                      </select>
                      <has-error :form="form" field="department_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Main Component</label>
                      <select v-model="form.main_component_id" required name="main_component_id" class="form-control" :class="{'is-invalid': form.errors.has('main_component_id'),}" @change="getComponentList(form.main_component_id)">
                        <option value selected>Select main component</option>
                        <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{ mainComponent.name }}</option>
                      </select>
                      <has-error :form="form" field="main_component_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Component Name :</label>
                      <select v-model="form.component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('component_id'),}" @change="getSubComponent(form.component_id)">
                        <option value selected>Select component</option>
                        <option v-for="component in components" :key="component.id" :value="component.id">{{ component.name }}</option>
                      </select>
                      <has-error :form="form" field="component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Sub Component</label>
                      <select v-model="form.sub_component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('sub_component_id'),}" @change="getSubSubComponent(form.sub_component_id)">
                        <option value="0" selected>Select component</option>
                        <option v-for="subcomponent in subcomponents" :key="subcomponent.id" :value="subcomponent.id">{{ subcomponent.name }}</option>
                      </select>
                      <has-error :form="form" field="sub_component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Sub Sub Component</label>
                      <select v-model="form.sub_sub_component_id" name="component_id" class="form-control" :class="{'is-invalid': form.errors.has('sub_sub_component_id'),}">
                        <option value="0" selected>Select component</option>
                        <option v-for="subsubcomponent in subsubcomponents" :key="subsubcomponent.id" :value="subsubcomponent.id">{{ subsubcomponent.name }}</option>
                      </select>
                      <has-error :form="form" field="sub_sub_component_id"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Job Name</label>
                      <input autocomplete="off" required v-model="form.name" type="text" name="name" class="form-control" :class="{'is-invalid': form.errors.has('name'),}"/>
                      <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group">
                      <label>Job Type Name :</label>
                      <select v-model="form.job_type_id" required name="job_type_id" class="form-control" :class="{'is-invalid': form.errors.has('job_type_id'),}">
                        <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                      </select>
                      <has-error :form="form" field="job_type_id"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Job Code :</label>
                      <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{'is-invalid': form.errors.has('code'),}"/>
                      <has-error :form="form" field="code"></has-error>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Counter Type :</label>
                      <select v-model="form.counter_type" required name="counter_type" class="form-control" :class="{'is-invalid': form.errors.has('counter_type'),}">
                        <option value>Select Counter Type</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Two-Yearly">2 yearly</option>
                        <option value="Thirty-Monthly">2.5 yearly</option>
                        <option value="Three-Yearly">3 yearly</option>
                        <option value="Four-Yearly">4 yearly</option>
                        <option value="Five-Yearly">5 yearly</option>
                        <option value="Ten-Yearly">10 yearly</option>
                        <option value="Fifteen-Yearly">15 yearly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Three-Monthly">3 Monthly</option>
                        <option value="Six-Monthly">6 Monthly</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Running Hours">Running Hours</option>
                      </select>
                      <has-error :form="form" field="counter_type"></has-error>
                    </div>

                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label> Running Hours at previous Maintenance : </label>
                      <input autocomplete="off" v-model="form.running_hours_value" name="running_hours_value" class="form-control" :class="{'is-invalid': form.errors.has('running_hours_value'),}"/>
                      <has-error :form="form" field="running_hours_value"></has-error>
                    </div>
                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label>Schedule Hour :</label>
                      <input autocomplete="off" v-model="form.running_schedule_value" name="running_schedule_value" class="form-control" :class="{'is-invalid': form.errors.has('running_schedule_value'),}"/>
                      <has-error :form="form" field="running_schedule_value"></has-error>
                    </div>
                    <div class="form-group" v-if="form.counter_type == 'Running Hours'">
                      <label>Current Running Hour Value :</label>
                      <input autocomplete="off" v-model="form.current_running_hours_value" name="current_running_hours_value" class="form-control" :class="{'is-invalid': form.errors.has('current_running_hours_value'),}"/>
                      <has-error :form="form" field="current_running_hours_value"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Start Date (Last Maintenance Date)</label>
                      <br/>
                      <date-picker required type="datetime" v-model="form.start_date" name="start_date" valueType="format"></date-picker>
                      <has-error :form="form" field="start_date"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Instruction Manual :</label>
                      <input autocomplete="off" type="file" name="instruction_manual" class="form-control des" :class="{'is-invalid': form.errors.has('instruction_manual'),}" @change="previewFiles" accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.ppt,.csv,.xls,.xlsx"/>
                      <has-error :form="form" field="instruction_manual"></has-error>
                    </div>

                    <div class="form-group">
                      <label>Assign Ranked Fixed :</label>
                      <select :disabled="!isPermissionExist(PERMISSIONS.JOB_ADD_ASSIGNED_RANK)" v-model="form.rank_fixed" name="rank_fixed" class="form-control" :class="{'is-invalid': form.errors.has('rank_fixed'),}" @change="clearRankedFixed">
                        <option v-for="rank in ranked" :key="rank.id" :value="rank.id">{{ rank.name }}</option>
                      </select>
                      <has-error :form="form" field="rank_fixed"></has-error>
                    </div>
                    <div class="form-group">
                      <label> Assign Permit to Work/Risk Assessment </label>
                      <select :disabled="editMode" v-model="form.pw_ra_assign" name="pw_ra_assign" class="form-control" :class="{'is-invalid': form.errors.has('pw_ra_assign'),}">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <has-error :form="form" field="pw_ra_assign"></has-error>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Job Instruction :</label>
                      <vue-editor v-model="form.job_remarks"></vue-editor>
                      <!-- <textarea v-model="form.job_remarks" class="resizable_textarea form-control" placeholder="Enter Description" rows="8"></textarea> -->
                      <has-error :form="form" field="job_remarks"></has-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="showModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="showModalLabel">Job Instruction</h5>

              <button @click="print('jobInstructionPrintArea','Job Instruction ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>


            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                  <tr>
                    <th>Instruction</th>
                    <td>
                      <span v-html="form.job_remarks"></span>
                    </td>
                  </tr>
                  </tbody>
                </table>


                <div id="jobInstructionPrintArea" style="display: none">
                  <div style="text-align: center;line-height: 20px;">
                    <h2>{{ appName }}</h2>
                    <h4>Vessel Name: {{ this.vessel_name }}</h4>
                    <div class="font-weight-bold">Job Instruction</div>
                    <p>Job Name: {{form?.name}} ({{form?.code}})</p>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <table class="table print-table text-center">
                    <tbody>
                    <tr>
                      <th>Instruction</th>
                      <td>
                        <span v-html="form.job_remarks"></span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="componenttModel"
        tabindex="-1"
        role="dialog"
        aria-labelledby="customerModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">Job QR Code</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container justify-content-center">
                <div class="row">
                  <div class="col-md-6 offset-md-3">
                    <qrcode-vue
                      class="mt-1"
                      :value="value"
                      :size="size"
                      level="H"
                    ></qrcode-vue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->

      <!-- Modal -->
      <div class="modal fade" id="runningHourUpdate" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="runningHourUpdateTitle">Running Hour Update</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="jobRunningHourUpdate()" @keydown="jobRunningHourForm.onKeydown($event)">
                <div class="modal-body">
                  <alert-error :form="jobRunningHourForm"></alert-error>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="checkbox" v-model="jobRunningHourForm.isEnabled"/>
                        <label>&nbsp;&nbsp; Apply this all child component and subcomponent</label>
                      </div>
                      <div class="form-group">
                        <label>Running Hours at previous Maintenance :</label>
                        <input readonly autocomplete="off" v-model="jobRunningHourForm.prev_maintanance_running_hour" name="running_hours_value" class="form-control" :class="{ 'is-invalid': jobRunningHourForm.errors.has('running_hours_value') }"/>
                        <has-error :form="jobRunningHourForm" field="running_hours_value"></has-error>
                      </div>
                      <div class="form-group">
                        <label>Current Running Hour Value :</label>
                        <input autocomplete="off" v-model="jobRunningHourForm.current_running_hour_value" name="current_running_hour_value" class="form-control" :class="{ 'is-invalid': jobRunningHourForm.errors.has('current_running_hours_value') }"/>
                        <has-error :form="jobRunningHourForm" field="current_running_hour_value"></has-error>
                      </div>
                      <div class="form-group">
                        <label>Current Running Hours Value Date</label> <br/>
                        <date-picker type="datetime" v-model="jobRunningHourForm.start_date" name="start_date" valueType="format" :class="{'is-invalid': jobRunningHourForm.errors.has('start_date'),}"></date-picker>
                        <has-error :form="jobRunningHourForm" field="start_date"></has-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button :disabled="jobRunningHourForm.busy" type="submit" class="btn btn-info">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Running Modal -->
    </div>

  </div>
</template>

<script>

    import DatePicker from 'vue2-datepicker'
    import QrcodeVue from 'qrcode.vue'
    import RunningHourJob from '../RunningHourJob.vue'
    import {VueEditor} from 'vue2-editor'
    import 'vue2-datepicker/index.css'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import permissionsMixin from "@/mixin/permissionsMixin";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [permissionsMixin, printMixin, goBackButtonMixin],
        name: 'Job',
        components: {
            TableData,
            DatePicker,
            QrcodeVue,
            VueEditor,
            RunningHourJob,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                RunningReport: false,
                listMode: true,
                selectedJob: '',
                value: '',
                size: 200,
                listType: '',
                editMode: false,
                running_hours_value_type: false,
                user_type: auth.getUserType(),
                permissions: auth.getPermissionData(),
                query: '',
                queryFiled: 'name',
                types: [],
                departmentId: '',
                mainComponentId: '',
                departments: [],
                departmentWiseJob: [],
                selectedDepartment: '',
                mainComponents: [],
                components: [],
                subcomponents: [],
                subsubcomponents: [],
                jobs: [],
                ranked: [],
                jobRunningHourForm: new Form({
                    isEnabled: false,
                    job_id: '',
                    prev_maintanance_running_hour: '',
                    schedule_hour: '',
                    current_running_hour_value: '',
                    start_date: '',
                }),
                form: new Form({
                    id: '',
                    name: '',
                    department_id: '',
                    main_component_id: '',
                    component_id: '',
                    sub_component_id: '0',
                    sub_sub_component_id: '0',
                    job_type_id: '',
                    job_duration_type: 'Yearly',
                    job_duration: '1',
                    manual_id: '',
                    code: '',
                    counter_type: '',
                    running_hours_value: '0',
                    running_schedule_value: '',
                    current_running_hours_value: 0,
                    start_date: '',
                    end_date: '',
                    instruction_manual: '',
                    rank_fixed: auth.getUserType(),
                    pw_ra_assign: 'Yes',
                    job_remarks: '',
                    status: 'Approved',
                    vessel_id: auth.getVesselId()
                }),
                vessel_id: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                pagination: {
                    current_page: 1,
                },
            }
        },
        mounted() {
            this.jobType();
            this.departmentList();
            this.getData();
            this.getAllRanked();
            this.getDepartmentWiseJob();
        },
        watch: {
            'form.department_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.departments);
                }
            },
            'form.main_component_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.mainComponents);
                }
            },
            'form.component_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.components);
                }
            },
            'form.sub_component_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.subcomponents);
                }
            },
            'form.sub_sub_component_id': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.code = this.updateCode(newVal, this.subsubcomponents);
                }
            },
        },
        methods: {
            updateCode(itemId, components) {
                const selectedComponent = components.find(component => component.id === itemId);
                if (selectedComponent && selectedComponent.code) {
                    return selectedComponent.code + '-';
                }
                return '';
            },
            copyComponent(job) {
                //this.form.reset();
                //this.form.clear();
                //this.form.fill(job);
                let self = this;
                setTimeout(function () {
                    self.getMainComponent(job.department_id);
                    self.getComponentList(job.main_component_id);
                    self.getSubComponent(job.sub_component_id);
                    self.getSubSubComponent(job.sub_sub_component_id);
                    self.form.fill(job);

                }, 1500);
                $('#copyModal').modal('show')
            },
            getData() {
                this.loading = true;
                const params = {
                    vessel_id: this.vessel_id,
                    counter_type: this.listType,
                    department_id: this.selectedDepartment
                };

                this.$http.get('/api/job', {params}).then((response) => {
                    console.log("job",response)
                    this.jobs = response.data.success;
                    this.loading = false;
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch((e) => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error('Something went wrong')
                    }
                });
            },
            selectDepartment(departmentId) {
                if (this.selectedDepartment === departmentId) {
                    this.selectedDepartment = '';
                } else {
                    this.selectedDepartment = departmentId;
                }
                this.getData();
            },
            getFilterData() {
                this.$http.get('/api/filter/selected-job/' + this.listType + '/' + this.vessel_id).then((response) => {
                    this.jobs = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch((e) => {
                    console.log(e)
                })
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $('#jobModal').modal('show')
            },
            store() {
                this.form.busy = true;
                this.form.post('/api/job').then((response) => {
                    this.getData();
                    this.getDepartmentWiseJob();
                    $('#jobModal').modal('hide');
                    $('#copyModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Job Successfully Saved')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch((e) => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (e.response.data?.errors) {
                            const errors = e.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        this.$snotify.error(response?.data?.error)
                    }
                })
            },
            edit(job) {
                let self = this;
                this.editMode = true;
                self.getMainComponent(job.department_id);
                self.getComponentList(job.main_component_id);
                self.getSubComponent(job.component_id);
                self.getSubSubComponent(job.sub_component_id);
                self.form.fill(job);
                setTimeout(function () {
                    self.form.code = job.code;
                }, 1500);

                $('#jobModal').modal('show');
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.departmentList();
                this.form.post('/api/job/' + this.form.id).then((response) => {
                    this.getData();
                    this.getDepartmentWiseJob();
                    $('#jobModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Job Successfully Updated', 'Success')
                    } else {
                        this.$snotify.error('Something went wrong try again later.', 'Error')
                    }
                }).catch((e) => {
                    console.log(e)
                })
            },
            show(job) {
                let self = this;
                self.form.reset();
                self.form.fill(job);
                setTimeout(function () {
                    self.form.code = job?.code;
                }, 500);
                $('#showModal').modal('show')
            },
            runningHourUpdate(job) {
                this.jobRunningHourForm.job_id = job.id;
                this.jobRunningHourForm.prev_maintanance_running_hour = job.running_hours_value;
                this.jobRunningHourForm.schedule_hour = job.running_schedule_value;
                this.jobRunningHourForm.current_running_hour_value = job.current_running_hours_value;
                $('#runningHourUpdate').modal('show');
            },
            jobRunningHourUpdate() {
                this.jobRunningHourForm.busy = true;
                this.jobRunningHourForm.post('/api/running-hour-update').then((response) => {
                    $('#runningHourUpdate').modal('hide');
                    if (this.jobRunningHourForm.successful) {
                        this.$snotify.success('Running Hour Update Successfully');
                        this.getData();
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch((e) => {
                    var error = this.jobRunningHourForm.errors.errors.error;
                    this.$snotify.error(error)
                })
            },
            destroy(data) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/job/' + data.id).then((response) => {
                        this.getData()
                        this.getDepartmentWiseJob();
                        this.$snotify.success('Job Successfully Deleted')
                    })
                        .catch((e) => {
                            this.$snotify.error('Job Not Deleted')
                        })
                }).catch(function () {
                })
            },
            jobType() {
                this.$http.get('/api/filter/job-type/' + this.vessel_id).then((response) => {
                    this.types = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },
            getDepartmentWiseJob() {
                this.$http.get('/api/filter/get-department-wise-job/' + this.vessel_id).then((response) => {
                    console.log("vessel", response)
                    this.departmentWiseJob = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },

            departmentList() {
                this.$http.get('/api/filter/get-active-department/' + this.vessel_id).then((response) => {
                    this.departments = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },
            getMainComponent(department_id) {

                this.mainComponents = [];
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.form.main_component_id = '';
                this.form.component_id = '';
                this.form.sub_component_id = '0';
                this.form.sub_sub_component_id = '0';

                if (department_id !== '') {
                    this.$http.get('/api/filter/main-component/' + this.vessel_id + '/' + department_id).then((response) => {
                        this.mainComponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getComponentList(main_component_id) {
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.form.component_id = '';
                this.form.sub_component_id = '0';
                this.form.sub_sub_component_id = '0';
                if (main_component_id !== '') {
                    this.$http.get('/api/filter/component/' + this.vessel_id + '/' + main_component_id).then((response) => {
                        this.components = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubComponent(component_id) {
                console.log("sun", component_id)
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.form.sub_component_id = '0';
                this.form.sub_sub_component_id = '0';
                if (component_id != '0') {
                    this.$http.get('/api/filter/sub-component/' + this.vessel_id + '/' + component_id).then((response) => {
                        this.subcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubSubComponent(sub_component_id) {
                this.subsubcomponents = []
                this.form.sub_sub_component_id = '0';
                if (sub_component_id != '0') {
                    this.$http.get('/api/filter/sub-sub-component/' + this.vessel_id + '/' + sub_component_id).then((response) => {
                        this.subsubcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            previewFiles(event) {
                var file = event.target.files[0];
                this.form.instruction_manual = file;
            },
            getAllRanked() {
                this.$http.get('/api/role').then((response) => {
                    this.ranked = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },
            clearRankedFixed() {
                if (this.form.rank_fixed != '') {
                    this.form.rank_variable = ''
                }
            },
            //store item to component data
            showQRCode(job) {
                this.value = job.id.toString();
                $('#componenttModel').modal('show');
            },
            //approved pending job
            approvedJob(job) {
                this.$http.get('/api/job-approved/' + job.id).then((response) => {
                    this.getData();
                    this.$snotify.success('Job Successfully Approved ')
                }).catch((e) => {
                    this.$snotify.error('Job  Approved Can not be Successfull')
                })
            },

            /*runningHourUpdate (job) {
              // if(job.job_schedules[i].permit_to_work==null && job.job_schedules[i].risk_assessment!=null){
              if (job != null) {
                this.selectedJob = job
                this.RunningReport = true
                this.listMode = false
              } else {
                this.RunningReport = false
                this.listMode = true
              }
            },*/

            changeListMode() {
                this.RunningReport = false;
                this.listMode = true;
                this.jobType();
                this.departmentList();
                this.getData();
                this.getAllRanked();
            },

        },
    }
</script>

<style>
  .mx-datepicker-popup {
    z-index: 9000 !important;
  }

  .des {
    height: 36% !important;
  }
</style>
