import {PERMISSIONS} from "@/utils/permission";

export default {
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
  methods: {
    isPermissionExist(permission) {
      return this.permissions.includes(permission);
    },
  },
};
