<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="serialNumber =   1" >
    <tr>
      <th>#</th>
      <th>Job Name (Code)</th>
      <th>Component Name(Code)</th>
      <th colspan="3">Spares Consumed Quantity</th>
      <th>Completion Date</th>
      <th>Maintenance Type  </th>
      <th>Done By</th>
    </tr>
    <tr>
      <th colspan="3"></th>
      <th>Spares Consumed</th>
      <th>Quantity</th>
      <th>ROB</th>
      <th colspan="3"></th>
    </tr>
    </thead>
    <tbody>
    <template v-for="(job,i) in jobList" >
      <tr :key="job.id" >
        <td :rowspan="job?.spare_consumed?.length">{{i+1}}</td>
        <td :rowspan="job?.spare_consumed?.length">{{job?.job_schedules?.job?.name}}({{job?.job_schedules?.job?.code}})</td>
        <td :rowspan="job?.spare_consumed?.length">{{job?.job_schedules?.job?.component?.name}}({{job?.job_schedules?.job?.component?.code}})</td>
        <td>{{job?.spare_consumed?.length > 0  ? getItemName(job?.spare_consumed[0]?.spares_consumed) : ''}} </td>
        <td>{{job?.spare_consumed?.length > 0 ?  job?.spare_consumed[0]?.spares_qty : ''}} </td>
        <td>{{job?.spare_consumed?.length > 0  ? getItemRob(job?.spare_consumed[0]?.spares_consumed) : ''}} </td>
        <td :rowspan="job?.spare_consumed?.length">{{job.date | moment('DD-MM-YYYY')}}</td>
        <td :rowspan="job?.spare_consumed?.length" >{{job?.maintenance_type}}</td>
        <td :rowspan="job?.spare_consumed?.length"> {{job?.done_by_rank?.name}}</td>
      </tr>
      <tr  v-for="(input,k) in job?.spare_consumed" v-show="k != 0"  :key="`${job.id}-${k}`" style="padding: 0">
        <td style="min-width: 166px" > {{getItemName(input?.spares_consumed)}} </td>
        <td style="min-width: 166px">{{input.spares_qty}}</td>
        <td style="min-width: 166px" > {{getItemRob(input?.spares_consumed)}} </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobList : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    getItemName: {
      type: Function,
      required: true
    },
    getItemRob: {
      type: Function,
      required: true
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
