<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left"> Category</h2>
          <ul class="nav navbar-right panel_toolbox align-right">
            <li>
              <router-link to="/category-tree">
                <button class="btn btn-info btn-sm">View Tree <i class="fa fa-eye"></i></button>
              </router-link>
            </li>
            <li>
              <button @click="goBack" class="btn btn-danger btn-sm float-right mt-1">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <h5 class="card-header">Category List</h5>
            <div class="card-body">
              <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Category Name</th>
                  <th>Critical Equipment Spares</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(category , index) in categorys" :key="category.id">
                  <td>{{index+1}}</td>
                  <td>{{category.name}}</td>
                  <td>{{category?.critical_equipment_spares}}</td>
                  <td>
                    <button title="Edit Category" type="button" @click="edit(category)" class="btn btn-primary btn-sm">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button title="Delete Category" type="button" @click="destroy(category)" class="btn btn-danger btn-sm">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Modal -->
          <div class="card">
            <h5 class="card-header" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add ' }} Category </h5>
            <div class="card-body">
              <div class="modal-dialog" role="document">
                <div class="modal-content modal-content2">
                  <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                    <div class="modal-body">
                      <alert-error :form="form"></alert-error>
                      <div class="form-group">
                        <label>Category Name</label>
                        <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name"></has-error>
                      </div>
                      <div class="form-group">
                        <label> Parent Name : </label>
                        <Select2 v-model="form.parent_id" :options="myOptions" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
                      </div>
                      <div class="form-group">
                        <label> Critical Equipment Spares </label>
                        <select v-model="form.critical_equipment_spares" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('critical_equipment_spares') }">
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <has-error :form="form" field="status"></has-error>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Select2 from 'v-select2-component'
    import auth from '../../../auth'

    export default {
        name: 'CategoryList',
        components: {Select2},
        data() {
            return {
                editMode: false,
                query: '',
                queryFiled: 'name',
                myOptions: [],
                categorys: [],
                form: new Form({
                    id: '',
                    name: '',
                    parent_id: '',
                    critical_equipment_spares: 'No',
                    vessel_id: auth.getVesselId()
                }),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getData()
            this.create()
        },
        methods: {
            getData() {
                this.$http.get('/api/filter/category/create/' + this.form.vessel_id).then(response => {
                    this.categorys = response.data.success
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    console.log(e)
                })
            },
            async create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                this.myOptions = []
                var itemList = await this.$http.get('/api/filter/category/create/' + this.form.vessel_id)
                this.results = itemList.data.success
                var newObj = {
                    id: '',
                    text: 'No Selected'
                }
                this.myOptions.push(newObj)
                this.results.filter(item => {
                    var newObj = {
                        id: item.id,
                        text: item.name
                    }
                    this.myOptions.push(newObj)
                })
            },
            store() {
                this.form.busy = true
                this.form.post('/api/category').then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Category Successfully Saved')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            edit(category) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.form.fill(category)
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/category/' + this.form.id).then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.create()
                        this.$snotify.success('Category Successfully Updated')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(category) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/category/' + category.id).then(response => {
                        this.getData()
                        this.$snotify.success(
                            'Category  Successfully Deleted'
                        )
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err
                        )
                    })
                }).catch(function () {

                })
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.form.parent_id = id
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
