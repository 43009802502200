<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a v-if="permitWorkMode" href="javascript:;" @click="print('printableArea')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print">Print</i></a>
        </div>
      </div>
      <div id="printableArea" class="row">
        <div class="col-md-12">
          <h4 class="text-center mt-4">Permit For Work On Pressure Vessels</h4>
        </div>

        <div class="col-md-2"></div>
        <div class="col-md-8 ">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>M/V</th>
                  <th colspan="1">
                    {{mv}}
                  </th>
                  <th>Date</th>
                  <th>
                    <DatePicker v-if="permitWorkMode==false" type="date" v-model="info.date" valueType="format"></DatePicker>

                    <span v-if="permitWorkMode">{{info.date}}</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="permitWorkMode" v-model="info.way_or_port" type="radio" name="exampleRadios" id="exampleRadios1" value="on_way">
                      <label class="form-check-label" for="exampleRadios1">
                        Under way to /
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="permitWorkMode" v-model="info.way_or_port" type="radio" name="exampleRadios" id="exampleRadios2" value="at_port">
                      <label class="form-check-label" for="exampleRadios2">
                        at Port of
                      </label>
                    </div>
                  </th>
                  <th><input type="text" :disabled="info.way_or_port=='' || permitWorkMode" v-model="info.way_or_port_value" class="form-control"></th>
                  <th><p>Voyage No.</p></th>
                  <th><input type="text" v-model="info.voyage_no" class="form-control" :disabled="permitWorkMode"></th>
                </tr>
                <tr>
                  <th><p>Description of the work</p></th>
                  <th>{{work_description}}</th>
                  <th><p>Location of the work</p></th>
                  <th>{{allInfo.component.maincomponent.name}},{{allInfo.component.maincomponent.department.name}}</th>
                </tr>
                <tr v-if="user_type==3">
                  <th>Permit Valid Form</th>
                  <th>
                    <div>
                      <!-- <datetime v-model="info.permit_valid_form"  format="YYYY-MM-DD H:i:s" width="300px"  name="permit_valid_form"></datetime> -->
                      <DatePicker :disabled="chiefMode" type="datetime" v-model="info.permit_valid_form" valueType="format"></DatePicker>
                      <has-error :form="info" field="permit_valid_form"></has-error>
                    </div>
                  </th>
                  <th>Permit Valid Up to</th>
                  <th>
                    <div>
                      <!-- <datetime  v-model="info.permit_valid_up_to"   format="YYYY-MM-DD H:i:s" name="permit_valid_up_to" width="300px" ></datetime> -->
                      <DatePicker :disabled="chiefMode" type="datetime" v-model="info.permit_valid_up_to" valueType="format"></DatePicker>
                      <has-error :form="info" field="permit_valid_up_to"></has-error>
                    </div>
                  </th>
                </tr>
                <tr v-if="user_type==4">
                  <th>Permit Valid Form</th>
                  <th>
                    <div>
                      <p v-if="mode">
                        {{info.permit_valid_form}}
                      </p>
                    </div>
                  </th>
                  <th>Permit Valid Up to</th>
                  <th>
                    <p v-if="mode"> {{info.permit_valid_up_to}}</p>
                  </th>
                </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>

        <div class="col-md-2"></div>
        <div class="col-md-8 ">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th colspan="2"><strong>SECTION 1</strong></th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th><p>1</p></th>
                  <th>
                    <div class="form-check">
                      <input v-model="info.primary_permit" :disabled="permitWorkMode" class="form-check-input" type="checkbox" value="1">
                    </div>
                  </th>
                  <th><p>All concerned informed of the work to be carried out.</p></th>
                </tr>
                <tr>
                  <th><p>2</p></th>
                  <th>
                    <div class="form-check">
                      <input v-model="info.primary_permit" :disabled="permitWorkMode" class="form-check-input" type="checkbox" value="2">
                    </div>
                  </th>
                  <th><p>Work-piece isolated from other equipment, valves shut, blanks fitted etc.</p></th>
                </tr>
                <tr>
                  <th><p>3</p></th>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="permitWorkMode" type="checkbox" v-model="info.primary_permit" value="3">
                    </div>
                  </th>
                  <th><p>Measures taken to prevent inadventent starting of associated machinery etc.</p></th>
                </tr>
                <tr>
                  <th><p>4</p></th>
                  <th>
                    <div class="form-check">
                      <input v-model="info.primary_permit" :disabled="permitWorkMode" class="form-check-input" type="checkbox" value="4">
                    </div>
                  </th>
                  <th><p>Relevant warning notices posted up conspicuously.</p></th>
                </tr>
                <tr>
                  <th><p>5</p></th>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" :disabled="permitWorkMode" v-model="info.primary_permit" type="checkbox" value="5">
                    </div>
                  </th>
                  <th><p>Drain cocks on pressurised systems opened to release pressure.</p></th>
                </tr>
                <tr>
                  <th><p>6</p></th>
                  <th>
                    <div class="form-check">
                      <input v-model="info.primary_permit" :disabled="permitWorkMode" class="form-check-input" type="checkbox" value="6">
                    </div>
                  </th>
                  <th><p>All fire precautions taken as applicable</p></th>
                </tr>
                <tr>
                  <th><p>7</p></th>
                  <th>
                    <div class="form-check">
                      <input v-model="info.primary_permit" :disabled="permitWorkMode" class="form-check-input" type="checkbox" value="7">
                    </div>
                  </th>
                  <th><p>Protective gear worn as applicable</p></th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>

        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th colspan="2"><strong>SECTION 2</strong></th>
                  <th colspan="2">ANY OTHER PERMIT REQUIRED / ISSUED</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :disabled="permitWorkMode" v-model="info.other_permit" value="1">
                    </div>
                  </th>
                  <th><p>Enclosed space entry</p></th>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :disabled="permitWorkMode" v-model="info.other_permit" value="2">
                    </div>
                  </th>
                  <th><p>Hot Work</p></th>
                </tr>
                <tr>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :disabled="permitWorkMode" v-model="info.other_permit" value="3">
                    </div>
                  </th>
                  <th><p>Electrical Isolation</p></th>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :disabled="permitWorkMode" v-model="info.other_permit" value="4">
                    </div>
                  </th>
                  <th><p>Cold Work</p></th>
                </tr>
                <tr>
                  <th>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :disabled="permitWorkMode" v-model="info.other_permit" value="5">
                    </div>
                  </th>
                  <th><p>Working Aloft</p></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th colspan="4">In the circumstanced noted of is considered safe to proceed with the work</th>
                </tr>
                <tr>
                  <th colspan="2">Responsible Person Responsibel for Job ( Name / Sign )</th>
                  <th colspan="2">
                    <input type="text" class="form-control" :disabled="permitWorkMode" v-model="info.responsible_person_name">
                  </th>
                </tr>
                <tr>
                  <th colspan="2">Issued by officer in charge ( Name / Sign )</th>
                  <th colspan="2">
                    <input type="text" class="form-control" :disabled="permitWorkMode" v-model="info.issued_person_name">
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>

        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="modal-body">
            <div class="table-responsive" v-if="user_type==3">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th colspan="2"><strong>SECTION 3</strong></th>
                  <th colspan="2"><strong>The work has been completed and all persons under my supervision, materials and equipment have been withdrawn</strong></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th><p>Authorized Officer in charge Name & Signature</p></th>
                  <th></th>
                  <th>
                    <span class="mb-3">Signature Name</span>
                    <input type="text" :disabled="chiefMode" class="form-control" v-model="info.responsible_person_completed_name" name="responsible_person_completed_name"><br>
                    <has-error :form="info" field="responsible_person_completed_name"></has-error>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="mb-3">Signature</span>
                        <input type="file" :disabled="chiefMode" class="form-control" onchange="readURL(this);" accept="image/*">
                        <input type="hidden" value="" id="itemImage">
                      </div>
                      <div class="col-md-6">
                        <span class="mb-3">Preview</span>
                        <div class="file-upload-content">
                          <img v-if="!chiefMode" class="file-upload-image" src="#" alt="your image"/>

                          <!-- this.form.image=$('#itemImage').val(); -->
                        </div>
                        <img v-if="chiefMode" :src="info.responsible_person_completed_signature" height="50" width="50"/>
                      </div>
                    </div>

                  </th>
                  <th>
                    Date Time<span> </span>
                    <DatePicker type="datetime" :disabled="chiefMode" v-model="info.completed_date" valueType="format"></DatePicker>
                  </th>
                </tr>

                </tbody>
              </table>
            </div>
            <div class="table-responsive" v-else>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th colspan="2"><strong>SECTION 3</strong></th>
                  <th colspan="2"><strong>The work has been completed and all persons under my supervision, materials and equipment have been withdrawn</strong></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th><p>Authorized Officer in charge Name & Signature</p></th>
                  <th></th>
                  <th>
                    <span class="mb-3">Signature Name</span>
                    <div class="row">
                      <div class="col-md-6">
                        <p>{{info.responsible_person_completed_name}}</p>
                        <span class="mb-3">Signature</span>
                        <img :src="info.responsible_person_completed_signature" height="50" width="50">

                      </div>
                      <div class="col-md-6">

                      </div>
                    </div>

                  </th>
                  <th>
                    Date Time :<span> </span>
                    {{info.completed_date}}
                  </th>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="pull-right">
            <button v-if="user_type==4" :disabled="permitWorkMode" type="button" class="btn btn-success" @click="store"> Submit</button>
            <button v-if="user_type==3" :disabled="chiefMode" type="button" class="btn btn-success" @click="updatePermitWork">Update</button>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import auth from '../../auth'

  export default {
    name: 'PermitToWork',
    components: { DatePicker },
    props: {
      jobInfo: Object,
      mode: Boolean,
      chiefMode: Boolean,
      Schedule: ''
    },
    data () {
      return {
        permitWorkMode: this.mode,
        id: this.jobInfo.id,
        allInfo: this.jobInfo,
        vessel_id: auth.getVesselId(),
        mv: this.jobInfo.component.name,
        work_description: this.jobInfo.name,
        info: new Form({
          id: '',
          date: '',
          way_or_port: '',
          way_or_port_value: '',
          voyage_no: Date.now() + this.getRandomInt(100000000),
          permit_valid_form: '',
          permit_valid_up_to: '',
          primary_permit: [],
          other_permit: [],
          responsible_person_name: '',
          issued_person_name: '',
          responsible_person_completed_name: '',
          responsible_person_completed_signature: '',
          completed_date: '',
          scheduler_id: this.jobInfo.job_schedules[this.Schedule].id,
        }),
        user_type: auth.getUserType()
      }

    },
    mounted () {
      this.updateModeValueSet();
    },
    methods: {
      getRandomInt (max) {
        return Math.floor(Math.random() * Math.floor(max))
      },
      //item store operation
      store () {
        this.info.busy = true
        this.info.post('/api/permit-to-work').then(response => {
            this.$snotify.success('your Permit work Successfully Saved.')
            this.$emit('enableListMode');
          }).catch(e => {
            var error = this.form.errors.errors.error
            this.$snotify.error('Sorry Item can not create successfully')
          });
      },
      //append data for view mode
      updateModeValueSet () {
        if (this.mode) {
          this.info.fill(this.allInfo.job_schedules[this.Schedule].permit_to_work)
          var primaryPermit = this.allInfo.job_schedules[this.Schedule].permit_to_work.primary_permit
          var other_permit = this.allInfo.job_schedules[this.Schedule].permit_to_work.other_permit
          this.info.primary_permit = primaryPermit.split(',')
          this.info.other_permit = other_permit.split(',')
        }
      },
      updatePermitWork () {
        this.info.busy = true;
        this.info.responsible_person_completed_signature = $('#itemImage').val();
        this.info.id = this.allInfo.job_schedules[this.Schedule].permit_to_work.id;
        this.$http.post('/api/permit-to-work-update', this.info).then(response => {
            this.$snotify.success('your Permission Successfully Sended.')
            this.$emit('enableListMode')
          }).catch(e => {
            var error = this.info.errors.errors.error
            this.$snotify.error('Please Fill Up all required Field')
          });
      },
      print (el) {
        this.$htmlToPaper(el)
      },
    }
  }
</script>

<style scoped>
  /* @import './node_modules/vue-datetime/dist/vue-datetime.css' */
</style>
