<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Supplier List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button type="button" v-if="isPermissionExist(PERMISSIONS.SUPPLIER_CREATE)" class="btn btn-info btn-sm" @click="create">Add Supplier <i class="fa fa-plus"></i></button>
              </li>
              <li>
                <button @click="print('printArea','Supplier List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30">
                    </p>
                    <TableData :supplierList="supplierList" :edit="edit" :changeStatus="changeStatus" :destroy="destroy"/>
                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <div class="font-weight-bold">Supplier List</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      </div>
                      <TableData :supplierList="supplierList" :isPrinting="true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? 'Edit' : 'Add New' }} Supplier</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
            <div class="modal-body">
              <!-- <alert-error :form="form"></alert-error> -->
              <div class="form-group">
                <label>Type</label>
                <select v-model="form.supplier_type_id" name="supplier_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('supplier_type_id') }">
                  <option v-for="row in supplierType" :key="row.id" :value="row.id">{{row.name}}</option>
                </select>
                <has-error :form="form" field="supplier_type_id"></has-error>
              </div>
              <div class="form-group">
                <label> Name</label>
                <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label> Email</label>
                <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                <has-error :form="form" field="email"></has-error>
              </div>
              <div class="form-group">
                <label> Contact Number </label>
                <input v-model="form.contact_no" type="text" name="contact_no" class="form-control" :class="{ 'is-invalid': form.errors.has('contact_no') }">
                <has-error :form="form" field="contact_no"></has-error>
              </div>
              <div class="form-group">
                <label>Address </label>
                <input v-model="form.address" type="text" name="address" class="form-control" :class="{ 'is-invalid': form.errors.has('address') }">
                <has-error :form="form" field="address"></has-error>
              </div>
              <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control form-control-sm" v-model="form.country_id">
                  <option value="" selected>Select country</option>
                  <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{row.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Verified By </label>
                <select v-model="form.approved_by" name="approved_by" class="form-control" :class="{ 'is-invalid': form.errors.has('approved_by') }">
                  <option value="">Select One</option>
                  <option value="1">Super Admin</option>
                  <option value="2">Admin</option>
                  <option value="3">Chief Engineer</option>
                  <option value="4">Second Engineer</option>
                </select>
                <has-error :form="form" field="approved_by"></has-error>
              </div>
              <div class="form-group">
                <label>ISO Certification </label>
                <input autocomplete="off" type="file" name="doc" class="form-control" v-on:change="onImageChange">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->

  </div>
</template>

<script>
    import TableData from "./TableData.vue";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import auth from "@/auth";
    import printMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, printMixin],
        name: 'Supplier',
        components: {TableData, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                permissions: auth.getPermissionData(),
                query: '',
                queryFiled: 'name',
                supplierList: [],
                supplierType: [],
                CountryData: [],
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    contact_no: '',
                    address: '',
                    approved_by: '',
                    iso_certificate: '',
                    country_id: '',
                }),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getData()
            this.getSupplierType()
            this.getCountryData()
        },
        methods: {
            onImageChange(e) {
                let file = e.target.files[0]
                let reader = new FileReader()
                reader.onloadend = (file) => {
                    this.form.iso_certificate = reader.result
                }
                reader.readAsDataURL(file)
            },


            getCountryData() {
                this.$http.get('/api/country').then(response => {
                    this.CountryData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getData() {
                this.loading = true;
                this.$http.get('/api/supplier')
                    .then(response => {
                        this.loading = false;
                        this.supplierList = response.data.success;
                        setTimeout(() => $('#datatables').DataTable(), 1000)
                    })
                    .catch(e => {
                        this.loading = false;
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        }
                    })
            },
            create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                $('#roleModal').modal('show')
            },
            store() {
                this.form.busy = true
                this.form.post('/api/supplier').then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Supplier Created Successfully')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            edit(supplier) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(supplier);
                $('#roleModal').modal('show');
            },
            update() {
                this.form.busy = true;
                this.form._method = 'put';
                this.form.post('/api/supplier/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Supplier Successfully Updated')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.'
                        )
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            changeStatus(supplier, status) {

                this.form.reset()
                this.form.clear()
                this.form.fill(supplier)
                this.form.status = status
                this.form.supplier_type_id = supplier.supplier_type_id
                this.form
                    .put('/api/supplier/' + this.form.id)
                    .then(response => {
                        this.getData()
                        if (this.form.successful) {
                            this.$snotify.success('Supplier Successfully Updated')
                        } else {
                            this.$snotify.error(
                                'Something went wrong try again later.'
                            )
                        }
                    })
                    .catch(e => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            this.$snotify.error("something went wrong")
                        }
                    })
            },
            destroy(type) {
                this.$dialog.confirm('Do You Want to delete This ?')
                    .then(() => {
                        this.$http.delete('/api/supplier/' + type.id)
                            .then(response => {
                                this.getData()
                                this.$snotify.success(
                                    'Supplier Successfully Deleted'
                                )
                            })
                            .catch(error => {
                                var err = error.response.data.error
                                this.$snotify.error(err
                                )
                            })
                    })
                    .catch(function () {

                    })

            },
            getSupplierType() {
                this.$http.get('/api/supplier-type').then(response => {
                    this.supplierType = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            }
        }
    }
</script>

<style scoped>

</style>
