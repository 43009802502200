<!-- TableComponent.vue -->
<template>

      <div class="col-sm-10 border">
        <section class="content invoice p-2"  >

          <div class="  invoice-header" style="text-align: center" >
            <h3><i class="fa fa-globe"></i> Global Maricruit</h3>
            <p><strong>795 Freedom Ave, Suite 600 Singapore</strong></p>
          </div>

          <div
            :class="{ 'row invoice-info': !isPrinting }"
            :style="{ display: isPrinting ? 'flex' : '', paddingBottom: isPrinting ? '10px' : '' }">
            <div
              :class="{ 'col-sm-4 invoice-col': !isPrinting }"
              :style="{ width: isPrinting ? '33%' : ''}">
              From
              <address>
                <strong>Global Maricruit</strong>
                <br>Singapore
                <br>Phone: 1 (804) 123-9876
                <br>Email: info@maricruit.com
              </address>
            </div>

            <div
              :class="{ 'col-sm-4 invoice-col': !isPrinting }"
              :style="{ width: isPrinting ? '33%' : ''}">
            To
              <address>
                <strong>{{purchaseData?.supplier.name}}</strong>
                <br>{{purchaseData?.supplier.address}}
                <br>{{purchaseData?.supplier.email}}
                <br>{{purchaseData?.supplier.contact_no}}
              </address>
            </div>

            <div
              :class="{ 'col-sm-4 invoice-col': !isPrinting }"
              :style="{ width: isPrinting ? '33%' : ''}">
            <b>Invoice #{{invoiceData?.invoice_no}}</b>
              <br>
              <br>
              <b>Order ID:</b> {{purchaseData?.po_no}}
              <br>
              <b>Payment Due:</b> {{ invoiceData?.date | moment('DD/MM/YYYY') }}
            </div>

          </div>

          <div class="  table">
            <table  :class="getTableClasses"  style="width:100%">
              <thead>
              <tr>
                <th>#</th>
                <th>Item name</th>
                <th>Impa code</th>
                <th>Model</th>
                <th>Maker</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>Receive Qty</th>
                <th class="text-right">Price</th>
                <th class="text-right">Subtotal</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item , index) in purchaseData?.purchase_item" :key="index">
                <td>{{++index}}</td>
                <td>{{item?.item?.name}}</td>
                <td>{{item?.item?.inpa_code}}</td>
                <td>{{item?.item?.model}}</td>
                <td>{{item?.item?.maker}}</td>
                <td>{{item?.item?.unit?.name}}</td>
                <td>{{item?.qty}}</td>
                <td>{{item?.receive_qty}}</td>
                <td class="text-right">${{item?.price}} </td>
                <td class="text-right">${{(parseFloat(item?.receive_qty) * parseFloat(item?.price))}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr>
          <div class=" ">
            <table class="table print-table text-right" style="width:100%">
              <tbody>
              <tr>
                <th>Payment Status:</th>
                <td>
                  <span v-if="invoiceData?.payment_status === 1"  >Paid</span>
                  <span v-else >Unpaid</span>
                </td>
              </tr>
              <tr>
                <th>Subtotal:</th>
                <td style="width:10%">${{purchaseData?.sub_total}}</td>
              </tr>
              <tr>
                <th>Tax ({{parseFloat(purchaseData?.tax).toFixed(2)}}%)</th>
                <td>${{purchaseData?.tax_amount}}</td>
              </tr>
              <tr>
                <th>Shipping:</th>
                <td>${{purchaseData?.shipping_cost}}</td>
              </tr>
              <tr>
                <th>Discount Amount:</th>
                <td>${{purchaseData?.discount_amount}}</td>
              </tr>
              <tr>
                <th>Total:</th>
                <td>${{purchaseData?.total_amount}}</td>
              </tr>

              <tr>
                <th class="text-danger">Invoice Amount:</th>
                <td class="text-danger fwb">${{invoiceData?.amount}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

</template>

<script>
export default {
  props: {
    purchaseData : {
      required: true
    },
    invoiceData : {
      required: true
    },

    isPrinting: {
      type: Boolean,
      default: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
