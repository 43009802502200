<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="seriaNumber =   1" >
    <tr>
      <th>#</th>
      <th>Job Name(Code)</th>
      <th>Department (Code)</th>
      <th>Component Name</th>
      <th>Completion Date</th>
      <th>Done By</th>
      <th>Verified By</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(jobData, index) in jobList" :key="jobData?.id">
      <td> {{index+1}} </td>
      <td>{{jobData?.job_schedules?.job?.name}}({{jobData?.job_schedules?.job?.code}})</td>
      <td>{{jobData?.job_schedules?.job?.department?.name}} ({{jobData?.job_schedules?.job?.department?.code}})</td>
      <td>{{jobData?.job_schedules?.job?.component?.name}} ({{jobData?.job_schedules?.job?.component?.code}})</td>
      <td>{{jobData?.date | moment('DD-MM-YYYY')}}</td>
      <td>
        {{jobData?.done_by_rank?.name}}
      </td>
      <td>
        {{jobData?.verified_by_rank?.name}}

      </td>
      <td v-if="!isPrinting">
        <button title="Job Done" type="button" v-if="jobData?.job_done_report == null" class="btn btn-info btn-sm" @click="jobDoneFunction(jobData)"><i class="fa fa-thumbs-up"></i></button>
        <button title="Job Done" type="button" v-else-if="jobData?.job_done_report  != null && job.job_schedules?.status != 'Done'" style="background-color: #69845b; color:#ffff" class="btn  btn-sm" @click="jobDoneFunction(jobData)"><i class="fa fa-thumbs-up"></i></button>
        <button title="Job Done" type="button" v-else class="btn btn-success btn-sm" @click="jobDoneFunction(jobData)"><i class="fa fa-thumbs-up"></i></button>
      </td>

    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobList : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    jobDoneFunction: {
      type: Function,
      required: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
