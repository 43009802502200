<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Department List</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right ">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                </li>
              </ul>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered  text-center table-sm" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th> Name</th>
                          <th> Code</th>
                          <th>Job Defer Approval (Only For Critical Equipment)</th>
                          <th> Status</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(department , index) in departments" :key="department.id">
                          <td>{{index+1}}</td>
                          <td>{{department?.name}}</td>
                          <td>{{department?.code}}</td>
                          <td>{{department?.defer_approval}}</td>
                          <td>{{department?.status}}</td>
                          <td>
                            <button type="button" @click="edit(department)" class="btn btn-primary btn-sm">
                              <i class="fa fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              @click="destroy(department)"
                              class="btn btn-danger btn-sm"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="customerModalLongTitle"
              >{{ editMode ? 'Edit' : 'Add New' }} Department</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <alert-error :form="form"></alert-error>
                <div class="form-group">
                  <label>Department Name</label>
                  <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label>Department Code</label>
                  <input autocomplete="off" v-model="form.code" type="text" name="code" class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                  <has-error :form="form" field="code"></has-error>
                </div>
                <div class="form-group">
                  <label>Job Defer Approval (Only For Critical Equipment) </label>
                  <select v-model="form.defer_approval" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <has-error :form="form" field="status"></has-error>
                </div>
                <div class="form-group">
                  <label>Status </label>
                  <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                    <option value="Activate">Activate</option>
                    <option value="Deactivate">Deactivate</option>
                  </select>
                  <has-error :form="form" field="status"></has-error>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->
    </div>
  </div>
</template>

<script>
    import auth from '../../auth'
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        components: {PulseLoader},
        name: 'Department',
        data() {
            return {
                loading: false,
                editMode: false,
                query: '',
                queryFiled: 'name',
                departments: [],
                form: new Form({
                    id: '',
                    name: '',
                    code: '',
                    vessel_id: auth.getVesselId(),
                    status: 'Activate',
                    defer_approval: 'No',
                }),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.loading = true
                this.$http.get('/api/filter/department/' + this.form.vessel_id).then(response => {
                    this.departments = response.data.success
                    this.loading = false
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            create() {
                this.editMode = false
                this.form.reset()
                this.form.clear()
                $('#roleModal').modal('show')
            },
            store() {
                this.form.busy = true
                this.form.post('/api/department').then(response => {
                    this.getData()
                    $('#roleModal').modal('hide')
                    if (this.form.successful) {
                        this.$snotify.success('Department Successfully Saved')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    let response = e.response
                    let status = e.response.status
                    if (status === 500) {
                        let msg = response.data.error
                        this.$snotify.error(msg)
                    }
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                    // if (status === 422){
                    //   if (response.data?.errors) {
                    //     const errors = response.data.errors;
                    //     for (const key in errors) {
                    //       this.$snotify.error(errors[key][0])
                    //     }
                    //   }
                    // }
                })
            },

            edit(department) {
                this.editMode = true
                this.form.reset()
                this.form.clear()
                this.form.fill(department)
                $('#roleModal').modal('show')
            },
            update() {
                this.form.busy = true
                this.form._method = 'put';
                this.form.post('/api/department/' + this.form.id).then(response => {
                    this.getData();
                    $('#roleModal').modal('hide');
                    if (this.form.successful) {
                        this.$snotify.success('Department Successfully Updated')
                    } else {
                        this.$snotify.error(
                            'Something went wrong try again later.',
                            'Error'
                        )
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(department) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete('/api/department/' + department.id).then(response => {
                        this.getData()
                        this.$snotify.success(
                            'Department  Successfully Deleted',
                            'Success'
                        )
                    }).catch(e => {
                        let response = e.response?.data
                        this.$snotify.error(
                            response?.error,
                            'error'
                        )
                    })
                }).catch(function () {

                })

            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
